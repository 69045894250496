import styled from "styled-components";

const PinCodeStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0,0,0,1);
  z-index: 10000;
  
  .pinCodeValues {
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
  
  .pinCodeValueItem {
    border: 2px solid white;
    border-radius: 50%;
    background-color: transparent;
    width: 20px;
    height: 20px;
    display: block;
  }
  
  .pinCodeTitle {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .pinCodeKeyboard {
    display: flex;
    flex-direction: column;
    align-items: center;  
    margin-top: 50px;
  }
  
  .pinCodeKeyboardRow {
    display: flex;
    align-items: center;
    
    > div:not(:last-child) {
      margin-right: 15px;
    }
    
    > div {
      margin-bottom: 15px;
    }
  }
  
  .pinCodeKeyboardItem {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    width: 70px;
    height: 70px;
    font-size: 21px;
    transition: .2s all;
    
    &:active {
      transition: .2s all;
      transform: scale(1.1);
    }
  }

  .pinCodeValueItemActive {
    background-color: #fff !important;
  }
`

export default PinCodeStyled