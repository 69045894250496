import React, {useEffect, useState} from 'react'
import GoogleMapReact from 'google-map-react'
import supercluster from 'points-cluster'
import {markersData} from "../fakeData";
import Marker from "../components/Marker";
import ClusterMarker from "../components/ClusterMarker";
import {useDispatch, useSelector} from "react-redux";
import {changeField, searchVehicles} from "../redux/actions";
import AppSidebar from "../components/AppSidebar";
import {Grid} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import DashboardReservations from "../components/DashboardReservations"
import FutureReservations from "../components/FutureReservations"
import VanComponent from "./VanComponent"
import GoogleMapStyled from "../styles/GoogleMapStyled"
import MapLocation from "./MapLocation"
import filterTimeFormatted from "../services/filterTimeFormatted"
import MapFilter from "./MapFilter";
import AppPopup from "./AppPopup";
import moment from "moment";
import LocationMapFilter from "./LocationMapFilter";
import MapComponentInner from "./MapComponentInner";

const MapComponent = () => {

    const lang = useSelector(state => state.lang)
    const filter = useSelector(state => state.filter)
    const [isOutOfDateActive, setIsOutOfDateActive] = useState(false)

    const dispatch = useDispatch()

    const [isReservationPopupActive, setIsReservationPopupActive] = useState(false)

    useEffect(() => {
        const timer = setInterval(() => {

            const currentTime = new Date()
            const currentTimeMoment = moment(currentTime)
            const minutes = currentTimeMoment.minutes()
            const shiftedMinutes = filter.dateStart.minutes()

            //посчитать дифф и из него брать минуты
            const diff = filter.dateStart.diff(currentTimeMoment, 'minutes')
            console.log(diff)

            if (diff <= -15) {

                setIsReservationPopupActive(true)
                dispatch(
                    changeField({
                        field: 'filter',
                        value: {
                            ...filter,
                            dateStart: filterTimeFormatted.roundTime(moment(new Date())),
                            dateEnd: filterTimeFormatted.roundTime(moment(new Date()).add(1, 'hours')),
                        }
                    })
                )

                // if (Math.abs(shiftedMinutes - minutes) >= 15) {
                //
                // }
            }

        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [filter])

    return (
        <>
            <MapLocation/>
            <MapComponentInner />
            <MapFilter/>
            <LocationMapFilter />
            <VanComponent/>
            <AppPopup
                open={isOutOfDateActive}
                title=""
                description={`${lang.vehicle_result_out_of_date}`}
                nextText={`${lang.update}`}
                handleNext={() => {
                    setIsOutOfDateActive(false)
                    dispatch(
                        changeField({
                            field: 'activeVans',
                            value: []
                        })
                    )
                }}
            />
            <AppPopup
                open={isReservationPopupActive}
                nextText={`${lang.ok}`}
                handleNext={() => {
                    setIsReservationPopupActive(false)
                }}
                description={`${lang.res_time_changed}`}
            />
        </>
    )
}

export default MapComponent