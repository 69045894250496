class UserModel {

    constructor() {
        this.dlFrontImage = ''
        this.dlBackImage = ''
        this.dlNumber = ''
        this.dlExpDate = ''
        this.dlCountry = ''
        this.dlCategories = []
        this.photo = {
            data: '',
            mime_type: ''
        }
        this.documents = {
            dl: false,
            passport: false,
            idcard: false,
            residence: false
        }
    }

    static createFromState(state) {
        const userModel = new this()

        userModel._setDriversLicense({
            frontBase64: state.documents.dlFront,
            backBase64: state.documents.dlBack,
            number: state.dlNumber,
            expDate: state.expDate,
            country: state.country,
            categories: ['B'],
        })



        userModel._setPhoto({
            data: state.selfie.replace('data:image/jpeg;base64,', ''),
            mimeType: 'image/jpeg'
        })

        userModel._setUser({
            email: state.email,
            password: state.password,
            needTempPassword: state.need24password,
            gender: 'male',
            firstName: state.firstName,
            lastName: state.lastName,
            phone: state.phone.replace(/[ \-()]/g, ''),
            selfie: state.selfie,
            birthDate: state.dateOfBirth
        })

        userModel._setLivingAddress({
            zipCode: state.zipCodeLiving,
            houseNumber: state.houseNumberLiving,
            houseNumberExtension: state.numberAdditionLiving,
            streetName: state.streetLiving,
            city: state.cityLiving,
            country: state.countryLiving
        })

        if (!state.theSameInvoice) {
            userModel._setInvoiceAddress({
                zipCode: state.zipCodeLiving,
                houseNumber: state.houseNumberLiving,
                houseNumberExtension: state.numberAdditionLiving,
                streetName: state.streetLiving,
                city: state.cityLiving,
                country: state.countryLiving
            })
        } else {
            userModel._setInvoiceAddress({
                zipCode: state.zipCodeInvoice,
                houseNumber: state.houseNumberInvoice,
                houseNumberExtension: state.numberAdditionInvoice,
                streetName: state.streetInvoice,
                city: state.cityInvoice,
                country: state.countryInvoice
            })
        }

        if (state.documents.passport) {
            userModel._setPassport({
                frontBase64: state.documents.passport,
                number: state.idcardpasNumber,
                expDate: state.idcardpasExpdate,
                country: state.idcardpasCountry
            })
        } else if (state.documents.idCardFront) {
            userModel._setIdCard({
                frontBase64: state.documents.idCardFront,
                backBase64: state.documents.idCardBack,
                number: state.idcardpasNumber,
                expDate: state.idcardpasExpdate,
                country: state.idcardpasCountry
            })
        }

        return userModel
    }

    _formatDate(date) {
        const dateParts = date.split('-')
        return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
    }

    _setIdCard({frontBase64, backBase64, number, expDate, country}) {
        this.documents.idcard = {
            front: frontBase64,
            back: backBase64,
            number: number,
            expDate: this._formatDate(expDate),
            country: country
        }
    }

    _setPhoto({data, mimeType}) {
        this.photo = {
            data,
            mime_type: mimeType
        }
    }

    _setPassport({frontBase64, number, expDate, country}) {
        this.documents.passport = {
            front: frontBase64,
            number: number,
            expDate: this._formatDate(expDate),
            country: country
        }
    }

    _setDriversLicense({frontBase64, backBase64, number, expDate, categories, country}) {
        this.documents.dl = {
            front: frontBase64,
            back: backBase64,
            number: number,
            expDate: this._formatDate(expDate),
            categories: categories,
            country: country
        }
    }

    _setUser({email, password, needTempPassword, gender, firstName, lastName, phone, selfie, birthDate}) {
        this.email = email
        this.password = password
        this.needTempPassword = needTempPassword
        this.gender = gender
        this.firstName = firstName
        this.lastName = lastName
        this.phone = phone
        this.selfie = selfie
        this.birthDate = this._formatDate(birthDate)
    }

    _setLivingAddress({zipCode, houseNumber, houseNumberExtension, streetName, city, country}) {
        this.livingAddress = {
            zipCode,
            houseNumber,
            houseNumberExtension,
            streetName,
            city,
            country
        }
    }

    _setInvoiceAddress({zipCode, houseNumber, houseNumberExtension, streetName, city, country}) {
        this.invoiceAddress = {
            zipCode,
            houseNumber,
            houseNumberExtension,
            streetName,
            city,
            country
        }
    }
}

export {UserModel}