import React, {useState} from 'react'
import styled from "styled-components"
import {Checkbox} from "@mui/material"

const FilterItemStyled = styled.div`

      display: flex;
      align-items: center;
      font-family: "Open Sans", sans-serif;
      justify-content: space-between;

      .filterTextSection {
        display: flex;
        align-items: center;
      }

      .filterName {
        font-size: 14px;
        color: black;
      }

      .filterDescription {
        font-size: 12px;
        color: #666666;
      }
    `

const FilterItem = ({icon, title, description, onClick, isActive}) => {

    return (
        <FilterItemStyled onClick={onClick}>
            <div className="filterTextSection">
                <Checkbox checked={isActive} style={{color: '#E9392C'}}/>
                <div>
                    <p className="filterName">{title}</p>
                    {
                        description ?
                            <p className="filterDescription">{description}</p>
                            :
                            ''
                    }
                </div>
            </div>
            {
                icon ?
                    <div>
                        <img style={{maxWidth: '120px', height: 'auto'}} src={icon} alt=""/>
                    </div>
                    :
                    ''
            }

        </FilterItemStyled>
    )
}

export default FilterItem
