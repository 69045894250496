import React, {useEffect, useState} from 'react'
import {FormControlLabel, Grid, Radio, TextField} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import PageTitleStyled from "../styles/PageTitleStyled";
import {useDispatch, useSelector} from "react-redux";
import {bunqRefund, changeField, showErrorMessage} from "../redux/actions";
import {useHistory} from "react-router-dom";
import PageSubtitleRegularStyled from "../styles/PageSubtitleRegularStyled";
import BankInformationPopup from "../components/BankInformationPopup";
import CustomerPreloader from "../components/CustomerPreloader";

const ReclaimBalancePage = () => {

    const lang = useSelector(state => state.lang)
    const apiToken = useSelector(state => state.apiToken)
    const [paymentType, setPaymentType] = useState('200payment')
    const [amount, setAmount] = useState()
    const [resultPayment, setResultPayment] = useState(0)
    const client = useSelector(state => state.client)
    const dispatch = useDispatch()
    const history = useHistory()
    const [isIbanNeed, setIsIbanNeed] = useState(false)

    const onClaimHandler = () => {
        dispatch(
            bunqRefund({
                apiToken,
                clientId: client.id,
                amount,
                onSuccess: (res) => {
                    dispatch(
                        showErrorMessage({
                            title: '',
                            message: lang.reclaim_1
                        })
                    )

                    history.push('/dashboard')
                }
            })
        )
    }

    useEffect(() => {
        if (client && client.bank_account && client.bank_account.iban && client.bank_account.iban.length > 0) {
            setIsIbanNeed(false)
        } else {
            setIsIbanNeed(true)
        }
    }, [client])

    if (client) {
        return (
            <div className="app-page-wrapper">
                <Grid container spacing={2} style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '96vh'
                }}>

                    <Grid item xs={12}>
                        <AppReservationHeader onBackHandler={() => {
                            history.push('/dashboard')
                        }} isBackActive={true}/>

                        <Grid item xs={12} style={{marginTop: '16px'}}>
                            <PageTitleStyled>{lang.reclaim_balance}</PageTitleStyled>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: '16px'}}>
                            <PageSubtitleRegularStyled>{lang.reclaim_2}</PageSubtitleRegularStyled>
                        </Grid>

                        <Grid item xs={12} md={6} style={{marginTop: '16px', marginLeft: 'auto', marginRight: 'auto'}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <FormControlLabel
                                        style={{
                                            color: '#666666', fontSize: '16px',
                                        }}
                                        value="customPayment"
                                        onClick={() => {
                                            setPaymentType('customPayment')
                                        }}
                                        checked={true}
                                        control={<Radio style={{color: '#E9392C'}}/>}
                                        label={`€`}/>

                                    <div style={{width: '150px'}}>
                                        <TextField
                                            name="number"
                                            type="number"
                                            value={amount}
                                            onChange={(e) => {
                                                setAmount(e.target.value)
                                            }}
                                            className="zipCode"
                                            autoComplete="off"
                                            label="Amount"
                                            placeholder="0"
                                            variant="outlined"/>
                                    </div>
                                </div>

                                <p style={{fontSize: '14px', marginLeft: '10px'}}>
                                    {lang.balance_is} <b>€
                                    {client !== false ? parseFloat(client.contract.current_balance).toFixed(2) : '-'}</b></p>
                            </div>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: '16px'}}>
                            {
                                parseFloat(client.contract.current_balance) >= parseFloat(amount) && amount.length >= 0 ?
                                    ''
                                    :
                                    <p style={{color: '#E9392C', textAlign: 'center'}}>{lang.reclaim_3}</p>
                            }
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        {
                            amount > 0 && client && parseFloat(client.contract.current_balance) >= parseFloat(amount)  ?
                                <div onClick={onClaimHandler} className="start-reservation-button">
                                    {lang.claim} € {amount}
                                </div>
                                :
                                <div style={{backgroundColor: 'gray'}} className="start-reservation-button">
                                    {lang.claim}
                                </div>
                        }
                    </Grid>

                </Grid>

                <BankInformationPopup handleClose={() => {setIsIbanNeed(false)}} isActive={isIbanNeed} />

            </div>
        )
    } else {
        return (
            <div>
                <CustomerPreloader />
            </div>
        )
    }
}

export default ReclaimBalancePage