import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import ReservationHistoryStatus from "./ReservationHistoryStatus";
import TableStyled from "../styles/TableStyled";
import filterTimeFormatted from "../services/filterTimeFormatted";
import {useSelector} from "react-redux";

const ReservationHistoryStyled = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 160px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  border-left: 2px solid ${props => props.bColor};
`

const ReservationHistoryItem = ({reservation}) => {

    const lang = useSelector(state => state.lang)

    const [reservationColor, setReservationColor] = useState('#E9392C')

    useEffect(() => {
        if (reservation.status == 'expired' || reservation.status == 'cancelled') {
            setReservationColor('#E9392C')
        } else {
            setReservationColor('#7EE96D')
        }
    }, [reservation])

    const timeAgo = () => {
        const time = filterTimeFormatted.getTotalTime(new Date(),
            reservation.planned_start_date
        )

        if (time.days < 0 || time.hours < 0 || time.minutes < 0) {
            if (time.days) {
                return `${lang.in} ${Math.abs( time.days ) } ${lang.days}`
            }

            if (time.hours) {
                return `${lang.in} ${ Math.abs( time.hours) } ${lang.hours}`
            }

            return `${lang.in} ${ Math.abs( time.minutes) } ${lang.minutes}`
        } else {
            if (time.days) {
                return `${time.days} ${lang.days} ago`
            }

            if (time.hours) {
                return `${time.hours} ${lang.hours} ago`
            }

            return `${time.minutes} ${lang.minutes} ago`
        }
    }

    return (
        <ReservationHistoryStyled
            bColor={reservationColor}
        >
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <p style={{fontWeight: 'bold', fontSize: '16px'}}>{lang.reservation} #{reservation.id}</p>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <ReservationHistoryStatus status={reservation.status} />
                    <p style={{fontSize: '12px'}}>{timeAgo()}</p>
                </div>
            </div>

            <TableStyled>
                <table>
                    <tr>
                        <td>{lang.license_plate}:</td>
                        <td>{reservation.vehicle.license_plate}</td>
                    </tr>
                    <tr>
                        <td>{lang.location}: </td>
                        <td>{reservation.return_branch.address}</td>
                    </tr>
                    <tr>
                        <td>{lang.pick_up_date}: </td>
                        <td>{ filterTimeFormatted.fromPhpToMomentJsFormat(reservation.planned_start_date)}</td>
                    </tr>
                    <tr>
                        <td>{lang.return_date}:</td>
                        <td>{ filterTimeFormatted.fromPhpToMomentJsFormat( reservation.planned_end_date )}</td>
                    </tr>
                </table>
            </TableStyled>

        </ReservationHistoryStyled>
    )
}

export default ReservationHistoryItem