import React, {useEffect, useState} from 'react'
import {Grid} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import PageTitleStyled from "../styles/PageTitleStyled";
import PageSubtitleRegularStyled from "../styles/PageSubtitleRegularStyled";
import TransactionHistoryItem from "../components/TransactionHistoryItem";
import {useDispatch, useSelector} from "react-redux";
import {getTransactionsList} from "../redux/actions";
import {Link} from "react-router-dom";

const TransactionHistoryPage = () => {

    const lang = useSelector(state => state.lang)
    const dispatch = useDispatch()
    const apiToken = useSelector(state => state.apiToken)
    const client = useSelector(state => state.client)

    const [transactions, setTransactions] = useState([])

    useEffect(() => {

        if (apiToken && client) {
            dispatch(
                getTransactionsList({
                    apiToken,
                    clientId: client.id,
                    onSuccess: (res) => {
                        setTransactions(res)
                    }
                })
            )
        }

    }, [apiToken, client])

    return (
        <div className="app-page-wrapper">
            <Grid container spacing={2}>
                <AppReservationHeader isBackActive={true}/>

                <Grid item xs={12}>
                    <PageTitleStyled>{lang.tr_history}</PageTitleStyled>
                    <PageSubtitleRegularStyled>{lang.last_10_tr}</PageSubtitleRegularStyled>
                </Grid>

                {
                    transactions.length === 0 ?
                        <Grid item xs={12}>
                            <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                                <p style={{fontFamily: 'Open Sans', textAlign: 'center'}}>
                                    {lang.tr_list_empty}
                                </p>
                            </Grid>
                        </Grid>
                        :
                        transactions.map((item) => {
                            return (
                                <Grid key={item.id} item xs={12}>
                                    <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                                        <TransactionHistoryItem transaction={item}/>
                                    </Grid>
                                </Grid>
                            )
                        })
                }

                {
                    transactions.length >= 10 ?
                        <Grid item xs={12}>
                            <a
                                style={{
                                    color: '#E9392C',
                                    fontSize: '13px',
                                    fontFamily: 'Open Sans',
                                    textAlign: 'center',
                                    display: 'block'
                                }}
                                target="_blank" href="https://mobielrijden.nl/9-kav-virtueel/">{lang.see_whole_tr_history}</a>
                        </Grid>
                        :
                        ''
                }

            </Grid>
        </div>
    )
}

export default TransactionHistoryPage