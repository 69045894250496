import React, {useEffect, useRef, useState} from 'react'
import * as tf from '@tensorflow/tfjs'
import * as automl from '@tensorflow/tfjs-automl'
import {useDispatch, useSelector} from "react-redux";
import {
    preloaderStart,
    preloaderStop,
    putPassportPhotoToResult,
} from "../redux/actions";
import config from "../config";
import {Button} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ReactGA from "react-ga";

const PassportPhotoPage = () => {

    const dispatch = useDispatch()
    const videoRef = useRef(false)
    const preProcessCanvas = useRef(false)
    const resCanvasRef = useRef(false)
    const [isPhotoDone, setIsPhotoDone] = useState(false)

    const [isLoaded, setIsLoaded] = useState(false)
    const [needHelp, setNeedHelp] = useState(false)
    const [innerStream, setInnerStream] = useState({})

    const handleOnSuccessImage = (base64Image) => {

        dispatch(putPassportPhotoToResult({
            imageBase64: base64Image
        }))
    }

    const makePhotoHandler = () => {
        const resWidth = videoRef.current.offsetWidth
        const resHeight = videoRef.current.offsetHeight

        resCanvasRef.current.width = resWidth
        resCanvasRef.current.height = resHeight

        resCanvasRef.current.getContext('2d').drawImage(videoRef.current, 0, 0, resWidth, resHeight)

        const resBaseImage = resCanvasRef.current.toDataURL('image/jpeg', 1)

        innerStream.getTracks()
            .forEach((track) => track.stop())

        handleOnSuccessImage(resBaseImage)
    }

    useEffect(() => {

        dispatch(preloaderStart())

        var count = 0
        var isLoadedInner = false

        async function run() {

            const constraintsEnv = {
                audio: false,
                video: {
                    width: 800,
                    height: 800,
                    facingMode: {
                        exact: "environment"
                    }
                }
            }

            const constraintsDefault = {
                audio: false,
                video: {
                    width: 800,
                    height: 800,
                }
            }

            let stream = {}

            try {
                stream = await navigator.mediaDevices.getUserMedia(constraintsEnv)
            } catch (e) {
                stream = await navigator.mediaDevices.getUserMedia(constraintsDefault)
            }

            setInnerStream(stream)

            videoRef.current.srcObject = stream

            const model = await automl.loadObjectDetection('/model/dl2/model.json')

            let images = []
            let start = true
            var prevDoc = false

            function stopInverval() {
                start = false
                console.log('stop')
            }

            function drawPreCanvas() {
                preProcessCanvas.current.width = 800
                preProcessCanvas.current.height = 800

                preProcessCanvas.current.getContext('2d').drawImage(videoRef.current, 0, 0)
            }

            var intervalHandler = setInterval(async () => {

                let isOk = 0
                let currentDoc = false

                drawPreCanvas()

                const options = {
                    score: 0.3,
                    iou: 0.2,
                    topk: 10
                }

                const predictions = await model.detect(preProcessCanvas.current, options);

                dispatch(preloaderStop())

                if (isLoaded === false) {
                    setIsLoaded(true)
                }

                if (isLoadedInner === false) {
                    isLoadedInner = true
                }

                predictions.forEach(function (item) {

                    const fieldLabel = item.label.replace(/(\r\n|\n|\r)/gm, "")

                    if (fieldLabel === 'document') {
                        currentDoc = item
                        isOk += 20
                    } else {
                        isOk += 1
                    }

                })

                console.log(isOk)

                if (isOk >= config.mlThreshold) {
                    if (currentDoc !== false) {
                        if (prevDoc !== false) {
                            if (currentDoc.box.width > 300) {
                                if (Math.abs(prevDoc.box.left - currentDoc.box.left) < 10) {
                                    if (Math.abs(prevDoc.box.top - currentDoc.box.top) < 10) {

                                        const resCanvas = resCanvasRef.current
                                        resCanvas.width = currentDoc.box.width
                                        resCanvas.height = currentDoc.box.height

                                        const resCtx = resCanvas.getContext('2d')

                                        const currentImage = preProcessCanvas.current.getContext('2d').getImageData(
                                            Math.round(currentDoc.box.left),
                                            Math.round(currentDoc.box.top),
                                            Math.round(currentDoc.box.width),
                                            Math.round(currentDoc.box.height)
                                        )

                                        resCtx.putImageData(currentImage, 0, 0)

                                        const base64ResImage = resCanvas.toDataURL('image/jpeg', 1)

                                        stream.getTracks()
                                            .forEach((track) => track.stop())

                                        handleOnSuccessImage(base64ResImage)

                                        stopInverval()
                                        clearInterval(intervalHandler)
                                    }
                                }
                            }
                        }
                        prevDoc = currentDoc
                    }
                }

                if (isLoadedInner) {
                    count += 700

                    if (count > config.timeoutToManualPhoto) {
                        clearInterval(intervalHandler)
                        setNeedHelp(true)
                    }
                }

            }, 700)

            return () => clearInterval(intervalHandler)
        }

        run()

    }, [])

    const lang = useSelector(state => state.lang)

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'Passport photo page'
        });
    }, [])

    return (
        <div>
            <canvas style={{display: 'none'}} ref={preProcessCanvas}/>
            <div className="make-photo-wrapper">
                <div className="make-photo-title">{lang.camera_text_1}</div>
                <video style={{width: '100%', height: 'auto', display: isPhotoDone? 'none': 'block'}}
                       ref={videoRef} playsInline autoPlay/>
                <div className="dl-placeholder">
                    {
                        needHelp ?
                            <>
                                <Button
                                    onClick={makePhotoHandler}
                                    className="make-photo-btn"
                                    variant="outlined"> <PhotoCameraIcon/> </Button>

                            </>
                            :
                            ''
                    }
                </div>

                {
                    needHelp ?

                        <div className="make-photo-description">
                            {lang.camera_text_2}
                        </div>
                        :
                        <div className="make-photo-description">
                            {lang.photo_auto}
                        </div>
                }
            </div>

            <canvas style={{display: isPhotoDone ? 'block': 'none'}} ref={resCanvasRef}/>
        </div>
    )
}

export default PassportPhotoPage
