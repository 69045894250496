import React, {useEffect} from 'react'
import PageManager from "../components/PageManager";
import AppLoginPopup from "../components/AppLoginPopup";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const HomePage = () => {

    const apiToken = useSelector(state => state.apiToken)
    const history = useHistory()

    if (apiToken.length > 0) {
        history.push('/dashboard')
    }

    useEffect(() => {
        window.onbeforeunload = function(){
            return "Do you want to leave?"
        }
    }, [])

    return (
        <>
            <AppLoginPopup />
            <PageManager/>
        </>
    )
}

export default HomePage
