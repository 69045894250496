import moment from "moment";
import filterTimeFormatted from "./filterTimeFormatted";

const userDocumentValidator = {
    validateExpirationDocuments: (documents) => {

        const validDocumentTypes = ['drivers_license', 'passport', 'id_card']

        const now = moment(new Date())
        let validationResult = true

        documents.forEach((document) => {
            if (validDocumentTypes.includes(document.type)) {
                const documentExp = moment(document.expiration_date)
                const diffDate = filterTimeFormatted.getTimeDiff(now, documentExp)

                if (diffDate.days <= 0) {
                    validationResult = document.type
                }
            }
        })

        return validationResult
    }
}

export default userDocumentValidator