import React, {useEffect, useState} from 'react'
import {Grid} from "@mui/material"

import AppHeader from "../components/AppHeader"
import logoIcon from '../assets/logo.jpg'
import gpIcon from '../assets/google_icon.jpg'
import aIcon from '../assets/apple_icon.jpg'
import {useSelector} from "react-redux"

import lastAnimation from '../assets/last_anima_min.mp4'
import ReactGA from "react-ga";

const DonePage = () => {

    const lang = useSelector(state => state.lang)

    const [isAnimationEnd, setIsAnimationEnd] = useState(false)

    const onLoadVideo = () => {
        var timeoutHandle = setTimeout(() => {
            setIsAnimationEnd(true)
            clearTimeout(timeoutHandle)
        }, 6400)
    }

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: true
        })

        ReactGA.event({
            category: 'User',
            action: 'End registration'
        });
    }, [])

    return (
        <Grid container spacing={2}
              style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4px'}}>
            <AppHeader/>

            <Grid container item spacing={2} xs={11}>
                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} item xs={12}>
                    <Grid style={{marginTop: '10px'}} container item spacing={2} xs={12}>
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <img src={logoIcon} alt=""/>
                        </Grid>
                        {
                            !isAnimationEnd ?
                                <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12}>
                                    <video loop={false}

                                           onLoadedData={onLoadVideo}
                                           className="document-video"
                                           autoPlay={true} muted={true} playsInline src={lastAnimation}/>
                                </Grid>
                                :
                                ''
                        }

                        {
                            isAnimationEnd ?
                                <>
                                    <Grid item xs={12}>
                                        <p className="done-page-title">{lang.welcome}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className="done-page-text">{lang.rent_a_bus}<br/>
                                            {lang.get_the_app}!</p>
                                    </Grid>
                                </>
                                :
                                ''
                        }

                        <Grid className="done-icon-wrapper" item xs={12}
                              style={{display: 'flex', justifyContent: 'center'}}>
                            <a target="_blank"
                               className="done-icon"
                               href="https://play.google.com/store/apps/details?id=nl.deft.kav.kav">
                                <img src={gpIcon} alt=""/>
                            </a>
                        </Grid>
                        <Grid className="done-icon-wrapper" item xs={12}
                              style={{display: 'flex', justifyContent: 'center'}}>
                            <a target="_blank"
                               className="done-icon"
                               href="https://apps.apple.com/nl/app/kav-autoverhuur/id1089515042">
                                <img src={aIcon} alt=""/>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DonePage