import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import styled from "styled-components";

const CloseIconWrapperStyle = styled.div`
    
`

const CloseIconWrapper = () => {
    return (
        <CloseIconWrapperStyle>
            <CloseIcon />
        </CloseIconWrapperStyle>
    )
}

export default CloseIconWrapper