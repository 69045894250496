import React, {useEffect, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, Grid, TextField} from "@mui/material";
import DatePicker from "./DatePicker";
import TimePicker from "./TimePicker";
import OutlinedButton from "../styles/OutlinedButton";
import filterTimeFormatted from "../services/filterTimeFormatted";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {editReservation} from "../redux/actions";

const ExtendReservationPopup = ({reservationId, isActive, handleClose, timeFrom, timeTo, onUpdate}) => {

    const lang = useSelector(state => state.lang)
    const dispatch = useDispatch()
    const apiToken = useSelector(state => state.apiToken)
    const [newDate, setNewDate] = useState(moment(timeTo))
    const [newTime, setNewTime] = useState(moment(timeTo))
    const [diff, setDiff] = useState(0)

    useEffect(() => {
        setNewDate(moment(timeTo))
    }, [timeTo])

    const onPlusHandler = () => {
        setDiff(diff + 30)

        const tempNewTime = moment(newDate)
        tempNewTime.add(30, 'minutes')

        setNewDate(tempNewTime)
        setNewTime(tempNewTime)
    }

    const onMinusHandler = () => {
        setDiff(diff - 30)

        const tempNewTime = moment(newDate)
        tempNewTime.add(-30, 'minutes')

        setNewDate(tempNewTime)
        setNewTime(tempNewTime)
    }

    const onExtendHandler = () => {
        dispatch(
            editReservation({
                apiToken,
                reservationId,
                status: false,
                endDate: filterTimeFormatted.apiFormat(newDate, newTime),
                onSuccess: () => {
                    onClose()
                    onUpdate()
                }
            })
        )
    }

    const onClose = () => {
        setDiff(0)
        handleClose()
    }

    return (
        <div>
            <Dialog
                open={isActive}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid style={{width: '100%'}} item md={12}>
                            <p style={{textAlign: 'center', fontWeight: '600', fontSize: '18px', color: '#E9392C'}}>
                                {lang.extend_reservation}
                            </p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center',
                    justifyContent: 'space-around'
                }}>

                    <Grid container spacing={2}>
                        <Grid item container xs={12}>
                            <Grid item xs={2}>
                                {lang.from}
                            </Grid>
                            <Grid item xs={10}>
                                {filterTimeFormatted.fromPhpToMomentJsFormat(timeFrom)}
                            </Grid>
                        </Grid>

                        <Grid style={{display: 'flex', alignItems: 'center'}} item container xs={12} spacing={2}>
                            <Grid item xs={2}>
                                {lang.to}
                            </Grid>
                            <Grid item xs={10}>
                                {filterTimeFormatted.fromPhpToMomentJsFormat(newDate)}
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={6} style={{display: 'flex', justifyContent: 'center'}}>
                                {
                                    diff > 0 ?
                                        <OutlinedButton onClick={onMinusHandler} style={{color: '#E9392C'}}>
                                            -30 {lang.minutes}
                                        </OutlinedButton>
                                        :
                                        <OutlinedButton style={{opacity: '0.4', color: '#E9392C'}}>
                                            -30 {lang.minutes}
                                        </OutlinedButton>
                                }

                            </Grid>

                            <Grid item xs={6} style={{display: 'flex', justifyContent: 'center'}}>
                                <OutlinedButton onClick={onPlusHandler} style={{color: '#E9392C'}}>
                                    +30 {lang.minutes}
                                </OutlinedButton>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            {
                                diff > 0 ?
                                    <p style={{fontFamily: 'Open Sans', textAlign: 'center'}}>{lang.extend_text_1} {diff} {lang.minutes}</p>
                                    :
                                    ''
                            }
                        </Grid>

                    </Grid>

                    <Button
                        onClick={onExtendHandler}
                        className="main-button main-button-register popup-action-button-margin"
                        disabled={!diff > 0}
                        style={{width: '200px', fontWeight: '600', marginTop: '15px'}}
                        variant="contained">{lang.extend_reservation}</Button>

                    <Button
                        style={{textTransform: 'none'}}
                        variant="contained"
                        onClick={onClose}
                        className="simple-button"
                        color="primary">{lang.cancel}</Button>

                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ExtendReservationPopup