import React, {useEffect, useMemo, useState} from 'react'
import {Button, Checkbox, FormControlLabel, Grid, TextField} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {changeField, changePage, experianValidateDL} from "../redux/actions"
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import AppHeader from "../components/AppHeader"
import countryList from 'react-select-country-list'
import validator from '../services/validator'
import AppPopup from "../components/AppPopup";
import AppPopupErrors from "../components/AppPopupErrors";
import config from "../config";
import {findError} from "../services/findError";
import AppInputMaskedDate from "../components/AppInputMaskedDate";
import MenuItem from "@mui/material/MenuItem";
import ReactGA from "react-ga";

const DocumentDataPage = () => {

    const lang = useSelector(state => state.lang)

    const countries = useMemo(() => countryList().getData(), [])

    const [isAlertShow, setIsAlertShow] = useState(false)

    const dispatch = useDispatch()

    const country = useSelector(state => state.country)
    const firstName = useSelector(state => state.firstName)
    const lastName = useSelector(state => state.lastName)
    const dlNumber = useSelector(state => state.dlNumber)
    const expDate = useSelector(state => state.expDate)
    const dateOfBirth = useSelector(state => state.dateOfBirth)
    const isCategoryB = useSelector(state => state.categoryB)

    const documents = useSelector(state => state.documents)

    const [errors, setErrors] = useState([])
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    const [isDocumentProblem, setIsDocumentProblem] = useState(false)
    const [isApproveOpen, setIsApproveOpen] = useState(false)
    const [isOpenForNotDutch, setIsOpenForNotDutch] = useState(false)

    const handleNextStep = (e) => {

        const isDisabled = e.target.getAttribute('data-disabled')

        if (isDisabled === 'true') {
            const incorrectFields = validator.documentVerification([
                {type: 'firstName', value: firstName, message: lang.name},
                {type: 'lastName', value: lastName, message: lang.surname},
                {type: 'dlNumber', value: dlNumber, message: lang.drivers_license_number},
                {type: 'expDate', value: expDate, message: lang.dl_exp_date},
                {type: 'dateOfBirth', value: dateOfBirth, message: lang.date_of_birth},
                {type: 'isCategoryB', value: isCategoryB, message: lang.category_b},
            ])

            if (incorrectFields.length > 0) {
                setIsAlertShow(true)
                setErrors(incorrectFields)
            }
        } else {

            if (country.toLowerCase() !== 'nl') {
                setIsApproveOpen(true)
                return
            }

            if (config.build !== 'development') {
                dispatch(experianValidateDL({
                    dlNumber,
                    dateOfBirth,
                    cb: (res) => {

                        if (res.result.valid === false) {
                            setIsDocumentProblem(true)
                        } else {
                            //GO TO SELFIE

                            setIsApproveOpen(true)
                        }
                    },
                    errCb: (err) => {
                        setIsDocumentProblem(true)
                    }
                }))
            } else {
                //GO TO SELFIE
                setIsApproveOpen(true)
            }
        }
    }

    const handleChangeCountry = (e) => {

        dispatch(
            changeField({
                field: e.target.name,
                value: e.target.value
            })
        )
    }

    const handleChecked = (e) => {
        dispatch(
            changeField({
                field: e.target.name,
                value: !isCategoryB
            })
        )
    }

    const handleAlertClose = () => {
        setIsAlertShow(false)
    }

    const handleDocumentClose = () => {
        setIsDocumentProblem(false)
    }

    const handleApproveClose = () => {
        setIsApproveOpen(false)
    }

    const goToNextHandler = () => {

        if (country.toLowerCase() !== 'nl') {
            setIsOpenForNotDutch(true)
        } else {
            dispatch(changePage({pageName: 'selfie-page'}))
        }
    }

    const notDutchNextHandler = () => {
        dispatch(changePage({pageName: 'passport-idcard-page'}))
    }

    useEffect(() => {
        const incorrectFields = validator.documentVerification([
            {type: 'firstName', value: firstName, message: lang.name},
            {type: 'lastName', value: lastName, message: lang.surname},
            {type: 'dlNumber', value: dlNumber, message: lang.drivers_license_number},
            {type: 'expDate', value: expDate, message: lang.dl_exp_date},
            {type: 'dateOfBirth', value: dateOfBirth, message: lang.date_of_birth},
            {type: 'isCategoryB', value: isCategoryB, message: lang.category_b},
        ])

        if (incorrectFields.length === 0) {
            setIsButtonDisabled(false)
        } else {
            setIsButtonDisabled(true)
        }

        setErrors(incorrectFields)

    }, [firstName, lastName, dlNumber, isCategoryB, dateOfBirth, expDate])

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'Document Data Page'
        });
    }, [])

    return (
        <Grid container spacing={2}
              style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4px'}}>
            <AppHeader/>

            <AppPopup title={lang.not_dutch_dl}
                      open={isOpenForNotDutch}
                      nextText="Ok"
                      handleNext={notDutchNextHandler}
            />

            <AppPopup title={lang.is_info_correct}
                      open={isApproveOpen}
                      handleClose={handleApproveClose}
                      description=""
                      nextText={lang.yes}
                      closeText={lang.no}
                      handleNext={goToNextHandler}
            />

            <AppPopup title={lang.dl_problem}
                      open={isDocumentProblem}
                      closeText={lang.cancel}
                      handleClose={handleDocumentClose}
                      description={lang.please_call_us}
            />

            <AppPopupErrors title={lang.you_did_not_provide}
                            open={isAlertShow}
                            closeText={lang.cancel}
                            handleClose={handleAlertClose}
                            errors={errors}
            />
            <Grid className="containerAlignBottom" container item spacing={2} xs={11}>
                <Grid item xs={12}>
                    <h1 className="secondH1Title">{lang.document_verification}</h1>

                    <Grid container item xs={12}>
                        <Grid item md={4} xs={12}>
                            <div className="documents-list">
                                <div
                                    className={`document-item ${documents.dlFront && documents.dlBack ? 'document-item-success' : ''}`}>
                                    <div className="document-item-inner">
                                        <CameraAltIcon/>
                                    </div>
                                    <p>{lang.drivers_license}</p>
                                </div>
                                <div className="document-item">
                                    <div className="document-item-inner">
                                        <CameraAltIcon/>
                                    </div>
                                    <p>{lang.selfie}</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid style={{marginTop: '10px'}} container item spacing={2} md={7} xs={12}>
                            <Grid item xs={12}>
                                <TextField
                                    name="firstName"
                                    value={firstName}
                                    error={findError('firstName', errors)}
                                    onChange={handleChangeCountry}
                                    style={{width: '100%'}}
                                    label={lang.name}
                                    variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="lastName"
                                    error={findError('lastName', errors)}
                                    value={lastName}
                                    onChange={handleChangeCountry}
                                    style={{width: '100%'}}
                                    label={lang.surname}
                                    variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    style={{width: '100%'}}
                                    name="country"
                                    select
                                    label={lang.country_of_issue}
                                    value={country}
                                    onChange={handleChangeCountry}
                                >
                                    {countries.map((item) => {
                                        return (
                                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                        )
                                    })}
                                </TextField>
                            </Grid>

                            <Grid item xs={7}>
                                <TextField
                                    style={{width: '100%'}}
                                    name="dlNumber"
                                    error={findError('dlNumber', errors)}
                                    onChange={handleChangeCountry}
                                    value={dlNumber}
                                    label={lang.drivers_license_number}
                                    variant="outlined"/>
                            </Grid>

                            <Grid item xs={5}>

                                <AppInputMaskedDate
                                    value={expDate}
                                    label={lang.expiration_date}
                                    errors={errors}
                                    onChangeHandler={handleChangeCountry}
                                    name="expDate"
                                />

                            </Grid>

                            <Grid item xs={7}>

                                <AppInputMaskedDate
                                    value={dateOfBirth}
                                    label={lang.date_of_birth}
                                    errors={errors}
                                    onChangeHandler={handleChangeCountry}
                                    name="dateOfBirth"
                                />

                            </Grid>

                            <Grid item xs={5}>
                                <FormControlLabel className="category-checkbox"
                                                  control={<Checkbox
                                                      name="categoryB"
                                                      checked={isCategoryB}
                                                      onChange={handleChecked}/>}
                                                  label={`${lang.category_b}`}/>
                            </Grid>

                            <Grid className="hide-mobile"
                                  style={{display: 'flex', justifyContent: 'flex-end'}}
                                  item xs={12}>
                                <Button
                                    className="main-button main-button-register hide-mobile"
                                    style={{marginBottom: '20px'}}
                                    data-disabled={isButtonDisabled}
                                    onClick={handleNextStep}
                                    variant="contained">{lang.continue}</Button>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} item xs={12}>
                    <Button
                        className="main-button main-button-register hide-desktop"
                        style={{marginBottom: '20px'}}
                        data-disabled={isButtonDisabled}
                        onClick={handleNextStep}
                        variant="contained">{lang.continue}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DocumentDataPage