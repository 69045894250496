import React from 'react'
import {Button} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDispatch, useSelector} from "react-redux";
import {changeField, changePage, uploadDLBackPage} from "../redux/actions";
import {useHistory, useParams} from "react-router-dom";

const PhotoResultPage = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const currentImage = useSelector(state => state.tempPhoto)
    const docPhoto = useSelector(state => state.docPhoto)
    const {type, side} = useParams()

    console.log(side)

    const onConfirmHandler = () => {
        dispatch(
            changeField({
                field: 'docPhoto',
                value: {
                    ...docPhoto,
                    type: type,
                    isActive: true,
                    [side]: currentImage
                }
            })
        )

        history.push('/profile/add-document')
    }

    const onDeleteHandler = () => {
        dispatch(
            changeField({
                field: 'tempPhoto',
                value: false
            })
        )

        dispatch(
            changeField({
                field: 'docPhoto',
                value: {
                    ...docPhoto,
                    type: type,
                    isActive: true,
                    [side]: false
                }
            })
        )

        history.push(`/make-photo/${type}/${side}`)
    }

    const lang = useSelector(state => state.lang)

    return (
        <div className="image-result-wrapper">
            <div className="image-result-wrapper-inner">
                <img src={currentImage} alt=""/>
                <Button style={{backgroundColor: '#7EE96D', borderRadius: '30px', color: '#ffffff', marginTop: '30px'}}
                        onClick={onConfirmHandler}
                        variant="contained">{lang.confirm} <CheckIcon /> </Button>
                <Button style={{backgroundColor: '#ffffff', border: '1px solid #F56E64', borderRadius: '30px',
                    marginTop: '30px', color: '#F56E64', width: '90px'}}
                        onClick={onDeleteHandler}
                        variant="outlined"> <DeleteIcon /> </Button>
            </div>

        </div>
    )
}

export default PhotoResultPage