import React, {memo, useEffect, useRef, useState} from 'react'
import AppSidebar from "../components/AppSidebar"
import {Grid} from "@mui/material"
import AppReservationHeader from "../components/AppReservationHeader"
import PageTitleStyled from "../styles/PageTitleStyled"
import PageSubtitleStyled from "../styles/PageSubtitleStyled"
import DatePicker from "../components/DatePicker"
import TimePicker from "../components/TimePicker"
import InputField from "../components/InputField"
import locationIcon from "../assets/location2Icon.png"
import locationFilterIcon from "../assets/locationFilter.png"
import filterIcon from "../assets/filterIcon.png"
import ReservationFilter from "../components/ReservationFilter"
import LocationFilter from "../components/LocationFilter"
import PrimaryButton from "../styles/PrimaryButton"
import {useHistory} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {actionCalculateBookingPrice, changeField, getFullReservationList, showErrorMessage} from "../redux/actions"
import moment from "moment"
import filterTimeFormatted from "../services/filterTimeFormatted"
import config from "../config"
import AppPopup from "../components/AppPopup"
import Geolocation from "../components/Geolocation"
import Geocode from "react-geocode"
import Cookies from "universal-cookie";
import {isInteger} from "lodash";
import BottomNotification from "../components/BottomNotification";
import GeolocationCheck from "../components/GeolocationCheck";
import AppPopupV2 from "../components/AppPopupV2";
import {useCookies} from "react-cookie";
import TableStyled from "../styles/TableStyled";
import TableStyledInner from "../styles/TableStyledInner";
import OffsetTimePickerManage from "../components/OffsetTimePickerManage";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SelectField from "../components/SelectField";

const ManagerReservation = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['geo_available', 'geo_was_shown'])
    const apiToken = useSelector(state => state.apiToken)
    const client = useSelector(state => state.client)
    const history = useHistory()
    const dispatch = useDispatch()
    const [isReservationPopupActive, setIsReservationPopupActive] = useState(false)
    const [isTimePopupActive, setIsTimePopup] = useState(false)
    const [validation, setValidation] = useState({
        timeOK: true,
        location: true,
    })


    const [isDL, setIsDL] = useState(true)
    const [isGeoRequestPopupActive, setIsGeoRequestPopupActive] = useState(false)
    const [isDepositInfoActive, setIsDepositInfoActive] = useState(false)
    const [isCallCenterPopupActive, setIsCallCenterPopupActive] = useState(false)
    const [isCallCenterWasPressed, setIsCallCenterWasPressed] = useState(false)
    const [depositPrice, setDepositPrice] = useState(200)

    useEffect(() => {
        if (!navigator.geolocation) {
            console.log('here')
            setIsGeoRequestPopupActive(true)
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                const {latitude, longitude} = position.coords
                setIsGeoRequestPopupActive(false)
                console.log('coords')
            }, (err) => {
                console.log('error')
                setIsGeoRequestPopupActive(true)
            })
        }
    }, [])

    const lang = useSelector(state => state.lang)

    const locationInfo = useSelector(state => state.locationInfo)

    const onContinueClick = () => {

        const totalTime = filterTimeFormatted.getTotalTime(
            filterTimeFormatted.apiFormat(filter.dateEnd),
            filterTimeFormatted.apiFormat(filter.dateStart)
        )

        if (totalTime.days >= 1 && (totalTime.hours > 0 || totalTime.minutes > 0)) {
            if (isCallCenterWasPressed === true) {
                history.push('/choose-van')
            } else {
                setIsCallCenterPopupActive(true)
                setIsCallCenterWasPressed(true)
            }
        } else {
            history.push('/choose-van')
        }
    }

    const filter = useSelector(state => state.filter)

    useEffect(() => {

        console.log('time was set : ' + filter.timeWasSet)

        if (filter.timeWasSet === false) {
            dispatch(
                changeField({
                    field: 'filter',
                    value: {
                        ...filter,
                        dateStart: filterTimeFormatted.roundTime(moment(new Date())),
                        dateEnd: filterTimeFormatted.roundTime(moment(new Date()).add(1, 'hours')),
                        timeWasSet: true
                    }
                })
            )
        }

    }, [filter.timeWasSet])

    const filterChange = (name, value) => {
        dispatch(changeField({
            field: 'filter',
            value: {
                ...filter,
                [name]: moment(value)
            }
        }))
    }

    const inputChange = (name, value) => {
        dispatch(changeField({
            field: 'filter',
            value: {
                ...filter,
                [name]: value
            }
        }))
    }

    const [price, setPrice] = useState(0)
    const [resPrice, setResPrice] = useState(0)

    useEffect(() => {
        if (apiToken) {
            dispatch(
                getFullReservationList({
                    apiToken,
                    clientId: client.id,
                    limit: 100,
                    onSuccess: (res) => {
                        if (res && res.length > 1) {
                            //set deposit to 300 euro
                            setDepositPrice(300)
                        }
                    }
                })
            )
        }
    }, [apiToken])

    useEffect(() => {

        const totalTime = filterTimeFormatted.getTotalTime(
            filterTimeFormatted.apiFormat(filter.dateEnd),
            filterTimeFormatted.apiFormat(filter.dateStart)
        )

        if (totalTime.days > 0 || totalTime.hours >= 1) {
            dispatch(
                actionCalculateBookingPrice({
                    categoryId: 100,
                    startDate: filterTimeFormatted.apiFormat(filter.dateStart),
                    endDate: filterTimeFormatted.apiFormat(filter.dateEnd),
                    km: filter.km,
                    onSuccess: (res) => {
                        if (res.data.total_price_including_vat > 0) {
                            setResPrice(res.data.total_price_including_vat.toFixed(2))
                            setPrice((depositPrice + res.data.total_price_including_vat).toFixed(2))
                        }
                    }
                })
            )
        }

    }, [filter.dateStart, filter.dateEnd, filter.km, depositPrice])

    const userDocuments = useSelector(state => state.userDocuments)

    useEffect(() => {
        userDocuments.forEach((document) => {

            if (document.type == 'drivers_license') {
                const documentDate = moment(document.expiration_date)
                const timeDiff = filterTimeFormatted.getTimeDiff(filter.dateEnd, documentDate)

                if (timeDiff.days <= 0) {

                    setIsDL(false)

                    dispatch(
                        showErrorMessage({
                            title: '',
                            message: lang.document_expired_manage
                        })
                    )
                }
            }
        })
    }, [filter.dateEnd])

    // const [isGeoRequestPopupActive, setIsGeoRequestPopupActive] = useState(true)
    // const [isGeoRequestApprove, setIsGeoRequestApprove] = useState(false)
    //
    // useEffect(() => {
    //     if (cookies.get('geo_available') == 'true') {
    //         setIsGeoRequestPopupActive(false)
    //         setIsGeoRequestApprove(true)
    //     }
    //
    //     if (cookies.get('geo_available') == 'false') {
    //         setIsGeoRequestPopupActive(false)
    //         setIsGeoRequestApprove(false)
    //     }
    // }, [cookies])

    useEffect(() => {
        const timer = setInterval(() => {

            const currentTime = new Date()
            const currentTimeMoment = moment(currentTime)
            const minutes = currentTimeMoment.minutes()
            const shiftedMinutes = filter.dateStart.minutes()

            //посчитать дифф и из него брать минуты
            const diff = filter.dateStart.diff(currentTimeMoment, 'minutes')
            console.log(diff)

            if (diff <= -15) {

                const oldEndDate = filterTimeFormatted.roundTime(moment(filter.dateEnd))
                const newStartDate = filterTimeFormatted.roundTime(moment(new Date()))

                console.log(oldEndDate)
                console.log(newStartDate)

                const dateEndDiff = newStartDate.diff(oldEndDate, 'minutes')
                const newEndDate = filterTimeFormatted.roundTime(moment(newStartDate).add(dateEndDiff, 'minutes'))
                console.log("dateEndDiff: " + dateEndDiff)

                setIsReservationPopupActive(true)

                // dispatch(
                //     changeField({
                //         field: 'filter',
                //         value: {
                //             ...filter,
                //             dateStart: newStartDate,
                //             dateEnd: newEndDate,
                //         }
                //     })
                // )

                dispatch(
                    changeField({
                        field: 'filter',
                        value: {
                            ...filter,
                            dateStart: filterTimeFormatted.roundTime(moment(filter.dateStart).add(15, 'minutes')),
                            dateEnd: filterTimeFormatted.roundTime(moment(filter.dateEnd).add(15, 'minutes'))
                        }
                    })
                )
            }

        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [filter])

    useEffect(() => {

        const totalTime = filterTimeFormatted.getTotalTime(
            filterTimeFormatted.apiFormat(filter.dateEnd),
            filterTimeFormatted.apiFormat(filter.dateStart)
        )

        if (totalTime.days <= 0 && totalTime.hours < 1) {

            console.log(totalTime)

            setIsTimePopup(true)

            dispatch(
                changeField({
                    field: 'filter',
                    value: {
                        ...filter,
                        //dateStart: filterTimeFormatted.roundTime(filter.dateStart),
                        dateEnd: moment(filter.dateStart).add(1, 'hours')
                    }
                })
            )

            const timer = setTimeout(() => {
                setIsTimePopup(false)
            }, 6000)

        }
    }, [filter.dateStart, filter.dateEnd])

    const validate = () => {

        const totalTime = filterTimeFormatted.getTotalTime(
            filterTimeFormatted.apiFormat(filter.dateEnd),
            filterTimeFormatted.apiFormat(filter.dateStart)
        )

        let isTime = false
        let isLocation = false

        if (totalTime.days > 0 || totalTime.hours >= 1) {
            isTime = true
        }

        if (locationInfo.address !== '' || locationInfo.type === 'geo') {
            isLocation = true
        }

        setValidation({
            timeOK: isTime,
            location: isLocation
        })
    }

    useEffect(() => {
        validate()
    }, [filter, locationInfo])

    const btnRef = useRef(null)
    const telRef = useRef(null)

    return (
        <div className="app-page-wrapper">
            <AppSidebar/>
            <Grid container spacing={1}>
                <AppReservationHeader onBackHandler={() => {
                    history.push('/dashboard')
                }} isBackActive={true}/>
                <Grid item xs={12}>
                    <PageTitleStyled>{lang.manage_res}</PageTitleStyled>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <PageSubtitleStyled>
                            <p>{lang.min_res_time}</p> <b>60 {lang.min}.</b>
                        </PageSubtitleStyled>
                        <PageSubtitleStyled>
                            <p>{lang.time_interval}</p><b>15 {lang.min}.</b>
                        </PageSubtitleStyled>
                        <PageSubtitleStyled>
                            <p>{lang.time_interval_end}</p><b>30 {lang.min}.</b>
                        </PageSubtitleStyled>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <Grid style={{marginTop: '0px'}} item container xs={12} spacing={2}>
                            <Grid item xs={2} style={{display: 'flex', alignItems: 'center', fontSize: '18px'}}>
                                {lang.from}
                            </Grid>
                            <Grid item xs={5}>
                                <DatePicker isError={false} onChange={filterChange} name="dateStart"
                                            value={filter.dateStart}/>
                            </Grid>
                            <Grid item xs={5}>
                                <TimePicker isError={false} name="dateStart" value={filter.dateStart}
                                            onChange={filterChange}/>
                            </Grid>
                        </Grid>

                        <Grid style={{marginTop: '0px'}} item container xs={12} spacing={2}>
                            <Grid item xs={2} style={{display: 'flex', alignItems: 'center', fontSize: '18px'}}>
                                {lang.to}
                            </Grid>
                            <Grid item xs={10}>
                                <OffsetTimePickerManage isError={false} name="dateEnd" value={filter.dateEnd}
                                                        onChange={filterChange}/>
                            </Grid>
                        </Grid>

                        <Grid style={{marginTop: '0px'}} item container xs={12} spacing={2}>
                            <Grid item xs={7} style={{display: 'flex', alignItems: 'center', fontSize: '16px'}}>
                                {lang.tr_distance}
                            </Grid>
                            <Grid item xs={5}>
                                <SelectField type="number" value={filter.km} postfix="km" icon={locationIcon}
                                            onChange={inputChange}
                                            placeholder={0}
                                            name="km"/>
                            </Grid>
                        </Grid>

                        <Grid style={{marginTop: '16px'}} item xs={12}>
                            <TableStyledInner>
                                <table style={{fontFamily: 'Open Sans'}}>
                                    <tbody>
                                    <tr>
                                        <td><span style={{color: '#666666'}}>{lang.reservation_price}: </span></td>
                                        <td>€ {resPrice}</td>
                                    </tr>
                                    <tr>
                                        <td><span style={{color: '#666666'}}>{lang.deposit}: </span></td>

                                        <td>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <p style={{position: 'relative'}}>
                                                    {
                                                        depositPrice > 200 ?
                                                            `€ ${depositPrice}`
                                                            :
                                                            `€ ${config.reservationDeposit}-${config.reservationDepositMax}`
                                                    }
                                                    <InfoOutlinedIcon
                                                        onClick={() => {
                                                            setIsDepositInfoActive(true)
                                                        }}
                                                        style={{
                                                            color: '#666666',
                                                            cursor: 'pointer',
                                                            position: 'absolute',
                                                            right: '-30px',
                                                            top: 0,
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span style={{color: '#666666'}}>{lang.balance_not_less}: </span></td>
                                        <td><b style={{color: '#000000'}}>€ {price}</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </TableStyledInner>
                            {/*<p style={{fontFamily: 'Open Sans'}}>*/}
                            {/*    <span style={{color: '#666666'}}>{lang.reservation_price}: </span>*/}
                            {/*    <b style={{color: '#000000'}}>€ {resPrice}</b>*/}
                            {/*</p>*/}
                        </Grid>

                        {/*<Grid style={{marginTop: '16px'}} item xs={12}>*/}
                        {/*    <p style={{fontFamily: 'Open Sans'}}>*/}
                        {/*        <span style={{color: '#666666'}}>{lang.deposit}: </span>*/}
                        {/*        <b style={{color: '#000000'}}>€ {config.reservationDeposit}.00</b>*/}
                        {/*    </p>*/}
                        {/*</Grid>*/}

                        {/*<Grid style={{marginTop: '16px'}} item xs={12}>*/}
                        {/*    <p style={{fontFamily: 'Open Sans'}}>*/}
                        {/*        <span style={{color: '#666666'}}>{lang.balance_not_less}: </span>*/}
                        {/*        <b style={{color: '#000000'}}>€ {price}</b>*/}
                        {/*    </p>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>

                <hr style={{
                    backgroundColor: '#C8C4C4',
                    width: '100%',
                    height: '1px',
                    marginTop: '10px',
                    marginBottom: '5px'
                }}/>
                <Grid item xs={12}>
                    <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <LocationFilter isError={!validation.location}/>
                    </Grid>

                </Grid>
                <Grid item xs={12} style={{marginTop: '10px'}}>
                    <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <ReservationFilter btnRef={btnRef}/>
                    </Grid>

                </Grid>
                <Grid ref={btnRef} style={{display: 'flex', justifyContent: 'center', paddingBottom: '60px'}} item
                      xs={12}>

                    {
                        validation.timeOK && validation.location && isDL ?
                            <div onClick={onContinueClick} className="start-reservation-button">
                                {lang.continue}
                            </div>
                            :
                            <div
                                onClick={() => {
                                    dispatch(showErrorMessage({
                                        title: '',
                                        message: lang.fill_address_or_geo
                                    }))

                                    setValidation({
                                        ...validation,
                                        location: false
                                    })
                                }}
                                style={{backgroundColor: 'gray'}} className="start-reservation-button">
                                {lang.continue}
                            </div>
                    }

                </Grid>
            </Grid>

            <a ref={telRef} href="tel:+31880883434" />

            <GeolocationCheck/>

            {
                locationInfo.type == 'addressLocation' ?
                    ''
                    :
                    <Geolocation/>
            }

            {/*<AppPopup*/}
            {/*    open={isGeoRequestPopupActive}*/}
            {/*    closeText="no"*/}
            {/*    nextText="yes"*/}
            {/*    handleNext={() => {*/}
            {/*        setIsGeoRequestApprove(true)*/}
            {/*        setIsGeoRequestPopupActive(false)*/}
            {/*    }}*/}
            {/*    handleClose={() => {*/}
            {/*        setIsGeoRequestPopupActive(false)*/}
            {/*    }}*/}
            {/*    title=""*/}
            {/*    description="To continue the correct operation of the system, you need to provide access to geolocation"*/}
            {/*/>*/}

            <AppPopupV2
                open={isGeoRequestPopupActive}
                closeText={lang.no}
                nextText={lang.ok}
                handleNext={() => {
                    setIsGeoRequestPopupActive(false)

                    setCookie('geo_was_shown', true, {
                        expires: new Date(Date.now() + 99990000000)
                    })
                }}
                handleClose={() => {
                    setIsGeoRequestPopupActive(false)
                    setCookie('geo_was_shown', true, {
                        expires: new Date(Date.now() + 2592000)
                    })
                }}
                title=""
                description={lang.geo_request}
            />

            <AppPopupV2
                open={isDepositInfoActive}
                nextText={lang.ok}
                handleNext={() => {
                    setIsDepositInfoActive(false)
                }}
                description={lang.deposit_info_text}
                handleClose={() => {
                    setIsDepositInfoActive(false)
                }}
                closeText={lang.no}
                extraStyle={{
                    textAlign: 'left',
                    fontSize: '15px'
                }}
            />

            <AppPopupV2
                open={isCallCenterPopupActive}
                nextText={lang.ok}
                handleNext={() => {
                    telRef.current.click()
                }}
                description={lang.call_center_day_reason}
                handleClose={() => {
                    setIsCallCenterPopupActive(false)
                }}
                closeText={lang.no}
            />

            <AppPopup
                open={isReservationPopupActive}
                nextText={lang.ok}
                handleNext={() => {
                    setIsReservationPopupActive(false)
                }}
                description={`${lang.notifications_2}`}
            />

            {
                isTimePopupActive ?
                    <BottomNotification description={lang.res_auto}/>
                    :
                    ''
            }

        </div>
    )
}

export default ManagerReservation