import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

export default function AppPopup({
                                     open,
                                     handleClose = false,
                                     title,
                                     description = '',
                                     closeText = 'close',
                                     handleNext = false,
                                     nextText = 'yes'
                                 }) {

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose ? handleClose: () => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className="popup-title" style={{textAlign: 'center'}}>{title}</DialogTitle>
                <DialogContent className="popup-content-wrapper">
                    <DialogContentText
                        dangerouslySetInnerHTML={{__html: description}}>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={ handleClose && handleNext ? {display: 'flex', justifyContent: 'space-between'} :
                    {display: 'flex', justifyContent: 'center'}}>

                    {
                        handleClose ?
                            <Button className="cancelButtonText" onClick={handleClose} color="primary">
                                {closeText}
                            </Button>
                            :
                            ''
                    }

                    {handleNext ?
                        (
                            <Button className="nextButtonText"
                                    onClick={handleNext}
                                    variant="contained"
                                    color="primary">
                                {nextText}
                            </Button>
                        )
                        :
                        false
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}