import styled from "styled-components"

const VanComponentStyled = styled.div`
  background: #FFFFFF;
  border: 2px solid #C8C8C8;
  border-radius: 4px;
  width: 99%;
  min-height: 150px;
  padding: 5px 0;
  font-family: "Open Sans", sans-serif;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  
  .vanComponentUnavailable {
    background-color: rgba(255,255,255,0.9);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    
    span {
      display: block;
      font-size: 14px;
      padding: 0 20px;
      text-align: center;
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      color: #E9392C;
      font-weight: bold;
    }
    
    ul {
      list-style: none;
      max-height: 80px;
      overflow-y: auto;
      text-align: center;
      
      li {
        font-size: 14px;
      }
    }
    
    p {
      position: fixed;
      bottom: 0;
      text-transform: uppercase;
      font-size: 18px;
      color: #E9392C;
    }
  }
  
  .vanComponentClose {
    position: absolute;
    right: 0;
    z-index: 3;
    top: -15px;
    font-size: 38px;
    font-weight: bold;
    color: #E9392C;
    cursor: pointer;
  }
  
  .vanComponentInformation {
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
  }

  .vanComponentImageWrapper {
    width: 30%;
  }

  .vanComponentName {
    width: 30%;
  }

  .vanComponentTechnicalInformation {
    width: 35%;
    
    margin-top: 10px;
    
    .vanComponentTechnical:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .vanComponentAddress {
    display: flex;
    align-items: flex-start;
    font-size: 10px;
    margin-bottom: 10px;
    
    p {
      text-align: left !important;
    }
    
    img {
      margin-top: 2px;
      margin-right: 2px;
    }
  }

  .vanComponentPlateIcon {
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    //width: 67px;
    //height: 15px;
    
    p {
      position: relative;
      left: 20px;
    }
  }

  .vanComponentImage {
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    width: 100%;
    height: 75px;
    background-position: center;
  }
  
  .vanComponentTechnical {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    
    p {
      text-transform: capitalize;
    }
    
    img {
      margin-right: 5px;
    }
  }

  .vanComponentName {
    font-size: 12px;
    margin-top: 10px;
    
    p {
      text-align: center;

      span {
        display: block;
      }
    }
  }
  
  .vanComponentButtons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 10px;
  }
  
  .vanComponentNavigation {
    position: absolute;
    top: 50%;
    display: none;
    
    img:first-child {
      position: absolute;
      left: 0;
      transform: rotate(180deg);
    }
    
    img:last-child {
      position: absolute;
      right: 0;
    }
  }
  
`

export default VanComponentStyled