import React, {useEffect, useState} from 'react'
import AppSidebar from "../components/AppSidebar";
import {Button, Grid} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import PageTitleStyled from "../styles/PageTitleStyled";
import PageSubtitleRegularStyled from "../styles/PageSubtitleRegularStyled";
import styled from "styled-components";
import busIcon from '../assets/reservationcar.png'
import AppPopup from "../components/AppPopup";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import filterTimeFormatted from "../services/filterTimeFormatted";
import {
    actionCalculateBookingPrice,
    changeField,
    createReservation,
    editUserInformation,
    getClientInfo, getFullReservationList, getReservationList
} from "../redux/actions";
import config from "../config";
import firebase from "../firebase";
import {useCookies} from "react-cookie";
import moment from "moment";
import { isMacOs, isIOS } from "react-device-detect"

const ReservationStyled = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 10px;

  .reservationConfirmTable {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    
    @media (min-width: 900px) {
      font-size: 16px;
    }

    tr td:last-child {
      color: #666666;
      padding-left: 10px;
    }

    tr td:first-child {
      font-weight: 600;
    }

    td {
      color: black;
      padding-top: 5px;

      b {
        font-weight: bold;
      }
      
      @media (min-width: 900px) {
        padding-top: 10px;
      }
    }

    tr:last-child td {
      font-size: 14px;
    }

    tr:last-child td:last-child {
      font-size: 16px;
    }
  }
`

const ReservationGroupBtnStyled = styled.div`
  
  height: 100%;
  
  .reservationConfirmGroupBtn {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`

const ConfirmReservation = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const lang = useSelector(state => state.lang)
    const apiToken = useSelector(state => state.apiToken)
    const [isCancelActive, setIsCancelActive] = useState(false)
    const [isConfirmActive, setIsConfirmActive] = useState(false)
    const [price, setPrice] = useState(0)
    const [resPrice, setResPrice] = useState(0)

    const selectedVan = useSelector(state => state.selectedVan)
    const filter = useSelector(state => state.filter)
    const client = useSelector(state => state.client)
    const [totalTime, setTotalTime] = useState(false)
    const [isPushPopup, setIsPushPopup] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['push_enable'])
    const [isReservationPopupActive, setIsReservationPopupActive] = useState(false)
    const [depositPrice, setDepositPrice] = useState(200)

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0,0)
        }, 1000)
    }, [])

    let msg = false

    if ( !(isMacOs || isIOS) ) {
        msg = firebase.messaging()
    }

    useEffect(() => {
        if ( !(isMacOs || isIOS) ) {
            if (cookies.push_enable != 'true') {
                setIsPushPopup(true)
            }
        }
    }, [cookies])

    useEffect(() => {
        dispatch(
            actionCalculateBookingPrice({
                categoryId: selectedVan.model.category.id,
                startDate: filterTimeFormatted.apiFormat(filter.dateStart, filter.timeStart),
                endDate: filterTimeFormatted.apiFormat(filter.dateEnd, filter.timeEnd),
                km: filter.km,
                onSuccess: (res) => {
                    setPrice(depositPrice + res.data.total_price_including_vat)
                    setResPrice(res.data.total_price_including_vat)
                }
            })
        )

        setTotalTime(filterTimeFormatted.getTotalTime(
                filterTimeFormatted.apiFormat(filter.dateEnd, filter.timeEnd),
                filterTimeFormatted.apiFormat(filter.dateStart, filter.timeStart)
            )
        )

    }, [filter, depositPrice])

    useEffect(() => {
        const timer = setInterval(() => {

            const currentTime = new Date()
            const currentTimeMoment = moment(currentTime)
            const minutes = currentTimeMoment.minutes()
            const shiftedMinutes = filter.dateStart.minutes()

            //посчитать дифф и из него брать минуты
            const diff = filter.dateStart.diff(currentTimeMoment, 'minutes')
            console.log(diff)

            if (diff <= -15) {

                setIsReservationPopupActive(true)
                dispatch(
                    changeField({
                        field: 'filter',
                        value: {
                            ...filter,
                            dateStart: filterTimeFormatted.roundTime(moment(new Date())),
                            dateEnd: filterTimeFormatted.roundTime(moment(new Date()).add(1, 'hours')),
                        }
                    })
                )

                // if (Math.abs(shiftedMinutes - minutes) >= 15) {
                //
                // }
            }

        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [filter])

    const onCancelHandler = () => {

        dispatch(
            changeField({
                field: 'filter',
                value: {
                    ...filter,
                    timeWasSet: false
                }
            })
        )

        dispatch(
            changeField({
                field: 'activeVans',
                value: []
            })
        )

        history.push('/dashboard')
    }

    const onConfirmHandler = () => {

        if (client.contract.current_balance >= price) {
            dispatch(
                createReservation({
                    apiToken: apiToken,
                    startDate: filterTimeFormatted.apiFormat(filter.dateStart, filter.timeStart),
                    endDate: filterTimeFormatted.apiFormat(filter.dateEnd, filter.timeEnd),
                    clientId: client.id,
                    vehicleId: selectedVan.id,
                    km: filter.km,
                    lang,
                    onSuccess: (res) => {

                        if (!(isMacOs || isIOS) && cookies.push_enable == 'true') {
                            msg.requestPermission().then(() => {
                                return msg.getToken();
                            }).then((data) => {
                                console.log("token", data)
                                dispatch(
                                    editUserInformation({
                                        apiToken,
                                        pushToken: data
                                    })
                                )
                            })
                        }

                        dispatch(
                            changeField({
                                field: 'filter',
                                value: {
                                    ...filter,
                                    timeWasSet: false
                                }
                            })
                        )

                        dispatch(
                            changeField({
                                field: 'activeVans',
                                value: []
                            })
                        )

                        dispatch(getClientInfo({
                            apiToken
                        }))

                        history.push('/dashboard')
                    },
                    onError: (err) => {
                        console.log(err)
                    }
                })
            )
        } else {
            dispatch(
                changeField({
                    field: 'payment',
                    value: {
                        amount: Math.abs(price - client.contract.current_balance),
                        active: true,
                        information: `${lang.confirm_1}`
                    }
                })
            )

            history.push('/payment')
        }
    }

    const turnOnPush = () => {
        if (!(isMacOs || isIOS)) {
            setCookie('push_enable', true, {
                maxAge: 99999999
            })

            setIsPushPopup(false)

            msg.requestPermission().then(() => {
                return msg.getToken();
            }).then((data) => {
                console.log("token", data)
                dispatch(
                    editUserInformation({
                        apiToken,
                        pushToken: data
                    })
                )
            })
        }
    }

    useEffect(() => {
        if (apiToken) {
            dispatch(
                getFullReservationList({
                    apiToken,
                    clientId: client.id,
                    limit: 100,
                    onSuccess: (res) => {
                        if (res && res.length > 1) {
                            //set deposit to 300 euro
                            setDepositPrice(config.reservationDepositMax)
                        } else {
                            setDepositPrice(config.reservationDeposit + selectedVan.branch.extra_deposit)
                        }
                    }
                })
            )
        }
    }, [apiToken, selectedVan])

    return (
        <div className="app-page-wrapper">
            <AppSidebar/>
            <Grid container spacing={2}>
                <AppReservationHeader isBackActive={true}/>
                <Grid item xs={12}>
                    <PageTitleStyled>{lang.confirm_2}</PageTitleStyled>

                    {
                        totalTime ?
                            <PageSubtitleRegularStyled>
                                {lang.for} {totalTime.days} {lang.day}, {totalTime.hours} {lang.hour}, {totalTime.minutes} {lang.minutes}
                            </PageSubtitleRegularStyled>
                            :
                            ''
                    }

                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <ReservationStyled>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <img style={{width: '170px', height: 'auto'}}
                                     src={selectedVan.model.image ? selectedVan.model.image.thumb : busIcon} alt=""/>
                                <p style={{marginTop: '10px'}}>{lang.future_reservation}</p>
                            </div>

                            <hr style={{
                                backgroundColor: '#F5F5F5',
                                height: '1px',
                                width: '100%',
                                marginTop: '10px',
                                border: '1px solid #F5F5F5'
                            }}/>

                            <div>
                                <table className="reservationConfirmTable">
                                    <tbody>
                                    <tr>
                                        <td>{lang.vehicle}:</td>
                                        <td>{selectedVan.model.brand} {selectedVan.model.model}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.location}:</td>
                                        <td>{selectedVan.branch.address}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.pick_up_date}:</td>
                                        <td>{filterTimeFormatted.visualFormat(filter.dateStart, filter.timeStart)}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.return_date}:</td>
                                        <td>{filterTimeFormatted.visualFormat(filter.dateEnd, filter.timeEnd)}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.license_plate}:</td>
                                        <td>{selectedVan.license_plate}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.reservation_price}</td>
                                        <td>€ {resPrice.toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.deposit}</td>
                                        <td>€ {depositPrice}.00</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.total_price}:</td>
                                        <td><b>€ {price.toFixed(2)}</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ReservationStyled>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{height: '30vh'}}>
                    <ReservationGroupBtnStyled>
                        <div className="reservationConfirmGroupBtn">
                            {
                                price > 0 ?
                                    <div onClick={() => {
                                        setIsConfirmActive(true)
                                    }} className="start-reservation-button">
                                        {lang.continue}
                                    </div>
                                    :
                                    ''
                            }

                            <div onClick={() => {
                                setIsCancelActive(true)
                            }}
                                 style={{textAlign: 'center', marginTop: '15px'}} className="closeButton">
                                {lang.cancel}
                            </div>
                        </div>
                    </ReservationGroupBtnStyled>
                </Grid>
            </Grid>
            <AppPopup handleClose={() => {
                setIsCancelActive(false)
            }}
                      handleNext={onCancelHandler}
                      open={isCancelActive}
                      title={`${lang.cancel_reservation_1}`}
                      description={`${lang.cancel_reservation_2}`}
                      closeText={lang.no}
                      nextText={lang.yes}/>

            <AppPopup handleClose={() => {
                setIsConfirmActive(false)
            }}
                      handleNext={onConfirmHandler}
                      open={isConfirmActive}
                      title={lang.confirm_reservation_1}
                      description={lang.do_you_want_to_confirm}
                      closeText={lang.no}
                      nextText={lang.yes}/>

            <AppPopup
                open={isPushPopup}
                title={lang.enable_nofitications}
                description={lang.nofitications_1}
                closeText={lang.close}
                handleClose={() => {
                    setIsPushPopup(false)
                }}
                nextText={lang.turn_on}
                handleNext={turnOnPush}
            />

            <AppPopup
                open={isReservationPopupActive}
                nextText={lang.ok}
                handleNext={() => {
                    setIsReservationPopupActive(false)
                }}
                description={lang.notifications_2}
            />
        </div>
    )
}

export default ConfirmReservation