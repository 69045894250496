import React, {useState} from 'react'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import styled from "styled-components";
import ReservationFilter from "./ReservationFilter";

const MapFilterStyled = styled.div`
  
  .filterIcon {
    position: fixed;
    top: 250px;
    right: 10px;
    background-color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E9392C;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  
  .filterContent {
    position: fixed;
    top: 150px;
    left: 10px;
    z-index: 50;
  }
`

const MapFilter = () => {

    const [isActive, setIsActive] = useState(false)

    return (
        <>
            <MapFilterStyled>
                <div className="filterIcon" onClick={() => {setIsActive(!isActive)}}>
                    <FilterAltIcon/>
                </div>
                {
                    <div style={{display: isActive ? 'block': 'none' }} className="filterContent">
                        <ReservationFilter type="mini"/>
                    </div>
                }

                {
                    isActive ?
                        <div style={{position: 'fixed', top: '0', zIndex: '49', left: '0', height: '100vh', width: '100%',
                        backgroundColor: 'rgba(0,0,0, .4)'}}
                             onClick={() => {
                                 setIsActive(false)
                             }} />
                        :
                        ''
                }
            </MapFilterStyled>
        </>
    )
}

export default MapFilter
