import React from 'react'
import { TimePicker as TimePickerExternal } from '@material-ui/pickers'
import DateFnsUtils from "@date-io/date-fns"
import {MuiPickersUtilsProvider} from "@material-ui/pickers"
import styled from "styled-components"
import timeIcon from '../assets/timeIcon.png'
import moment from "moment";
import SkyeTimePicker from "./SkyeTimePicker";

const TimePickerStyled = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  height: 43px;
  display: flex;
  align-items: center;
  position: relative;
  border: ${props => props.isError ? '2px solid red' : 'none'};

  img {
    position: absolute;
    left: 10px;
  }

  input {
    border: none;
    padding-left: 50px;
  }

  div::before, div::after {
    border-bottom: none !important;
  }
`

const TimePicker = ({value, onChange, name, isError = false, readOnly = false}) => {

    const now = moment()

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePickerStyled isError={isError}>
                <img src={timeIcon} alt=""/>
                {/*<TimePickerExternal ampm={false}*/}
                {/*                    minutesStep={15}*/}
                {/*                    readOnly={readOnly}*/}
                {/*                    minTime={now}*/}
                {/*                    value={value}*/}
                {/*                    onChange={(e) => { onChange(name, e) }}*/}
                {/*                    format="HH:mm" />*/}

                <SkyeTimePicker value={value} onChange={(e) => onChange(name, e)} minTime={now} />

            </TimePickerStyled>
        </MuiPickersUtilsProvider>
    )
}

export default TimePicker