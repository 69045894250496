import React, {useEffect, useRef, useState} from 'react'
import * as tf from '@tensorflow/tfjs'
import * as automl from '@tensorflow/tfjs-automl'
import {useDispatch, useSelector} from "react-redux";
import {changeField, changePage, preloaderStart, preloaderStop, putDLFrontPhotoToResult} from "../redux/actions";
import config from "../config";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {Button} from "@mui/material";
import cameraSound from "../assets/03203_min.mp3";
import ReactGA from "react-ga";

const DLFrontPhotoPageComputer = () => {

    const dispatch = useDispatch()
    const videoRef = useRef(false)
    const preProcessCanvas = useRef(false)
    const resCanvasRef = useRef(false)
    const [isPhotoDone, setIsPhotoDone] = useState(false)
    const [stream, setStream] = useState({})

    const [isLoaded, setIsLoaded] = useState(false)
    const [needHelp, setNeedHelp] = useState(false)
    const [innerStream, setInnerStream] = useState({})

    const handleOnSuccessImage = (base64Image) => {
        dispatch(putDLFrontPhotoToResult({
            imageBase64: base64Image
        }))
    }

    const placeholderRef = useRef(false)

    const makePhotoHandler = () => {
        const resWidth = videoRef.current.offsetWidth
        const resHeight = videoRef.current.offsetHeight

        preProcessCanvas.current.width = resWidth
        preProcessCanvas.current.height = resHeight

        preProcessCanvas.current.getContext('2d').drawImage(videoRef.current, 0, 0, resWidth, resHeight)

        const resBaseImage = preProcessCanvas.current.toDataURL('image/jpeg', 1)

        stream.getTracks()
            .forEach((track) => track.stop())

        setStream({})

        handleOnSuccessImage(resBaseImage)
    }

    useEffect(() => {

        dispatch(preloaderStart())

        function run() {
            const constraintsDefault = {
                audio: false,
                video: {
                    width: 800,
                    height: 800,
                    facingMode: "environment"
                    // facingMode: {
                    //     exact: "user"
                    // }
                }
            }

            navigator.mediaDevices.getUserMedia(constraintsDefault)
                .then((stream) => {
                    dispatch(preloaderStop())

                    videoRef.current.srcObject = stream
                    setStream(stream)
                })
        }

        run()

    }, [videoRef])

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'DL Front Photo page computer'
        });
    }, [])

    const lang = useSelector(state => state.lang)

    return (
        <div>
            <canvas style={{display: 'none'}} ref={preProcessCanvas}/>
            <div className="make-photo-wrapper">
                <video style={{width: '100%', height: 'auto', display: isPhotoDone ? 'none' : 'block'}}
                       ref={videoRef} playsInline autoPlay/>
                <div ref={placeholderRef} className="dl-placeholder">
                    {
                        <Button
                            onClick={makePhotoHandler}
                            className="make-photo-btn"
                            variant="outlined"> <PhotoCameraIcon/> </Button>
                    }
                </div>

                <div className="make-photo-description">
                    {lang.camera_text_2}
                </div>

            </div>

            <canvas style={{display: isPhotoDone ? 'block' : 'none'}} ref={resCanvasRef}/>
        </div>
    )
}

export default DLFrontPhotoPageComputer