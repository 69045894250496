import React, {useEffect, useState} from 'react'
import AppSidebar from "../components/AppSidebar";
import {Grid} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import PageTitleStyled from "../styles/PageTitleStyled";
import PageSubtitleRegularStyled from "../styles/PageSubtitleRegularStyled";
import {useDispatch, useSelector} from "react-redux";
import PendingPopup from "../components/PendingPopup";
import {
    changeField,
    getClientInfo,
    getTransactionsHistory,
    preloaderStart,
    preloaderStop,
    triggerPayment
} from "../redux/actions";
import SuccessPopup from "../components/SuccessPopup";
import RejectedPopup from "../components/RejectedPopup";
import {useHistory, useParams} from "react-router-dom";
import TextStyled from "../styles/TextStyled";
import {isMacOs, isIOS} from "react-device-detect"
import AppPopupV2 from "../components/AppPopupV2";

const PaymentPage = () => {

    const lang = useSelector(state => state.lang)
    const history = useHistory()
    const payment = useSelector(state => state.payment)
    const dispatch = useDispatch()
    const client = useSelector(state => state.client)
    const apiToken = useSelector(state => state.apiToken)

    const [isPendingActive, setIsPendingActive] = useState(false)
    const [isSuccessActive, setIsSuccessActive] = useState(false)
    const [isRejectedActive, setIsRejectedActive] = useState(false)
    const [tempClient, setTempClient] = useState(false)
    const [payUrl, setPayUrl] = useState('')
    const [payPopupActive, setPayPopupActive] = useState(false)

    const {type = 'customer_triggered_payment'} = useParams()

    useEffect(() => {
        dispatch(
            getClientInfo({
                apiToken,
                onSuccess: (res) => {
                    setTempClient(res)
                }
            })
        )
    }, [])

    const onPayHandler = () => {

        dispatch(preloaderStart())

        dispatch(
            triggerPayment({
                apiToken,
                clientId: client.id,
                amount: payment.amount.toFixed(2),
                onSuccess: (res) => {
                    dispatch(preloaderStop())
                    const url = res.data.url

                    if (isMacOs || isIOS) {
                        setPayUrl(url)
                        setPayPopupActive(true)
                    } else {
                        if (url) {
                            setIsPendingActive(true)
                            window.open(url, '_blank')
                        }
                    }
                },
                onError: (err) => {
                    dispatch(preloaderStop())
                },
                type
            })
        )
    }

    useEffect(() => {
        if (isPendingActive) {

            let count = 0

            const timer = setInterval(() => {

                dispatch(
                    getClientInfo({
                        apiToken,
                        onSuccess: (clientInner) => {
                            if (tempClient !== false) {

                                if (count >= 24) {
                                    setIsPendingActive(false)
                                    setIsRejectedActive(true)
                                    count = 1
                                }

                                if (clientInner.contract.current_balance >= tempClient.contract.current_balance + payment.amount) {

                                    setIsPendingActive(false)
                                    setIsSuccessActive(true)

                                    setTimeout(() => {
                                        setIsSuccessActive(false)

                                        if (payment.information !== false) {
                                            history.push(`/confirm-payment/111111`)
                                        } else {

                                            dispatch(
                                                changeField({
                                                    field: 'payment',
                                                    value: {
                                                        amount: 0,
                                                        active: false,
                                                        information: false
                                                    }
                                                })
                                            )

                                            history.push(`/dashboard`)
                                        }

                                    }, 4000)
                                }
                            }
                        }
                    })
                )

                dispatch(
                    getTransactionsHistory({
                        apiToken: apiToken,
                        clientId: client.id,
                        limit: 1,
                        onSuccess: (res) => {
                            const lastPayment = res.data[0]

                            if (lastPayment.status === 'accepted') {
                                setIsPendingActive(false)
                                setIsSuccessActive(true)

                                setTimeout(() => {
                                    setIsSuccessActive(false)

                                    if (payment.information !== false) {
                                        history.push(`/confirm-payment/${lastPayment.id}`)
                                    } else {

                                        dispatch(
                                            changeField({
                                                field: 'payment',
                                                value: {
                                                    amount: 0,
                                                    active: false,
                                                    information: false
                                                }
                                            })
                                        )

                                        history.push(`/dashboard`)
                                    }

                                }, 4000)

                            }

                            if (lastPayment.status === 'rejected') {
                                setIsPendingActive(false)
                                setIsRejectedActive(true)
                            }
                        },
                        onError: (err) => {
                            console.log(err)
                            alert('Error!')
                        }
                    })
                )

            }, 5000)

            return () => {
                clearInterval(timer)
            }
        }
    }, [isPendingActive])

    return (
        <div className="app-page-wrapper">

            <Grid container spacing={2}>
                <AppReservationHeader isBackActive={true}/>

                <Grid item xs={12}>
                    <PageTitleStyled>{lang.payment}</PageTitleStyled>
                </Grid>

                <Grid item xs={12} style={{
                    height: '50vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <p style={{
                        fontFamily: 'Open Sans',
                        fontSize: '24px'
                    }}>{lang.you_have_to_pay}</p>
                    <p style={{
                        fontFamily: 'Open Sans',
                        fontSize: '24px',
                        marginTop: '10px'
                    }}> € {payment.amount.toFixed(2)}</p>

                    {
                        payment.information ?
                            <p style={{
                                color: 'black',
                                fontFamily: 'Open Sans',
                                fontSize: '16px',
                                textAlign: 'center',
                                fontWeight: '600',
                                marginTop: '30px',
                                maxWidth: '280px'
                            }}>{payment.information}</p>
                            :
                            ''
                    }

                </Grid>

                {
                    payment.active ?
                        <Grid style={{
                            position: 'fixed',
                            bottom: '40px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }} item xs={12}>
                            <div className="start-reservation-button" onClick={onPayHandler}>
                                {lang.pay}
                            </div>
                            <div>
                                <TextStyled>
                                    <p style={{textAlign: 'center', marginTop: '10px', color: '#787878', fontWeight: 'bold'}}>
                                        {lang.pay_1} <br />
                                        {lang.pay_2}</p>
                                </TextStyled>
                            </div>
                        </Grid>
                        :
                        ''
                }

            </Grid>

            <PendingPopup open={isPendingActive} onCloseHandler={() => {
                setIsPendingActive(false)
            }}/>
            <SuccessPopup open={isSuccessActive}/>
            <RejectedPopup open={isRejectedActive} onPayAgainHandler={() => {
                setIsRejectedActive(false)
            }}/>

            <AppPopupV2
                open={payPopupActive}
                title=""
                description={lang.confirm_bunq}
                nextText={lang.confirm}
                handleNext={() => {
                    setIsPendingActive(true)
                    setPayPopupActive(false)
                }}
                handleClose={() => {
                    setPayPopupActive(false)
                }}
                needLink={true}
                link={payUrl}
            />

        </div>
    )
}

export default PaymentPage