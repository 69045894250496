import styled from "styled-components";

const TableStyled = styled.div`

  margin-top: 10px;
  width: 100%;

  table {

    td {
      padding-bottom: 5px;
      font-size: 16px;
    }

    tr td:first-child {
      width: 40%;
      font-weight: 600;
    }

    tr td:last-child {
      width: 60%;
      color: #666666;
    }
  }
  
  select {
    border: none;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    background-color: #fff;
    color: #666666;
    position: relative;
    left: -5px;
  }
  
  @media (min-width: 1000px) {
    table {
      td {
        font-size: 16px !important;
        padding-bottom: 5px !important;
      }
    }
  }
`

export default TableStyled