import React, {useEffect, useMemo, useState} from 'react'
import {Button, Checkbox, FormControlLabel, Grid, MenuItem, Switch, TextField} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {changeField, changePage, experianValidateDL} from "../redux/actions"
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import AppHeader from "../components/AppHeader"
import countryList from 'react-select-country-list'
import validator from '../services/validator'
import AppPopup from "../components/AppPopup";
import AppPopupErrors from "../components/AppPopupErrors";
import AppInputMaskedDate from "../components/AppInputMaskedDate";
import {findError} from "../services/findError";
import ReactGA from "react-ga";

const NotDutchDocumentDataPage = () => {

    const countries = useMemo(() => countryList().getData(), [])

    const [isAlertShow, setIsAlertShow] = useState(false)

    const dispatch = useDispatch()


    const idcardpasNumber = useSelector(state => state.idcardpasNumber)
    const idcardpasExpdate = useSelector(state => state.idcardpasExpdate)
    const idcardpasCountry = useSelector(state => state.idcardpasCountry)

    const documents = useSelector(state => state.documents)

    const [errors, setErrors] = useState([])
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    const [isDocumentProblem, setIsDocumentProblem] = useState(false)
    const [isApproveOpen, setIsApproveOpen] = useState(false)
    const [isOpenForNotDutch, setIsOpenForNotDutch] = useState(false)

    const lang = useSelector(state => state.lang)

    const handleNextStep = (e) => {

        const isDisabled = e.target.getAttribute('data-disabled')

        if (isDisabled === 'true') {
            const incorrectFields = validator.documentVerification([
                {type: 'idcardpasNumber', value: idcardpasNumber, message: lang.document_number},
                {type: 'idcardpasExpdate', value: idcardpasExpdate, message: lang.expiration_date},
                {type: 'idcardpasCountry', value: idcardpasCountry, message: lang.drivers_license_number},
            ])

            if (incorrectFields.length > 0) {
                setIsAlertShow(true)
                setErrors(incorrectFields)
            }
        } else {

            setIsApproveOpen(true)
        }
    }

    const handleChangeCountry = (e) => {

        dispatch(
            changeField({
                field: e.target.name,
                value: e.target.value
            })
        )
    }

    const handleAlertClose = () => {
        setIsAlertShow(false)
    }

    const handleDocumentClose = () => {
        setIsDocumentProblem(false)
    }

    const handleApproveClose = () => {
        setIsApproveOpen(false)
    }

    const goToNextHandler = () => {

        dispatch(changePage({pageName: 'selfie-page'}))
    }

    useEffect(() => {
        const incorrectFields = validator.documentVerification([
            {type: 'idcardpasNumber', value: idcardpasNumber, message: lang.document_number},
            {type: 'idcardpasExpdate', value: idcardpasExpdate, message: lang.expiration_date},
            {type: 'idcardpasCountry', value: idcardpasCountry, message: lang.drivers_license_number},
        ])

        if (incorrectFields.length === 0) {
            setIsButtonDisabled(false)
        } else {
            setIsButtonDisabled(true)
        }

        setErrors(incorrectFields)

    }, [idcardpasNumber, idcardpasExpdate, idcardpasCountry])

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'Not dutch document data page'
        });
    }, [])

    return (
        <Grid container spacing={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4px'}}>
            <AppHeader />

            <AppPopup title={lang.is_info_correct}
                      open={isApproveOpen}
                      handleClose={handleApproveClose}
                      description=""
                      nextText={lang.yes}
                      closeText={lang.no}
                      handleNext={goToNextHandler}
            />

            <AppPopupErrors title={lang.you_did_not_provide}
                            open={isAlertShow}
                            closeText={lang.cancel}
                            handleClose={handleAlertClose}
                            errors={errors}
            />
            <Grid className="containerAlignBottom" container item spacing={2} xs={11}>
                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} item xs={12}>
                    <h1 className="secondH1Title">{lang.document_verification}</h1>
                    <div className="documents-list">
                        <div className={`document-item ${documents.dlFront && documents.dlBack ? 'document-item-success' : ''}`}>
                            <div className="document-item-inner">
                                <CameraAltIcon />
                            </div>
                            <p>{lang.drivers_license}</p>
                        </div>

                        {
                            documents.passport ?
                                <div className={`document-item document-item-success`}>
                                    <div className="document-item-inner">
                                        <CameraAltIcon />
                                    </div>
                                    <p>{lang.passport}</p>
                                </div>
                                :
                                ''
                        }

                        {
                            documents.idCardFront && documents.idCardBack ?
                                <div className={`document-item document-item-success`}>
                                    <div className="document-item-inner">
                                        <CameraAltIcon />
                                    </div>
                                    <p>{lang.id_card}</p>
                                </div>
                                :
                                ''
                        }

                        <div className="document-item">
                            <div className="document-item-inner">
                                <CameraAltIcon />
                            </div>
                            <p>{lang.selfie}</p>
                        </div>
                    </div>

                    <Grid style={{marginTop: '10px'}} container item spacing={2} xs={12}>
                        <Grid item xs={12}>
                            <p className="small-subtitle">{lang.passport_id_card_number}</p>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                name="idcardpasNumber"
                                value={idcardpasNumber}
                                error={findError('idcardpasNumber', errors)}
                                onChange={handleChangeCountry}
                                style={{width: '100%'}}
                                label={lang.passport_id_card_number}
                                variant="outlined" />
                        </Grid>

                        <Grid item xs={5}>

                            <AppInputMaskedDate
                                value={idcardpasExpdate}
                                label={lang.expiration_date}
                                errors={errors}
                                onChangeHandler={handleChangeCountry}
                                name="idcardpasExpdate"
                            />

                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                style={{width: '100%'}}
                                name="idcardpasCountry"
                                select
                                label={lang.country_of_issue}
                                value={idcardpasCountry}
                                onChange={handleChangeCountry}
                            >
                                {countries.map((item) => {
                                    return (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                    </Grid>


                </Grid>

                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} item xs={12}>
                    <Button
                        className="main-button main-button-register"
                        style={{marginBottom: '20px'}}
                        data-disabled={isButtonDisabled}
                        onClick={handleNextStep}
                        variant="contained">{lang.continue}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NotDutchDocumentDataPage