import styled from "styled-components";

const TableStyledInner = styled.div`
    table {
      tr td:last-child {
        padding-left: 5px;
      }
    }
`

export default TableStyledInner