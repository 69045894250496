import React, {useEffect, useState} from 'react'
import {Grid} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getDamageReportList, getReservationInformation, getVehicleStatus} from "../redux/actions";
import PageTitleStyled from "../styles/PageTitleStyled";
import TextStyled from "../styles/TextStyled";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BoxWrapperStyled from "../styles/BoxWrapperStyled";
import kavPlaceholder from '../assets/kavplaceholder.jpg'
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined'
import {Controlled as ControlledZoom} from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import DamageReportItem from "../components/DamageReportItem";

const DamageReportPage = () => {

    const lang = useSelector(state => state.lang)
    const {id, reservationId} = useParams()
    const apiToken = useSelector(state => state.apiToken)
    const dispatch = useDispatch()
    const [damageList, setDamageList] = useState([])
    const [resInfo, setResInfo] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (id) {
            dispatch(
                getDamageReportList({
                    apiToken,
                    vehicleId: id,
                    onSuccess: (reports) => {
                        setDamageList(reports)
                    }
                })
            )

            dispatch(
                getReservationInformation({
                    apiToken,
                    reservationId,
                    onSuccess: (res) => {
                        setResInfo(res)
                    }
                })
            )
        }
    }, [id])

    return (
        <div className="app-page-wrapper">
            <Grid container spacing={2}>
                <AppReservationHeader isBackActive={true}/>

                <Grid item xs={12}>
                    <PageTitleStyled>{lang.damage_report_title}</PageTitleStyled>
                    <PageTitleStyled style={{color: 'black', textTransform: 'uppercase'}}>
                        {
                            resInfo ?
                                resInfo.vehicle.license_plate
                                :
                                ''
                        }
                    </PageTitleStyled>
                </Grid>

                <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <InfoOutlinedIcon style={{color: '#E9392C', marginRight: '10px'}}/>
                    <TextStyled>
                        {lang.damage_report_1}
                    </TextStyled>
                </Grid>

                {
                    damageList.map((item) => {
                        return (
                            <Grid key={item.id} item xs={12}>
                                <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                                    <DamageReportItem report={item}/>
                                </Grid>
                            </Grid>
                        )
                    })
                }

                <div
                    onClick={() => {
                        history.push(`/add-damage-report/${id}/${reservationId}`)
                    }}
                    style={{
                        bottom: '0',
                        left: '0',
                        height: '100px',
                        width: '100%',
                        backgroundColor: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '5px'
                    }} item xs={12}>
                    <div style={{width: '300px'}} className="start-reservation-button">
                        + {lang.damage_report_new}
                    </div>
                </div>
            </Grid>
        </div>
    )
}

export default DamageReportPage