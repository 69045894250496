import moment from "moment";
import filterTimeFormatted from "../services/filterTimeFormatted";

const initialState = {
    apiToken: false,
    currentPage: 'index-page',
    isPreloaderActive: false,
    preloaderText: '',
    currentPhoto: false,
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    dlNumber: '',
    expDate: '',
    country: 'NL',
    categoryB: true,
    selfie: false,
    documents: {
        dlFront: '',
        dlBack: '',
        passport: '',
        idCardFront: '',
        idCardBack: ''
    },
    zipCodeLiving: '',
    houseNumberLiving: '',
    numberAdditionLiving: '',
    streetLiving: '',
    cityLiving: '',
    zipCodeInvoice: '',
    houseNumberInvoice: '',
    numberAdditionInvoice: '',
    streetInvoice: '',
    cityInvoice: '',
    countryLiving: 'NL',
    countryInvoice: 'NL',
    email: '',
    phone: '',
    need24password: false,
    password: '',
    repassword: '',
    theSameInvoice: false,
    error: {
        isActive: false,
        title: '',
        message: ''
    },
    idcardpasNumber: '',
    idcardpasExpdate: '',
    idcardpasCountry: 'DE',
    login: {
        isActive: false,
        title: '',
        message: ''
    },
    language: 'nl',
    lang: {},
    isSidebarActive: false,
    activeVans: [],
    filter: {
        dateStart: filterTimeFormatted.roundTime(moment(new Date())),
        dateEnd: filterTimeFormatted.roundTime( moment(new Date()).add(1, 'hours') ),
        timeWasSet: false,
        // timeStart: filterTimeFormatted.roundTime(moment(new Date(), 'HH:mm')),
        // timeEnd: filterTimeFormatted.roundTime(moment(new Date(), 'HH:mm').add(1, 'hours')),
        km: 25,
        location: {
            type: 'geo',
            coordinates: {
                lat: '0',
                lng: '0'
            },
            address: ''
        },
        vehicles: [],
        transmissions: [],
        distance: 0,
        price: 0
    },
    selectedVan: false,
    user: false,
    client: false,
    map: {
        zoom: 8,
        coords: {lat: 52.07222855854113, lng: 6.902552998038657}
    },
    locationInfo: {
        type: 'geo',
        address: '',
        coordinates: {
            lat: '0',
            lng: '0'
        },
    },
    payment: {
        amount: 0,
        active: false,
        information: false
    },
    isGeoAvailable: 'unset',
    activeReservation: false,
    futureReservations: [],
    subsidiaryInfo: false,
    apiTokenV3: '',
    geoCoords: {
        lat: '0',
        lng: '0'
    },
    tempPhoto: false,
    docPhoto: {
        isActive: false,
        type: '',
        front: false,
        back: false,
        country: 'NL',
        number: '',
        expiration: '',
        categoryB: false
    },
    filterVans: {
        total: 0,
        filtered: 0
    },
    isPinActive: 'unset',
    isPinSuccess: false,
    isDocumentExpiredGlobal: false,
    userDocuments: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'HIDE_ERROR_MESSAGE':
            return {
                ...state,
                error: {
                    ...state.error,
                    title: '',
                    message: '',
                    isActive: false
                }
            }

        case 'SHOW_ERROR_MESSAGE':
            return {
                ...state,
                error: {
                    isActive: true,
                    title: action.payload.title,
                    message: action.payload.message
                }
            }

        case 'SHOW_LOGIN_POPUP':
            return {
                ...state,
                login: {
                    ...state.login,
                    isActive: true,
                }
            }

        case 'HIDE_LOGIN_POPUP':
            return {
                ...state,
                login: {
                    isActive: false
                }
            }

        case 'CHANGE_FIELD':
            return {
                ...state,
                [action.payload.field]: action.payload.value
            }

        case 'CHANGE_DL_FRONT_PAGE_RESULT':
            return {
                ...state,
                currentPage: 'dl-front-page-result',
                currentPhoto: action.payload,
                documents: {
                    ...state.documents,
                    dlFront: action.payload
                }
            }

        case 'CHANGE_DL_BACK_PAGE_RESULT':
            return {
                ...state,
                currentPage: 'dl-back-page-result',
                currentPhoto: action.payload,
                documents: {
                    ...state.documents,
                    dlBack: action.payload
                }
            }

        case 'CHANGE_PASSPORT_PAGE_RESULT':
            return {
                ...state,
                currentPage: 'passport-photo-result-page',
                currentPhoto: action.payload,
                documents: {
                    ...state.documents,
                    passport: action.payload
                }
            }

        case 'CHANGE_ID_CARD_FRONT_PAGE_RESULT':
            return {
                ...state,
                currentPage: 'idcard-front-result-page',
                currentPhoto: action.payload,
                documents: {
                    ...state.documents,
                    idCardFront: action.payload
                }
            }

        case 'CHANGE_ID_CARD_BACK_PAGE_RESULT':
            return {
                ...state,
                currentPage: 'idcard-back-result-page',
                currentPhoto: action.payload,
                documents: {
                    ...state.documents,
                    idCardBack: action.payload
                }
            }

        case 'CHANGE_SELFIE_RESULT':
            return {
                ...state,
                currentPage: 'selfie-result-page',
                currentPhoto: action.payload,
                selfie: action.payload
            }

        case 'CHANGE_API_TOKEN':

            return {
                ...state,
                apiToken: action.payload
            }

        default:
            return state
    }
}

export default reducer
