import React, {useEffect, useState} from 'react'
import {Grid, Slider} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import PageTitleStyled from "../styles/PageTitleStyled";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {actionCalculateBookingPrice} from "../redux/actions";
import moment from "moment";
import filterTimeFormatted from "../services/filterTimeFormatted";
import useDebounce from "../hooks/useDebounce";

const InnerStyled = styled.div`
  font-family: "Open Sans", sans-serif;

  .textContent {
    font-size: 16px;
    line-height: 1.5rem;
    font-weight: bold;

    i {
      color: red;
    }
  }

  input {
    font-size: 16px;
    padding: 5px;
    width: 100px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
  
  .spanInput {
    font-size: 16px;
    padding: 5px;
    width: 100px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
`

const CalculatorPage = () => {

    const lang = useSelector(state => state.lang)
    const [distance, setDistance] = useState(1)
    const [km, setKm] = useState(25)

    const [resDistance, setResDistance] = useState({
        hours: 1,
        minutes: 0,
        btw: 16.12,
        exBtw: 19.50
    })

    const distanceDeb = useDebounce(distance, 500)
    const kmDeb = useDebounce(km, 500)

    const dispatch = useDispatch()

    const onSliderChange = (event, newValue) => {
        console.log(newValue)
        setDistance( newValue )
    }

    const calculatePrice = (hours, km) => {

        var priceKm = 0.45
        var priceHours = 19.5

        var resPrice = 0

        if (hours > 0 && km >= 0) {

            if (hours >= 6) {
                hours = 6
            }

            resPrice = (hours * priceHours + priceKm * km).toFixed(2)

            //calculate BTW
            var btwPrice = (resPrice - (resPrice * 17.33 / 100)).toFixed(2)

            return {
                resPrice,
                btwPrice
            }
        }

        return false
    }

    useEffect(() => {

        let minutes = 0

        if ( distance <= 6) {

            const _distance = Math.floor((distance % 1) * Math.pow(10, 1))

            if (_distance == 0) {
                minutes = 0
            } else {
                minutes = 30
            }

            const resPrice = calculatePrice(distance, km)

            if (resPrice) {
                setResDistance({
                    ...resDistance,
                    hours: distance,
                    minutes: minutes,
                    // btw: resPrice.resPrice,
                    // exBtw: resPrice.btwPrice
                })
            }
        }

    }, [distance, km])

    useEffect(() => {
        dispatch(
            actionCalculateBookingPrice({
                categoryId: 100,
                startDate: filterTimeFormatted.apiFormat(moment(new Date())),
                endDate: filterTimeFormatted.apiFormat(moment(new Date()).add(distance, 'hours')),
                km: km,
                onSuccess: (res) => {

                    let minutes = 0

                    const _distance = Math.floor((distance % 1) * Math.pow(10, 1))

                    if (_distance == 0) {
                        minutes = 0
                    } else {
                        minutes = 30
                    }

                    const resPrice = calculatePrice(distance, km)

                    if (resPrice) {
                        setResDistance({
                            hours: distance,
                            minutes: minutes,
                            btw: res.data.total_price_including_vat.toFixed(2),
                            exBtw: res.data.total_price_excluding_vat.toFixed(2)
                        })
                    }
                },
                needPreloader: false
            })
        )
    }, [distanceDeb, kmDeb])

    return (
        <div className="app-page-wrapper">
            <Grid container spacing={2}>
                <AppReservationHeader isBackActive={true}/>

                <Grid item xs={12}>
                    <PageTitleStyled>{lang.calculator_1}</PageTitleStyled>
                </Grid>

                <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                    <InnerStyled>
                        <p style={{fontWeight: 'bold', fontSize: '18px'}}>{lang.calculator_2}</p>

                        {
                            distance < 6 ?
                                <p><b>{ parseInt(resDistance.hours) }</b> {lang.hour} + <b>{resDistance.minutes}</b> {lang.minutes}</p>
                                :
                                <p><b>6-24 {lang.hour}</b></p>
                        }

                        <div style={{padding: '0 35px'}}>
                            <Slider style={{color: '#E9392C'}}
                                    defaultValue={1}
                                    step={0.5}
                                    min={1}
                                    max={6}
                                    value={distance}
                                    onChange={onSliderChange}
                                    aria-label="Default"/>
                        </div>

                        <div>
                            <p style={{fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>{lang.calculator_3}</p>
                            <p style={{display: 'flex', alignItems: 'center'}}>
                                <b style={{marginRight: '10px'}}>km</b>
                                <select
                                    value={km}
                                    onChange={(e) => {
                                    setKm(e.target.value)
                                }}
                                    style={{
                                        fontSize: '16px'
                                    }}
                                >
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                    <option value="150">150</option>
                                    <option value="200">200</option>
                                    <option value="300">300</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                </select>
                            </p>

                        </div>


                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '320px',
                            justifyContent: 'space-between',
                            marginTop: '10px',
                            marginBottom: '10px'
                        }}>
                            <p>{lang.calculator_4}</p>
                            <span className="spanInput">{resDistance.exBtw}</span>
                        </div>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '320px',
                            justifyContent: 'space-between'
                        }}>
                            <p style={{marginRight: '10px'}}>{lang.calculator_5}</p>
                            <span className="spanInput">{resDistance.btw}</span>
                        </div>

                        <div className="textContent" style={{marginTop: '10px'}}>
                            <p> <i>{lang.calculator_10}</i> {lang.calculator_11} </p>
                            <br/>
                            <p><i>{lang.calculator_6}</i> {lang.calculator_7}</p>
                            <p><i>{lang.calculator_8}</i> {lang.calculator_9}</p>
                        </div>
                    </InnerStyled>

                </Grid>
            </Grid>
        </div>
    )
}

export default CalculatorPage