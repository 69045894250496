import React, {useEffect, useState} from 'react'
import {Grid} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {changeField} from "../redux/actions"
import backIcon from "../assets/backIcon.svg"
import {useHistory} from "react-router-dom"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import styled from "styled-components";
import AppPopup from "./AppPopup";
import startGif from "../assets/startingInstructions-invers_cloudboxx.gif";
import questionIcon from '../assets/question.svg'
import HeaderHavbarAccountBalance from "./HeaderHavbarAccountBalance";

const HelpStyled = styled.div`
    svg {
      color: #E9392C;
      font-size: 38px;
    }
  
  @media (min-width: 1000px) {
    img {
      width: 45px;
    }
  }
`

const AppReservationHeader = ({isBackActive = false, information = false, onBackHandler = false}) => {

    const dispatch = useDispatch()

    const onSidebarOpenClick = () => {
        dispatch(changeField({
            field: 'isSidebarActive',
            value: true
        }))
    }

    const history = useHistory()
    const client = useSelector(state => state.client)
    const lang = useSelector(state => state.lang)
    const [isInfoPopupActive, setIsInfoPopupActive] = useState(false)

    useEffect(() => {
        const pathname = window.location.pathname
        window.localStorage.setItem('prevPath', pathname)
    }, [])

    return (
        <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: ' auto'}}>

            <AppPopup
                open={isInfoPopupActive}
                description={
                    `
<div style="margin-bottom: 10px">
<p>
${lang.open_doors_3}
</p>
<ul style="font-size: 14px; padding-left: 20px">
<li>${lang.open_doors_4}</li>
<li>${lang.open_doors_5}</li>
<li>${lang.open_doors_6}</li>
</ul>
<p>${lang.open_doors_7}</p>
</div>
<img style="max-width: 100%" src=${startGif}>`
                }
                nextText={lang.ok}
                handleNext={() => {
                    setIsInfoPopupActive(false)
                }}
            />

            <nav className="app-navbar">
                {
                    !isBackActive ?
                        <div onClick={onSidebarOpenClick} className="navbar-burger-btn">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        :
                        <div onClick={() => {

                            if (onBackHandler === false) {
                                history.goBack()
                            } else {
                                onBackHandler()
                            }

                        }} className="navbar-burger-btn">
                            <img style={{width: '25px', margin: '0 auto'}} src={backIcon} alt=""/>
                        </div>
                }

                {
                    information == false ?
                        <HeaderHavbarAccountBalance value={client ? parseFloat(client.contract.current_balance).toFixed(2) : '-'} />
                        :
                        <HelpStyled>
                            {/*<HelpOutlineIcon onClick={() => {setIsInfoPopupActive(true)} } />*/}
                            <img src={questionIcon} onClick={() =>{setIsInfoPopupActive(true)} } alt=""/>
                        </HelpStyled>
                }

            </nav>
        </Grid>
    )
}

export default AppReservationHeader