import React, {useRef} from 'react'
import styled from "styled-components"
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {DatePicker as DatePickerExternal} from "@material-ui/pickers";
import dateIcon from '../assets/dataIcon.png'
import moment from "moment";

const DatePickerStyled = styled.div`
  
  display: flex;
  align-items: center;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  height: 43px;
  border: ${props => props.isError ? '2px solid red' : 'none'};
  
  img {
    position: absolute;
    left: 10px;
  }
  
  input {
    border: none;
    padding-left: 40px;
  }
  
  div::before, div::after {
    border-bottom: none !important;
  }
  
  @media (max-width: 355px) {
    
    input {
      font-size: 13px !important; 
    }
  }
`

const DatePicker = ({value, name, onChange, isError = false, readOnly = false}) => {

    const now = moment()

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePickerStyled isError={isError}>
                <img src={dateIcon} alt=""/>
                <DatePickerExternal
                    readOnly={readOnly}
                    minDate={now}
                    onChange={(e) => {onChange(name, e)}}
                    value={value}
                    format="dd-MM-yyyy" />
            </DatePickerStyled>
        </MuiPickersUtilsProvider>
    )
}

export default DatePicker