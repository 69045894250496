import React from 'react'
import {useSelector} from "react-redux";
import DLFrontPhotoPage from "../pages/DLFrontPhotoPage";
import IndexPage from "../pages/IndexPage";
import DocumentVerification from "../pages/DocumentVerification";
import DLFrontPage from "../pages/DLFrontPage";
import DLFrontPageResult from "../pages/DLFrontPageResult";
import DLBackPage from "../pages/DLBackPage";
import DLBackPagePhoto from "../pages/DLBackPagePhoto";
import DocumentDataPage from "../pages/DocumentDataPage";
import DLBackPageResult from "../pages/DLBackPageResult";
import SelfiePage from "../pages/SelfiePage";
import SelfiePhotoPage from "../pages/SelfiePhotoPage";
import SelfieResult from "../pages/SelfieResult";
import ContactsPage from "../pages/ContactsPage";
import CredentialsPage from "../pages/CredentialsPage";
import SignYourContractPage from "../pages/SignYourContractPage";
import DonePage from "../pages/DonePage";
import PassportIdcardPage from "../pages/PassportIdcardPage";
import PassportPage from "../pages/PassportPage";
import PassportPhotoPage from "../pages/PassportPhotoPage";
import PassportPhotoResultPage from "../pages/PassportPhotoResultPage";
import IdCardFront from "../pages/IdCardFront";
import IdcardPhotoFrontPage from "../pages/IdcardPhotoFrontPage";
import IdCardFrontResultPage from "../pages/IdCardFrontResultPage";
import IdCardBackPage from "../pages/IdCardBackPage";
import IdcardPhotoBackPage from "../pages/IdCardPhotoBackPage";
import IdCardBackResultPage from "../pages/IdCardBackResultPage";
import NotDutchDocumentDataPage from "../pages/NotDutchDocumentDataPage";

import {BrowserView, MobileView} from 'react-device-detect';
import DLFrontPhotoPageComputer from "../pages/DLFrontPhotoPageComputer";
import DLBackPhotoPageComputer from "../pages/DLBackPhotoPageComputer";
import PassportPhotoPageComputer from "../pages/PassportPhotoPageComputer";
import IdcardPhotoFrontPageComputer from "../pages/IdcardPhotoFrontPageComputer";
import IdcardPhotoBackPageComputer from "../pages/IdcardPhotoBackPageComputer";

const PageManager = () => {

    const currentPage = useSelector(state => state.currentPage)

    switch (currentPage) {
        case 'index-page':
            return (
                <IndexPage/>
            )

        case 'document-verification':
            return (
                <DocumentVerification/>
            )

        case 'dl-front-page':
            return (
                <DLFrontPage/>
            )


        case 'dl-front-page-photo':
            return (
                <>
                    <BrowserView>
                        <DLFrontPhotoPageComputer/>
                    </BrowserView>
                    <MobileView>
                        <DLFrontPhotoPage/>
                    </MobileView>
                </>
            )

        case 'dl-front-page-result':
            return (
                <DLFrontPageResult/>
            )

        case 'dl-back-page-result':
            return (
                <DLBackPageResult/>
            )

        case 'dl-back-page':
            return (
                <DLBackPage/>
            )

        case 'dl-back-page-photo':
            return (
                <>
                    <BrowserView>
                        <DLBackPhotoPageComputer/>
                    </BrowserView>
                    <MobileView>
                        <DLBackPagePhoto/>
                    </MobileView>
                </>
            )

        case 'document-data-page':
            return (
                <DocumentDataPage/>
            )

        case 'selfie-page':
            return (
                <SelfiePage/>
            )

        case 'selfie-photo-page':
            return (
                <SelfiePhotoPage/>
            )

        case 'selfie-result-page':
            return (
                <SelfieResult/>
            )

        case 'contacts-page':
            return (
                <ContactsPage/>
            )

        case 'credentials-page':
            return (
                <CredentialsPage/>
            )

        case 'sign-your-contract-page':
            return (
                <SignYourContractPage/>
            )

        case 'done-page':
            return (
                <DonePage/>
            )

        case 'passport-idcard-page':
            return (
                <PassportIdcardPage/>
            )

        case 'passport-page':
            return (
                <PassportPage/>
            )

        case 'passport-photo-page':
            return (
                <>
                    <BrowserView>
                        <PassportPhotoPageComputer/>
                    </BrowserView>
                    <MobileView>
                        <PassportPhotoPage/>
                    </MobileView>
                </>
            )

        case 'passport-photo-result-page':
            return (
                <PassportPhotoResultPage/>
            )

        case 'idcard-front-page':
            return (
                <IdCardFront/>
            )

        case 'idcard-photo-front-page':
            return (
                <>
                    <BrowserView>
                        <IdcardPhotoFrontPageComputer/>
                    </BrowserView>
                    <MobileView>
                        <IdcardPhotoFrontPage/>
                    </MobileView>
                </>
            )

        case 'idcard-front-result-page':
            return (
                <IdCardFrontResultPage/>
            )

        case 'idcard-back-page':
            return (
                <IdCardBackPage/>
            )

        case 'idcard-photo-back-page':
            return (

                <>
                    <BrowserView>
                        <IdcardPhotoBackPageComputer/>
                    </BrowserView>
                    <MobileView>
                        <IdcardPhotoBackPage/>
                    </MobileView>
                </>

            )

        case 'idcard-back-result-page':
            return (
                <IdCardBackResultPage/>
            )

        //RP FRONT
        case 'rp-front-page':
            return (
                <PassportIdcardPage/>
            )

        //RP FRONT PHOTO
        case 'rp-photo-front-page':
            return (
                <PassportIdcardPage/>
            )

        //RP FRONT RESULT
        case 'rp-front-result-page':
            return (
                <PassportIdcardPage/>
            )

        //RP BACK
        case 'rp-back-page':
            return (
                <PassportIdcardPage/>
            )

        // RP BACK PHOTO
        case 'rp-photo-back-page':
            return (
                <PassportIdcardPage/>
            )

        // RP BACK PHOTO RESULT
        case 'rp-back-result-page':
            return (
                <PassportIdcardPage/>
            )

        case 'not-dutch-document-data-page':
            return (
                <NotDutchDocumentDataPage/>
            )

        default:
            return (
                <div>Page Not Found</div>
            )
    }
}

export default PageManager