import React from 'react'
import InputMask from "react-input-mask";
import {TextField} from "@mui/material";
import {findError} from "../services/findError";

const AppInputMaskedDate = ({value, label, onChangeHandler, name, errors}) => {
    return (
        <InputMask value={value} mask="39-19-9999"
                   formatChars={
                       {
                           '3': '[0-3]',
                           '9': '[0-9]',
                           '1': '[0-1]'
                       }
                   }
                   onChange={onChangeHandler}>
            {
                (inputProps) => <TextField
                    style={{width: '100%'}}
                    {...inputProps}
                    name={name}
                    error={findError(name, errors)}
                    placeholder="dd-mm-yyyy"
                    InputLabelProps = { {  shrink : true  } }
                    value={value}
                    label={label}
                    variant="outlined" />
            }

        </InputMask>
    )
}

export default AppInputMaskedDate