import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

export default function AppPopupErrors({
                                     open,
                                     handleClose,
                                     title,
                                     errors,
                                     closeText = 'close',
                                     handleNext = false,
                                     nextText = 'yes'
                                 }) {

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className="popup-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText className="popup-content-wrapper">
                        <ul>
                            {
                                errors.map((field, index) => {
                                    return (
                                        <li key={index}>{field.message}</li>
                                    )
                                })
                            }
                        </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button className="cancelButtonText" onClick={handleClose} color="primary">
                        {closeText}
                    </Button>
                    {handleNext ?
                        (
                            <Button className="nextButtonText"
                                    onClick={handleNext}
                                    variant="contained"
                                    color="primary">
                                {nextText}
                            </Button>
                        )
                        :
                        false
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}