import React, {useEffect} from 'react'
import {Button, Grid} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {changePage} from "../redux/actions"
import DLFrontImage from '../assets/dlfront_res.jpg'
import AppHeader from "../components/AppHeader";
import ReactGA from "react-ga";

const DLFrontPage = () => {

    const dispatch = useDispatch()

    const handleNextStep = () => {
        dispatch(changePage({pageName: 'dl-front-page-photo'}))
    }

    const lang = useSelector(state => state.lang)

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'DL Front Page'
        });
    }, [])

    return (
        <Grid container spacing={2}
              style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4px'}}>
            <AppHeader/>

            <h1 className="indexPageTitle hide-mobile">{lang.take_a_photo_of_the_front}</h1>

            <Grid className="containerAlignBottom" container item spacing={2} xs={11}>
                <Grid container item xs={12} className="desktop-take-photo-center">

                    <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} item xs={12} md={4}>
                        <h1 className="secondH1Title hide-desktop">{lang.take_a_photo_of_the_front}</h1>
                        <img className="document-image" src={DLFrontImage} alt=""/>
                    </Grid>

                    <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} item md={4} xs={12}>
                        <h2 style={{fontWeight: 'bold'}}>{lang.attention}</h2>
                        <ol className="ol-list" type="1">
                            <li>
                                {lang.at_1}
                            </li>
                            <li>
                                {lang.at_2}
                            </li>
                        </ol>
                        <Button
                            className="main-button main-button-register take-photo-btn hide-mobile"
                            onClick={handleNextStep}
                            variant="contained">{lang.take_a_photo}</Button>
                    </Grid>

                </Grid>
                <Grid
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end'}}
                    item xs={12}>
                    <Button
                        className="main-button main-button-register hide-desktop"
                        onClick={handleNextStep}
                        variant="contained">{lang.take_a_photo}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DLFrontPage
