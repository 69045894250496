const nl = {
    'create_account': 'Maak een <br> account aan',
    'to_register_you_will_need': 'Om te registeren heeft u nodig:',
    'reg_term_1': 'Een geldig rijbewijs (categorie B)',
    'reg_term_2': 'Een mobiel telefoonnummer',
    'reg_term_3': 'Een emailadres',
    'register': 'Registreer',
    'sign_1': 'Ik verklaar dat mijn rijbewijs echt is',
    'sign_2': 'Ik ga ermee akkoord dat mijn foto’s en persoonlijke gegevens zichtbaar worden opgeslagen voor onze medewerkers.',
    'cancel': 'Annuleer',
    'confirm': 'Bevestig',
    'document_verification': 'Documentverificatie',
    'dv_1': 'Maak een foto van het rijbewijs',
    'dv_2': 'Check of alle gegevens kloppen',
    'dv_3': 'Maak een selfie    ',
    'continue': 'Ga verder',
    'take_a_photo_of_the_front': 'Maak een foto van de voorkant',
    'attention': 'Let op!',
    'at_1': 'We accepteren alleen foto’s van het originele rijbewijs',
    'at_2': "Maak geen foto's van kopieën van documenten of afbeeldingen van andere apparaten",
    'take_a_photo': 'Maak een foto',
    'camera_text_1': 'Houd de camera parallel aan het oppervlak',
    'camera_text_2': 'Druk op de knop om een foto te maken...',
    'take_a_photo_of_the_back': 'Maak een foto van de achterkant',
    'drivers_license': 'Rijbewijs',
    'selfie': 'Selfie',
    'name': 'Naam',
    'surname': 'Achternaam',
    'country_of_issue': 'Land van uitgifte van het rijbewijs',
    'drivers_license_number': 'Rijbewijsnummer',
    'expiration_date': 'Verloopdatum',
    'date_of_birth': 'Geboortedatum',
    'category_b': 'Categorie B',
    'dl_problem': 'Er is iets mis met je rijbewijs',
    'please_call_us': 'Bel ons alstublieft <a href="tel:+31880883434">088 - 088 34 34</a>',
    'close': 'Sluiten',
    'is_info_correct': 'Is de ingevulde informatie correct?',
    'yes': 'Ja',
    'no': 'Nee',
    'ok': 'Ja',
    'not_dutch_dl': 'Omdat u geen Nederlands rijbewijs heeft is het verplicht om ook een foto van een andere ID in te sturen',
    'choose_the_type_of_your_document': 'Kies uw identificatiebewijs',
    'passport': 'Paspoort',
    'id_card': 'ID kaart ',
    'passport_id_card_number': 'Paspoort / ID kaart nummer',
    'zip_code': 'Postcode',
    'house_number': 'Huisnummer',
    'number_addition': 'Toevoeging',
    'street': 'Straat',
    'city': 'Gemeente',
    'country': 'Land',
    'use_the_same': 'Gebruik hetzelfde adres voor de factuur',
    'email': 'Email',
    'phone': 'Telefoon',
    'credentials': 'Inloggegevens',
    'set_24_password': 'Stuur mij een 24 uurs wachtwoord per sms',
    'sign_your_new_contract': 'Teken je nieuwe contract',
    'agree_1': 'Ik verklaar hierbij dat ik de Algemene Voorwaarden heb gelezen en ermee akkoord ga',
    'agree_2': 'Hierbij verklaar ik dat ik de huisregels heb gelezen en hiermee akkoord ga',
    'agree_3': 'Ik verklaar hierbij dat ik het klantcontract heb gelezen en ermee akkoord ga',
    'agree_4': 'Ik verklaar hierbij dat ik de procedure uitleg heb gelezen en ermee akkoord ga',
    'agree': 'Mee eens',
    'welcome': 'Welkom!',
    'rent_a_bus': 'Huur een bus',
    'get_the_app': 'Download de App!',
    'already_account_title': 'Je hebt al een account!',
    'already_account_text': 'Download de applicatie om door te gaan',
    'download': 'Downloaden',
    'contacts': 'Contactgegevens',
    'the_are_problems_with_your_camera': '',
    'permission_to_camera': 'Er is geen camera aanwezig. Gebruik uw mobiele telefoon om de registratie af te ronden.',
    'home_zip_code': 'Postcode thuis',
    'home_house_number': 'Home Huisnummer',
    'home_street': 'Thuisstraat',
    'home_city': 'Thuis stad',
    'invoice_zip_code': 'Factuur postcode',
    'invoice_house_number': 'Factuur Huisnummer',
    'invoice_street': 'Factuurstraat',
    'invoice_city': 'Factuur Stad',
    'you_did_not_provide': 'Je hebt niet verstrekt:',
    'home_address': 'Huisadres',
    'invoice_address': 'Factuuradres',
    'error': 'Fout',
    'password': 'Wachtwoord',
    'pas_req_1': 'Minimaal 7 karakters',
    'pas_req_2': 'Minimaal 1 cijfer en 1 letter',
    'repeat_password': 'Herhaal wachtwoord',
    'pas_error': 'Wachtwoorden moeten overeenkomen en complex zijn',
    'photo_auto': 'De foto wordt automatisch gemaakt, wacht alstublieft...',
    'front_of_id_card': 'Voorkant ID kaart',
    'document_number': 'Document Nummer',
    'selfie_pic_text': "Maak geen foto's van uzelf vanaf andere foto's of andere apparaten.",
    'make_selfie': 'Selfie maken',
    'already_account': 'je hebt al een account!',
    'customer_with_email_1': 'De klant met',
    'customer_with_email': 'emailadres',
    'already_exists': 'bestaat al..',
    'call_us_2': 'Bel ons alstublieft of reset uw wachtwoord',
    'reset_password': 'Herstel wachtwoord',
    'call_us': 'Bel ons',
    'dl_exp_date': 'Verloopdatum rijbewijs',
    'no_need': 'Nee',
    'recovery_link_message': 'Een herstellink is naar uw emailadres gestuurd',

    'no_active_res': 'Geen actieve reserveringen',
    'active_reservation': 'Actieve reservering',
    'license_plate': 'Kenteken',
    'location': 'Locatie',
    'pick_up_date': 'Ophaaldatum',
    'return_date': 'Retourdatum',
    'damage_report': 'Schade-melding',
    'navigate_to_vehicle': 'NAVIGEREN NAAR VOERTUIG',
    'open_doors': 'Open deuren',
    'manage_reservation': 'Beheer reservering',
    'open_doors_1': 'U staat op het punt de deuren te openen',
    'open_doors_2': 'Weet u zeker dat u de reservering wilt starten?',
    'open_doors_3': 'Volg onderstaande stappen om de rit te starten:',
    'open_doors_4': 'Houder en de tankpass voor de sleutel en de tankpas in het dashboardkastje',
    'open_doors_5': 'Pak de sleutelhanger met druppel sleutelhouder',
    'open_doors_6': 'Start de auto',
    'open_doors_7': 'U kunt nu gaan rijden. Goede reis!',
    'future_reservations': 'Toekomstige reserveringen',
    'no_future_reservations': 'Er zijn geen toekomstige reserveringen',
    'cancel_reservation': 'Wilt u de toekomstige reservering annuleren?',
    'cancel_free': 'Annuleringskosten',
    'read_terms': 'Lees verder:',
    'help': 'Assistentie',
    'calculate': 'Berekenen',
    'add_reservation': '+ Reservering',
    'res_not_1': 'De prijs van de reservering is inclusief een borg van <b>€200</b>',
    'account_problem_1': "Uw account wordt beoordeeld! <br> Het duurt meestal maximaal 15 minuten, <br> als u uw account onmiddellijk wilt activeren, bel dan het call center: <br> <a href='tel:+31880883434'>+31 88 088 3434</a>",
    'geo_request': 'Om een correcte werking van het systeem te garanderen, moet u toegang verlenen tot <b>geolocatie</b>',
    'profile': 'Profiel',
    'res_history': 'Reserveringsgeschiedenis',
    'tr_history': 'Transactiegeschiedenis',
    'terms_of_use': 'Gebruiksvoorwaarden',
    'language': 'Taal',
    'manage_res': 'Beheer reservering',
    'want_open_doors': 'Wilt u echt de deuren openen?',
    'want_close_doors': 'Wilt u echt de deuren sluiten?',
    'reg_term_4': 'Web- / telefooncamera',
    'have_account': 'ik heb al een account',
    'login': 'Log in',
    'questions_call_us': 'Vragen? Bel ons',
    'send': 'Versturen',
    'reset_1': 'Instructies voor wachtwoordherstel worden hier verzonden',
    'or_return': 'of keer terug',
    'enter_reset_1': 'Voer het e-mailadres in waarmee u zich',
    'enter_reset_2': 'heeft aangemeld',
    'dashboard': 'Home',
    'no_geo': "Geolocatie is uitgeschakeld of er is geen toegang verleend",
    'report_a_new_damage': 'Meld een nieuwe schade',
    'select_location': 'Selecteer een locatie',
    'left_front': 'Links voor',
    'right_front': 'Recht voor',
    'hood': 'Kap',
    'roof': 'Dak',
    'door_left_front': 'Deur links voor',
    'door_left_back': 'Deur links achter',
    'door_right_front': 'Deur rechts voor',
    'door_right_back': 'Deur rechts achter',
    'left_back': 'Links achter',
    'right_back': 'Zo terug',
    'trunk': 'Kofferbak',
    'other': 'Ander',
    'description': 'Beschrijving',
    'upload_damage_report': '+ UPLOADEN schaderapport',
    'add_document': 'Document toevoegen',
    'bank_statement': 'Bankafschrift of screenshot van internetbankieren',
    'residence_permit': 'Verblijfsvergunning',
    'image': 'Afbeelding',
    'upload_image': 'Afbeelding uploaden',
    'upload': 'UPLOADEN',
    'front': 'Voorzijde',
    'back': 'Achterzijde',
    'number': 'Nummer',
    'expiration': 'Vervaldatum',
    'category': 'Categorie',
    'reset_1_text': 'Een link om uw wachtwoord opnieuw in te stellen',
    'reset_2_text': 'is naar uw e-mail verzonden',
    'reset_3_text': 'Vergeet niet uw spamfolder te controleren',
    'logout': 'Uitloggen',
    'want_logout': 'Wilt u uitloggen?',
    'bank_info': 'Bankinformatie',
    'need_bank': 'We hebben uw bankrekeninggegevens nodig om een terugbetaling te doen',
    'owner_name': 'Naam rekeninghouder',
    'or': 'OF',
    'pay': 'Betaal',
    'with_ideal': '',
    'delete': 'Verwijderen',
    'confirm_deletion': 'Bevestig verwijdering',
    'confirm_deletion_1': 'Weet u zeker dat u het document wilt verwijderen?',
    'years': 'jaar',
    'months': 'maanden',
    'days': 'dagen',
    'expired': 'NIET MEER GELDIG',
    'expires_in': 'Verloopt in',
    'from': 'Van',
    'to': 'Tot',
    'extend_reservation': 'Reservering verlengen',
    'minutes': 'minuten',
    'extend_text_1': 'Deze reservering is verlengd met',
    'in': 'in',
    'reservation': 'Reservering',
    'gasoline_1': 'Geachte klant, u heeft',
    'gasoline_2': 'brandstof in de tank.',
    'gasoline_3': 'Graag voltanken (met de tankpas)',
    'gasoline_4': '',
    'use_current_location': 'Huidige locatie gebruiken',
    'disabled': 'uitgeschakeld',
    'vehicle_result_out_of_date': "Voertuigresultaten zijn verouderd klik op 'Update'",
    'update': 'Update',
    'res_time_changed': "Uw reserveringstijd is automatisch gewijzigd met <b>15 minuten</b>",
    'current_location': 'Huidige locatie',
    'top_up_balance': 'Saldo opwaarderen',
    'reclaim_balance': 'Saldo terugvorderen',
    'in_progress': 'Bezig',
    'enter_correct_pin_code': 'Voer de juiste pincode in',
    'pwa_1': 'Gebruik als mobiele app.',
    'pwa_2': "Klik hiervoor op 'Delen'",
    'pwa_3': 'en selecteer \'Ga naar scherm\' - Home',
    'pwa_4': 'Druk op de installatieknop',
    'install': 'Installeren',
    'pay_again': 'OPNIEUW BETALEN',
    'rejected': 'Afgekeurd',
    'no_vehicle_for_crit': 'Geen voertuigen beschikbaar voor de geselecteerde criteria!',
    'vehicle_selection': 'Voertuigselectie',
    'transmission': 'Versnelling',
    'distance_to_vehicle': 'Afstand tot voertuig',
    'manual': 'Handmatig',
    'auto': 'Auto',
    'reset_filter': 'RESET FILTER',
    'filter_settings': 'Filterinstellingen',
    'loading_space': 'Laadruimte',
    'hours': 'uur',
    'set_pin_code': 'Voer de nieuwe pincode in',
    'success': 'Gelukt',
    'updated_at': 'Bijgewerkt op',
    'unavailable': 'niet beschikbaar',
    'navigate': 'NAVIGEREN',
    'continue_1': 'DOORGAAN',
    'calculator_1': 'Rekenmachine',
    'calculator_2': 'Hoe lang wilt u huren?',
    'hour': 'uur',
    'calculator_3': 'Geschatte reisafstand',
    'calculator_4': 'Geschatte kosten excl. BTW',
    'calculator_5': 'Geschatte kosten incl. BTW',
    'calculator_6': 'Tarieven',
    'calculator_7': 'zijn inclusief brandstof!',
    'calculator_8': '',
    'calculator_9': 'Geen eigen risico bij schade',
    'confirm_1': 'Het door u gekozen voertuig wordt pas na betaling definitief gereserveerd',
    'confirm_2': 'Bevestig reservering',
    'day': 'dag',
    'for': 'voor',
    'future_reservation': 'Toekomstige reservering',
    'vehicle': 'Voertuig',
    'total_price': 'Totale prijs',
    'cancel_reservation_1': 'Annuleer reservering',
    'cancel_reservation_2': 'Wilt u uw reservering annuleren?',
    'confirm_reservation_1': 'Bevestig reservering',
    'do_you_want_to_confirm': 'Als u uw reservering annuleert, worden er annuleringskosten in rekening gebracht: €5,- bij annulering meer dan 24 uur voor aanvang van de reservering en €19,50  bij annulering minder dan 24 uur voor aanvang van de reservering.',
    'enable_nofitications': 'Notificaties aanzetten',
    'nofitications_1': 'Schakel meldingen in om herinneringen te ontvangen over uw boeking',
    'turn_on': 'Aanzetten',
    'notifications_2': 'Uw reserveringstijd is automatisch gewijzigd met <b>15 minuten</b>',
    'damage_report_title': 'Schaderapport',
    'damage_report_1': 'als de afbeelding niet laadt, ververs dan de resultaten',
    'damage_report_new': 'MELD EEN NIEUWE SCHADE',
    'end_reservation_not_like': 'Wat vond je precies niet leuk, laat dan een reactie achter',
    'end_reservation_2': 'Vond je onze service leuk?',
    'not_really': 'Niet echt',
    'you_can_contact_us': 'U kunt contact met ons opnemen met',
    'help_1': 'Huur een busje 24/7 - Geen eigen risico',
    'help_2': '19,50 per uur, maximaal 6 uur per dag (24 uur)',
    'fuel_type': 'Brandstoftype',
    'fuel_level': 'Brandstofniveau',
    'fuel_card_pin': 'Pincode tankkaart',
    'unlock_doors': 'deuren openen',
    'lock_doors': 'deuren op slot',
    'navigate_to_return_location': 'Navigeer naar terugkeerlocatie',
    'end_reservation': 'Reservering beëindigen',
    'end_res_1': 'Weet je zeker dat u de reservering wilt beëindigen?',
    'end_res_2': 'In de volgende stappen laten we zien hoe u ',
    'end_res_3': 'de sleutel terugplaatst in de houder',
    'end_res_4': 'stap uit het voertuig',
    'end_res_5': 'de deuren op slot doen',
    'step': 'Stap',
    'next': 'VOLGENDE',
    'step_1': "Stap 1: plaats de zwart-witte druppel terug in de daarvoor bestemde opening van de sleutelhouder en druk op 'Volgende'.",
    'step_2': "Als de zwart-witte druppel niet terug in de opening is, gaan de deuren niet dicht!",
    'step_3': "Verlaat het voertuig",
    'step_4': "Druk niet op de . totdat u uit het voertuig bent ",
    'step_5': '"Reservering beëindigen"',
    'step_6': "knop!",
    'step_7': "Zorg ervoor dat u zich buiten het voertuig bevindt voordat u op de knop 'Reservering beëindigen' drukt",
    'step_8': "Neem bij het verlaten van het voertuig al uw persoonlijke bezittingen mee.",
    'step_9': "",
    'step_10': "",
    'end_reservation_btn': 'EINDE RESERVERING',
    'min_res_time': 'Minimale reserveringstijd',
    'min': 'min',
    'time_interval': 'Tijdsinterval ophalen',
    'tr_distance': 'Geschatte reisafstand',
    'balance_not_less': 'Balans mag niet minder zijn dan',
    'fill_address_or_geo': 'Vul het adresveld in of geef toegang tot de huidige geolocatie of controleer de vervaldatum van het rijbewijs',
    'res_auto': 'Reservering EINDtijd automatisch gewijzigd!',
    'payment': 'Betaling',
    'you_have_to_pay': 'U dient te betalen',
    'pay_1': 'De betalingsmodule opent in een nieuw venster,',
    'pay_2': 'ga na voltooiing terug',
    'pin_code': 'Pincode',
    'documents': 'Documenten',
    'document': 'document',
    'delete_account': 'Verwijder profiel',
    'delete_account_1': 'Uw account wordt samen met uw persoonlijke gegevens verwijderd, wilt u doorgaan?',
    'reclaim_1': 'Het terugbetaalverzoek is succesvol verzonden, het bedrag wordt binnen 2 werkdagen bijgeschreven.',
    'reclaim_2': 'Vergeet niet het minimaal benodigde bedrag op de balans te laten voor de volgende huur',
    'balance_is': 'Balans is',
    'reclaim_3': 'U kunt niet meer geld claimen dan u op uw balans heeft staan!',
    'claim': 'Claim',
    'res_h_1': 'De lijst met ritten is voorlopig leeg, maar dit is eenvoudig op te lossen door',
    'res_h_2': 'het starten van de eerste reservering',
    'call_us_1': 'Heeft u een vraag? Bel ons: +31 88 088 3434',
    't_p_1': '€ 19,50 per uur (incl. btw)',
    't_p_2': 'Geen financieel risico bij schade',
    't_p_3': 'Dagprijs max € 117,- per 24 uur (incl. BTW) - 45 cent per KM (incl. BTW en brandstof)',
    'select_payment_amount': 'Selecteer het te betalen bedrag',
    'amount': 'Hoeveelheid',
    'last_10_tr': 'De laatste 10 transacties',
    'tr_list_empty': 'Transactielijst is leeg',
    'see_whole_tr_history': 'Uw document is verlopen, ga naar het profielgedeelte en werk de gegevens bij',
    'document_expired_global': 'Uw {document} is verlopen, ga naar het profielgedeelte en werk de gegevens bij',
    'document_expired_manage': 'Uw rijbewijs verloopt voordat de reservering afloopt. Wijzig de einddatum van de reservering of verleng uw rijbewijs in het profielgedeelte',
    'deposit': 'Borg',
    'reservation_price': 'Reserveringsprijs',
    'van_busy': 'Dit busje is bezet tijdens de volgende periodes',
    'how_to_give_permissions': 'Hoe krijg je toegang tot de camera?',
    'video_perm_1': 'Geef aub toegang tot uw camera of ga door met registreren op uw smartphone',
    'video_perm_2': 'Geef aub toestemming om uw camera te gebruiken',
    'video_perm_3': 'U heeft geen camera of deze is geblokkeerd op uw toestel',
    'call_us_12': 'Vragen? Bel ons: <a href="tel:+31880883434"> +31 88 088 34 34',
    'select_pick_up_time': 'Ophaaltijd',
    'select': 'Selecteer',
    'no_date_of_birth': 'Geboortedatum is niet ingevuld',
    'no_drivers_license': 'Rijbewijsnummer is niet ingevuld',
    'driversLicenseNotChecked': "Uw rijbewijs wordt gecontroleerd",
    'invalidDriversLicense': "Uw rijbewijs is niet goedgekeurd, neem contact op met de helpdesk om het probleem op te lossen",
    'amountDue': "Het bedrag op uw balans is te laag om een nieuwe reservering te maken. Dit komt voor als u al een reservering heeft lopen.",
    'invalidScreening': "Uw account wordt gecontroleerd",
    'manage_reservation_popup': 'Om uw reservering aan te passen dient u de website op uw mobiele telefoon te openen.',
    'confirm_bunq': 'Bevestig de betaling aan Bunq om de betaalopdracht af te ronden',
    'geo_request_2': 'Om de app correct te gebruiken dient u toegang te geven aan <b>geolocation</b> <br> ' +
        '<a style="color: gray" target="_blank" href="https://kav2go.nl/how-to-grant-the-necessary-permissions-in-the-browser/">Toegang verlenen tot geolocatie</a>',
    'iban_get_bank_info': 'Om uw bankrekeningnummer te verkrijgen kunt u uw gegevens hieronder invullen of ons 1 cent betalen via de ideal knop',
    'location_settings': 'Locatie instellingen',
    'gasoline_5': 'tot een volle tank.',
    'reset': 'Resetten',
    'return_time': 'Tijd van terugkomst',
    'time_interval_end': 'Tijdsinterval retourneren',
    'calculator_10': 'Getoonde tarieven',
    'calculator_11': 'zijn indicatief en hieraan kunnen geen rechten worden ontleend.',
    'general': 'Algemeen',
    'you_have_active_reservation': 'Er is al een actieve reservering gaande. U kunt daarom nu geen tweede reservering maken.',
    'read_terms_1': 'Lees de voorwaarden',
    'deposit_info_text': "<b style='text-align: center; width: 100%; display: block'>Borgregeling</b> <br> Klanten die voor het eerst huren betalen: € 200 <br> <br> Terugkerende klanten betalen: € 300 <br> <br> Alle klanten die huren bij onze 24/7 Pick Up Points betalen: € 300",
    'call_center_day_reason': "U staat op het punt een bestelwagen voor meer dan één dag te huren. Neem contact met ons op om de staat vast te stellen. <br> <a href='tel:+31880883434'>Bel: 088 - 088 34 34</a>",
    "please_wait_loading": "Even geduld a.u.b. laden...",
    'pick_up_time_wrong': 'De startdatum of -tijd van de boeking kan niet vroeger zijn dan de huidige tijd'
}

export default nl
