import React, {useEffect, useState} from 'react'
import kavPlaceholder from '../assets/kavlogo.jpg'
import AppReservationHeader from "../components/AppReservationHeader";
import AppPopup from "../components/AppPopup";
import {useHistory, useParams} from "react-router-dom";
import {Button, Grid} from "@mui/material";
import TextStyled from "../styles/TextStyled";
import AppSidebar from "../components/AppSidebar";
import {BrowserView, MobileView, isBrowser, isMobile} from 'react-device-detect'
import {useSelector} from "react-redux";
import ReactGA from "react-ga";

const EndReservationPage = () => {

    const lang = useSelector(state => state.lang)
    const {id} = useParams()
    const history = useHistory()
    const [isLikeOpen, setIsLikeOpen] = useState(false)
    const [isDislikeOpen, setIsDislikeOpen] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [description, setDescription] = useState('')

    const uploadHandler = () => {
        history.push('/dashboard')
    }

    // useEffect(() => {
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function(event) {
    //         window.history.pushState(null, document.title, window.location.href);
    //     });
    // }, [])

    useEffect(() => {
        if (description.length < 5) {
            setIsValid(false)
        } else {
            setIsValid(true)
        }
    }, [description])

    return (
        <div className="app-page-wrapper">
            {/*<AppReservationHeader />*/}
            {/*<AppSidebar reservationActive={true}/>*/}

            <span
                onClick={() => {

                    ReactGA.initialize("UA-211006469-1", {
                        debug: false
                    })

                    ReactGA.event({
                        category: 'pwa',
                        action: 'Closing the quality assessment'
                    });

                    history.push('/dashboard')
                }}
                style={{
                color: '#E9392C',
                position: 'fixed',
                fontSize: '48px',
                cursor: 'pointer',
                right: '10px',
                top: '0'
            }}>&times;</span>

            {
                isDislikeOpen ?
                    <Grid container spacing={2} style={{marginTop: '100px'}}>
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <img style={{maxWidth: '500px', width: '100%', height: 'auto'}} src={kavPlaceholder} alt=""/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextStyled>
                                <p style={{textAlign: 'center'}}>{lang.end_reservation_not_like}:</p>
                            </TextStyled>
                        </Grid>
                        <Grid item xs={12}>
                            <textarea
                                style={{
                                    height: '100px',
                                    resize: 'none',
                                    fontFamily: "Open Sans",
                                    padding: '5px',
                                    fontSize: '16px',
                                    width: '100%',
                                    borderRadius: '4px'
                                }}
                                value={description} onChange={(e) => {setDescription(e.target.value)}} />
                        </Grid>
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <Button onClick={uploadHandler}
                                    style={{textTransform: 'none', borderRadius: '30px', color: 'white', backgroundColor: '#E9392C'}}
                                    variant="contained"
                                    disabled={!isValid}
                                    className="nextButtonTextWidth"
                                    color="primary">{lang.send}</Button>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2} style={{marginTop: '100px'}}>
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <img style={{maxWidth: '500px', width: '100%', height: 'auto'}} src={kavPlaceholder} alt="" />
                        </Grid>
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                            <TextStyled>
                                <p style={{fontWeight: 'bold', fontSize: '18px'}}>{lang.end_reservation_2}</p>
                            </TextStyled>
                        </Grid>
                        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
                            <Grid item xs={6} style={{display: 'flex', justifyContent: 'center'}}>
                                <Button className="cancelButtonText"
                                        onClick={() => {
                                            setIsLikeOpen(false)
                                            setIsDislikeOpen(true)
                                        }}
                                        color="primary">
                                    {lang.not_really}
                                </Button>
                            </Grid>
                            <Grid item xs={6} style={{display: 'flex', justifyContent: 'center'}}>
                                <Button className="nextButtonText"
                                        variant="contained"
                                        onClick={() => {
                                            if (isMobile) {
                                                window.open("https://www.google.com/maps/place/KAV2GO,+Klokkenbergweg,+1101+AK+Amsterdam-Zuidoost,+%D0%9D%D0%B8%D0%B4%D0%B5%D1%80%D0%BB%D0%B0%D0%BD%D0%B4%D1%8B/@52.3023229,4.9369905,16z/data=!4m2!3m1!1s0x47c60be57874f391:0xc61828ecbee2268", '_blank')
                                            } else {
                                                window.open("https://www.google.com/maps/place/KAV2GO/@52.3023229,4.9348018,17z/data=!4m7!3m6!1s0x47c60be57874f391:0xc61828ecbee2268!8m2!3d52.3023229!4d4.9369905!9m1!1b1", '_blank')
                                            }

                                            history.push('/dashboard')
                                        }}
                                        color="primary">
                                    {lang.yes}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
            }


            <AppPopup
                open={isLikeOpen}
                title=""
                description={`
<div style="display: flex; flex-direction: column; align-items: center">
<img src="${kavPlaceholder}" alt="">
                <p>${lang.end_reservation_2}</p>
</div>
                
                `}
                nextText={`${lang.yes}`}
                handleNext={() => {
                    setIsLikeOpen(false)

                    ReactGA.initialize("UA-211006469-1", {
                        debug: false
                    })

                    ReactGA.event({
                        category: 'pwa',
                        action: 'Like'
                    });

                    window.open("https://www.google.com/maps/place/KAV2GO/@52.3023229,4.9348018,17z/data=!4m7!3m6!1s0x47c60be57874f391:0xc61828ecbee2268!8m2!3d52.3023229!4d4.9369905!9m1!1b1", '_blank')
                    history.push('/dashboard')
                }}
                closeText={`${lang.not_really}`}
                handleClose={() => {

                    ReactGA.initialize("UA-211006469-1", {
                        debug: false
                    })

                    ReactGA.event({
                        category: 'pwa',
                        action: 'Dislike'
                    });

                    setIsLikeOpen(false)
                    setIsDislikeOpen(true)
                }}
            />

        </div>
    )
}

export default EndReservationPage
