import React, {useEffect, useState} from 'react'
import SkyeTimePickerStyled from "../styles/SkyeTimePickerStyled";
import {Button, FormControl, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import filterTimeFormatted from "../services/filterTimeFormatted";
import {useSelector} from "react-redux";

const SkyeTimePicker = ({value, onChange}) => {

    const lang = useSelector(state => state.lang)
    const [isActive, setIsActive] = useState(false)
    const [isTimeOkay, setIsTimeOkay] = useState(true)
    const [needAnimation, setNeedAnimation] = useState(false)

    const hours = [
        {label: '00', value: 24},
        {label: '01', value: 1},
        {label: '02', value: 2},
        {label: '03', value: 3},
        {label: '04', value: 4},
        {label: '05', value: 5},
        {label: '06', value: 6},
        {label: '07', value: 7},
        {label: '08', value: 8},
        {label: '09', value: 9},
        {label: '10', value: 10},
        {label: '11', value: 11},
        {label: '12', value: 12},
        {label: '13', value: 13},
        {label: '14', value: 14},
        {label: '15', value: 15},
        {label: '16', value: 16},
        {label: '17', value: 17},
        {label: '18', value: 18},
        {label: '19', value: 19},
        {label: '20', value: 20},
        {label: '21', value: 21},
        {label: '22', value: 22},
        {label: '23', value: 23},
    ]

    const minutes = [
        {label: '00', value: 0},
        {label: '15', value: 15},
        {label: '30', value: 30},
        {label: '45', value: 45},
    ]

    const [time, setTime] = useState(value)

    useEffect(() => {
        setTime(value)
    }, [value])

    const onHoursChange = (e) => {
        const newTime = moment(time).set('hour', e.target.value)
        setTime(newTime)
    }

    const onMinutesChange = (e) => {
        const newTime = moment(time).set('minute', e.target.value)
        setTime(newTime)
    }

    const onSubmit = () => {
        onChange(time)
        setIsActive(false)
    }

    useEffect(() => {
        const currentTime = filterTimeFormatted.roundTime(moment(new Date()))
        const newTime = moment(time)
        const timeDifference = filterTimeFormatted.getTimeDiff(currentTime, newTime)
        if (timeDifference.minutes < 0) {
            setIsTimeOkay(false)
        } else {
            setIsTimeOkay(true)
            setNeedAnimation(true)
        }
    }, [time])

    useEffect(() => {
        if (needAnimation == true) {
            setTimeout(() => {
                setNeedAnimation(false)
            }, 1000)
        }
    }, [needAnimation])

    return (
        <SkyeTimePickerStyled>
            <p style={{width: '100%', textAlign: 'left', paddingLeft: '50px', cursor: 'pointer'}}
               onClick={() => {
                   setIsActive(true)
               }}>{filterTimeFormatted.timeFormat(value)}</p>
            {
                isActive ?
                    <>
                        <div onClick={() => {
                            setIsActive(false)
                        }} className="popup-wrapper"></div>
                        <div className="popup">
                            <div className="popup-title-timer"
                                 style={{textAlign: 'center', marginBottom: '10px'}}>{lang.select_pick_up_time}</div>
                            <div className="popup-timer">
                                <span
                                    onClick={() => {
                                        setIsActive(false)
                                    }}
                                    style={{
                                        position: 'absolute',
                                        top: '-5px',
                                        right: '0',
                                        fontSize: '36px',
                                        cursor: 'pointer',
                                        color: 'rgb(233, 57, 44)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '50px'
                                    }}>
                                    &times;
                                </span>
                                <div className="popup-timer-inner">
                                    <FormControl variant="filled">
                                        <Select
                                            style={{
                                                border: 'none',
                                                padding: '5px 0',
                                                width: '100px',
                                                borderRadius: '0',
                                                textAlign: 'center',
                                                borderBottomLeftRadius: '4px',
                                                borderTopLeftRadius: '4px',
                                            }}
                                            label="Age"
                                            value={time.hours() == 0 ? 24 : time.hours()}
                                            onChange={onHoursChange}
                                        >
                                            {
                                                hours.map((item) => {
                                                    return (
                                                        <MenuItem value={item.value}>{item.label}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="filled">
                                        <Select
                                            style={{
                                                border: 'none',
                                                borderRadius: '0',
                                                padding: '5px 0',
                                                width: '100px',
                                                textAlign: 'center',
                                                borderBottomRightRadius: '4px',
                                                borderTopRightRadius: '4px',
                                            }}
                                            label="Age"
                                            value={time.minutes()}
                                            onChange={onMinutesChange}
                                        >
                                            {
                                                minutes.map((item) => {
                                                    return (
                                                        <MenuItem value={item.value}>{item.label}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {
                                !isTimeOkay ?
                                    <>
                                        <p
                                            style={{
                                                color: '#E9392C',
                                                fontSize: '14px',
                                                padding: '0 5px',
                                                marginTop: '10px',
                                                textAlign: 'center'
                                            }}
                                        >{lang.pick_up_time_wrong}</p>
                                    </>
                                    :
                                    ''
                            }
                            <div>

                            </div>
                            <div style={{marginTop: '30px', display: 'flex', justifyContent: 'center'}}>
                                <div>
                                    <Button style={{color: '#fff', backgroundColor: isTimeOkay ? '#E9392C' : 'gray'}}
                                            className={needAnimation ? 'alertInformationSmallAnimation' : ''}
                                            disabled={!isTimeOkay}
                                            onClick={onSubmit}
                                            variant="contained">{lang.confirm} {time.hours()}:{time.minutes() == 0 ? '00' : time.minutes()}</Button>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    ''
            }

        </SkyeTimePickerStyled>
    )
}

export default SkyeTimePicker
