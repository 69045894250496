import React, {useState} from 'react'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useDispatch, useSelector} from "react-redux";
import {changeField} from "../redux/actions";

const SidebarLanguage = () => {

    const [isActive, setIsActive] = useState(false)
    const lang = useSelector(state => state.lang)
    const language = useSelector(state => state.language)
    const dispatch = useDispatch()

    return (
        <div className="sidebar-language-wrapper">
            <div onClick={() => setIsActive(!isActive)} className="sidebar-language">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{textTransform: 'uppercase'}} className="sidebar-active-language">{language}</span>
                    <p>{lang.language}</p>
                </div>
                <ChevronRightIcon style={{marginRight: '10px'}}/>
            </div>
            {
                isActive ?
                    <div className="sidebar-sublanguages">
                        <span onClick={() => {
                            dispatch(
                                changeField({
                                    field: 'language',
                                    value: 'en'
                                })
                            )
                        }}>EN</span>
                        <span
                            onClick={() => {
                                dispatch(
                                    changeField({
                                        field: 'language',
                                        value: 'nl'
                                    })
                                )
                            }}
                        >NL</span>
                    </div>
                    :
                    ''
            }

        </div>
    )
}

export default SidebarLanguage