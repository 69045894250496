import React, {useEffect, useState} from 'react'
import AppSidebar from "../components/AppSidebar";
import {Grid} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import MapComponent from "../components/MapComponent";
import {useHistory} from "react-router-dom";

const MapPage = () => {

    const history = useHistory()

    return (
        <>
            <div className="app-page-wrapper" style={{
                paddingBottom: '0 !important',
                paddingRight: '0',
                paddingLeft: '0',
                overflow: 'hidden',
                marginTop: '10px',
            }}>
                <AppSidebar/>
                <Grid container spacing={2}
                      style={{
                          padding: '10px',
                          position: 'fixed',
                          top: '0',
                          left: '0',
                          zIndex: '50',
                          backgroundColor: '#fff'
                      }}>
                    <AppReservationHeader onBackHandler={() => {
                        history.push('/manage-reservation')
                    }} isBackActive={true}/>
                </Grid>
                <Grid container spacing={0} style={{marginTop: '10px'}}>
                    <Grid item xs={12}>
                        <MapComponent/>
                    </Grid>
                </Grid>

            </div>

        </>
    )
}

export default MapPage