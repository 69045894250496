import React, {useEffect, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, Grid, TextField} from "@mui/material";
import TextStyled from "../styles/TextStyled";
import ideal from '../assets/ideal.png'
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changeField, getClientInfo, triggerPayment, updateClient} from "../redux/actions";

const BankInformationPopup = ({isActive, handleClose}) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const apiToken = useSelector(state => state.apiToken)
    const client = useSelector(state => state.client)
    const lang = useSelector(state => state.lang)

    const [ownerName, setOwnerName] = useState('')
    const [iban, setIban] = useState('')
    const [isValid, setIsValid] = useState(false)

    const onPaymentHandler = () => {
        dispatch(
            changeField({
                field: 'payment',
                value: {
                    amount: 0.01,
                    active: true,
                    information: false
                }
            })
        )

        history.push('/payment/verification_payment')
    }

    const onUpdateHandler = () => {
        dispatch(
            updateClient({
                apiToken,
                clientId: client.id,
                updateObject: {
                    "bank_account": {
                        "owner": ownerName,
                        "iban": iban
                    }
                },
                onSuccess: (res) => {
                    dispatch(
                        getClientInfo({
                            apiToken
                        })
                    )
                    handleClose()
                }
            })
        )
    }

    useEffect(() => {
        let _isValid = true

        if (ownerName.length <= 1) {
            _isValid = false
        }

        if (iban.length < 5) {
            _isValid = false
        }

        setIsValid(_isValid)

    }, [ownerName, iban])

    return (
        <div>
            <Dialog
                open={isActive}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid style={{width: '100%', position: 'relative'}} item md={12}>
                            <p style={{textAlign: 'center', fontWeight: '600', fontSize: '18px'}}>
                                {lang.bank_info}
                            </p>
                            <span
                                onClick={() => {history.push('/dashboard')}}
                                style={{
                                position: 'absolute',
                                top: '0',
                                right: '-10px',
                                fontSize: '36px',
                                cursor: 'pointer',
                                color: 'tomato'
                            }}>&times;</span>
                        </Grid>
                        <Grid item xs={12}>
                            <TextStyled>
                                <p style={{textAlign: 'center'}}>{lang.need_bank}</p>
                            </TextStyled>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center',
                    justifyContent: 'space-around'
                }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                value={ownerName}
                                onChange={(e) => {
                                    setOwnerName(e.target.value)
                                }}
                                name="owner_name"
                                style={{width: '100%'}}
                                label={`${lang.owner_name}`}
                                type="text"
                                variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="iban"
                                value={iban}
                                onChange={(e) => {
                                    setIban(e.target.value)
                                }}
                                style={{width: '100%'}}
                                label={`IBAN`}
                                type="text"
                                variant="outlined"/>
                        </Grid>

                        <Grid item xs={12}>
                            <TextStyled>
                                <p style={{textAlign: 'center'}}>{lang.iban_get_bank_info}</p>
                            </TextStyled>
                        </Grid>

                        <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12}>
                            <Button
                                disabled={!isValid}
                                className="main-button main-button-register popup-action-button-margin"
                                style={{width: '200px', fontWeight: '600', marginTop: '15px'}}
                                onClick={onUpdateHandler}
                                variant="contained">{lang.update}</Button>
                        </Grid>

                        <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12}>
                            <p>-{lang.or}-</p>
                        </Grid>

                        <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12}>
                            <div onClick={onPaymentHandler}
                                 style={{
                                     display: 'flex',
                                     alignItems: 'center',
                                     cursor: 'pointer',
                                     border: '2px solid rgb(233, 57, 44)',
                                     borderRadius: '40px',
                                     padding: '2px 8px'
                                 }}>
                                <TextStyled>
                                    <p style={{fontWeight: 'bold'}}>{lang.pay} € 0.01 {lang.with_ideal}</p>
                                </TextStyled>
                                <img style={{maxWidth: '50px', marginLeft: '10px'}} src={ideal} alt=""/>
                            </div>
                        </Grid>
                    </Grid>

                </DialogActions>
            </Dialog>
        </div>
    )
}

export default BankInformationPopup