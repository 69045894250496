import styled from "styled-components"

import nextIcon from '../assets/nextIcon.svg'
import prevIcon from '../assets/prevIcon.svg'

const VanComponentWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  .swiper-button-next {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-40%);

    &:after {
      animation: scaleAnimation 7s linear;
      background-image: url(${nextIcon});
      width: 28px;
      height: 31px;
      content: '';
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
    }
  }

  .swiper-button-prev {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-40%);

    &:after {
      animation: scaleAnimation 7s linear;
      background-image: url(${prevIcon});
      width: 28px;
      height: 31px;
      content: '';
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
    }
  }
  
  @media (min-width: 1000px) {
    width: 450px;
  }
`

export default VanComponentWrapper