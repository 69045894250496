import React, {useState} from 'react'
import {Controlled as ControlledZoom} from "react-medium-image-zoom";
import kavPlaceholder from "../assets/kavplaceholder.jpg";
import TextStyled from "../styles/TextStyled";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import BoxWrapperStyled from "../styles/BoxWrapperStyled";
import {useSelector} from "react-redux";

const DamageReportItem = ({report}) => {

    const [isZoomed, setIsZoomed] = useState(false)
    const lang = useSelector(state => state.lang)

    const handleZoomChange = (e) => {
        setIsZoomed(e)
    }

    const formatLocation = () => {
        return report.location.replace('_', ' ')
    }

    return (
        <BoxWrapperStyled>
            <div style={{display: 'flex', position: 'relative'}}>

                <div style={{
                    background: '#ffffff',
                    border: '1px solid #C4C4C4',
                    borderRadius: '4px',
                    padding: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10px'
                }}>

                    <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
                        <div style={{
                            backgroundImage: `url(${report.image.full})`,
                            width: '90px',
                            height: '71px',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}/>
                    </ControlledZoom>
                </div>

                <div>
                    <TextStyled>
                        <p style={{fontSize: '16px', fontWeight: 'bold', marginTop: '5px', textTransform: 'capitalize'}}>
                            {formatLocation()}
                        </p>
                    </TextStyled>

                    <hr/>

                    <TextStyled style={{marginTop: '10px'}}>
                        <p style={{fontWeight: 'bold', fontSize: '14px'}}>{lang.description}:</p>
                        <p style={{maxWidth: '220px'}}>{report.description}
                        </p>
                    </TextStyled>
                    <span
                        onClick={() => {setIsZoomed(true)}}
                        style={{position: 'absolute', right: '0', bottom: '0', cursor: 'pointer'}}>
                                    <ZoomInOutlinedIcon style={{fontSize: '36px'}}/>
                                </span>
                </div>
            </div>
        </BoxWrapperStyled>
    )
}

export default DamageReportItem