import React, {useEffect} from 'react'
import {Grid} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {changePage} from "../redux/actions"
import AppHeader from "../components/AppHeader"
import passportImage from '../assets/passport.jpg'
import idCard from '../assets/idcard.jpg'
import ReactGA from "react-ga";

const PassportIdcardPage = () => {

    const dispatch = useDispatch()

    const handleNextPassport = () => {
        dispatch(changePage({pageName: 'passport-page'}))
    }

    const handleNextIdcard = () => {
        dispatch(changePage({pageName: 'idcard-front-page'}))
    }

    const lang = useSelector(state => state.lang)

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'Passport id card page'
        });
    }, [])

    return (
        <Grid container spacing={2}
              style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4px'}}>
            <AppHeader/>
            <Grid className="containerAlignBottom" container item spacing={2} xs={11}>
                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} item xs={12}>
                    <h1 className="secondH1Title">{lang.choose_the_type_of_your_document}</h1>

                    <div onClick={handleNextPassport} className="choose-document-item">
                        <img src={passportImage} alt=""/>
                        <p>{lang.passport}</p>
                    </div>

                    <div onClick={handleNextIdcard} className="choose-document-item">
                        <img src={idCard} alt=""/>
                        <p>{lang.id_card}</p>
                    </div>

                </Grid>

            </Grid>
        </Grid>
    )
}

export default PassportIdcardPage