import axios from "axios";
import config from "../config";
import userService from "../services/userService";
import moment from "moment";
import errorService from "../services/errorService";

export const changeField = ({field, value}) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_FIELD',
            payload: {
                field,
                value
            }
        })
    }
}

export const preloaderStart = (text = '') => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_FIELD',
            payload: {
                field: 'isPreloaderActive',
                value: true
            }
        })

        if (text.length > 0) {
            dispatch({
                type: 'CHANGE_FIELD',
                payload: {
                    field: 'preloaderText',
                    value: text
                }
            })
        }
    }
}

export const preloaderStop = () => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_FIELD',
            payload: {
                field: 'isPreloaderActive',
                value: false
            }
        })

        dispatch({
            type: 'CHANGE_FIELD',
            payload: {
                field: 'preloaderText',
                value: ''
            }
        })
    }
}

export const changePage = ({pageName}) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_FIELD',
            payload: {
                field: 'currentPage',
                value: pageName
            }
        })
    }
}

export const uploadDLFrontPageSimple = ({imageBase64, onSuccess}) => {
    return (dispatch) => {

        dispatch(preloaderStart())

        axios({
            method: 'post',
            url: config.dlFrontOcrUrl,
            data: {
                document: imageBase64
            }
        }).then((res) => {

            const result = res.data
            const fields = result.payload
            const resultFields = {
                number: '',
                expiration: '',
                categoryB: false
            }

            fields.forEach((item) => {

                if (item.label === 'dl_number') {
                    resultFields.number = item.value
                }

                if (item.label === 'dl_exp') {
                    resultFields.expiration = item.value
                }

                if (item.label === 'dl_categories') {
                    const dlCategory = item.value.toLowerCase()
                    if (dlCategory !== 'am' || dlCategory !== 'a1' || dlCategory !== 'a2' || dlCategory !== 'a') {
                        resultFields.categoryB = true
                    }
                }

            })

            onSuccess(resultFields)

            dispatch(preloaderStop())
        }).catch((err) => {
            dispatch(preloaderStop())
        })
    }
}

export const uploadDLFrontPage = ({imageBase64}) => {
    return (dispatch) => {

        dispatch(preloaderStart())

        axios({
            method: 'post',
            url: config.dlFrontOcrUrl,
            data: {
                document: imageBase64
            }
        }).then((res) => {

            const result = res.data

            const fields = result.payload

            fields.forEach((item) => {

                if (item.label === 'dl_number') {
                    dispatch(changeField({
                        field: 'dlNumber',
                        value: item.value
                    }))
                }

                if (item.label === 'dl_exp') {
                    dispatch(changeField({
                        field: 'expDate',
                        value: item.value
                    }))
                }

                if (item.label === 'date_of_birth') {
                    dispatch(changeField({
                        field: 'dateOfBirth',
                        value: item.value
                    }))
                }

                if (item.label === 'dl_first_name') {
                    dispatch(changeField({
                        field: 'firstName',
                        value: item.value
                    }))
                }

                if (item.label === 'dl_last_name') {
                    dispatch(changeField({
                        field: 'lastName',
                        value: item.value
                    }))
                }

                if (item.label === 'dl_categories') {
                    const dlCategory = item.value.toLowerCase()
                    if (dlCategory !== 'am' || dlCategory !== 'a1' || dlCategory !== 'a2' || dlCategory !== 'a') {
                        dispatch(changeField({
                            field: 'categoryB',
                            value: true
                        }))
                    }
                }

            })

            dispatch(changeField({
                field: 'currentPhoto',
                value: false
            }))

            dispatch(
                changePage({
                    pageName: 'dl-back-page'
                })
            )

            dispatch(preloaderStop())
        }).catch((err) => {
            dispatch(preloaderStop())

            dispatch(changeField({
                field: 'currentPhoto',
                value: false
            }))

            dispatch(
                changePage({
                    pageName: 'dl-back-page'
                })
            )

        })
    }
}

export const uploadDLBackPage = ({imageBase64}) => {
    return (dispatch) => {

        dispatch(changeField({
            field: 'currentPhoto',
            value: false
        }))

        dispatch(
            changePage({
                pageName: 'document-data-page'
            })
        )
    }
}

export const uploadPassportSimple = ({imageBase64, onSuccess}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            method: 'post',
            url: config.pasFrontOcrUrl,
            data: {
                document: imageBase64
            }
        }).then((res) => {
            dispatch(preloaderStop())

            const result = res.data
            const fields = result.payload

            const resultFields = {
                number: '',
                expiration: ''
            }

            fields.forEach((item) => {

                if (item.label === 'i_pas_number') {
                    if (item.value !== null) {
                        resultFields.number = item.value
                    }
                }

                if (item.label === 'i_pas_exp_date') {
                    if (item.value !== null) {
                        resultFields.expiration = item.value
                    }
                }

            })

            onSuccess(resultFields)

        }).catch((err) => {
            dispatch(preloaderStop())
        })

    }
}

export const uploadPassport = ({imageBase64}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            method: 'post',
            url: config.pasFrontOcrUrl,
            data: {
                document: imageBase64
            }
        }).then((res) => {
            dispatch(preloaderStop())

            const result = res.data

            const fields = result.payload

            fields.forEach((item) => {

                if (item.label === 'i_pas_number') {

                    if (item.value !== null) {
                        dispatch(changeField({
                            field: 'idcardpasNumber',
                            value: item.value
                        }))
                    }


                }

                if (item.label === 'i_pas_exp_date') {

                    if (item.value !== null) {
                        dispatch(changeField({
                            field: 'idcardpasExpdate',
                            value: item.value
                        }))
                    }


                }

                if (item.label === 'i_pas_country') {

                    if (item.value !== null) {
                        dispatch(changeField({
                            field: 'idcardpasCountry',
                            value: item.value
                        }))
                    }


                }

            })

            dispatch(changeField({
                field: 'currentPhoto',
                value: false
            }))

            dispatch(
                changePage({
                    pageName: 'not-dutch-document-data-page'
                })
            )
        }).catch((err) => {
            dispatch(preloaderStop())

            dispatch(changeField({
                field: 'currentPhoto',
                value: false
            }))

            dispatch(
                changePage({
                    pageName: 'not-dutch-document-data-page'
                })
            )
        })

    }
}

export const uploadIdCardFront = ({imageBase64}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            method: 'post',
            url: config.idCardFrontOcrUrl,
            data: {
                document: imageBase64
            }
        }).then((res) => {

            const result = res.data

            const fields = result.payload

            fields.forEach((item) => {

                if (item.label === 'id_card_number') {
                    dispatch(changeField({
                        field: 'idcardpasNumber',
                        value: item.value
                    }))
                }

                if (item.label === 'id_card_exp_date') {
                    dispatch(changeField({
                        field: 'idcardpasExpdate',
                        value: item.value
                    }))
                }

                if (item.label === 'id_card_country') {
                    dispatch(changeField({
                        field: 'idcardpasCountry',
                        value: item.value
                    }))
                }

            })

            dispatch(changeField({
                field: 'currentPhoto',
                value: false
            }))

            dispatch(
                changePage({
                    pageName: 'idcard-back-page'
                })
            )

            dispatch(preloaderStop())
        }).catch((err) => {
            dispatch(preloaderStop())

            dispatch(changeField({
                field: 'currentPhoto',
                value: false
            }))

            dispatch(
                changePage({
                    pageName: 'idcard-back-page'
                })
            )

        })

    }
}

export const uploadIdCardBack = ({imageBase64}) => {
    return (dispatch) => {
        //dispatch(preloaderStart())

        dispatch(changeField({
            field: 'currentPhoto',
            value: false
        }))

        dispatch(
            changePage({
                pageName: 'not-dutch-document-data-page'
            })
        )
    }
}

export const putDLFrontPhotoToResult = ({imageBase64}) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_DL_FRONT_PAGE_RESULT',
            payload: imageBase64
        })
    }
}

export const putDLBackPhotoToResult = ({imageBase64}) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_DL_BACK_PAGE_RESULT',
            payload: imageBase64
        })
    }
}

export const putPassportPhotoToResult = ({imageBase64}) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_PASSPORT_PAGE_RESULT',
            payload: imageBase64
        })
    }
}

export const putIdcardFrontPhotoToResult = ({imageBase64}) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_ID_CARD_FRONT_PAGE_RESULT',
            payload: imageBase64
        })
    }
}

export const putIdcardBackPhotoToResult = ({imageBase64}) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_ID_CARD_BACK_PAGE_RESULT',
            payload: imageBase64
        })
    }
}

export const experianValidateDL = ({dlNumber, dateOfBirth, cb, errCb}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            method: 'post',
            url: config.experianDLUrl,
            data: {
                document_type: 'drivers_license',
                document_id: `${dlNumber}`,
                date_of_birth: `${dateOfBirth}`
            }
        }).then((res) => {

            const resData = res.data

            cb(resData)

            dispatch(preloaderStop())
        }).catch((err) => {
            errCb(err)
            dispatch(preloaderStop())
        })
    }
}

export const putSelfieToResult = ({imageBase64}) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_SELFIE_RESULT',
            payload: imageBase64
        })
    }
}

export const hideErrorMessage = () => {
    return (dispatch) => {
        dispatch({
            type: 'HIDE_ERROR_MESSAGE'
        })
    }
}

export const showErrorMessage = ({title, message}) => {
    return (dispatch) => {
        dispatch({
            type: 'SHOW_ERROR_MESSAGE',
            payload: {
                title,
                message
            }
        })
    }
}

export const makeRegistration = ({userObject, onApiToken}) => {
    return (dispatch) => {

        dispatch(preloaderStart())

        userService
            .makeShortRegistration({
                registration_source: 'website',
                customer_type: 'private',
                gender: userObject.gender,
                first_name: userObject.firstName,
                last_name: userObject.lastName,
                mobile_phone_number: userObject.phone,
                email_address: userObject.email,
                password: userObject.needTempPassword ? '123456' : userObject.password,
                generate_ephemeral_password: userObject.needTempPassword,
                place_of_birth: userObject.livingAddress.city,
                country_of_birth: userObject.livingAddress.country,
                nationality: userObject.livingAddress.country,
                date_of_birth: userObject.birthDate,
                photo: userObject.photo,
                residence_address: {
                    zip_code: userObject.livingAddress.zipCode,
                    house_number: userObject.livingAddress.houseNumber,
                    house_number_extension: userObject.livingAddress.houseNumberExtension,
                    street_name: userObject.livingAddress.streetName,
                    city: userObject.livingAddress.city,
                    country: userObject.livingAddress.country
                },
                address: {
                    zip_code: userObject.invoiceAddress.zipCode,
                    house_number: userObject.invoiceAddress.houseNumber,
                    house_number_extension: userObject.invoiceAddress.houseNumberExtension,
                    street_name: userObject.invoiceAddress.streetName,
                    city: userObject.invoiceAddress.city,
                    country: userObject.invoiceAddress.country
                }
            })
            .then((res) => {

                const apiToken = res.data.token

                userService
                    .getClientInfo(apiToken)
                    .then((clientRes) => {
                        const clientId = clientRes.data[0].id

                        userService
                            .signContract({
                                customerId: clientId,
                                base64Image: 'iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAAFElEQVR42mNkIBIwjiocVTiqEAQAJNYAFd868w4AAAAASUVORK5CYII=',
                                apiToken
                            })
                            .then((signContractRes) => {

                                userService
                                    .uploadDriverLicense({
                                        apiToken,
                                        driverLicenseNumber: userObject.documents.dl.number,
                                        expirationDate: userObject.documents.dl.expDate,
                                        categories: ['B'],
                                        country: userObject.documents.dl.country,
                                        frontImage: userObject.documents.dl.front,
                                        backImage: userObject.documents.dl.back
                                    })
                                    .then((res) => {

                                        if (userObject.documents.passport !== false) {
                                            userService.uploadPassport({
                                                apiToken,
                                                passportNumber: userObject.documents.passport.number,
                                                expirationDate: userObject.documents.passport.expDate,
                                                country: userObject.documents.passport.country,
                                                frontImage: userObject.documents.passport.front
                                            }).then((pasRes) => {
                                                dispatch(preloaderStop())

                                                onApiToken(apiToken)

                                                dispatch(changePage({
                                                    pageName: 'done-page'
                                                }))

                                            }).catch((pasErr) => {
                                                dispatch(showErrorMessage({
                                                    message: pasErr.response.data.error.message,
                                                    title: 'Something wrong'
                                                }))
                                                dispatch(preloaderStop())
                                            })
                                        } else if (userObject.documents.idcard !== false) {

                                            userService.uploadIdCard({
                                                apiToken,
                                                documentNumber: userObject.documents.idcard.number,
                                                expirationDate: userObject.documents.idcard.expDate,
                                                country: userObject.documents.idcard.country,
                                                frontImage: userObject.documents.idcard.front,
                                                backImage: userObject.documents.idcard.back
                                            }).then((idCardRes) => {

                                                dispatch(preloaderStop())

                                                onApiToken(apiToken)

                                                dispatch(changePage({
                                                    pageName: 'done-page'
                                                }))

                                            }).catch((idCardErr) => {
                                                dispatch(showErrorMessage({
                                                    message: idCardErr.response.data.error.message,
                                                    title: 'Something wrong'
                                                }))
                                                dispatch(preloaderStop())
                                            })
                                        } else {
                                            dispatch(preloaderStop())

                                            onApiToken(apiToken)

                                            dispatch(changePage({
                                                pageName: 'done-page'
                                            }))
                                        }

                                    })
                                    .catch((dlRrr) => {

                                        console.log(dlRrr)

                                        dispatch(showErrorMessage({
                                            message: dlRrr.response.data.error.message,
                                            title: 'Something wrong'
                                        }))
                                        dispatch(preloaderStop())
                                    })
                            })
                            .catch((signContractErr) => {
                                dispatch(preloaderStop())
                            })

                    })
                    .catch((clientErr) => {
                        dispatch(preloaderStop())
                    })

            })
            .catch((err) => {
                dispatch(preloaderStop())
                dispatch(showLoginPopup())
            })
    }
}

export const showLoginPopup = () => {
    return (dispatch) => {
        dispatch({
            type: 'SHOW_LOGIN_POPUP'
        })
    }
}

export const hideLoginPopup = () => {
    return (dispatch) => {
        dispatch({
            type: 'HIDE_LOGIN_POPUP'
        })
    }
}

export const zipCodeAutocomplete = ({zipCode, houseNumber, additionalHouseNumber}) => {
    return (dispatch) => {

        dispatch(preloaderStart())

        axios({
            method: 'get',
            url: config.getZipCodeUrl({
                zipCode,
                houseNumber,
                additionalHouseNumber
            })
        }).then((res) => {

            const {street, city} = res.data.payload

            dispatch(changeField({
                field: 'streetLiving',
                value: street
            }))

            dispatch(changeField({
                field: 'cityLiving',
                value: city
            }))

            dispatch(preloaderStop())
        }).catch((err) => {
            dispatch(preloaderStop())
        })
    }
}

export const resetPassword = ({email, cbSuccess}) => {
    return (dispatch) => {

        dispatch(preloaderStart())
        dispatch(hideLoginPopup())

        userService
            .resetPassword({
                email
            })
            .then((res) => {
                cbSuccess()
                dispatch(preloaderStop())
            })
            .catch((err) => {
                dispatch(preloaderStop())
                dispatch(
                    showErrorMessage({
                        title: '',
                        message: err.response.data.error.message
                    })
                )

            })
    }
}

export const searchVehicles = ({apiToken, startDate, endDate, onSuccess, onError}) => {
    return (dispatch) => {
        axios({
            method: 'get',
            url: config.getSearchVehicleUrl({startDate, endDate}),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        }).then((res) => {

            onSuccess(res)
        }).catch((err) => {
            onError(err)
        })
    }
}

export const actionCalculateBookingPrice = ({categoryId, startDate, endDate, km, onSuccess, needPreloader = true}) => {
    return (dispatch) => {

        if (needPreloader) {
            dispatch(preloaderStart())
        }

        axios({
            method: 'get',
            url: config.getCalculatorUrl({
                categoryId: 100,
                startDate,
                endDate,
                km: km ? km : 0
            })
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res)
        }).catch((err) => {
            dispatch(preloaderStop())
            showErrorMessage({
                title: '',
                message: err.response.data.error.message
            })
        })
    }
}

export const clientLoginV4 = ({email, password, onSuccess, onError}) => {
    return (dispatch) => {

        dispatch(preloaderStart())

        axios({
            method: 'post',
            url: config.getLoginUrl(),
            data: {
                username: email,
                password: password
            }
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res)
        }).catch((err) => {
            dispatch(preloaderStop())
            onError(err)
            showErrorMessage({
                title: '',
                message: err.response.data.error.message
            })
        })
    }
}

export const changeApiToken = ({apiToken}) => {
    return (dispatch) => {

        dispatch(
            changeField({
                field: 'apiToken',
                value: apiToken
            })
        )
    }
}

export const getUserInfo = ({apiToken, onError = false}) => {
    return (dispatch) => {
        axios({
            method: 'get',
            url: config.getUserInfo({apiToken}),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        }).then((res) => {
            dispatch(
                changeField({
                    field: 'user',
                    value: res.data
                })
            )
        }).catch((err) => {
            if (err.response.status == 401) {
                if (onError != false) {
                    onError()
                }
            }

            dispatch(preloaderStop())
        })
    }
}

export const getClientInfo = ({apiToken, onSuccess = false}) => {
    return (dispatch) => {
        axios({
            method: 'get',
            url: config.getClientInfoUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        }).then((res) => {

            let privateClient = res.data.filter((c) => {
                if (c.type === 'private') return true
                return false
            })

            dispatch(
                changeField({
                    field: 'client',
                    value: privateClient[0]
                })
            )

            if (onSuccess !== false) {
                onSuccess(res.data[0])
            }
        })
            .catch((err) => {
                dispatch(preloaderStop())
            })
    }
}

export const getFullReservationList = ({apiToken, clientId, limit, onSuccess}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            method: 'get',
            url: config.getReservationListUrl({clientId, status: '', limit, offset: 0}),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        }).then((res) => {
            onSuccess(res.data)
            dispatch(preloaderStop())
        }).catch((err) => {
            dispatch(preloaderStop())
        })
    }
}

export const getReservationList = ({apiToken, clientId, status, subsidiaryInfo, limit = 10, offset = 0}) => {
    return (dispatch) => {

        dispatch(
            preloaderStart()
        )

        axios({
            method: 'get',
            url: config.getReservationListUrl({clientId, status, limit, offset}),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        }).then((res) => {

            const currentTime = moment(new Date())
            const earlyPickUp = subsidiaryInfo.rental_period_configuration.early_pickup_in_minutes
            const reservations = res.data

            const futureReservations = reservations.filter((item) => {
                const status = item.status
                const dateStart = moment(item.planned_start_date)

                if (dateStart.diff(currentTime, 'minutes') >= earlyPickUp && status == 'new') {
                    return true
                }

                return false
            })

            let activeReservation = false

            reservations.forEach((item) => {
                const status = item.status
                const dateStart = moment(item.planned_start_date)

                if ((status == 'picked_up' || status == 'new') && dateStart.diff(currentTime, 'minutes') < earlyPickUp) {
                    activeReservation = item
                }
            })

            dispatch(
                changeField({
                    field: 'activeReservation',
                    value: activeReservation
                })
            )

            dispatch(
                changeField({
                    field: 'futureReservations',
                    value: futureReservations
                })
            )

            dispatch(
                preloaderStop()
            )
        })
    }
}

export const createReservation = ({apiToken, startDate, endDate, clientId, vehicleId, km, lang, onSuccess, onError}) => {
    return (dispatch) => {

        dispatch(preloaderStart())

        axios({
            method: 'post',
            url: config.getCreateReservationUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                planned_start_date: startDate,
                planned_end_date: endDate,
                customer: clientId,
                vehicle: vehicleId,
                expected_kilometers: km
            }
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res)
        })
            .catch((err) => {
                dispatch(preloaderStop())
                onError(err)
                dispatch(
                    showErrorMessage({
                        title: 'Error',
                        message: errorService.translate(err.response.data.error.message, lang)
                    })
                )
            })
    }
}

export const cancelReservation = ({apiToken, id, onSuccess, onError}) => {
    return (dispatch) => {
        axios({
            method: 'patch',
            url: config.getCancelReservationUrl({id}),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        }).then((res) => {
            onSuccess(res)
        }).catch((err) => {
            onError(err)
        })
    }
}

export const getTransactionsHistory = ({apiToken, clientId, limit, onSuccess, onError}) => {
    return (dispatch) => {
        axios({
            method: 'get',
            url: config.getTransactionsListUrl({
                clientId,
                limit,
                offset: 0
            }),
            headers: {
                'Authorization': `Bearer ${apiToken}`,
            }
        }).then((res) => {
            onSuccess(res)
        }).catch((err) => {
            onError(err)
        })
    }
}

export const loginV3 = ({email, password, onSuccess}) => {
    return (dispatch) => {

        onSuccess({})

        // axios({
        //     method: 'post',
        //     url: config.getLoginV3Url(),
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     data: {
        //         username: email,
        //         password: password,
        //         userType: 'client',
        //         subsidiaryId: 9,
        //         mobileAppVersion: '2.0.0'
        //     }
        // }).then((res) => {
        //     onSuccess(res)
        // }).catch((err) => {
        //     dispatch(
        //         showErrorMessage({
        //             title: '',
        //             message: "Incorrect login. Please, contact call center"
        //         })
        //     )
        // })
    }
}

export const triggerPayment = ({
                                   apiToken,
                                   clientId,
                                   amount,
                                   onSuccess,
                                   onError,
                                   type = 'customer_triggered_payment'
                               }) => {
    return (dispatch) => {
        axios({
            method: 'post',
            url: config.getTriggerPaymentUrl({clientId}),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                amount,
                type
            }
        }).then((res) => {
            onSuccess(res)
        }).catch((err) => {
            onError(err)
        })
    }
}

export const getFleetModels = ({onSuccess, onError}) => {
    return (dispatch) => {
        axios({
            method: 'get',
            url: config.getFleetModelsUrl()
        }).then((res) => {
            onSuccess(res)
        }).catch((err) => {
            onError(err)
            dispatch(
                showErrorMessage({
                    title: 'Error',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const getVehicleInformation = ({apiTokenV3, id, onSuccess, onError}) => {
    return (dispatch) => {

        dispatch(preloaderStart())

        axios({
            method: 'post',
            url: config.getVehicleInformationUrl({id}),
            headers: {
                'Authorization': `Bearer ${apiTokenV3}`
            }
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res)
        }).catch((err) => {
            dispatch(preloaderStop())
            onError(err)
            dispatch(
                showErrorMessage({
                    title: 'Error',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const getSubsidiaryInfo = () => {
    return (dispatch) => {
        axios({
            method: 'get',
            url: config.getSubsidiaryInfoUrl()
        }).then((res) => {
            dispatch(
                changeField({
                    field: 'subsidiaryInfo',
                    value: res.data
                })
            )
        }).catch((err) => {

        })
    }
}

export const editUserLanguage = ({apiToken, language = 'nl'}) => {
    return (dispatch) => {
        axios({
            method: 'patch',
            url: config.getUserEditUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`,
                'mobielrijden-client-app-language': language
            },
            data: {}
        }).then((res) => {

        })
    }
}

export const editUserInformation = ({apiToken, pushToken, userObject = false, onSuccess = false}) => {
    return (dispatch) => {

        let data = {}

        if (userObject == false) {
            data = {
                "push_notification_config": {
                    "provider": "firebase",
                    "token": pushToken
                }
            }
        } else {
            data = userObject
        }

        axios({
            method: 'patch',
            url: config.getUserEditUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: data
        }).then((res) => {

            if (onSuccess != false) {
                onSuccess(res)
            }

        }).catch((err) => {
            console.log(err)
            dispatch(
                showErrorMessage({
                    title: 'Error',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const getTransactionsList = ({apiToken, clientId, onSuccess}) => {
    return (dispatch) => {

        dispatch(preloaderStart())

        axios({
            method: 'get',
            url: config.getTransactionsListUrl({clientId, limit: 10, offset: 0}),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        }).then((res) => {
            onSuccess(res.data)
            dispatch(preloaderStop())
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: 'Error',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const getUserDocumentList = ({apiToken, onSuccess}) => {
    return (dispatch) => {

        dispatch(preloaderStart())

        axios({
            method: 'get',
            url: config.getUserDocumentListUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        }).then((res) => {
            onSuccess(res.data)
            dispatch(preloaderStop())
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: 'Error',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const deleteDocument = ({apiToken, docType, id, onSuccess}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            method: 'delete',
            url: config.getDeleteDocumentUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                type: docType,
                id: id
            }
        }).then((res) => {
            onSuccess(res)
            dispatch(preloaderStop())
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: 'Error',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const dlUploadHandler = ({apiToken, number, expiration, country, front, back, onSuccess, onError}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        userService
            .uploadDriverLicense({
                apiToken,
                driverLicenseNumber: number,
                expirationDate: expiration,
                country,
                categoriesArr: ['B'],
                frontImage: front,
                backImage: back
            }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res)
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: 'Error',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const psUploadHandler = ({apiToken, number, expiration, country, front, onSuccess, onError}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        userService.uploadPassport({
            apiToken,
            passportNumber: number,
            expirationDate: expiration,
            country,
            frontImage: front
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res)
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: 'Error',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const idCardUploadHandler = ({apiToken, number, expiration, country, front, back, onSuccess, onError}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        userService.uploadIdCard({
            apiToken,
            documentNumber: number,
            expirationDate: expiration,
            country,
            frontImage: front,
            backImage: back
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res)
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: 'Error',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const bunqRefund = ({apiToken, clientId, amount, onSuccess}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            url: config.getClaimBalanceUrl({clientId}),
            method: 'post',
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                amount
            }
        }).then((res) => {

            dispatch(preloaderStop())
            onSuccess(res)
        }).catch((err) => {

            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: 'Something wrong',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const deleteAccount = ({apiToken, clientId, onSuccess}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            url: config.getDeleteAccountUrl({clientId}),
            method: 'delete',
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res)
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: 'Something wrong',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const editReservation = ({apiToken, reservationId, status = false, endDate = false, onSuccess}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        let data = {}

        if (status !== false) {
            data = {
                status
            }
        }

        if (endDate !== false) {
            data = {
                planned_end_date: endDate
            }
        }

        axios({
            url: config.getReservationEditUrl({reservationId}),
            method: 'patch',
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: data
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res)
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: '',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const getReservationInformation = ({apiToken, reservationId, onSuccess}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            url: config.getReservationInformationUrl({reservationId}),
            method: 'get',
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res.data)
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: '',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const getVehicleStatus = ({apiToken, vehicleId, onSuccess}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            url: config.getVehicleStatusUrl({vehicleId}),
            method: 'get',
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res.data)
        }).catch((err) => {
            dispatch(preloaderStop())
            // dispatch(
            //     showErrorMessage({
            //         title: '',
            //         message: err.response.data.error.message
            //     })
            // )
        })
    }
}

export const getDamageReportList = ({apiToken, vehicleId, onSuccess}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            url: config.getDamageReportListUrl({vehicleId}),
            method: 'get',
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        }).then((res) => {
            onSuccess(res.data)
            dispatch(preloaderStop())
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: '',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const createNewDamageReport = ({
                                          apiToken,
                                          vehicleId,
                                          reservationId,
                                          photo,
                                          location,
                                          description,
                                          onSuccess
                                      }) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            url: config.getNewDamageUrl({vehicleId}),
            method: 'post',
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                photo,
                reservation: reservationId,
                location,
                description
            }
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res)
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: '',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const uploadSimpleDocument = ({apiToken, image, type, onSuccess}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        const images = []

        images.push({
            side: 'front',
            mime_type: image.mimeType,
            light_type: 'white',
            data: image.data
        })

        axios({
            url: config.getUploadDriverLicenseUrl(),
            method: 'post',
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                type,
                images
            }
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res)
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: '',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const updateClient = ({apiToken, clientId, updateObject, onSuccess}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            url: config.getClientEditUrl({clientId}),
            method: 'patch',
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: updateObject
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res)
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: '',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const changeVehicleStatus = ({apiToken, vehicleId, status, onSuccess}) => {
    return (dispatch) => {
        dispatch(preloaderStart())

        axios({
            url: config.getVehicleChangeStatusUrl({vehicleId}),
            method: 'patch',
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: status
        }).then((res) => {
            dispatch(preloaderStop())
            onSuccess(res)
        }).catch((err) => {
            dispatch(preloaderStop())
            dispatch(
                showErrorMessage({
                    title: '',
                    message: err.response.data.error.message
                })
            )
        })
    }
}

export const googleAutocomplete = ({search, onSuccess}) => {
    return (dispatch) => {
        axios({
            url: `https://cors.idsugarfree.com/https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${search}&key=${config.googleMapApi}`,
            method: 'get',
        }).then((res) => {
            onSuccess(res)
        })
    }
}

export const googleAddressGeo = ({apiToken, address, onSuccess}) => {
    return (dispatch) => {
        axios({
            url: config.getGeocodingUrl({address}),
            method: 'get',
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        }).then((res) => {
            onSuccess(res)
        })
    }
}

export const actionGetTextUrl = ({apiToken, key, onSuccess}) => {
    return (dispatch) => {
        axios({
            url: config.getTextUrl({key}),
            method: 'get',
        }).then((res) => {
            onSuccess(res)
        })
    }
}

export const actionReservationMockEdit = ({apiToken, reservationId, onSuccess}) => {
    return (dispatch) => {
        axios({
            url: config.getReservationMockEdit({reservationId}),
            method: 'patch',
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                status: "cancelled"
            }
        }).then((res) => {
            onSuccess(res)
        })
    }
}