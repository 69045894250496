import React from 'react'
import {Switch, Redirect, Route} from 'react-router-dom';
import HomePage from "./router-pages/HomePage";
import DashboardPage from "./router-pages/DashboardPage";
import MapPage from "./router-pages/MapPage";
import ManagerReservation from "./router-pages/ManagerReservation";
import ConfirmReservation from "./router-pages/ConfirmReservation";
import PaymentPage from "./router-pages/PaymentPage";
import ConfirmPaymentPage from "./router-pages/ConfirmPaymentPage";
import ManageActiveReservationPage from "./router-pages/ManageActiveReservationPage";
import ReservationHistoryPage from "./router-pages/ReservationHistoryPage";
import TransactionHistoryPage from "./router-pages/TransactionHistoryPage";
import ProfilePage from "./router-pages/ProfilePage";
import AddDocumentPage from "./router-pages/AddDocumentPage";
import MakePhotoPage from "./router-pages/MakePhotoPage";
import PhotoResultPage from "./router-pages/PhotoResultPage";
import HelpPage from "./router-pages/HelpPage";
import TermsOfUsePage from "./router-pages/TermsOfUsePage";
import CalculatorPage from "./router-pages/CalculatorPage";
import TopUpBalancePage from "./router-pages/TopUpBalancePage";
import ReclaimBalancePage from "./router-pages/ReclaimBalancePage";
import DamageReportPage from "./router-pages/DamageReportPage";
import AddDamageReportPage from "./router-pages/AddDamageReportPage";
import EndReservationPage from "./router-pages/EndReservationPage";

const Routes = () => {

    return (
        <Switch>
            <Route
                component={HomePage}
                exact
                path="/"
            />
            <Route
                component={DashboardPage}
                exact
                path="/dashboard"
            />
            <Route
                component={ManagerReservation}
                exact
                path="/manage-reservation"
            />
            <Route
                component={MapPage}
                exact
                path="/choose-van"
            />
            <Route
                component={ConfirmReservation}
                exact
                path="/confirm-reservation"
            />
            <Route
                component={ConfirmPaymentPage}
                exact
                path="/confirm-payment/:id"
            />

            <Route component={PaymentPage}
                   exact
                   path="/payment/:type?"/>

            <Route
                component={ManageActiveReservationPage}
                exact
                path="/manage/:id"
            />

            <Route
                component={ReservationHistoryPage}
                exact
                path="/reservation-history"
            />

            <Route
                component={TransactionHistoryPage}
                exact
                path="/transaction-history"
            />

            <Route
                component={ProfilePage}
                exact
                path="/profile"
            />

            <Route
                component={AddDocumentPage}
                exact
                path="/profile/add-document"
            />

            <Route component={MakePhotoPage}
                   exact
                   path="/make-photo/:type/:side"
            />

            <Route component={PhotoResultPage}
                   exact
                   path="/confirm-photo/:type/:side"
            />

            <Route component={HelpPage}
                   exact
                   path="/help"
            />

            <Route component={TermsOfUsePage}
                   exact
                   path="/terms-of-use"
            />

            <Route component={CalculatorPage}
                   exact
                   path="/calculator"
            />

            <Route
                component={TopUpBalancePage}
                exact
                path="/top-up-balance"
            />

            <Route
                component={ReclaimBalancePage}
                exact
                path="/reclaim-balance"
            />

            <Route
                component={DamageReportPage}
                exact
                path="/damage-report/:id/:reservationId"
            />

            <Route
                component={AddDamageReportPage}
                exact
                path="/add-damage-report/:id/:reservationId"
            />

            <Route
                component={EndReservationPage}
                exact
                path="/end-reservation/:id"
            />

        </Switch>
    )
}

export default Routes