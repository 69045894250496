import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@mui/material"
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import {changeField, changePage, uploadDLFrontPage} from "../redux/actions";
import ReactGA from "react-ga";

const SelfieResult = () => {

    const dispatch = useDispatch()
    const currentImage = useSelector(state => state.currentPhoto)

    const onConfirmHandler = () => {

        dispatch(
            changePage({
                pageName: 'contacts-page'
            })
        )
    }

    const onDeleteHandler = () => {
        dispatch(
            changeField({
                field: 'currentPhoto',
                value: false
            })
        )

        dispatch(
            changePage({
                pageName: 'selfie-photo-page'
            })
        )
    }

    const lang = useSelector(state => state.lang)

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'Selfie Result'
        });
    }, [])

    return (
        <div className="image-result-wrapper">
            <div className="image-result-wrapper-inner">
                <img src={currentImage} alt=""/>
                <Button style={{backgroundColor: '#7EE96D', borderRadius: '30px', color: '#ffffff', marginTop: '30px'}}
                        onClick={onConfirmHandler}
                        variant="contained">{lang.confirm} <CheckIcon /> </Button>
                <Button style={{backgroundColor: '#ffffff', border: '1px solid #F56E64', borderRadius: '30px',
                    marginTop: '30px', color: '#F56E64', width: '90px'}}
                        onClick={onDeleteHandler}
                        variant="outlined"> <DeleteIcon /> </Button>
            </div>

        </div>
    )
}

export default SelfieResult