import React from 'react'
import styled from 'styled-components'
import mapLocationIcon from '../assets/mapLocationIcon.png'
import {useSelector} from "react-redux";
import filterTimeFormatted from "../services/filterTimeFormatted";

const MapLocationStyled = styled.div`
  position: fixed;
  top: 70px;
  z-index: 5;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 95%;
  display: flex;
  align-items: center;
  height: 78px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  padding: 0 10px;
  
  @media (max-width: 355px) {
    font-size: 14px;
    height: 50px  ;
  }
`

const MapLocation = () => {

    const lang = useSelector(state => state.lang)
    const filter = useSelector(state => state.filter)
    const locationInfo = useSelector(state => state.locationInfo)

    return (
        <MapLocationStyled>
            <div style={{marginRight: '15px'}}>
                <img src={mapLocationIcon} alt=""/>
            </div>
            <div>
                <p>{filterTimeFormatted.visualFormat(filter.dateStart, filter.timeStart)} -
                    {filterTimeFormatted.visualFormat(filter.dateEnd, filter.timeEnd)}</p>

                {
                    locationInfo.type === 'geo' ?
                        <p>{lang.current_location}</p>
                        :
                        <p>{locationInfo.address}</p>
                }

            </div>
        </MapLocationStyled>
    )
}

export default MapLocation