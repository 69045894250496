import React, {useState} from 'react'
import logoutIcon from '../assets/logoutIconMenu.png'
import AppPopup from "./AppPopup";
import LogoutIcon from '@mui/icons-material/Logout';
import {useSelector} from "react-redux";

const SidebarLogout = () => {

    const [isPopupActive, setIsPopupActive] = useState(false)
    const lang = useSelector(state => state.lang)

    const onLogoutHandler = () => {
        window.localStorage.removeItem('apiToken')
        window.localStorage.removeItem('apiTokenV3')
        document.location.href = '/'
    }

    return (
        <>
            <div onClick={() => {setIsPopupActive(true)}} className="sidebar-menu-item sidebar-logout">
                <LogoutIcon style={{color: '#E9392C', marginRight: '15px'}} />
                {lang.logout}
            </div>
            <AppPopup
                open={isPopupActive}
                title={`${lang.logout}`}
                description={`${lang.want_logout}`}
                closeText={`${lang.no}`}
                handleClose={() => {
                    setIsPopupActive(false)
                }}
                nextText={`${lang.yes}`}
                handleNext={onLogoutHandler}
            />
        </>
    )
}

export default SidebarLogout