import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CollapseTitle = styled.div`
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CollapseContent = styled.div`
  display: ${props => props.isActive ? 'flex': 'none'};
`

const Collapse = ({icon, title, children, isActiveInit = false}) => {

    const [isActive, setIsActive] = useState(isActiveInit)

    useEffect(() => {
        setIsActive(isActiveInit)
    }, [isActiveInit])

    return (
        <div>
            <CollapseTitle onClick={() => setIsActive(!isActive)}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {
                        icon ?
                            <img style={{marginRight: '20px', height: '15px', width: 'auto'}} src={icon}/>
                            :
                            ''
                    }
                    <p style={{color: "#666666", fontWeight: 'bold', cursor: 'pointer'}}>{title}</p>
                </div>
                <span>
                    {
                        isActive ?
                            <KeyboardArrowUpIcon/>
                            :
                            <KeyboardArrowDownIcon/>
                    }
                </span>
            </CollapseTitle>
            <CollapseContent isActive={isActive}>
                {children}
            </CollapseContent>
        </div>
    )
}

export default Collapse