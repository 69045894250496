import React, {memo, useEffect, useRef, useState} from 'react'
import locationFilterIcon from '../assets/locationFilter.png'
import Collapse from "./Collapse";
import {FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import LocationAutocomplete from "./LocationAutocomplete";
import styled from "styled-components";
import searchIcon from '../assets/searchIcon.png'
import Geocode from "react-geocode";
import {useDispatch, useSelector} from "react-redux";
import {changeField, googleAddressGeo} from "../redux/actions";
import config from "../config";
import {Input, OutlinedInput} from "@material-ui/core";
import Autocomplete, {usePlacesWidget} from "react-google-autocomplete";
import EditIcon from '@mui/icons-material/Edit';
import AppPopup from "./AppPopup";

const FilterInner = styled.div`
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      width: 100%;
      padding: 20px 10px;
      margin-top: 20px;
  
  @media (min-width: 1000px) {
    width: 400px;
  }
    `

const LocationFilter = ({isError, filterType = 'filter'}) => {

    const dispatch = useDispatch()
    const lang = useSelector(state => state.lang)
    const isGeoAvailable = useSelector(state => state.isGeoAvailable)
    const locationInfo = useSelector(state => state.locationInfo)
    const [isEditActive, setIsEditActive] = useState(false)
    const geoCoords = useSelector(state => state.geoCoords)

    useEffect(() => {
        if (locationInfo.address.length > 0) {
            setIsEditActive(false)
        } else {
            setIsEditActive(true)
        }

    }, [locationInfo])

    useEffect(() => {

        if (locationInfo.type === 'geo') {
            dispatch(
                changeField({
                    field: 'locationInfo',
                    value: {
                        ...locationInfo,
                        type: 'geo',
                        coordinates: {
                            lat: geoCoords.lat,
                            lng: geoCoords.lng
                        }
                    }
                })
            )

            dispatch(
                changeField({
                    field: 'map',
                    value: {
                        zoom: 8,
                        coords: {lat: geoCoords.lat, lng: geoCoords.lng}
                    }
                })
            )

        } else {
            dispatch(
                changeField({
                    field: 'locationInfo',
                    value: {
                        ...locationInfo,
                        type: 'addressLocation'
                    }
                })
            )

            dispatch(
                changeField({
                    field: 'map',
                    value: {
                        zoom: 8,
                        coords: {lat: locationInfo.coordinates.lat, lng: locationInfo.coordinates.lng}
                    }
                })
            )
        }


    }, [locationInfo.type])

    const onAddressChange = (address) => {

        if (address) {
            const lat = address.geometry.location.lat()
            const lng = address.geometry.location.lng()
            const formattedAddress = address.formatted_address

            //changeLocationType('addressLocation')

            setIsEditActive(false)
            // setLocationType('addressLocation')

            dispatch(
                changeField({
                    field: 'map',
                    value: {
                        zoom: 10,
                        coords: {lat, lng}
                    }
                })
            )

            dispatch(
                changeField({
                    field: 'locationInfo',
                    value: {
                        type: 'addressLocation',
                        address: formattedAddress,
                        coordinates: {
                            lat,
                            lng
                        },
                    }
                })
            )
        }
    }

    const changeLocationType = (type) => {
        dispatch(
            changeField({
                field: 'locationInfo',
                value: {
                    ...locationInfo,
                    type: type
                }
            })
        )
    }

    useEffect(() => {

        if (filterType == 'filter') {
            if (isGeoAvailable == false) {

                changeLocationType('addressLocation')

                // if (locationInfo.type == 'addressLocation') {
                //     changeLocationType('addressLocation')
                // } else {
                //     changeLocationType('geo')
                // }
            }

            if (isGeoAvailable == true) {

                if (locationInfo.type == 'geo') {
                    changeLocationType('geo')
                } else {
                    changeLocationType('addressLocation')
                }
            }

        }

    }, [isGeoAvailable, locationInfo.type])

    const [isGeoErrorMessage, setIsGeoErrorMessage] = useState(false)

    const apiToken = useSelector(state => state.apiToken)

    const onLocationAddress = (address) => {
        dispatch(
            googleAddressGeo({
                apiToken: apiToken,
                address,
                onSuccess: (res) => {
                    const location = res.data.results[0].geometry.location

                    dispatch(
                        changeField({
                            field: 'map',
                            value: {
                                zoom: 10,
                                coords: {lat: location.lat, lng: location.lng}
                            }
                        })
                    )

                    dispatch(
                        changeField({
                            field: 'locationInfo',
                            value: {
                                ...locationInfo,
                                type: 'addressLocation',
                                coordinates: {
                                    lat: location.lat,
                                    lng: location.lng
                                }
                            }
                        })
                    )
                }
            })
        )
    }

    if (filterType == 'filter') {
        return (
            <div>
                <Collapse isActiveInit={!isGeoAvailable} icon={locationFilterIcon} title={lang.location_settings}>
                    <FilterInner style={{width: '100%'}}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >

                            <FormControlLabel
                                style={{
                                    color: '#666666', fontSize: '16px',
                                    opacity: isGeoAvailable ? '1' : '0.5'
                                }}
                                value="currentLocation"
                                onClick={() => {
                                    if (isGeoAvailable) {
                                        changeLocationType('geo')
                                    } else {
                                        setIsGeoErrorMessage(true)
                                    }
                                }}
                                checked={locationInfo.type == 'geo' ? true : false}
                                control={<Radio style={{color: '#E9392C'}}/>}
                                label={`${lang.use_current_location} ${isGeoAvailable ? '': `(${lang.disabled})`}`}/>

                            <div style={{display: 'flex', alignItems: 'center'}}>

                                <FormControlLabel
                                    style={{marginRight: '0px', color: '#666666', fontSize: '16px'}}
                                    checked={locationInfo.type == 'addressLocation' ? true : false}
                                    onClick={() => {
                                        changeLocationType('addressLocation')
                                    }}
                                    value="addressLocation"
                                    control={<Radio style={{color: '#E9392C'}}/>} label=""/>

                                {
                                    isEditActive ?
                                        <div style={{position: "relative", width: '100%'}}
                                             className={isError && locationInfo.type == 'addressLocation' ? 'borderError': ''}>

                                            <LocationAutocomplete initAddress={locationInfo.address} onPlaceSelected={onAddressChange} />

                                            {/*<OutlinedInput*/}
                                            {/*    fullWidth*/}
                                            {/*    color="secondary"*/}
                                            {/*    inputComponent={({inputRef, onFocus, onBlur, ...props}) => (*/}
                                            {/*        <Autocomplete*/}
                                            {/*            apiKey={config.googleMapApi}*/}
                                            {/*            onChange={() => {*/}
                                            {/*                changeLocationType('addressLocation')*/}
                                            {/*            }}*/}
                                            {/*            {...props}*/}
                                            {/*            onPlaceSelected={(selected) => onAddressChange(selected)}*/}
                                            {/*        />*/}
                                            {/*    )}*/}
                                            {/*/>*/}

                                            <img style={{position: 'absolute', right: '11px', top: '14px'}} src={searchIcon}
                                                 alt=""/>
                                        </div>
                                        :
                                        <div style={{position: "relative", width: '100%'}}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <p
                                                    onClick={() => {
                                                        if (locationInfo.type != 'addressLocation') {
                                                            changeLocationType('addressLocation')
                                                        }
                                                    }}
                                                    style={{marginRight: '10px'}}>{locationInfo.address}</p>
                                                <EditIcon
                                                    onClick={() => {
                                                        setIsEditActive(true)
                                                    }}
                                                    style={{color: '#E9392C'}} />
                                            </div>
                                        </div>
                                }

                            </div>
                        </RadioGroup>
                    </FilterInner>
                </Collapse>
                <AppPopup
                    open={isGeoErrorMessage}
                    title=""
                    description={lang.no_geo}
                    handleNext={() => {setIsGeoErrorMessage(false)}}
                    nextText={`${lang.ok}`}
                />
            </div>
        )
    } else {
        return (
            <FilterInner>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                >

                    <FormControlLabel
                        style={{
                            color: '#666666', fontSize: '16px',
                            opacity: isGeoAvailable ? '1' : '0.5'
                        }}
                        value="currentLocation"
                        onClick={() => {
                            if (isGeoAvailable) {
                                changeLocationType('geo')
                            } else {
                                setIsGeoErrorMessage(true)
                            }
                        }}
                        checked={locationInfo.type == 'geo' ? true : false}
                        control={<Radio style={{color: '#E9392C'}}/>}
                        label={`${lang.use_current_location} ${isGeoAvailable ? '': `(${lang.disabled})`}`}/>

                    <div style={{display: 'flex', alignItems: 'center'}}>

                        <FormControlLabel
                            style={{marginRight: '0px', color: '#666666', fontSize: '16px'}}
                            checked={locationInfo.type == 'addressLocation' ? true : false}
                            onClick={() => {
                                changeLocationType('addressLocation')
                                onLocationAddress(locationInfo.address)
                            }}
                            value="addressLocation"
                            control={<Radio style={{color: '#E9392C'}}/>} label=""/>

                        {
                            isEditActive ?
                                <div style={{position: "relative", width: '100%', display: isEditActive ? 'block': 'none'}}
                                     className={isError && locationInfo.type == 'addressLocation' ? 'borderError': ''}>

                                    <LocationAutocomplete initAddress={locationInfo.address} onPlaceSelected={onAddressChange} />

                                    <img style={{position: 'absolute', right: '11px', top: '14px'}} src={searchIcon}
                                         alt=""/>
                                </div>
                                :
                                <div style={{position: "relative", width: '100%'}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <p
                                            onClick={() => {
                                                changeLocationType('addressLocation')
                                                onLocationAddress(locationInfo.address)
                                            }}
                                            style={{marginRight: '10px'}}>{locationInfo.address}</p>
                                        <EditIcon
                                            onClick={() => {
                                                setIsEditActive(true)
                                            }}
                                            style={{color: '#E9392C'}} />
                                    </div>
                                </div>
                        }

                    </div>
                </RadioGroup>
                <AppPopup
                    open={isGeoErrorMessage}
                    title=""
                    description={lang.no_geo}
                    handleNext={() => {setIsGeoErrorMessage(false)}}
                    nextText={`${lang.ok}`}
                />
            </FilterInner>
        )
    }

}

export default LocationFilter