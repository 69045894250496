const config = {
    companyId: 9,
    apiUrl: process.env.REACT_APP_MJR_API,
    apiVersion: '/v4',
    registerUrl: '/public/client/register',
    userInfoUrl: '/client/user',
    userRequirementsUrl: '/client/user/requirements',
    clientRequirementsUrl: '/client/user/requirements',
    customerInfoUrl: '/client/customers',
    clientInfoUrl: '/client/customer',
    signContractUrl: '/client/customer',
    mlThreshold: 22,
    timeoutToManualPhoto: 4900,
    dlFrontOcrUrl: 'https://ocr.idsugarfree.com/ocr/document/dl/front',
    dlBackOcrUrl: 'https://ocr.idsugarfree.com/ocr/document/dl/back',
    pasFrontOcrUrl: 'https://ocr.idsugarfree.com/ocr/document/international_passport/front',
    idCardFrontOcrUrl: 'https://ocr.idsugarfree.com/ocr/document/id_card/front',
    idCardBackOcrUrl: 'https://ocr.idsugarfree.com/ocr/document/id_card/back',
    experianDLUrl: 'https://api.mobielrijden.nl/v4/public/screener/experian',
    build: process.env.NODE_ENV, //development | production
    reservationDeposit: 200,
    reservationDepositMax: 300,
    googleMapApi: 'AIzaSyB65u0DapLI8cWLbt0Cf2fxC9k9pUldB2w',
    reservationTimePeriod: 15,
    documentCropServiceUrl: "https://crop.idsugarfree.com",
    documentCropTimeout: 30,

    _getPreUrl: function () {
        return this.apiUrl + this.apiVersion + '/' + this.companyId
    },

    getUploadDriverLicenseUrl: function () {
        return this._getPreUrl() + '/client/user/documents';
    },

    getSignContractUrl: function (customerId) {
        return this._getPreUrl() + this.signContractUrl + '/' + customerId + '/contract';
    },

    getShortRegisterUrl: function () {
        return this.apiUrl + this.apiVersion + '/' + this.companyId + this.registerUrl
    },

    getUserInfoUrl: function () {
        return this._getPreUrl() + this.userInfoUrl
    },

    getCustomerInfoUrl: function () {
        return this._getPreUrl() + this.customerInfoUrl
    },

    getClientRequirementsUrl: function (clientId) {
        return this._getPreUrl() + this.clientInfoUrl + '/' + clientId + '/requirements';
    },

    getUserRequirementsUrl: function (userId) {
        return this._getPreUrl() + this.userRequirementsUrl;
    },

    getFullUpdateUrl: function () {
        return this._getPreUrl() + '/client/user';
    },

    getAllDocumentsUrl: function () {
        return this._getPreUrl() + '/client/user/documents';
    },

    getPaymentUrl: function (customerId) {
        return this._getPreUrl() + '/client/customer/' + customerId + '/payment-request-bunq';
    },

    getEditBankInformationUrl: function (customerId) {
        return this._getPreUrl() + '/client/customer/' + customerId;
    },

    getLoginUrl: function () {
        return this._getPreUrl() + '/public/client/login';
    },

    getResetLinkUrl: function () {
        return this._getPreUrl() + '/public/client/reset-password';
    },

    getBlockPaymentStatus: function () {
        return 'pending'
    },

    getContractSignedType: function () {
        return 'contract_signed'
    },

    getRegistrationFeeType: function () {
        return 'registration_fee'
    },

    getBankInformationType: function () {
        return 'bank_information'
    },

    getUserBasicInformationType: function () {
        return 'user_basic_info'
    },

    getDriversLicenseType: function () {
        return 'drivers_license'
    },

    getIdCardType: function () {
        return 'id_card'
    },

    getPassportType: function () {
        return 'passport'
    },

    getSuccessUrl: function () {
        return 'https://kav2go.nl/success/'
    },

    getRestorePasswordLink: function () {
        return this._getPreUrl() + '/public/client/reset-password'
    },

    getImageCropToolUrl: function () {
        return 'https://kav2go.nl/resize.php'
    },

    getGAId: function () {
        return 'UA-211006469-1'
    },

    getRemoveDLUrl: function (api) {
        return this._getPreUrl() + '/client/user/documents'
    },

    getDriversLicenseOCR: function () {
        return 'https://dev.kav2go.nl/api/document/dispatch'
    },

    getZipCodeUrl: function ({
                                 zipCode,
                                 houseNumber,
                                 additionalHouseNumber
                             }) {

        return `https://ocr.idsugarfree.com/postcode/${zipCode}/${houseNumber}/${additionalHouseNumber}`
    },

    getSearchVehicleUrl: function ({startDate, endDate}) {
        return this._getPreUrl() + `/public/fleet/vehicle/search?start=${startDate}&end=${endDate}`
    },

    getCalculatorUrl: function ({categoryId, startDate, endDate, km}) {
        return this._getPreUrl() + `/public/pricing-calculator?category_id=${categoryId}&planned_start_date=${startDate}&planned_end_date=${endDate}&expected_kilometers=${km}`
    },

    getUserInfo: function ({apiToken}) {
        return this._getPreUrl() + `/client/user`
    },

    getClientInfoUrl: function () {
        return this._getPreUrl() + `/client/customers`
    },

    getReservationListUrl: function ({clientId, status, limit = 10, offset = 0}) {
        //return this._getPreUrl() + `/client/reservations?customer=&status[eq][]=${status}&limit=&offset=`
        return this._getPreUrl() + `/client/reservations?customer=&status=&limit=${limit}&offset=`
    },

    getCreateReservationUrl: function () {
        return this._getPreUrl() + `/client/reservations`
    },

    getCancelReservationUrl: function ({id}) {
        return this._getPreUrl() + `/client/reservation/${id}/cancel`
    },

    // getTransactionsHistoryUrl: function ({limit}) {
    //     return this.apiUrl + `/v3/client/transactions?subsidiary_id=${this.companyId}&limit=${limit}`
    // },

    getLoginV3Url: function () {
        return this.apiUrl + `/v3/auth/tokens`
    },

    getTriggerPaymentUrl: function ({clientId}) {
        return this._getPreUrl() + `/client/customer/${clientId}/payment-request-bunq`
    },

    getFleetModelsUrl: function () {
        return this._getPreUrl() + `/public/fleet/models`
    },

    getVehicleControlUrl: function ({id}) {
        return this.apiUrl + `/v3/client/reservations/${id}/vehicle-control-tasks`
    },

    getVehicleInformationUrl: function ({id}) {
        return this.apiUrl + `/v3/client/reservations/${id}`
    },

    getSubsidiaryInfoUrl: function () {
        return this._getPreUrl() + `/public/`
    },

    getUserEditUrl: function () {
        return this._getPreUrl() + '/client/user'
    },

    getTransactionsListUrl: function ({clientId, limit = 10, offset = 0}) {
        return this._getPreUrl() + `/client/customer/${clientId}/transactions?limit=${limit}&offset=${offset}`
    },

    getUserDocumentListUrl: function () {
        return this._getPreUrl() + '/client/user/documents'
    },

    getDeleteDocumentUrl: function () {
        return this._getPreUrl() + '/client/user/documents'
    },

    getClaimBalanceUrl: function ({clientId}) {
        return this._getPreUrl() + `/client/customer/${clientId}/refund-request-bunq`
    },

    getDeleteAccountUrl: function ({clientId}) {
        return this._getPreUrl() + `/client/customer/${clientId}`
    },

    getReservationEditUrl: function ({reservationId}) {
        return this._getPreUrl() + `/client/reservation/${reservationId}`
    },

    getReservationInformationUrl: function ({reservationId}) {
        return this._getPreUrl() + `/client/reservation/${reservationId}`
    },

    getVehicleStatusUrl: function ({vehicleId}) {
        return this._getPreUrl() + `/client/fleet/vehicle/${vehicleId}/status`
    },

    getDamageReportListUrl: function ({vehicleId}) {
        return this._getPreUrl() + `/client/fleet/vehicle/${vehicleId}/damages`
    },

    getNewDamageUrl: function({vehicleId}) {
        return this._getPreUrl() + `/client/fleet/vehicle/${vehicleId}/damages`
    },

    getClientEditUrl: function({clientId}) {
        return this._getPreUrl() + `/client/customer/${clientId}`
    },

    getVehicleChangeStatusUrl: function({vehicleId}) {
        return this._getPreUrl() + `/client/fleet/vehicle/${vehicleId}/status`
    },

    getGeocodingUrl: function({address}) {
        return this._getPreUrl() + `/client/geocode?address=${address}`
    },

    getTextUrl: function({key}) {
        return this._getPreUrl() + `/public/texts?key=${key}`
    },

    getReservationMockEdit: function ({reservationId}) {
        return this._getPreUrl() + `/client/reservation/${reservationId}/mock`
}
}

export default config
