import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import {useSelector} from "react-redux";

const SuccessPopup = ({open}) => {

    const lang = useSelector(state => state.lang)

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => {
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="popup-content-wrapper"
                               style={{

                               }}
                >

                    <div style={{
                        width: '250px',
                        height: '30vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            background: 'transparent',
                            border: '5px solid #28a745',
                            borderRadius: '50%',
                            width: '100px',
                            height: '100px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            animation: 'rotateAnimation 2s infinite'
                        }}>

                            <DoneIcon style={{color: '#28a745', fontWeight: 'bold', fontSize: '48px'}} />

                        </div>

                        <p style={{
                            fontFamily: 'Open Sans',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginTop: '10px'
                        }}>{lang.success}</p>
                    </div>

                </DialogContent>
            </Dialog>
        </div>
    )
}

export default SuccessPopup