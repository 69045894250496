import React, {useEffect, useState} from 'react'
import SkyeTimePickerStyled from "../styles/SkyeTimePickerStyled";
import {Button, FormControl, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import filterTimeFormatted from "../services/filterTimeFormatted";
import {useSelector} from "react-redux";
import OutlinedButton from "../styles/OutlinedButton";
import timeIcon from "../assets/timeIcon.png";
import dateIcon from "../assets/dataIcon.png";

const OffsetTimePicker = ({value, onChange}) => {

    const lang = useSelector(state => state.lang)
    const [isActive, setIsActive] = useState(false)
    const filterStartDate = useSelector(state => state.filter.dateStart)

    const [localStartDate, setLocalStartDate] = useState(filterStartDate)
    const [time, setTime] = useState(value)

    useEffect(() => {
        setLocalStartDate(moment(filterStartDate))
        setTime(value)
    }, [filterStartDate])

    useEffect(() => {
        setTime(value)
    }, [])

    useEffect(() => {
        setTime(value)
    }, [value])

    const onSubmit = () => {
        onChange(time)
        setIsActive(false)
        setNeedAnimation(false)
    }

    const onAddMinutes = (minutes) => {
        const newTime = moment(time).add('minutes', minutes)
        setTime(newTime)
    }

    const onAddHours = (hours) => {
        const newTime = moment(time).add('hours', hours)
        setTime(newTime)
    }

    const [needAnimation, setNeedAnimation] = useState(false)

    const onResetHandler = () => {
        const newTime = moment(localStartDate).add('hours', 1)
        onChange(newTime)
        setTime(newTime)
    }

    const onClose = () => {
        setTime(value)
    }

    useEffect(() => {
        if (needAnimation == true) {
            setTimeout(() => {
                setNeedAnimation(false)
            }, 1000)
        }
    }, [needAnimation])

    return (
        <SkyeTimePickerStyled>
            <p style={{
                width: '100%',
                textAlign: 'left',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: '10px',
                paddingRight: '10px',
            }}
               onClick={() => {
                   setIsActive(true)
               }}>
                <span style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '50%',
                    width: '100%',
                    justifyContent: 'flex-start',
                    fontSize: '16px',
                    color: 'rgba(0, 0, 0, 0.87)'
                }}>
                    <img style={{position: 'static', marginRight: '6px'}} src={dateIcon} alt=""/>
                    {filterTimeFormatted.dateFormat(value)}
                </span>
                <span style={{
                    position: 'relative',
                    display: 'flex',
                    maxWidth: '50%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginLeft: '34px'
                }}>
                    <img style={{position: 'static', marginRight: '17px'}} src={timeIcon} alt=""/>
                    {filterTimeFormatted.timeFormat(value)}
                </span>
            </p>
            {
                isActive ?
                    <>
                        <div onClick={() => {
                            setIsActive(false)
                            setNeedAnimation(false)
                            onClose()
                        }} className="popup-wrapper"></div>
                        <div className="popup">

                            <div className="popup-title-timer" style={{textAlign: 'center'}}>
                                {lang.select_pick_up_time}
                            </div>

                            <p style={{
                                marginBottom: '20px'
                            }}
                               className="popup-info-date">{filterTimeFormatted.visualFormat(filterStartDate)}</p>

                            <div className="popup-title-timer" style={{textAlign: 'center'}}>
                                {lang.return_time}
                            </div>
                            <div className="popup-timer" style={{flexDirection: 'column', alignItems: 'center'}}>
                                <span
                                    onClick={() => {
                                        setIsActive(false)
                                        setNeedAnimation(false)
                                        onClose()
                                    }}
                                    style={{
                                        position: 'absolute',
                                        top: '-5px',
                                        right: '0',
                                        fontSize: '36px',
                                        cursor: 'pointer',
                                        color: '#E9392C',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '50px'
                                    }}>&times;</span>

                                <div className="popup-info">
                                    <p className="popup-info-date">{filterTimeFormatted.visualFormat(time)}</p>
                                    <p className="popup-info-delta">

                                        {
                                            filterTimeFormatted.getTimeDiff(filterStartDate, time).minutes > 0 ?
                                                `( ${filterTimeFormatted.getTotalTime(time, filterStartDate).days > 0 ?
                                                    '+' + filterTimeFormatted.getTotalTime(time, filterStartDate).days + ' ' + lang.days + ', ' +
                                                    filterTimeFormatted.getTotalTime(time, filterStartDate).hours + ' ' + lang.hours + ', ' +
                                                    filterTimeFormatted.getTotalTime(time, filterStartDate).minutes + ' ' + lang.minutes + ')'
                                                    :
                                                    '+ ' + filterTimeFormatted.getTotalTime(time, filterStartDate).hours + ' ' + lang.hours + ', ' +
                                                    filterTimeFormatted.getTotalTime(time, filterStartDate).minutes + ' ' + lang.minutes + ')'

                                                } `
                                                :
                                                ''
                                        }

                                    </p>
                                </div>

                                <div className="popup-buttons">
                                    <OutlinedButton onClick={() => {
                                        onAddHours(23)
                                        setNeedAnimation(true)
                                    }}>
                                        +1 {lang.day}
                                    </OutlinedButton>

                                    <OutlinedButton onClick={() => {
                                        onAddMinutes(30)
                                        setNeedAnimation(true)
                                    }}>
                                        +30 {lang.minutes}
                                    </OutlinedButton>

                                    <OutlinedButton onClick={() => {
                                        onAddHours(1)
                                        setNeedAnimation(true)
                                    }}>
                                        +1 {lang.hour}
                                    </OutlinedButton>
                                </div>

                            </div>

                            <div style={{marginTop: '30px', display: 'flex', justifyContent: 'center'}}>
                                <div>
                                    <Button
                                        className={needAnimation ? 'alertInformationSmallAnimation' : ''}
                                        style={{
                                            color: '#fff',
                                            backgroundColor: filterTimeFormatted.getTimeDiff(value, time).minutes > 0 ? '#E9392C' : 'gray',
                                            pointerEvents: filterTimeFormatted.getTimeDiff(value, time).minutes > 0 ? 'auto': 'none'
                                    }}
                                        onClick={onSubmit}
                                        variant="contained">
                                        {lang.confirm} {filterTimeFormatted.visualFormat(time)}
                                    </Button>
                                </div>
                            </div>

                            {
                                filterTimeFormatted.getTimeDiff(value, time).minutes > 0 ||
                                filterTimeFormatted.getTimeDiff(filterStartDate, value).minutes > 120 ?
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '20px'
                                    }}>
                                        <OutlinedButton onClick={onResetHandler} style={{color: '#E9392C'}}>
                                            {lang.reset}
                                        </OutlinedButton>
                                    </div>
                                    :
                                    ''
                            }

                        </div>
                    </>
                    :
                    ''
            }

        </SkyeTimePickerStyled>
    )
}

export default OffsetTimePicker
