import React from 'react'
import Spy from "./Spy";
import MarkerStyled from "../styles/MarkerStyled";
import MarkerInGroupStyled from "../styles/MarkerInGroupStyled";
import carMultiple from '../assets/marker-car-multiple.png'
import carSingle from '../assets/marker-van-utility.png'
import {useDispatch, useSelector} from "react-redux";
import {changeField} from "../redux/actions";

const Marker = ({inGroup, address, lat, lng, points}) => {

    const dispatch = useDispatch()
    const mapZoom = useSelector(state => state.map.zoom)

    const onClick = () => {

        console.log(points)

        const addresses = []

        points.forEach((item) => {
            if (!addresses.includes(item.address)) {
                addresses.push(item.address)
            }
        })

        if (addresses.length > 1) {

            dispatch(
                changeField({
                    field: 'map',
                    value: {
                        zoom: mapZoom + 2,
                        coords: {lat, lng}
                    }
                })
            )

            dispatch(changeField({
                field: 'activeVans',
                value: []
            }))

        } else {
            dispatch(changeField({
                field: 'activeVans',
                value: points
            }))
        }

    }

    return (
        <div>
            {
                inGroup ?
                    <MarkerInGroupStyled
                        onClick={onClick}
                    >
                        <img src={carMultiple} alt=""/>
                    </MarkerInGroupStyled>
                    :
                    <MarkerStyled
                        onClick={onClick}
                    >
                        {
                            points.length == 1 && points[0].van.available == false ?
                                <img style={{filter: 'grayscale(1)'}} src={carSingle} alt=""/>
                                :
                                <img src={carSingle} alt=""/>
                        }
                    </MarkerStyled>
            }
        </div>
    )
}

export default Marker