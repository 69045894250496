import React, {useEffect, useMemo, useState} from 'react'
import {Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {changeField, changePage, zipCodeAutocomplete} from "../redux/actions"
import AppHeader from "../components/AppHeader"
import countryList from 'react-select-country-list'
import validator from '../services/validator'
import AppPopup from "../components/AppPopup"
import AppPopupErrors from "../components/AppPopupErrors"
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/material.css'
import {findError} from "../services/findError";
import useDebounce from "../hooks/useDebounce";
import ReactGA from "react-ga";

const ContactsPage = () => {

    const countries = useMemo(() => countryList().getData(), [])

    const [isAlertShow, setIsAlertShow] = useState(false)

    const dispatch = useDispatch()

    //Living
    const zipCodeLiving = useSelector(state => state.zipCodeLiving)
    const houseNumberLiving = useSelector(state => state.houseNumberLiving)
    const numberAdditionLiving = useSelector(state => state.numberAdditionLiving)
    const streetLiving = useSelector(state => state.streetLiving)
    const cityLiving = useSelector(state => state.cityLiving)
    const countryLiving = useSelector(state => state.countryLiving)

    //Invoice
    const zipCodeInvoice = useSelector(state => state.zipCodeInvoice)
    const houseNumberInvoice = useSelector(state => state.houseNumberInvoice)
    const numberAdditionInvoice = useSelector(state => state.numberAdditionInvoice)
    const streetInvoice = useSelector(state => state.streetInvoice)
    const cityInvoice = useSelector(state => state.cityInvoice)
    const countryInvoice = useSelector(state => state.countryInvoice)

    const debouncedZipCode = useDebounce(zipCodeLiving, 500)
    const debouncedHouseNumber = useDebounce(houseNumberLiving, 500)
    const debouncedAdditional = useDebounce(numberAdditionLiving, 500)

    useEffect(() => {

        if (debouncedZipCode.length > 0 && debouncedHouseNumber.length > 0) {
            dispatch(zipCodeAutocomplete({
                zipCode: debouncedZipCode,
                houseNumber: debouncedHouseNumber,
                additionalHouseNumber: debouncedAdditional
            }))
        }

    }, [debouncedZipCode, debouncedHouseNumber, debouncedAdditional])

    //User
    const email = useSelector(state => state.email)
    const phone = useSelector(state => state.phone)

    const isInvoiceActive = useSelector(state => state.theSameInvoice)
    const [errors, setErrors] = useState([])
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    const [isApproveOpen, setIsApproveOpen] = useState(false)

    const lang = useSelector(state => state.lang)

    const handleNextStep = (e) => {

        const isDisabled = e.target.getAttribute('data-disabled')

        if (isDisabled === 'true') {

            if (!isInvoiceActive) {
                const incorrectFields = validator.documentVerification([
                    {type: 'zipCodeLiving', value: zipCodeLiving, message: lang.zip_code},
                    {type: 'houseNumberLiving', value: houseNumberLiving, message: lang.house_number},
                    {type: 'streetLiving', value: streetLiving, message: lang.street},
                    {type: 'cityLiving', value: cityLiving, message: lang.city},

                    {type: 'email', value: email, message: lang.email},
                    {type: 'phone', value: phone, message: lang.phone},
                ])

                if (incorrectFields.length > 0) {
                    setIsAlertShow(true)
                }

                setErrors(incorrectFields)
            } else {
                const incorrectFields = validator.documentVerification([
                    {type: 'zipCodeLiving', value: zipCodeLiving, message: lang.home_zip_code},
                    {type: 'houseNumberLiving', value: houseNumberLiving, message: lang.home_house_number},
                    {type: 'streetLiving', value: streetLiving, message: lang.home_street},
                    {type: 'cityLiving', value: cityLiving, message: lang.home_city},

                    {type: 'zipCodeInvoice', value: zipCodeInvoice, message: lang.invoice_zip_code},
                    {type: 'houseNumberInvoice', value: houseNumberInvoice, message: lang.invoice_house_number},
                    {type: 'streetInvoice', value: streetInvoice, message: lang.invoice_street},
                    {type: 'cityInvoice', value: cityInvoice, message: lang.invoice_city},

                    {type: 'email', value: email, message: lang.email},
                    {type: 'phone', value: phone, message: lang.phone},
                ])

                if (incorrectFields.length > 0) {
                    setIsAlertShow(true)
                }

                setErrors(incorrectFields)
            }

        } else {

            setIsApproveOpen(true)
        }

    }

    const handleChangeCountry = (e) => {

        dispatch(
            changeField({
                field: e.target.name,
                value: e.target.value
            })
        )
    }

    const handleAlertClose = () => {
        setIsAlertShow(false)
    }

    const handleApproveClose = () => {
        setIsApproveOpen(false)
    }

    const goToNextHandler = () => {
        dispatch(changePage({pageName: 'credentials-page'}))
    }

    const onChangeInvoice = () => {
        dispatch(changeField({
            field: 'theSameInvoice',
            value: !isInvoiceActive
        }))
    }

    useEffect(() => {

        let incorrectFields = []

        if (!isInvoiceActive) {
            incorrectFields = validator.documentVerification([
                {type: 'zipCodeLiving', value: zipCodeLiving, message: lang.zip_code},
                {type: 'houseNumberLiving', value: houseNumberLiving, message: lang.house_number},
                {type: 'streetLiving', value: streetLiving, message: lang.street},
                {type: 'cityLiving', value: cityLiving, message: lang.city},
                {type: 'email', value: email, message: lang.email},
                {type: 'phone', value: phone, message: lang.phone},
            ])

            setErrors(incorrectFields)

        } else {
            incorrectFields = validator.documentVerification([
                {type: 'zipCodeLiving', value: zipCodeLiving, message: lang.home_zip_code},
                {type: 'houseNumberLiving', value: houseNumberLiving, message: lang.home_house_number},
                {type: 'streetLiving', value: streetLiving, message: lang.home_street},
                {type: 'cityLiving', value: cityLiving, message: lang.home_city},

                {type: 'zipCodeInvoice', value: zipCodeInvoice, message: lang.invoice_zip_code},
                {type: 'houseNumberInvoice', value: houseNumberInvoice, message: lang.invoice_house_number},
                {type: 'streetInvoice', value: streetInvoice, message: lang.invoice_street},
                {type: 'cityInvoice', value: cityInvoice, message: lang.invoice_city},

                {type: 'email', value: email, message: lang.email},
                {type: 'phone', value: phone, message: lang.phone},
            ])
        }

        if (incorrectFields.length === 0) {
            setIsButtonDisabled(false)
        } else {
            setIsButtonDisabled(true)
        }

        setErrors(incorrectFields)

    }, [isInvoiceActive, zipCodeLiving, houseNumberLiving, numberAdditionLiving, streetLiving, cityLiving,
        zipCodeInvoice, houseNumberInvoice, numberAdditionInvoice, streetInvoice, cityInvoice, email, phone])

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'Contacts Page'
        });
    }, [])

    return (
        <Grid container spacing={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4px'}}>
            <AppHeader />

            <AppPopup title={lang.is_info_correct}
                      open={isApproveOpen}
                      handleClose={handleApproveClose}
                      description=""
                      nextText={lang.yes}
                      closeText={lang.no}
                      handleNext={goToNextHandler}
            />

            <AppPopupErrors title={lang.you_did_not_provide}
                            open={isAlertShow}
                            closeText={lang.cancel}
                            handleClose={handleAlertClose}
                            errors={errors}
            />

            <Grid className="containerAlignBottom" container item spacing={2} xs={11}>
                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} item xs={12}>
                    <h1 className="secondH1Title">{lang.contacts}</h1>

                    <Grid style={{marginTop: '10px'}} container item spacing={2} xs={12}>
                        <Grid item xs={12}>
                            <p className="small-subtitle">{lang.home_address}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="zipCodeLiving"
                                className="zipCode"
                                error={findError('zipCodeLiving', errors)}
                                value={zipCodeLiving}
                                onChange={handleChangeCountry}
                                style={{width: '100%'}}
                                label={lang.zip_code}
                                variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                style={{width: '100%'}}
                                error={findError('houseNumberLiving', errors)}
                                name="houseNumberLiving"
                                onChange={handleChangeCountry}
                                value={houseNumberLiving}
                                label={lang.house_number}
                                variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                style={{width: '100%'}}
                                name="numberAdditionLiving"
                                error={findError('numberAdditionLiving', errors)}
                                type="text"
                                value={numberAdditionLiving}
                                onChange={handleChangeCountry}
                                label={lang.number_addition}
                                variant="outlined" />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                style={{width: '100%'}}
                                name="streetLiving"
                                error={findError('streetLiving', errors)}
                                type="text"
                                value={streetLiving}
                                label={lang.street}
                                onChange={handleChangeCountry}
                                variant="outlined" />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                style={{width: '100%'}}
                                name="cityLiving"
                                type="text"
                                error={findError('cityLiving', errors)}
                                value={cityLiving}
                                label={lang.city}
                                onChange={handleChangeCountry}
                                variant="outlined" />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                style={{width: '100%'}}
                                name="countryLiving"
                                error={findError('countryLiving', errors)}
                                select
                                label={lang.country}
                                value={countryLiving}
                                onChange={handleChangeCountry}
                            >
                                {countries.map((item) => {
                                    return (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid style={{marginTop: '10px'}} container item spacing={2} xs={12}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox onChange={onChangeInvoice}
                                                   checked={!isInvoiceActive}
                                                   className="kav2go-checkbox-style" />}
                                label={`${lang.use_the_same}`} />
                        </Grid>
                    </Grid>

                    {
                        isInvoiceActive ?
                            <Grid style={{marginTop: '10px'}} container item spacing={2} xs={12}>
                                <Grid item xs={12}>
                                    <p className="small-subtitle">{lang.invoice_address}</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="zipCodeInvoice"
                                        className="zipCode"
                                        error={findError('zipCodeInvoice', errors)}
                                        value={zipCodeInvoice}
                                        onChange={handleChangeCountry}
                                        style={{width: '100%'}}
                                        label={lang.zip_code}
                                        variant="outlined" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        style={{width: '100%'}}
                                        name="houseNumberInvoice"
                                        error={findError('houseNumberInvoice', errors)}
                                        onChange={handleChangeCountry}
                                        value={houseNumberInvoice}
                                        label={lang.house_number}
                                        variant="outlined" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        style={{width: '100%'}}
                                        name="numberAdditionInvoice"
                                        error={findError('numberAdditionInvoice', errors)}
                                        type="text"
                                        value={numberAdditionInvoice}
                                        onChange={handleChangeCountry}
                                        label={lang.number_addition}
                                        variant="outlined" />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        style={{width: '100%'}}
                                        name="streetInvoice"
                                        type="text"
                                        error={findError('streetInvoice', errors)}
                                        value={streetInvoice}
                                        label={lang.street}
                                        onChange={handleChangeCountry}
                                        variant="outlined" />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        style={{width: '100%'}}
                                        name="cityInvoice"
                                        error={findError('cityInvoice', errors)}
                                        type="text"
                                        value={cityInvoice}
                                        label={lang.city}
                                        onChange={handleChangeCountry}
                                        variant="outlined" />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        style={{width: '100%'}}
                                        name="countryInvoice"
                                        error={findError('countryInvoice', errors)}
                                        select
                                        label={lang.country}
                                        value={countryInvoice}
                                        onChange={handleChangeCountry}
                                    >
                                        {countries.map((item) => {
                                            return (
                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })}
                                    </TextField>
                                </Grid>
                            </Grid>
                            :
                            ''
                    }

                    <Grid style={{marginTop: '10px'}} container item spacing={2} xs={12}>
                        <Grid item xs={12}>
                            <TextField
                                name="email"
                                value={email}
                                inputProps={{autoCapitalize: "off"}}
                                error={findError('email', errors)}
                                onChange={handleChangeCountry}
                                style={{width: '100%'}}
                                label={lang.email}
                                variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneInput
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    onChange: handleChangeCountry,
                                    style: {width: '100%'}
                                }}
                                country={'nl'}
                                required
                                value={phone}
                            />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} item xs={12}>
                    <Button
                        className="main-button main-button-register"
                        style={{marginBottom: '20px'}}
                        data-disabled={isButtonDisabled}
                        onClick={handleNextStep}
                        variant="contained">{lang.continue}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ContactsPage