import React, {useEffect, useRef, useState} from 'react'
import * as tf from '@tensorflow/tfjs'
import * as automl from '@tensorflow/tfjs-automl'
import {useDispatch, useSelector} from "react-redux";
import {
    changeField,
    changePage,
    preloaderStart,
    preloaderStop,
    putDLFrontPhotoToResult,
    putSelfieToResult
} from "../redux/actions";
import config from "../config";
import DeleteIcon from "@mui/icons-material/Delete"
import {Button} from "@mui/material"
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import cameraSound from "../assets/03203_min.mp3";
import ReactGA from "react-ga";

const SelfiePhotoPage = () => {

    const dispatch = useDispatch()
    const videoRef = useRef(false)
    const preProcessCanvas = useRef(false)
    const resCanvasRef = useRef(false)
    const [isPhotoDone, setIsPhotoDone] = useState(false)

    const [stream, setStream] = useState({})

    const handleOnSuccessImage = (base64Image) => {

        dispatch(putSelfieToResult({
            imageBase64: base64Image
        }))

    }

    useEffect(() => {

        dispatch(preloaderStart())

        function run() {
            const constraintsDefault = {
                audio: false,
                video: {
                    width: 800,
                    height: 800,
                    facingMode: "user"
                    // facingMode: {
                    //     exact: "user"
                    // }
                }
            }

            navigator.mediaDevices.getUserMedia(constraintsDefault)
                .then((stream) => {
                    dispatch(preloaderStop())

                    videoRef.current.srcObject = stream
                    setStream(stream)
                })
        }

        run()

    }, [videoRef])

    const makePhotoHandler = () => {

        const myAudio = new Audio(cameraSound);
        myAudio.play()

        preProcessCanvas.current.width = videoRef.current.offsetWidth
        preProcessCanvas.current.height = videoRef.current.offsetHeight

        preProcessCanvas.current.getContext('2d').drawImage(videoRef.current, 0, 0,
            videoRef.current.offsetWidth, videoRef.current.offsetHeight)

        const base64ResImage = preProcessCanvas.current.toDataURL('image/jpeg', 1)

        handleOnSuccessImage(base64ResImage)

        stream.getTracks()
            .forEach((track) => track.stop())

        setStream({})
    }

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'Selfie Photo Page'
        });
    }, [])

    return (
        <div>
            <canvas style={{display: 'none'}} ref={preProcessCanvas}/>
            <div className="make-photo-wrapper">

                <div className="make-photo-selfie-wrapper">
                    <video style={{width: '100%', height: 'auto', display: isPhotoDone? 'none': 'block'}}
                           className="selfie-make-photo"
                           ref={videoRef} playsInline autoPlay/>

                    <Button
                        onClick={makePhotoHandler}
                        className="make-photo-btn selfie-make-photo-btn"
                        variant="outlined"> <PhotoCameraIcon /> </Button>
                </div>


            </div>

            <canvas style={{display: isPhotoDone ? 'block': 'none'}} ref={resCanvasRef}/>
        </div>
    )
}

export default SelfiePhotoPage