import React, {useRef} from 'react'
import styled from "styled-components";

const InputFieldStyled = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  height: 43px;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;

  img {
    position: absolute;
    left: 8px;
  }

  select {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    padding-left: 50px;
    padding-right: 20px;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    z-index: 20;
  }

  span {
    position: absolute;
    right: 10px;
  }
`

const SelectField = ({icon, postfix, value, name, onChange, type = 'text', placeholder = ''}) => {

    return (
        <InputFieldStyled>
            <img src={icon} alt=""/>

            <select
                onChange={(e) => {
                    onChange(name, e.target.value)
                }}
                value={value}
                name={name}>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
                <option value="500">500</option>
            </select>

            <span>{postfix}</span>
        </InputFieldStyled>
    )
}

export default SelectField