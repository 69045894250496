import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import styled from "styled-components";

const LinkStyled = styled.div`
  display: inline;
  a {
    color: #E9392C
  }
`

export default function AppPopupTermsOfUse({
                                     open,
                                     handleClose = false,
                                     title,
                                     description = '',
                                     closeText = 'close',
                                     handleNext = false,
                                     nextText = 'yes'
                                 }) {

    const lang = useSelector(state => state.lang)

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose ? handleClose: () => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className="popup-title" style={{textAlign: 'center'}}>{title}</DialogTitle>
                <DialogContent className="popup-content-wrapper">
                    <DialogContentText>
                        {lang.cancel_free} {description} EUR <br /> {lang.read_terms}
                        <LinkStyled>
                            <Link to="/terms-of-use">{lang.terms_of_use}</Link>
                        </LinkStyled>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={ handleClose && handleNext ? {display: 'flex', justifyContent: 'space-between'} :
                    {display: 'flex', justifyContent: 'center'}}>

                    {
                        handleClose ?
                            <Button className="cancelButtonText" onClick={handleClose} color="primary">
                                {closeText}
                            </Button>
                            :
                            ''
                    }

                    {handleNext ?
                        (
                            <Button className="nextButtonText"
                                    onClick={handleNext}
                                    variant="contained"
                                    color="primary">
                                {nextText}
                            </Button>
                        )
                        :
                        false
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}