import React from 'react'
import {Grid} from "@mui/material";
import carImage from '../assets/reservationcar.png'
import carImageDesktop from '../assets/indexPageBus_min.jpg'
import {useSelector} from "react-redux"
import imageBus from '../assets/bushome.png'
import filterTimeFormatted from "../services/filterTimeFormatted";
import styled from "styled-components";
import ActiveReservation from "./ActiveReservation";
import {BrowserView, MobileView, isBrowser, isMobile} from 'react-device-detect'

const DashboardReservations = () => {

    const activeReservation = useSelector(state => state.activeReservation)

    const lang = useSelector(state => state.lang)

    return (
        <Grid item xs={12}>
            <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                {
                    !activeReservation ?
                        <div className="dashboard-reservations">
                            <BrowserView>
                                <img style={{maxWidth: '180px', height: 'auto', marginTop: '10px'}} src={carImageDesktop} alt=""/>
                            </BrowserView>
                            <MobileView>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <img style={{maxWidth: '40%', height: 'auto'}} src={carImageDesktop} alt=""/>
                                </div>
                            </MobileView>
                            <span className="dashboard-reservations-line"></span>
                            <p>{lang.no_active_res}</p>
                        </div>
                        :
                        <ActiveReservation />
                }
            </Grid>
        </Grid>
    )
}

export default DashboardReservations