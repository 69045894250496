import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {Grid} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import ActiveReservationBoxStyled from "../styles/ActiveReservationBoxStyled";
import {useDispatch, useSelector} from "react-redux";
import {
    changeVehicleStatus,
    editReservation,
    getDamageReportList,
    getReservationInformation,
    getVehicleInformation,
    getVehicleStatus
} from "../redux/actions";
import busIcon from '../assets/bushome.png'
import TableStyled from "../styles/TableStyled";
import filterTimeFormatted from "../services/filterTimeFormatted";
import styled from "styled-components"
import unlockIcon from '../assets/unlockdoors.png'
import lockIcon from '../assets/lockdoors.png'
import extendIcon from '../assets/extendicon.png'
import navigateIcon from '../assets/returnlocation.png'
import damageReport from '../assets/damageReport.png'
import endReservationIcon from '../assets/endreservation.png'
import AppPopup from "../components/AppPopup";
import end1Gif from '../assets/endingInstructions-step_1-invers_cloudboxx.gif'
import end2Gif from '../assets/endingInstructions-step_2.gif'
import startGif from "../assets/startingInstructions-invers_cloudboxx.gif";
import ExtendReservationPopup from "../components/ExtendReservationPopup";
import moment from "moment";

const ActiveButtonStyled = styled.div`
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  color: #E9392C;
  background: #FFFFFF;
  border: 1px solid #E9392C;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  font-weight: bold;
  font-size: 14px;
  margin: 0 5px;

  img {
    margin-right: 5px;
    max-width: 25px;
    height: auto;
  }
  
  @media (max-width: 400px) {
    font-size: 12px;
  }
`

const ManageActiveReservationPage = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const lang = useSelector(state => state.lang)

    //reservation id
    const {id} = useParams()
    const apiToken = useSelector(state => state.apiToken)
    const [activeReservation, setActiveReservation] = useState(false)
    const [vehicleInformation, setVehicleInformation] = useState(false)

    const [isEndActive, setIsEndActive] = useState(false)
    const [isInstruction1, setIsInstruction1] = useState(false)
    const [isInstruction2, setIsInstruction2] = useState(false)
    const [isInstruction3, setIsInstruction3] = useState(false)

    const [damageReports, setDamageReports] = useState([])

    const resDate = JSON.parse(window.localStorage.getItem('resDate'))

    const getData = () => {
        dispatch(
            getReservationInformation({
                apiToken,
                reservationId: id,
                onSuccess: (res) => {
                    setActiveReservation(res)

                    dispatch(
                        getVehicleStatus({
                            apiToken,
                            vehicleId: res.vehicle.id,
                            onSuccess: (vehicleStatus) => {
                                setVehicleInformation(vehicleStatus)
                            }
                        })
                    )

                    dispatch(
                        getDamageReportList({
                            apiToken,
                            vehicleId: res.vehicle.id,
                            onSuccess: (reports) => {
                                setDamageReports(reports)
                            }
                        })
                    )
                }
            })
        )
    }

    useEffect(() => {
        getData()
    }, [id])

    const onEndReservationHandler = () => {
        dispatch(
            editReservation({
                apiToken,
                reservationId: id,
                status: 'returned',
                onSuccess: () => {
                    history.push(`/end-reservation/${id}`)
                }
            })
        )
    }

    const returnLocationHandler = () => {

        if (!navigator.geolocation) {
            window.open(`https://maps.google.com/?q=${activeReservation.return_branch.coordinates.latitude},${activeReservation.return_branch.coordinates.longitude}`, "_blank")
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                const {latitude, longitude} = position.coords
                window.open(`https://maps.google.com/?saddr=${latitude},${longitude}&daddr=${activeReservation.return_branch.coordinates.latitude},${activeReservation.return_branch.coordinates.longitude}&dirflg=w`)
            }, (err) => {
                window.open(`https://maps.google.com/?q=${activeReservation.return_branch.coordinates.latitude},${activeReservation.return_branch.coordinates.longitude}`, "_blank")
            })
        }
    }

    const [isExtendActive, setIsExtendActive] = useState(false)

    const onUpdateHandler = () => {
        setIsExtendActive(false)
        getData()
    }

    const [isOpenDoorsActive, setIsOpenDoorsActive] = useState(false)
    const [isCloseDoorsActive, setIsCloseDoorsActive] = useState(false)

    const openDoorsHandler = () => {
        dispatch(
            changeVehicleStatus({
                apiToken,
                vehicleId: activeReservation.vehicle.id,
                status: {
                    central_lock: 'unlocked'
                },
                onSuccess: (res) => {
                    setIsOpenDoorsActive(false)
                }
            })
        )
    }

    const closeDoorsHandler = () => {
        dispatch(
            changeVehicleStatus({
                apiToken,
                vehicleId: activeReservation.vehicle.id,
                status: {
                    central_lock: 'locked'
                },
                onSuccess: (res) => {
                    setIsCloseDoorsActive(false)
                }
            })
        )
    }

    const formatAddedDate = () => {
        const beginEndDate = moment(resDate.startDate)
        const currentEndDate = moment(activeReservation.planned_end_date)

        const res = filterTimeFormatted.getTotalTime(currentEndDate,beginEndDate)

        if (res.hours > 0) {
            return `(+ ${res.hours}h, ${res.minutes}m)`
        }

        if (res.minutes > 0) {
            return `(+ ${res.minutes}m)`
        }

        return ''
    }

    return (

        <div className="app-page-wrapper">
            <Grid container spacing={2}>

                <AppReservationHeader onBackHandler={() => {
                    history.push('/dashboard')
                }} isBackActive={true} information={true}/>

                <Grid style={{paddingTop: '0'}} item xs={12}>
                    <Grid style={{paddingTop: '8px', marginLeft: 'auto', marginRight: 'auto'}} item xs={12} md={6}>

                        {
                            activeReservation ?
                                <ActiveReservationBoxStyled>
                                    <div className="manage-top" style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
                                        <img className="busImage" src={busIcon} alt=""/>
                                        <p className="activeReservationTitle">{lang.active_reservation}
                                            <b>#{activeReservation ? activeReservation.id : ''}</b>
                                            <br/>
                                            {lang.license_plate}: {activeReservation.vehicle.license_plate}
                                        </p>
                                    </div>
                                    <hr/>
                                    <TableStyled>
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td>{lang.vehicle}:</td>
                                                <td>{activeReservation.vehicle.model.brand} {activeReservation.vehicle.model.model}</td>
                                            </tr>
                                            <tr>
                                                <td>{lang.location}:</td>
                                                <td>{activeReservation.pick_up_branch.address}</td>
                                            </tr>
                                            <tr>
                                                <td>{lang.pick_up_date}:</td>
                                                <td style={{color: '#666666'}}>{filterTimeFormatted.fromPhpToMomentJsFormat(activeReservation.planned_start_date)}</td>
                                            </tr>
                                            <tr>
                                                <td>{lang.return_date}:</td>
                                                <td style={{color: '#666666'}}>
                                                    {filterTimeFormatted.fromPhpToMomentJsFormat(activeReservation.planned_end_date)}

                                                    {
                                                        resDate && resDate.id == activeReservation.id ?
                                                            <span style={{color: '#E9392C'}}> {formatAddedDate()}</span>
                                                            :
                                                            ''
                                                    }

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{lang.transmission}:</td>
                                                <td style={{textTransform: 'capitalize'}}>{activeReservation.vehicle.model.transmission}</td>
                                            </tr>
                                            <tr>
                                                <td>{lang.fuel_type}:</td>
                                                <td style={{textTransform: 'capitalize'}}>{activeReservation.vehicle.model.fuel_type}</td>
                                            </tr>
                                            <tr>
                                                <td>{lang.fuel_level}:</td>
                                                <td style={{textTransform: 'capitalize'}}>
                                                    {
                                                        vehicleInformation ?
                                                            vehicleInformation.fuel_level + '%'
                                                            :
                                                            ''
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{lang.fuel_card_pin}:</td>
                                                <td style={{textTransform: 'capitalize'}}>
                                                    <b style={{color: 'black'}}>{activeReservation.vehicle.fuel_card_pin}</b>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </TableStyled>

                                </ActiveReservationBoxStyled>
                                :
                                ''
                        }

                    </Grid>
                </Grid>

                <Grid style={{paddingTop: '0'}} item container xs={12}>
                    <Grid style={{paddingTop: '8px', marginLeft: 'auto', marginRight: 'auto'}} container item xs={12} md={6}>
                        <Grid item xs={6}>
                            <ActiveButtonStyled onClick={
                                () => {setIsOpenDoorsActive(true)}
                            }>
                                <img src={unlockIcon} alt=""/>
                                {lang.unlock_doors}
                            </ActiveButtonStyled>
                        </Grid>
                        <Grid item xs={6}>
                            <ActiveButtonStyled onClick={
                                () => {setIsCloseDoorsActive(true)}
                            }>
                                <img src={lockIcon} alt=""/>
                                {lang.lock_doors}
                            </ActiveButtonStyled>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className="manage-btn-group" item container xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                    <Grid className="manage-btn-group-down" item xs={12} style={{marginBottom: '8px'}}>
                        <ActiveButtonStyled onClick={() => {
                            setIsExtendActive(true)
                        }}>
                            <img src={extendIcon} alt=""/>
                            {lang.extend_reservation}
                        </ActiveButtonStyled>
                    </Grid>
                    <Grid className="manage-btn-group-down" item xs={12} style={{marginBottom: '8px'}}>
                        <ActiveButtonStyled onClick={returnLocationHandler}>
                            <img src={navigateIcon} alt=""/>
                            {lang.navigate_to_return_location}
                        </ActiveButtonStyled>
                    </Grid>
                    <Grid className="manage-btn-group-down" item xs={12} style={{marginBottom: '8px'}}>
                        <ActiveButtonStyled
                            onClick={() => {
                                history.push(`/damage-report/${activeReservation.vehicle.id}/${id}`)
                            }}>
                            <img src={damageReport} alt=""/>
                            {lang.damage_report_title} ({damageReports.length})
                        </ActiveButtonStyled>
                    </Grid>
                    <Grid item xs={12}>
                        <ActiveButtonStyled onClick={() => {
                            setIsEndActive(true)
                        }}>
                            <img src={endReservationIcon} alt=""/>
                            {lang.end_reservation}
                        </ActiveButtonStyled>
                    </Grid>
                </Grid>

            </Grid>

            <AppPopup
                open={isEndActive}
                title={`${lang.end_reservation}`}
                description={`
                
                ${lang.end_res_1}
                   <br>
                ${lang.end_res_2}: <br>

<span style="color: #E9392C; font-weight: bold">${lang.step} 1</span> <b> - ${lang.end_res_3}</b>  <br>
<span style="color: #E9392C; font-weight: bold">${lang.step} 2</span> <b> - ${lang.end_res_4}</b> <br>
<span style="color: #E9392C; font-weight: bold">${lang.step} 3</span> <b> - ${lang.end_res_5}</b>
                
                `}
                nextText={`${lang.confirm}`}
                closeText={`${lang.cancel}`}
                handleNext={() => {
                    setIsEndActive(false)
                    setIsInstruction1(true)
                }}
                handleClose={() => {
                    setIsEndActive(false)
                }}
            />

            <AppPopup
                open={isInstruction1}
                title={`${lang.step} 1`}
                description={
                    `
<div style="margin-bottom: 10px;">
<p style="margin-bottom: 5px;">
${lang.step_1}
</p>
<img style="max-width: 100%; display: block" src=${end1Gif}>
<p>${lang.step_2}</p>
</div>
`
                }
                nextText={`${lang.next}`}
                closeText={`${lang.cancel}`}
                handleNext={() => {
                    setIsInstruction1(false)
                    setIsInstruction2(true)
                }}
                handleClose={() => {
                    setIsInstruction1(false)
                }}
            />

            <AppPopup
                open={isInstruction2}
                title={`${lang.step} 2`}
                description={
                    `
<div style="margin-bottom: 10px">
<b>${lang.step_3}</b> <br>
${lang.step_4}
<b>${lang.step_5}</b> ${lang.step_6}
</div>`
                }
                nextText={`${lang.next}`}
                closeText={`${lang.cancel}`}
                handleNext={() => {
                    setIsInstruction2(false)
                    setIsInstruction3(true)
                }}
                handleClose={() => {
                    setIsInstruction2(false)
                }}
            />

            <AppPopup
                open={isInstruction3}
                title={`${lang.step} 3`}
                description={
                    `
<div style="margin-bottom: 10px">
<p>
${lang.step_7}
</p>
<p style="margin-bottom: 5px">${lang.step_8}</p>
<img style="max-width: 100%" src=${end2Gif}>
</div>
`
                }
                nextText={`${lang.end_reservation_btn}`}
                closeText={`${lang.cancel}`}
                handleNext={() => {
                    setIsInstruction3(false)
                    onEndReservationHandler()
                }}
                handleClose={() => {
                    setIsInstruction3(false)
                }}
            />

            <ExtendReservationPopup reservationId={activeReservation.id}
                                    handleClose={() => {
                                        setIsExtendActive(false)
                                    }}
                                    onUpdate={onUpdateHandler}
                                    isActive={isExtendActive} timeFrom={activeReservation.planned_start_date}
                                    timeTo={activeReservation.planned_end_date}/>

            <AppPopup
                title=""
                description={lang.want_open_doors}
                open={isOpenDoorsActive}
                handleClose={() => {setIsOpenDoorsActive(false)}}
                handleNext={openDoorsHandler}
                closeText={lang.no}
                nextText={lang.yes}
            />

            <AppPopup
                title=""
                description={lang.want_close_doors}
                open={isCloseDoorsActive}
                handleClose={() => {setIsCloseDoorsActive(false)}}
                handleNext={closeDoorsHandler}
                closeText={lang.no}
                nextText={lang.yes}
            />

        </div>
    )
}

export default ManageActiveReservationPage