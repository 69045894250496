import React, {useEffect, useState} from 'react'
import PinCodeStyled from "../styles/PinCodeStyled";
import TextStyled from "../styles/TextStyled";
import {useSelector} from "react-redux";

const PinCodeComponent = ({title, onSuccess, onCancel = false}) => {

    const [pin, setPin] = useState([])
    const lang = useSelector(state => state.lang)

    const onAddPin = (pinNumber) => {
        setPin([
            ...pin, pinNumber
        ])
    }

    useEffect(() => {
        if (pin.length === 4) {
            onSuccess(pin)

            setPin([])
        }
    }, [pin])

    return (
        <PinCodeStyled>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                <div className="pinCodeTitle">
                    <TextStyled>
                        <p style={{color: 'white', fontSize: '18px', fontWeight: 'bold'}}>{title}</p>
                    </TextStyled>
                </div>
                <div className="pinCodeValues">
                    <span className={`pinCodeValueItem ${pin.length >= 1 ? 'pinCodeValueItemActive' : ''}`}></span>
                    <span className={`pinCodeValueItem ${pin.length >= 2 ? 'pinCodeValueItemActive' : ''}`}></span>
                    <span className={`pinCodeValueItem ${pin.length >= 3 ? 'pinCodeValueItemActive' : ''}`}></span>
                    <span className={`pinCodeValueItem ${pin.length >= 4 ? 'pinCodeValueItemActive' : ''}`}></span>
                </div>
                <div className="pinCodeKeyboard">
                    <div className="pinCodeKeyboardRow">
                        <div onClick={() => {onAddPin('1')}} className="pinCodeKeyboardItem">1</div>
                        <div onClick={() => {onAddPin('2')}} className="pinCodeKeyboardItem">2</div>
                        <div onClick={() => {onAddPin('3')}} className="pinCodeKeyboardItem">3</div>
                    </div>
                    <div className="pinCodeKeyboardRow">
                        <div onClick={() => {onAddPin('4')}} className="pinCodeKeyboardItem">4</div>
                        <div onClick={() => {onAddPin('5')}} className="pinCodeKeyboardItem">5</div>
                        <div onClick={() => {onAddPin('6')}} className="pinCodeKeyboardItem">6</div>
                    </div>
                    <div className="pinCodeKeyboardRow">
                        <div onClick={() => {onAddPin('7')}} className="pinCodeKeyboardItem">7</div>
                        <div onClick={() => {onAddPin('8')}} className="pinCodeKeyboardItem">8</div>
                        <div onClick={() => {onAddPin('9')}} className="pinCodeKeyboardItem">9</div>
                    </div>
                    <div className="pinCodeKeyboardRow">
                        <div onClick={() => {onAddPin('0')}} className="pinCodeKeyboardItem">0</div>
                    </div>
                </div>
                {
                    onCancel !== false ?
                        <div onClick={onCancel} style={{color: 'white'}}>
                            {lang.cancel}
                        </div>
                        :
                        ''
                }

            </div>

        </PinCodeStyled>
    )
}

export default PinCodeComponent