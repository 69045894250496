import React, {useEffect, useState} from 'react'
import {Grid} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import DashboardReservations from "../components/DashboardReservations";
import FutureReservations from "../components/FutureReservations";
import AppFooter from "../components/AppFooter";
import AppSidebar from "../components/AppSidebar";
import {useDispatch, useSelector} from "react-redux";
import {
    changeField,
    getClientInfo,
    getReservationList,
    getTransactionsHistory,
    loginV3,
    showErrorMessage
} from "../redux/actions";
import InfoIcon from '@mui/icons-material/Info';
import StarIcon from '@mui/icons-material/Star';
import AppPopup from "../components/AppPopup";
import Geolocation from "../components/Geolocation";
import {useCookies} from "react-cookie";
import Preloader from "../components/Preloader";
import PreloaderWrapper from "../styles/PreloaderWrapper";
import CustomerPreloader from "../components/CustomerPreloader";
import ReactGA from "react-ga";
import AppPopupV2 from "../components/AppPopupV2";
import errorService from "../services/errorService";
import {blockNativeNavigation} from "../services/blockNativeNavigation";
import {useHistory} from "react-router-dom";

const DashboardPage = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const client = useSelector(state => state.client)
    const apiToken = useSelector(state => state.apiToken)
    const [futureReservations, setFutureReservations] = useState([])
    const [isDisPopupActive, setIsDisPopupActive] = useState(false)
    const [problemWasSeen, setProblemWasSeen] = useState(false)
    const [accountDeactivated, setAccountDeactivated] = useState(false)

    const [isGeoRequestPopupActive, setIsGeoRequestPopupActive] = useState(false)
    const [isGeoRequestApprove, setIsGeoRequestApprove] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['geo_available', 'geo_was_shown'])
    const lang = useSelector(state => state.lang)
    const filter = useSelector(state => state.filter)

    useEffect(() => {
        if (!apiToken) {
            document.location = '/'
        }
    }, [apiToken])

    useEffect(() => {
        if (apiToken) {
            dispatch(
                getClientInfo({
                    apiToken
                })
            )
        }
    }, [apiToken])

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', blockNativeNavigation);
    }, [window])

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'pwa',
            action: 'dashboard'
        });

        dispatch(
            changeField({
                field: 'filter',
                value: {
                    ...filter,
                    timeWasSet: false
                }
            })
        )

        dispatch(
            changeField({
                field: 'activeVans',
                value: []
            })
        )
    }, [])

    useEffect(() => {

        if (cookies.geo_available == 'true') {
            setIsGeoRequestApprove(true)
        }

        if (cookies.geo_available == 'true' || cookies.geo_was_shown == 'true') {
            setIsGeoRequestPopupActive(false)
        } else {
            setIsGeoRequestPopupActive(true)
        }
    }, [cookies])

    useEffect(() => {
        if (client) {
            if (client.check_result.accepted == true) {
                setAccountDeactivated(false)
            } else {
                if (client.check_result.message != 'rentPermissionCheckResult.amountDue') {
                    setAccountDeactivated(true)
                    dispatch(
                        showErrorMessage({
                            title: '',
                            message: errorService.translate(client.check_result.message, lang)
                        })
                    )
                }
            }
        }
    }, [client])

    if (user !== false && client !== false) {
        return (
            <div className="app-page-wrapper">
                <AppSidebar reservationActive={problemWasSeen}/>
                <Grid container spacing={2}>
                    <AppReservationHeader/>
                    <Grid item xs={12}>
                        <p className="dashboard-person-name">{user.first_name} {user.last_name}

                            {
                                accountDeactivated && !problemWasSeen ?

                                    <div style={{
                                        position: 'relative',
                                        left: '10px',
                                        top: '-33px',
                                        zIndex: '5',
                                    }}>
                                        <StarIcon
                                            className="account-deactivated-icon"
                                            style={{
                                                color: '#ebc634',
                                                position: 'absolute',
                                                fontSize: '61px'
                                            }}/>

                                        <InfoIcon
                                            onClick={() => {
                                                setIsDisPopupActive(true)
                                            }}
                                            className="account-deactivated-icon"
                                            style={{
                                                color: 'white',
                                                fontSize: '38px',
                                                position: 'absolute',
                                                left: '12px',
                                                top: '12px'
                                            }}/>
                                    </div>

                                    :
                                    ''
                            }

                        </p>
                    </Grid>
                    <DashboardReservations/>
                    <FutureReservations/>
                    {/*добавить попап с предупреждением о 200 евро*/}
                    <AppFooter reservationActive={problemWasSeen}/>
                </Grid>

                {
                    !problemWasSeen && accountDeactivated ?
                        <div style={{
                            position: 'fixed',
                            backgroundColor: 'rgba(0,0,0,0.8)',
                            top: '0',
                            left: '0',
                            height: '100vh',
                            width: '100%'
                        }}></div>
                        :
                        ''
                }

                <AppPopup
                    open={isDisPopupActive}
                    closeText={lang.close}
                    handleClose={() => {
                        setIsDisPopupActive(false)
                        setProblemWasSeen(true)
                    }}
                    title=""
                    description={lang.account_problem_1}
                />

                <AppPopupV2
                    open={isGeoRequestPopupActive}
                    closeText={lang.no}
                    nextText={lang.yes}
                    handleNext={() => {
                        setIsGeoRequestApprove(true)
                        setIsGeoRequestPopupActive(false)
                        setCookie('geo_was_shown', true, {
                            expires: new Date(Date.now()+99990000000)
                        })
                    }}
                    handleClose={() => {
                        setIsGeoRequestPopupActive(false)
                        setIsGeoRequestApprove(false)
                        setCookie('geo_was_shown', true, {
                            expires: new Date(Date.now()+2592000)
                        })
                    }}
                    title=""
                    description={lang.geo_request}
                />

                {
                    isGeoRequestApprove ?
                        <Geolocation />
                        :
                        ''
                }

            </div>
        )
    } else {
        return (
            <div className="app-page-wrapper">
                <CustomerPreloader />
            </div>
        )
    }
}

export default DashboardPage
