import firebase from 'firebase'

var firebaseConfig = {
    apiKey: "AIzaSyAEQP8569Fm_HCHJGZI7E1uDY3kcybEYNw",
    authDomain: "mobielrijden.firebaseapp.com",
    databaseURL: "https://mobielrijden.firebaseio.com",
    projectId: "mobielrijden",
    storageBucket: "mobielrijden.appspot.com",
    messagingSenderId: "712773829378",
    appId: "1:712773829378:web:999e5a3bad5465bf5a781b"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase