import React, {useEffect} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changeField, createReservation} from "../redux/actions";
import filterTimeFormatted from "../services/filterTimeFormatted";

const ConfirmPaymentPage = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    //bunqID
    const {id} = useParams()
    const selectedVan = useSelector(state => state.selectedVan)
    const apiToken = useSelector(state => state.apiToken)
    const filter = useSelector(state => state.filter)
    const client = useSelector(state => state.client)
    const lang = useSelector(state => state.lang)

    useEffect(() => {
        if (id && selectedVan) {
            dispatch(
                createReservation({
                    apiToken: apiToken,
                    startDate: filterTimeFormatted.apiFormat(filter.dateStart, filter.timeStart),
                    endDate: filterTimeFormatted.apiFormat(filter.dateEnd, filter.timeEnd),
                    clientId: client.id,
                    vehicleId: selectedVan.id,
                    km: filter.km,
                    lang,
                    onSuccess: (res) => {
                        dispatch(
                            changeField({
                                field: 'selectedVan',
                                value: false
                            })
                        )

                        dispatch(
                            changeField({
                                field: 'payment',
                                value: {
                                    amount: 0,
                                    active: false,
                                    information: false
                                }
                            })
                        )

                        dispatch(
                            changeField({
                                field: 'filter',
                                value: {
                                    ...filter,
                                    timeWasSet: false
                                }
                            })
                        )

                        dispatch(
                            changeField({
                                field: 'activeVans',
                                value: []
                            })
                        )

                        history.push('/dashboard')
                    },
                    onError: (err) => {
                        console.log(err)
                    }
                })
            )
        }
    }, [id])

    return (
        <div className="app-page-wrapper">
            <p>Please, wait redirection...</p>
        </div>
    )
}

export default ConfirmPaymentPage