import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import TransactionHistoryItemBalance from "./TransactionHistoryItemBalance";
import {useSelector} from "react-redux";

const TransactionHistoryItemStyled = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 64px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  border-left: 2px solid ${props => props.color};
  
  .title {
    font-size: 12px;
  }
  
  .subtitle {
    font-size: 10px;
    color: #666666;
  }
  
  .amount {
    height: 37px;
    min-width: 100px;
    padding: 0 5px;
    border-radius: 20px;
    background: #FFFFFF;
    border: 1px solid #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666666;
    font-size: 16px;
  }
`

const TransactionHistoryItem = ({transaction}) => {

    const lang = useSelector(state => state.lang)
    const [color, setColor] = useState('#2CB0E9')

    useEffect(() => {
        if (transaction.status == 'pending') {
            setColor('#2CB0E9')
        }
        if (transaction.status == 'rejected' || transaction.status == 'revoked' || transaction.status == 'expired') {
            setColor('#CE6060')
        }
        if (transaction.status == 'accepted') {
            setColor('#5AB04C')
        }
    }, [transaction])

    const normalizeTitle = () => {

        if (transaction.method == 'bunq_request') {
            return 'Payment bunq/iDeal'
        }

        if (transaction.method == 'bunq_payment') {
            return 'Return payment bunq/iDeal'
        }

        return transaction.method
    }

    return (
        <TransactionHistoryItemStyled color={color}>
            <div style={{marginRight: '5px'}}>
                <TransactionHistoryItemBalance method={transaction.method} color={color} />
            </div>

            <div style={{marginRight: '5px'}}>
                <p className="title">{normalizeTitle()}
                <span style={{textTransform: 'capitalize'}}> ({transaction.status})</span>
                </p>
                <p className="subtitle">Created at: {transaction.created_at} <br/>
                    {lang.updated_at}: {transaction.updated_at}</p>
            </div>

            <div className="amount">
                € {transaction.amount}
            </div>
        </TransactionHistoryItemStyled>
    )
}

export default TransactionHistoryItem