import React, {useState} from 'react'
import styled from "styled-components"
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LocationFilter from "./LocationFilter";

const MapFilterStyled = styled.div`
  
  .filterIcon {
    position: fixed;
    top: 180px;
    right: 10px;
    background-color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E9392C;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  
  .filterContent {
    position: fixed;
    z-index: 51;
    top: 150px;
    left: 10px;
    width: 80%;
  }
`

const LocationMapFilter = () => {

    const [isActive, setIsActive] = useState(false)

    return (
        <MapFilterStyled>
            <div className="filterIcon" onClick={() => {setIsActive(!isActive)}}>
                <LocationOnIcon />
            </div>
            <div style={{display: isActive ? 'block': 'none' }} className="filterContent">
                <LocationFilter filterType="mini" />
            </div>
            {
                isActive ?
                    <div style={{position: 'fixed', top: '0', left: '0', height: '100vh', width: '100%',
                        backgroundColor: 'rgba(0,0,0, .4)'}}
                         onClick={() => {
                             setIsActive(false)
                         }} />
                    :
                    ''
            }

        </MapFilterStyled>
    )
}

export default LocationMapFilter
