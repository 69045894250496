import React from 'react'
import styled from "styled-components";
import BoxWrapperStyled from "../styles/BoxWrapperStyled";
import ArticleIcon from "@mui/icons-material/Article";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DocumentDLPopup from "./DocumentDLPopup";
import {useDispatch, useSelector} from "react-redux";
import {changeField} from "../redux/actions";
import DocumentPassportPopup from "./DocumentPassportPopup";
import DocumentIdCardPopup from "./DocumentIdCardPopup";

const DocumentItemStyled = styled.div`

  font-family: "Open Sans", sans-serif;

  .document-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const AddDocumentIdcardItem = () => {

    const lang = useSelector(state => state.lang)

    const dispatch = useDispatch()

    const onOpenHandler = () => {
        dispatch(
            changeField({
                field: 'docPhoto',
                value: {
                    isActive: true,
                    type: 'idcard',
                    front: false,
                    back: false,
                    country: 'NL',
                    number: '',
                    expiration: '',
                    categoryB: false
                }
            })
        )
    }

    return (
        <>
            <BoxWrapperStyled>
                <DocumentItemStyled>
                    <div className="document-item-header" onClick={onOpenHandler}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <ArticleIcon style={{
                                color: '#E9392C',
                                fontSize: '48px',
                                marginRight: '5px'
                            }}/>
                            <div>
                                <p style={{fontSize: '16px'}}>{lang.id_card}</p>
                            </div>
                        </div>
                        <AddCircleOutlineOutlinedIcon style={{color: '#E9392C'}}/>
                    </div>
                </DocumentItemStyled>
            </BoxWrapperStyled>
            <DocumentIdCardPopup />
        </>
    )
}

export default AddDocumentIdcardItem