import React, {useEffect, useRef, useState} from 'react'
import {Grid, Switch} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import PageTitleStyled from "../styles/PageTitleStyled";
import PageSubtitleRegularStyled from "../styles/PageSubtitleRegularStyled"
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import {useDispatch, useSelector} from "react-redux";
import TableStyled from "../styles/TableStyled";
import BoxWrapperStyled from "../styles/BoxWrapperStyled";
import OutlinedButton from "../styles/OutlinedButton";
import DocumentItem from "../components/DocumentItem";
import {
    changeField,
    deleteAccount,
    editUserInformation,
    getUserDocumentList,
    getUserInfo,
    preloaderStart, preloaderStop
} from "../redux/actions";
import {useHistory} from "react-router-dom";
import AppPopup from "../components/AppPopup";
import SetPinCodeComponent from "../components/SetPinCodeComponent";

const ProfilePage = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const apiToken = useSelector(state => state.apiToken)
    const [documents, setDocuments] = useState([])
    const history = useHistory()
    const client = useSelector(state => state.client)
    const language = useSelector(state => state.language)
    const isPinActive = useSelector(state => state.isPinActive)
    const [isPinSetupActive, setIsPinSetupActive] = useState(false)

    useEffect(() => {
        if (apiToken) {
            dispatch(
                getUserDocumentList({
                    apiToken,
                    onSuccess: (res) => {
                        setDocuments(res)
                    }
                })
            )

            dispatch(
                getUserInfo({
                    apiToken
                })
            )
        }
    }, [apiToken])

    const onUpdateNeed = () => {
        dispatch(
            getUserDocumentList({
                apiToken,
                onSuccess: (res) => {
                    setDocuments(res)
                }
            })
        )
    }

    const [isAccountDeletePopupActive, setIsAccountDeletePopupActive] = useState(false)

    const onAccountDeleteHandler = () => {
        dispatch(
            deleteAccount({
                apiToken,
                clientId: client.id,
                onSuccess: (res) => {
                    window.localStorage.removeItem('apiToken')
                    window.localStorage.removeItem('apiTokenV3')
                    document.location.href = '/'
                }
            })
        )
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const onChangeImage = (e) => {

        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            const type = file.type

            convertBase64(file).then((base64) => {
                let correctBase64 = base64.replace('data:image/jpeg;base64,', '')
                correctBase64 = correctBase64.replace('data:image/png;base64,', '')

                dispatch(
                    preloaderStart()
                )

                dispatch(
                    editUserInformation({
                        apiToken,
                        pushToken: '',
                        userObject: {
                            photo: {
                                mime_type: type,
                                data: correctBase64
                            }
                        },
                        onSuccess: (res) => {
                            dispatch(
                                getUserInfo({
                                    apiToken
                                })
                            )

                            dispatch(
                                preloaderStop()
                            )
                        }
                    })
                )
            })
        }
    }

    const imageRef = useRef(null)

    const onPinChange = (e) => {

        dispatch(
            changeField({
                field: 'isPinActive',
                value: isPinActive === 'unset' ? true : !isPinActive
            })
        )

        // if (e.target.value == 'on') {
        //     //setIsPinSetupActive(true)
        // } else {
        //     setIsPinSetupActive(false)
        //     window.localStorage.setItem('pin_setup', false)
        //     dispatch(
        //         changeField({
        //             field: 'isPinActive',
        //             value: false
        //         })
        //     )
        // }
    }

    useEffect(() => {
        if (isPinActive === true && window.localStorage.getItem('pin_setup') != 'true') {
            setIsPinSetupActive(true)
        }

        if (isPinActive === false && window.localStorage.getItem('pin_setup') == 'true') {
            window.localStorage.setItem('pin_setup', false)
        }

    }, [isPinActive])

    const lang = useSelector(state => state.lang)

    return (
        <div className="app-page-wrapper">
            <Grid container spacing={2}>

                <input style={{display: 'none'}} ref={imageRef} type="file" accept="image/*" capture="filesystem"
                       onChange={onChangeImage}/>

                <AppReservationHeader
                onBackHandler={() => {history.push('/dashboard')} }
                    isBackActive={true}/>

                <Grid item xs={12}>
                    <PageTitleStyled>{lang.profile}</PageTitleStyled>
                </Grid>

                <Grid item xs={12}>
                    <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <div
                            // onClick={() => {
                            //     imageRef.current.click()
                            // }}
                            style={{
                                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                width: '100%',
                                minHeight: '127px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '8px 0'
                            }}
                        >
                            {
                                user ?
                                    <div>
                                        <img style={{maxWidth: '130px', height: 'auto', borderRadius: '50%'}}
                                             src={user.photo.thumb} alt=""/>
                                    </div>

                                    :
                                    <PermIdentityOutlinedIcon style={{color: '#E9392C', fontSize: '74px'}}/>
                            }

                            <p style={{fontFamily: 'Open Sans'}}>{user.last_name} {user.first_name}</p>
                        </div>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <p
                            style={{
                                color: '#E9392C',
                                fontWeight: '700',
                                fontSize: '16px'
                            }}
                        >{lang.general}</p>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <BoxWrapperStyled>
                            <TableStyled>
                                <table>
                                    <tr>
                                        <td>Email:</td>
                                        <td>{user.email_address}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.language}:</td>
                                        <td>
                                            <select value={language} onChange={(e) => {
                                                dispatch(
                                                    changeField({
                                                        field: 'language',
                                                        value: e.target.value
                                                    })
                                                )
                                            }}>
                                                <option value="en">English</option>
                                                <option value="nl">Dutch</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{lang.pin_code}:</td>
                                        <td>
                                            <div style={{position: 'relative', left: '0', display: 'flex', alignItems: 'center'}}>
                                                <p>OFF</p>
                                                <Switch
                                                    checked={isPinActive === 'unset' ? false : isPinActive}
                                                    onChange={onPinChange}
                                                    inputProps={{'aria-label': 'controlled'}}/>
                                                <p>ON</p>
                                            </div>

                                        </td>
                                    </tr>
                                </table>
                            </TableStyled>
                        </BoxWrapperStyled>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <p
                            style={{
                                color: '#E9392C',
                                fontWeight: '700',
                                fontSize: '16px'
                            }}
                        >{lang.documents}</p>
                        <OutlinedButton
                            style={{
                                color: '#E9392C'
                            }}
                            onClick={
                                () => {
                                    history.push('/profile/add-document')
                                }
                            }
                        >+ {lang.document}</OutlinedButton>
                    </div>

                </Grid>

                {
                    documents.map((item) => {
                        return (
                            <Grid key={item.id} item xs={12}>
                                <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                                    <DocumentItem onUpdateNeed={onUpdateNeed} document={item}/>
                                </Grid>
                            </Grid>
                        )
                    })
                }

            </Grid>

            {/*<Grid item xs={12} style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px'}}>*/}
            {/*    <OutlinedButton style={{color: '#E9392C'}} onClick={() => {*/}
            {/*        setIsAccountDeletePopupActive(true)*/}
            {/*    }}>*/}
            {/*        {lang.delete_account}*/}
            {/*    </OutlinedButton>*/}
            {/*</Grid>*/}
            <AppPopup
                open={isAccountDeletePopupActive}
                title=""
                description={lang.delete_account_1}
                nextText={lang.yes}
                closeText={lang.no}
                handleNext={onAccountDeleteHandler}
                handleClose={() => {
                    setIsAccountDeletePopupActive(false)
                }}
            />

            {
                isPinSetupActive ?
                    <SetPinCodeComponent onCloseHandler={() => {
                        setIsPinSetupActive(false)
                    }}/>
                    :
                    ''
            }

        </div>
    )
}

export default ProfilePage