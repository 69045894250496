import React, {useState} from 'react'
import {Link, useHistory} from "react-router-dom"

import NavbarAccountBalance from "./NavbarAccountBalance"
import profileIcon from '../assets/profileIcon.png'
import reservationIcon from '../assets/timeIcon.png'
import bankIcon from '../assets/bankIcon.png'
import helpIcon from '../assets/ccIconMenu.png'
import termsIcon from '../assets/locationItemMenu.png'
import SidebarLanguage from "./SidebarLanguage";
import SidebarLogout from "./SIdebarLogout";
import {useDispatch, useSelector} from "react-redux";
import {changeField} from "../redux/actions";
import AppPopup from "./AppPopup";
import Cookies from "universal-cookie";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {useCookies} from "react-cookie";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';

const AppSidebar = ({reservationActive}) => {

    const dispatch = useDispatch()
    const isDocumentExpiredGlobal = useSelector(state => state.isDocumentExpiredGlobal)
    const [cookies, setCookie, removeCookie] = useCookies(['reservation_vat_show'])
    const isSidebarActive = useSelector(state => state.isSidebarActive)
    const lang = useSelector(state => state.lang)

    const onCloseHandler = () => {
        dispatch(changeField({
            field: 'isSidebarActive',
            value: false
        }))
    }

    const history = useHistory()
    const [reservationAlertActive, setReservationAlertActive] = useState(false)

    const onReservationClick = () => {
        if (cookies.reservation_vat_show == 'true') {
            history.push('/manage-reservation')
        } else {
            setReservationAlertActive(true)
            setCookie('reservation_vat_show', true, {
                expires: new Date(Date.now()+99990000000)
            })
        }

        dispatch(changeField({
            field: 'isSidebarActive',
            value: false
        }))
    }

    return (
        <div className={`app-sidebar ${isSidebarActive ? 'app-sidebar-active' : ''}`}>
            <div className="app-sidebar-menu">
                <NavbarAccountBalance />
                <div style={{marginTop: '20px'}}>
                    <div className="app-sidebar-link">
                        <Link to="/profile">
                            {/*<img src={profileIcon} alt=""/>*/}
                            <PermIdentityOutlinedIcon style={{color: '#E9392C', marginRight: '15px'}} />
                            {lang.profile}
                        </Link>
                    </div>
                    <div className="app-sidebar-link">
                        <Link to="/reservation-history">
                            {/*<img src={reservationIcon} alt=""/>*/}
                            <AccessTimeIcon style={{color: '#E9392C', marginRight: '15px'}} />
                            {lang.res_history}
                        </Link>
                    </div>
                    <div className="app-sidebar-link">
                        <Link to="/transaction-history">
                            {/*<img src={bankIcon} alt=""/>*/}
                            <AccountBalanceIcon style={{color: '#E9392C', marginRight: '15px'}} />
                            {lang.tr_history}
                        </Link>
                    </div>
                </div>

                <div style={{marginTop: '30px'}}>
                    <div className="app-sidebar-link">
                        <Link to="/help">
                            <img src={helpIcon} alt=""/>
                            {lang.help}
                        </Link>
                    </div>
                    <div className="app-sidebar-link">
                        <Link to="/terms-of-use">
                            <InfoOutlinedIcon style={{color: '#E9392C', marginRight: '15px'}} />
                            {lang.terms_of_use}
                        </Link>
                    </div>
                </div>

                <div style={{marginTop: '30px'}}>
                    <SidebarLanguage />
                    <SidebarLogout />
                </div>

            </div>
            <span onClick={onCloseHandler} className="app-sidebar-close">
                &times;
            </span>

            <AppPopup open={reservationAlertActive}
                      title=""
                      nextText={`${lang.ok}`}
                      handleNext={() => {
                          setReservationAlertActive(false);
                          history.push('/manage-reservation');
                      }}
                      description={lang.res_not_1} />
        </div>
    )
}

export default AppSidebar