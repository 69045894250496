import React, {useEffect, useRef, useState} from 'react'
import {Button, Grid} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import {useHistory, useParams} from "react-router-dom";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {useDispatch, useSelector} from "react-redux";
import {changeField, preloaderStart, preloaderStop, putIdcardFrontPhotoToResult} from "../redux/actions";
import * as automl from "@tensorflow/tfjs-automl";
import config from "../config";
import axios from "axios";

const MakePhotoPage = () => {

    const {type, side} = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const videoRef = useRef(false)
    const preProcessCanvas = useRef(false)
    const resCanvasRef = useRef(false)
    const [isPhotoDone, setIsPhotoDone] = useState(false)

    const [isLoaded, setIsLoaded] = useState(false)
    const [needHelp, setNeedHelp] = useState(false)
    const [innerStream, setInnerStream] = useState({})

    const handleOnSuccessImage = (base64Image) => {

        dispatch(
            changeField({
                field: 'tempPhoto',
                value: base64Image
            })
        )

        history.push(`/confirm-photo/${type}/${side}`)
    }

    const makePhotoHandler = () => {
        const resWidth = videoRef.current.offsetWidth
        const resHeight = videoRef.current.offsetHeight

        resCanvasRef.current.width = resWidth
        resCanvasRef.current.height = resHeight

        resCanvasRef.current.getContext('2d').drawImage(videoRef.current, 0, 0, resWidth, resHeight)

        const resBaseImage = resCanvasRef.current.toDataURL('image/jpeg', 1)

        innerStream.getTracks()
            .forEach((track) => track.stop())

        handleOnSuccessImage(resBaseImage)
    }

    useEffect(() => {

        var count = 0
        var isLoadedInner = false

        async function run() {

            const constraintsEnv = {
                audio: false,
                video: {
                    width: 800,
                    height: 800,
                    facingMode: {
                        exact: "environment"
                    }
                }
            }

            const constraintsDefault = {
                audio: false,
                video: {
                    width: 800,
                    height: 800,
                }
            }

            let stream = {}

            try {
                stream = await navigator.mediaDevices.getUserMedia(constraintsEnv)
            } catch (e) {
                stream = await navigator.mediaDevices.getUserMedia(constraintsDefault)
            }

            setInnerStream(stream)

            videoRef.current.srcObject = stream

            function drawPreCanvas() {
                preProcessCanvas.current.width = 800
                preProcessCanvas.current.height = 800
                preProcessCanvas.current.getContext('2d').drawImage(videoRef.current, 0, 0)
            }

            async function sendDocumentCropRequest() {
                drawPreCanvas()

                const base64ImageRaw = preProcessCanvas.current.toDataURL('image/jpeg', 1)
                const base64Image = base64ImageRaw.replace('data:image/jpeg;base64,', '')

                let response = {
                    code: 104
                }

                try {
                    response = await axios({
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        },
                        method: 'post',
                        url: config.documentCropServiceUrl,
                        data: {
                            image: base64Image
                        }
                    })
                } catch (exception) {
                    return {
                        code: 104
                    }
                }

                return response.data
            }

            let documentResponse = false

            while (documentResponse === false || documentResponse.code != 100) {

                try {
                    documentResponse = await sendDocumentCropRequest()
                    count = count + 1

                    if (count > config.documentCropTimeout) {
                        setNeedHelp(true)
                        break;
                    }

                    console.log("Response = ")
                    console.log(documentResponse)
                } catch(exception) {
                    break
                }
            }

            if (documentResponse != false && documentResponse.code == 100) {
                const resImage = "data:image/jpeg;base64," + documentResponse.image.data
                handleOnSuccessImage(resImage)
            }
        }

        run()

    }, [])

    const lang = useSelector(state => state.lang)

    return (
        <div>
            <canvas style={{display: 'none'}} ref={preProcessCanvas}/>
            <div className="make-photo-wrapper">
                <div className="make-photo-title">{lang.camera_text_1}</div>
                <video style={{width: '100%', height: 'auto', display: isPhotoDone ? 'none' : 'block'}}
                       ref={videoRef} playsInline autoPlay/>

                <div className="dl-placeholder">
                    {
                        needHelp ?
                            <>
                                <Button
                                    onClick={makePhotoHandler}
                                    className="make-photo-btn"
                                    variant="outlined"> <PhotoCameraIcon/> </Button>

                            </>
                            :
                            ''
                    }
                </div>

                {
                    needHelp ?

                        <div className="make-photo-description">
                            {lang.camera_text_2}
                        </div>
                        :
                        <div className="make-photo-description">
                            {lang.photo_auto}
                        </div>
                }
            </div>

            <canvas style={{display: isPhotoDone ? 'block' : 'none'}} ref={resCanvasRef}/>
        </div>
    )
}

export default MakePhotoPage
