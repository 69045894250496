import React from 'react'
import PinCodeComponent from "./PinCodeComponent";
import {useDispatch, useSelector} from "react-redux";
import {changeField} from "../redux/actions";

const SetPinCodeComponent = ({onCloseHandler}) => {

    const lang = useSelector(state => state.lang)
    const dispatch = useDispatch()

    const onSuccessPin = (pin) => {
        window.localStorage.setItem('pin', pin.join(''))
        window.localStorage.setItem('pin_setup', true)

        dispatch(
            changeField({
                field: 'isPinActive',
                value: true
            })
        )

        onCloseHandler()
    }

    return (
        <div>
            <PinCodeComponent
                onSuccess={onSuccessPin}
                onCancel={() => {
                    document.location.reload()
                }}
                title={`${lang.set_pin_code}`} />
        </div>
    )
}

export default SetPinCodeComponent