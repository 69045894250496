import axios from "axios";
import config from "../config";

const userService = {
    makeShortRegistration: (payload) => {
        return axios({
            method: 'post',
            url: config.getShortRegisterUrl(),
            data: payload
        });
    },

    getUserInfo: (payload) => {
        return axios({
            method: 'get',
            url: config.getUserInfoUrl(),
            headers: {
                'Authorization': `Bearer ${payload}`
            }
        });
    },

    getUserRequirements: ({userId, apiToken}) => {
        return axios({
            method: 'get',
            url: config.getUserRequirementsUrl(userId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
    },

    getClientRequirements: ({clientId, apiToken}) => {
        return axios({
            method: 'get',
            url: config.getClientRequirementsUrl(clientId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
    },

    getClientInfo: (payload) => {
        return axios({
            method: 'get',
            url: config.getCustomerInfoUrl(),
            headers: {
                'Authorization': `Bearer ${payload}`
            }
        });
    },

    signContract: ({customerId, base64Image, apiToken}) => {
        return axios({
            method: 'patch',
            url: config.getSignContractUrl(customerId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                signature: {
                    data: base64Image,
                    mime_type: 'image/png'
                }
            }
        });
    },

    updateFullInformation: (payload) => {
        return axios({
            method: 'patch',
            url: config.getFullUpdateUrl(payload),
            headers: {
                'Authorization': `Bearer ${payload.apiToken}`
            },
            data: {
                gender: payload.gender,
                first_name: payload.firstName,
                last_name: payload.last_name,
                last_name_prefix: payload.lastNamePrefix,
                date_of_birth: payload.dateOfBirth,
                nationality: payload.nationality,
                place_of_birth: payload.placeOfBirth,
                country_of_birth: payload.countryOfBirth,
                mobile_phone_number: payload.phone,
                address: {
                    zip_code: payload.zipCode,
                    house_number: payload.houseNumber,
                    house_number_extension: payload.houseNumberExtension,
                    street_name: payload.street,
                    city: payload.city,
                    country: payload.country
                },
                residence_address: {
                    zip_code: payload.zipCode,
                    house_number: payload.houseNumber,
                    house_number_extension: payload.houseNumberExtension,
                    street_name: payload.street,
                    city: payload.city,
                    country: payload.country
                },
            }
        })
    },

    updateUserPhoto: (payload) => {
        return axios({
            method: 'patch',
            url: config.getFullUpdateUrl(payload),
            headers: {
                'Authorization': `Bearer ${payload.apiToken}`
            },
            data: {
                photo: {
                    data: payload.image.replace('data:image/jpeg;base64,', ''),
                    mime_type: "image/jpeg"
                }
            }
        })
    },

    uploadIdCard: ({apiToken, documentNumber, expirationDate, country, frontImage, backImage}) => {
        let images = [];

        images.push({
            "side": "front",
            "mime_type": "image/jpeg",
            "light_type": "white",
            "data": frontImage.replace('data:image/jpeg;base64,', '')
        })

        images.push({
            "side": "back",
            "mime_type": "image/jpeg",
            "light_type": "white",
            "data": backImage.replace('data:image/jpeg;base64,', '')
        })

        return axios({
            method: 'post',
            url: config.getUploadDriverLicenseUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                "type": 'id_card',
                "number": documentNumber,
                "country": country,
                "expiration_date": expirationDate,
                "images": images
            }
        });
    },

    uploadPassport: ({apiToken, passportNumber, expirationDate, country, frontImage}) => {

        let images = [];

        images.push({
            "side": "front",
            "mime_type": "image/jpeg",
            "light_type": "white",
            "data": frontImage ? frontImage.replace('data:image/jpeg;base64,', '') : ''
        });

        return axios({
            method: 'post',
            url: config.getUploadDriverLicenseUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                "type": 'passport',
                "number": passportNumber,
                "country": country,
                "expiration_date": expirationDate,
                "images": images
            }
        });
    },

    uploadDriverLicense: ({apiToken, driverLicenseNumber, expirationDate, country, categoriesArr, frontImage, backImage}) => {

        const images = [];

        if (frontImage && !frontImage.startsWith('https')) {
            images.push({
                "side": "front",
                "mime_type": "image/jpeg",
                "light_type": "white",
                "data": frontImage ? frontImage.replace('data:image/jpeg;base64,', '') : ''
            });
        }

        if (backImage && !backImage.startsWith('https')) {
            images.push({
                "side": "back",
                "mime_type": "image/jpeg",
                "light_type": "white",
                "data": backImage ? backImage.replace('data:image/jpeg;base64,', '') : ''
            })
        }

        return axios({
            method: 'post',
            url: config.getUploadDriverLicenseUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                "type": "drivers_license",
                "number": driverLicenseNumber,
                "country": country,
                "expiration_date": expirationDate,
                "drivers_license_categories": ['B'],
                "images": images
            }
        });
    },

    getUserDriverLicenses: ({apiToken}) => {
        return axios({
            method: 'get',
            url: config.getAllDocumentsUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
    },

    payRegistrationFee: ({apiToken, customerId, amount}) => {
        return axios({
            method: 'post',
            url: config.getPaymentUrl(customerId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                type: 'registration_fee_payment',
                amount,
            }
        })
    },

    payBankInformation: ({apiToken, customerId, amount}) => {
        return axios({
            method: 'post',
            url: config.getPaymentUrl(customerId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                type: 'verification_payment',
                amount
            }
        })
    },

    editBankInformation: ({apiToken, customerId, bankOwner, iban}) => {
        return axios({
            method: 'patch',
            url: config.getEditBankInformationUrl(customerId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                "bank_account": {
                    "owner": bankOwner,
                    "iban": iban
                }
            }
        })
    },

    login: ({email, password}) => {
        return axios({
            method: 'post',
            url: config.getLoginUrl(),
            data: {
                username: email,
                password
            }
        })
    },

    resetPassword: ({email}) => {
        return axios({
            method: 'post',
            url: config.getResetLinkUrl(),
            data: {
                email_address: email
            }
        })
    },

    deleteDriverLicense: ({apiToken, dlNumber, type = 'drivers_license'}) => {
        return axios({
            method: 'delete',
            url: config.getRemoveDLUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                "type": "drivers_license",
                "number": dlNumber,
                "id": dlNumber
            }
        })
    },

    uploadDriversLicenseOCR: ({imageSrc}) => {
        return axios({
            method: 'post',
            url: config.getDriversLicenseOCR(),
            data: {
                'document_type': 'eu_drivers_license',
                'side': 'front',
                'image': imageSrc.replace('data:image/jpeg;base64,', ''),
                'mime_type': 'image/jpeg'
            }
        })
    },

    uploadPassportOCR: ({imageSrc}) => {
        return axios({
            method: 'post',
            url: config.getDriversLicenseOCR(),
            data: {
                'document_type': 'international_passport',
                'side': 'front',
                'image': imageSrc.replace('data:image/jpeg;base64,', ''),
                'mime_type': 'image/jpeg'
            }
        })
    }
};

export default userService;