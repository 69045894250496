import styled from 'styled-components'

const FutureReservationListStyled = styled.div`
  
  font-family: "Open Sans", sans-serif;
  min-height: 50vh;
  overflow: auto;
  padding: 3px;
  
  .future-reservation-item {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .future-reservation-buttons {
    display: flex;
    justify-content: flex-start;
  }
  
  table {
    
    td {
      font-size: 16px;
    }
    
    tr td:first-child {
      width: 40%;
      font-weight: 600;
    }

    tr td:last-child {
      width: 60%;
      color: #666666;
    }
  }
  
  @media (min-width: 1000px) {
    table {
      td {
        font-size: 18px !important;
        padding-bottom: 10px;
      }
    }
  }
`

export default FutureReservationListStyled;