const en = {
    'create_account': 'Create Account',
    'to_register_you_will_need': 'To register you will need:',
    'reg_term_1': 'Valid drivers license (category B)',
    'reg_term_2': 'Mobile phone number',
    'reg_term_3': 'E-mail address',
    'register': 'Register',
    'sign_1': 'I declare that my uploaded ID is genuine',
    'sign_2': 'I agree that pictures and personal data are visibly stored and displayed in our secured backoffice',
    'cancel': 'Cancel',
    'confirm': 'Confirm',
    'document_verification': 'Document verification',
    'dv_1': 'Take a photo of the drivers license',
    'dv_2': 'Check if all personal data is correct',
    'dv_3': 'Take a selfie',
    'continue': 'Continue',
    'take_a_photo_of_the_front': 'Take a photo of the front',
    'attention': 'Attention',
    'at_1': 'We only accept a photo of the original document',
    'at_2': 'Do not take photographs of copies of documents or images from other devices',
    'take_a_photo': 'Take a photo',
    'camera_text_1': 'Keep the camera parallel to the surface',
    'camera_text_2': 'Press the button to make a photo...',
    'take_a_photo_of_the_back': 'Take a photo of the back',
    'drivers_license': 'Drivers license',
    'selfie': 'Selfie',
    'name': 'Name',
    'surname': 'Surname',
    'country_of_issue': 'Country of issue of the document',
    'drivers_license_number': 'Drivers license number',
    'expiration_date': 'Expiration date',
    'date_of_birth': 'Date of birth',
    'category_b': 'Category B',
    'dl_problem': 'Something wrong with your Drivers license',
    'please_call_us': 'Please call us <a href="tel:+31880883434">088 - 088 34 34</a>',
    'close': 'close',
    'is_info_correct': 'Is the information you entered correct?',
    'yes': 'yes',
    'no': 'no',
    'ok': 'ok',
    'not_dutch_dl': 'Because you don’t have a Dutch drivers license we need a copy of an ID as well',
    'choose_the_type_of_your_document': 'Choose the type of your document',
    'passport': 'Passport',
    'id_card': 'ID card',
    'passport_id_card_number': 'Passport / ID card number',
    'zip_code': 'Zip code',
    'house_number': 'House number',
    'number_addition': 'Number addition',
    'street': 'Street',
    'city': 'City',
    'country': 'Country',
    'use_the_same': 'use the same address for invoice',
    'email': 'Email',
    'phone': 'Phone',
    'credentials': 'Credentials',
    'set_24_password': 'Set a 24-hour password and SMS it to me.',
    'sign_your_new_contract': 'Sign your new contract',
    'agree_1': 'I hereby declare that I have read and agree to the Terms and conditions',
    'agree_2': 'I hereby declare that I have read and agree to the House rules',
    'agree_3': 'I hereby declare that I have read and agree to the Customer contract',
    'agree_4': 'I hereby declare that I have read and agree to the Procedure description',
    'agree': 'Agree',
    'welcome': 'Welcome!',
    'rent_a_bus': 'Rent a bus',
    'get_the_app': 'Get the App!',
    'already_account_title': 'You already have an account!',
    'already_account_text': 'Please, download application to continue',
    'download': 'Download',
    'contacts': 'Contacts',
    'the_are_problems_with_your_camera': '',
    'permission_to_camera': 'There is no camera, please continue registration using your mobile phone',
    'home_zip_code': 'Home zip code',
    'home_house_number': 'Home House number',
    'home_street': 'Home street',
    'home_city': 'Home city',
    'invoice_zip_code': 'Invoice Zip code',
    'invoice_house_number': 'Invoice House number',
    'invoice_street': 'Invoice Street',
    'invoice_city': 'Invoice City',
    'you_did_not_provide': 'You did not provide:',
    'home_address': 'Home address',
    'invoice_address': 'Invoice address',
    'error': 'Error',
    'password': 'Password',
    'pas_req_1': 'Minimum 7 characters',
    'pas_req_2': 'Minimum 1 digit and 1 letter',
    'repeat_password': 'Repeat password',
    'pas_error': 'Passwords must match and be complex',
    'photo_auto': 'The photo will be taken automatically, please wait ...',
    'front_of_id_card': 'Front of ID card',
    'document_number': 'Document number',
    'selfie_pic_text': 'Do not take pictures of yourself from other photos or other devices.',
    'make_selfie': 'Take a selfie',
    'already_account': 'You already have an account!',
    'customer_with_email_1': 'Customer with',
    'customer_with_email': 'email address',
    'already_exists': 'already exists..',
    'call_us_2': 'Please, call us or reset password',
    'reset_password': 'Reset password',
    'call_us': 'Call us support',
    'dl_exp_date': 'Drivers license expiration date',
    'no_need': 'No need',
    'recovery_link_message': 'A recovery link has been sent to your email',

    'no_active_res': 'No active reservations',
    'active_reservation': 'Active reservation',
    'license_plate': 'License plate',
    'location': 'Location',
    'pick_up_date': 'Pick-up date',
    'return_date': 'Return date',
        'damage_report': 'DAMAGE REPORT',
    'navigate_to_vehicle': 'NAVIGATE TO VEHICLE',
    'open_doors': 'Open doors',
    'manage_reservation': 'Manage reservation',
    'open_doors_1': 'You are about to open the doors',
    'open_doors_2': 'Are you sure you want to start the reservation?',
    'open_doors_3': 'Follow the steps below to start the ride:',
    'open_doors_4': 'This car has a terminal for the key and the fuel card in the glove compartment',
    'open_doors_5': 'Take the tag with the key from the key holder',
    'open_doors_6': 'Start the engine',
    'open_doors_7': 'You are ready to go, have a nice trip!',
    'future_reservations': 'Future reservations',
    'no_future_reservations': 'There are no future reservations',
    'cancel_reservation': 'Do you want to cancel the future reservation?',
    'cancel_free': 'Cancellation fee',
    'read_terms': 'Read more:',
    'help': 'Help',
    'calculate': 'Calculate',
    'add_reservation': '+ Reservation',
    'res_not_1': 'The price of the reservation includes a deposit of <b>€200</b>',
    'account_problem_1': "Your account is under review! <br> It usually takes up to 15 minutes, <br> if you want to activate your account immediately, then call support: <br> <a href='tel:+31880883434'>+31 88 088 3434</a>",
    'geo_request': 'To continue the correct operation of the system, you need to provide access to <b>geolocation</b> <br> ' +
        '<a style="color: gray" target="_blank" href="https://kav2go.nl/how-to-grant-the-necessary-permissions-in-the-browser/">How to grant access to geolocation</a> ',
    'profile': 'Profile',
    'res_history': 'Reservation history',
    'tr_history': 'Transaction history',
    'terms_of_use': 'Terms of use',
    'language': 'Language',
    'manage_res': 'Manage reservation',
    'want_open_doors': 'Do you really want to open the doors?',
    'want_close_doors': 'Do you really want to close the doors?',
    'reg_term_4': 'Web / Phone camera',
    'have_account': 'I already have an account',
    'login': 'Login',
    'questions_call_us': 'Questions? Call us',
    'send': 'Send',
    'reset_1': 'Password recovery instructions will be sent here',
    'or_return': 'or return',
    'enter_reset_1': 'Enter the email address you',
    'enter_reset_2': 'registered with',
    'dashboard': 'Home',
    'no_geo': "You disabled or didn't grant access to geolocation",
    'report_a_new_damage': 'Report a new damage',
    'select_location': 'Select location',
    'left_front': 'Left front',
    'right_front': 'Right front',
    'hood': 'Hood',
    'roof': 'Roof',
    'door_left_front': 'Door Left Front',
    'door_left_back': 'Door Left Back',
    'door_right_front': 'Door Right Front',
    'door_right_back': 'Door Right Back',
    'left_back': 'Left Back',
    'right_back': 'Right Back',
    'trunk': 'Trunk',
    'other': 'Other',
    'description': 'Description',
    'upload_damage_report': '+ UPLOAD damage REPORT',
    'add_document': 'Add document',
    'bank_statement': 'Bank statement or screenshot of internet banking',
    'residence_permit': 'Residence permit',
    'image': 'Image',
    'upload_image': 'Upload image',
    'upload': 'UPLOAD',
    'front': 'Front',
    'back': 'Back',
    'number': 'Number',
    'expiration': 'Expiration',
    'category': 'Category',
    'reset_1_text': 'A link to reset your password',
    'reset_2_text': 'has been sent to your email',
    'reset_3_text': 'Don\'t forget to check on your spam folder',
    'logout': 'Log out',
    'want_logout': 'Do you want to log out?',
    'bank_info': 'Bank information',
    'need_bank': 'We need your bank account information to make a refund',
    'owner_name': 'Name account holder',
    'or': 'OR',
    'pay': 'Pay',
    'with_ideal': '',
    'delete': 'Delete',
    'confirm_deletion': 'Confirm deletion',
    'confirm_deletion_1': 'Are you sure you want to delete the document?',
    'years': 'years',
    'months': 'months',
    'days': 'days',
    'expired': 'EXPIRED',
    'expires_in': 'expires in',
    'from': 'From',
    'to': 'To',
    'extend_reservation': 'Extend reservation',
    'minutes': 'minutes',
    'extend_text_1': 'This reservation has been extended by',
    'in': 'in',
    'reservation': 'Reservation',
    'gasoline_1': 'Dear customer, you have',
    'gasoline_2': 'fuel left in the tank.',
    'gasoline_3': 'Please fill up the (using our tankcard)',
    'gasoline_4': '',
    'use_current_location': 'Use current location',
    'disabled': 'disabled',
    'vehicle_result_out_of_date': "Vehicle results are out of date click 'Update'",
    'update': 'Update',
    'res_time_changed': "Your reservation time has been changed automatically by <b>15 minutes</b>",
    'current_location': 'Current location',
    'top_up_balance': 'Top up balance',
    'reclaim_balance': 'Reclaim balance',
    'in_progress': 'In progress',
    'enter_correct_pin_code': 'Please enter the correct PIN code',
    'pwa_1': 'Use as a mobile app.',
    'pwa_2': 'To do this, click "Share"',
    'pwa_3': 'and select "Go to Screen" - Home',
    'pwa_4': 'Press the install button',
    'install': 'Install',
    'pay_again': 'PAY AGAIN',
    'rejected': 'Rejected',
    'no_vehicle_for_crit': 'No vehicles available for the selected criteria!',
    'vehicle_selection': 'Vehicle selection',
    'transmission': 'Transmission',
    'distance_to_vehicle': 'Distance to vehicle',
    'manual': 'Manual',
    'auto': 'Auto',
    'reset_filter': 'RESET FILTER',
    'filter_settings': 'Filter settings',
    'loading_space': 'Cargo space',
    'hours': 'hours',
    'set_pin_code': 'Please enter the new PIN-Code',
    'success': 'Success',
    'updated_at': 'Updated at',
    'unavailable': 'unavailable',
    'navigate': 'NAVIGATE',
    'continue_1': 'CONTINUE',
    'calculator_1': 'Calculator',
    'calculator_2': 'How long do you want to rent?',
    'hour': 'hour',
    'calculator_3': 'Estimated travel distance',
    'calculator_4': 'Estimated costs excl. VAT',
    'calculator_5': 'Estimated costs incl. VAT',
    'calculator_6': 'Rates include fuel',
    'calculator_7': '',
    'calculator_8': 'No financial risk in case of damage',
    'calculator_9': '',
    'confirm_1': 'The vehicle you have chosen will be reserved for you only after payment',
    'confirm_2': 'Confirm reservation',
    'day': 'day',
    'for': 'for',
    'future_reservation': 'Future reservation',
    'vehicle': 'Vehicle',
    'total_price': 'Total price',
    'cancel_reservation_1': 'Cancel reservation',
    'cancel_reservation_2': 'Do you want to cancel your reservation?',
    'confirm_reservation_1': 'Confirm reservation',
    'do_you_want_to_confirm': ' If you cancel your reservation, you will be charged a cancellation fee of €5,- if canceled more than 24 hours before the start of the reservation or €19.50  if canceled less than 24 hours to before the start of the reservation.',
    'enable_nofitications': 'Enable notifications',
    'nofitications_1': 'Turn on notifications to get reminders about your booking',
    'turn_on': 'Turn on',
    'notifications_2': 'Your reservation time has been changed automatically by <b>15 minutes</b>',
    'damage_report_title': 'Damage report',
    'damage_report_1': 'if the image does not load, refresh the results',
    'damage_report_new': 'REPORT A NEW DAMAGE',
    'end_reservation_not_like': 'What exactly did you not like, please leave a comment',
    'end_reservation_2': 'Did you like our service?',
    'not_really': 'Not really',
    'you_can_contact_us': 'You can contact us with',
    'help_1': 'Rent a van 24/7 - No excess',
    'help_2': '19.50 per hour, maximum 6 hours per day (24 hours)',
    'fuel_type': 'Fuel type',
    'fuel_level': 'Fuel level',
    'fuel_card_pin': 'Fuel card PIN',
    'unlock_doors': 'unlock doors',
    'lock_doors': 'lock doors',
    'navigate_to_return_location': 'Navigate to return location',
    'end_reservation': 'End reservation',
    'end_res_1': 'Are you sure you want to end the reservation?',
    'end_res_2': 'In the next steps we show you how to',
    'end_res_3': 'return the key in the holder',
    'end_res_4': 'get out of the vehicle',
    'end_res_5': 'locking the doors',
    'step': 'Step',
    'next': 'NEXT',
    'step_1': "Step 1: put the black-white tag back in the intended opening of the keyholder and press 'Next'.",
    'step_2': "If the black-white tag is not back, the doors will not close!",
    'step_3': "Please get out of the vehicle.",
    'step_4': "Until you get out of the vehicle, do not press the ",
    'step_5': '"End reservation"',
    'step_6': "button!",
    'step_7': "Make sure that you are outside of the vehicle before pressing the button 'End reservation'",
    'step_8': "Please take all your personal belongings when leaving the vehicle.",
    'step_9': "",
    'step_10': "",
    'end_reservation_btn': 'END RESERVATION',
    'min_res_time': 'Minimum reservation time',
    'min': 'min',
    'time_interval': 'Pick up time interval',
    'tr_distance': 'Approximate travel distance',
    'balance_not_less': 'Balance should be not less',
    'fill_address_or_geo': 'Fill in the address field or provide access to the current geolocation or check drivers license expiration date',
    'res_auto': 'Reservation END time has been changed automatically!',
    'payment': 'Payment',
    'you_have_to_pay': 'You need to pay',
    'pay_1': 'Payment module will open in a new window,',
    'pay_2': 'after completion, go back',
    'pin_code': 'PIN-code',
    'documents': 'Documents',
    'document': 'document',
    'delete_account': 'Delete profile',
    'delete_account_1': 'Your account, along with personal data will be deleted, do you want to continue?',
    'reclaim_1': 'The reclaim request was sent successfully, the amount will be credited within 2 working days.',
    'reclaim_2': 'Do not forget to leave the minimum required deposite for your next rent',
    'balance_is': 'Balance is',
    'reclaim_3': 'You cannot claim more money than you have in your account!',
    'claim': 'Claim',
    'res_h_1': 'The list of trips is empty for now, but it\'s easy to fix this by',
    'res_h_2': 'starting the first reservation',
    'call_us_1': 'Do you have questions? Call us: +31 88 088 3434',
    't_p_1': '€ 19,50 per Hour (incl. VAT)',
    't_p_2': 'No financial risk in case of damage',
    't_p_3': 'Day price max € 117,- per 24 hours (incl. VAT) - 45 cent per KM (incl. VAT and fuel)',
    'select_payment_amount': 'Select payment amount',
    'amount': 'Amount',
    'last_10_tr': 'The latest 10 transactions',
    'tr_list_empty': 'Transaction list is empty',
    'see_whole_tr_history': 'See the whole transaction history',
    'document_expired_global': 'Your {document} has expired, please go to the profile section and update the data',
    'document_expired_manage': 'Your driver\'s license will expire before the reservation ends. Please change the end date of the reservation or renew your driver\'s license in the profile section',
    'deposit': 'Deposit',
    'reservation_price': 'Reservation price',
    'van_busy': 'This van is in use during the following time periods',
    'how_to_give_permissions': 'How to get access to the camera?',
    'video_perm_1': 'Please give permission to use your camera or continue registration using your mobile phone',
    'video_perm_2': 'Please give permission to use your camera',
    'video_perm_3': 'You don\'t have a camera or the camera is blocked on your device',
    'call_us_12': 'Do you have questions? Call us: <a href="tel:+31880883434"> +31 88 088 34 34',
    'select_pick_up_time': 'Pick-up time',
    'select': 'Select',
    'no_date_of_birth': 'Date of birth is not provided',
    'no_drivers_license': 'Drivers license is not provided',
    'driversLicenseNotChecked': "Your driver's license is under review",
    'invalidDriversLicense': "Your driver's license hasn't passed the validation, please contact support to fix this issue",
    'amountDue': "There is not enough money on your account to create a new reservation. This may happen if you already have a reservation",
    'invalidScreening': "Your account is under review",
    'manage_reservation_popup': 'To manage your reservation you need to open the website from your mobile phone',
    'confirm_bunq': 'Confirm the transfer to Bunq to proceed with the payment',
    'geo_request_2': 'To continue the correct operation of the system, you need to turn on or provide access to <b>geolocation</b>',
    'iban_get_bank_info': 'To obtain your bank account number you can eighter leave your data here or pay us one cent using the Ideal button',
    'location_settings': 'Location settings',
    'gasoline_5': 'to a full tank.',
    'reset': 'Reset',
    'return_time': 'Return time',
    'time_interval_end': 'Return time interval',
    'calculator_10': 'The rates shown are indicative',
    'calculator_11': 'and no rights can be derived from them.',
    'general': 'General',
    'you_have_active_reservation': 'An active reservation is already in progress. You can therefore not make a second reservation now.',
    'read_terms_1': 'Read Terms',
    'deposit_info_text': "<b style='text-align: center; width: 100%; display: block'>Deposit Policy</b> <br> First time customers pay: €200 <br> Returning customers pay: €300 <br> All customers renting at our 24/7 Pick Up Points pay: €300",
    'call_center_day_reason': "You are about to rent a van for more than one day. Please contact us to determine the condition. <br> <a href='tel:+31880883434'>Call: 088 - 088 34 34</a>",
    "please_wait_loading": "Please wait for loading...",
    'pick_up_time_wrong': 'The reservation start date or time cannot be earlier than the current time'
}

export default en
