import React from 'react'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import styled from "styled-components";

const TransactionHistoryItemBalanceStyled = styled.div`
  display: flex;
  align-items: center;
  
  svg {
    font-size: 18px;
    color: ${props => props.color};
  }
`

const TransactionHistoryItemBalance = ({color, method}) => {
    return (
        <TransactionHistoryItemBalanceStyled color={color}>
            <AccountBalanceIcon />
            {
                method == 'bunq_payment' ?
                    <ArrowRightAltIcon style={{transform: 'rotate(180deg)'}} />
                    :
                    <ArrowRightAltIcon />
            }
        </TransactionHistoryItemBalanceStyled>
    )
}

export default TransactionHistoryItemBalance