const checkPasswordStrength = (password) => {
    let strength = 0, optionalfulfilledCriteria = [];
    if (password !== '') strength += 0.5;
    if (password.length > 7) strength += 0.5;

    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
        strength += 1;
        optionalfulfilledCriteria.push('letters_numbers');
    }
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
        strength += 1;
        optionalfulfilledCriteria.push('uppercase_lowercase');
    }
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
        strength += 1;
        optionalfulfilledCriteria.push('one_special_character');
    }
    if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,",%,&,@,#,$,^,*,?,_,~])/)) {
        strength += 1;
        optionalfulfilledCriteria.push('multiple_special_characters');
    }

    return {
        score: strength * 10 * 2,
        optionalfulfilledCriteria: optionalfulfilledCriteria
    };
}

export default checkPasswordStrength