import { isPossiblePhoneNumber } from 'react-phone-number-input'

const validator = {

    isEmailAddress: function (str) {
        var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(str);  // returns a boolean
    },

    isNotEmpty: function (str) {
        var pattern = /\S+/;
        return pattern.test(str);  // returns a boolean
    },

    isNumber: function (str) {
        var pattern = /^\d+$/;
        return pattern.test(str);  // returns a boolean
    },

    documentVerification: function (fields) {

        let errors = []

        fields.forEach((item) => {

            if (item.type === 'isCategoryB') {
                if (item.value === false) {
                    errors.push(item)
                }
            } else if (item.type === 'expDate' || item.type === 'dateOfBirth' || item.type === 'idcardpasExpdate') {
                if (!validator._validateDate(item.value)) {
                    errors.push(item)
                }
            } else if (item.type === 'email') {
                if (!this.isEmailAddress(item.value)) {
                    errors.push(item)
                }
            } else if (item.type === 'phone') {
                const phoneNumber = item.value.replace(/[ \-()]/g, '')
                if (phoneNumber.length < 8) {
                    errors.push(item)
                }
            } else {

                if (item.value.length <= 0) {
                    errors.push(item)
                }
            }

        })

        return errors
    },

    _validateDate: function (date) {

        const dateParts = date.split('-')

        if (dateParts.length !== 3) {
            return false
        }

        if (!this.isNumber(dateParts[0])) {
            return false
        }

        if (!this.isNumber(dateParts[1])) {
            return false
        }

        if (!this.isNumber(dateParts[2])) {
            return false
        }

        return true
    }
}

export default validator