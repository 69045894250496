import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changePage, hideErrorMessage, hideLoginPopup, resetPassword, showErrorMessage} from "../redux/actions";
import {Button, Dialog, DialogActions, DialogContent, Grid} from "@mui/material";

const AppLoginPopup = () => {

    const dispatch = useDispatch()
    const isErrorShow = useSelector(state => state.login.isActive)
    const email = useSelector(state => state.email)
    const lang = useSelector(state => state.lang)
    const telRef = useRef(false)

    const handleClose = () => {
        dispatch(hideLoginPopup())

        // dispatch(changePage({
        //     pageName: 'done-page'
        // }))
    }

    const handleResetPasswordHandler = () => {
        dispatch(resetPassword(
            {
                email,
                cbSuccess: () => {
                    dispatch(showErrorMessage({
                        title: '',
                        message: lang.recovery_link_message
                    }))
                }
            }
        ))
    }

    const callUsHandler = () => {
        telRef.current.click()
    }

    return (
        <div>
            <Dialog
                open={isErrorShow}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid style={{width: '100%'}} item md={12}>
                            <p style={{textAlign: 'center'}}>
                                {lang.customer_with_email_1} <br/> {lang.customer_with_email}: <b>{email}</b>
                                <br/> {lang.already_exists}
                            </p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center',
                    justifyContent: 'space-around', marginTop: '40px'
                }}>
                    <Button onClick={handleResetPasswordHandler}
                            style={{textTransform: 'none'}}
                            variant="contained"
                            className="nextButtonText nextButtonTextWidth"
                            color="primary">{lang.reset_password}</Button>

                    <Button onClick={callUsHandler}
                            style={{marginTop: '20px', textTransform: 'none', marginLeft: '0'}}
                            variant="contained"
                            className="nextButtonText nextButtonTextWidth"
                            color="primary">{lang.call_us}</Button>

                    <p style={{marginTop: '20px', marginBottom: '20px'}}>088 - 088 34 34</p>

                </DialogActions>
            </Dialog>
            <a target="_blank" ref={telRef} style={{display: 'none'}} href="tel:0880883434"/>
        </div>
    )
}

export default AppLoginPopup