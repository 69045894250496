import React from 'react'
import styled from "styled-components";

const CustomPreloaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`

const CustomerPreloader = () => {
    return (
        <CustomPreloaderWrapper>
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </CustomPreloaderWrapper>
    )
}

export default CustomerPreloader