import React, {useState} from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, FormControl,
    Grid, IconButton,
    InputAdornment, InputLabel,
    OutlinedInput,
    TextField
} from "@mui/material";
import {findError} from "../services/findError";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    changeApiToken,
    changeField,
    clientLoginV4, getClientInfo,
    getUserInfo, loginV3,
    showErrorMessage
} from "../redux/actions";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const AppMakeLoginPopup = ({handleClose, isActive, showResetHandler}) => {

    const lang = useSelector(state => state.lang)
    const dispatch = useDispatch()
    const history = useHistory()
    const [showPassword, setShowPassword] = useState(false)

    const [user, setUser] = useState({
        username: '',
        password: ''
    })

    const onLogin = () => {
        dispatch(
            clientLoginV4({
                email: user.username,
                password: user.password,
                onSuccess: (res) => {

                    window.localStorage.setItem('apiToken', res.data.token)

                    dispatch(
                        changeApiToken({
                            apiToken: res.data.token
                        })
                    )

                    // dispatch(
                    //     loginV3({
                    //         email: user.username,
                    //         password: user.password,
                    //         onSuccess: ((resv1) => {
                    //             console.log(resv1)
                    //
                    //             window.localStorage.setItem('apiToken', res.data.token)
                    //             window.localStorage.setItem('apiTokenV3', resv1.data.token)
                    //
                    //             dispatch(
                    //                 changeApiToken({
                    //                     apiToken: res.data.token
                    //                 })
                    //             )
                    //
                    //             dispatch(
                    //                 changeField({
                    //                     field: 'apiTokenV3',
                    //                     value: resv1.data.token
                    //                 })
                    //             )
                    //         })
                    //     })
                    // )
                },
                onError: (err) => {
                    dispatch(
                        showErrorMessage({
                            title: '',
                            message: err.response.data.error.message
                        })
                    )
                }
            })
        )
    }

    return (
        <div>
            <Dialog
                open={isActive}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid style={{width: '100%'}} item md={12}>
                            <p style={{textAlign: 'center', fontWeight: '600', fontSize: '18px'}}>
                                {lang.login}
                            </p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center',
                    justifyContent: 'space-around'
                }}>

                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <TextField
                                name="email"
                                onChange={(e) => {setUser({
                                    ...user,
                                    username: e.target.value
                                })}}
                                style={{width: '100%'}}
                                label={`Email`}
                                type="text"
                                variant="outlined" />

                            {/*<TextField*/}
                            {/*    name="password"*/}
                            {/*    onChange={(e) => {setUser({*/}
                            {/*        ...user,*/}
                            {/*        password: e.target.value*/}
                            {/*    })}}*/}
                            {/*    style={{width: '100%', marginTop: '15px'}}*/}
                            {/*    label={`Password`}*/}
                            {/*    type="password"*/}
                            {/*    variant="outlined" />*/}

                            <FormControl style={{marginTop: '20px'}} sx={{ width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">{lang.password}</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={user.password}
                                    onChange={(e) => {setUser({
                                        ...user,
                                        password: e.target.value
                                    })}}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {setShowPassword(!showPassword)}}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Button
                        className="main-button main-button-register popup-action-button-margin"
                        style={{width: '200px', fontWeight: '600', marginTop: '15px'}}
                        onClick={onLogin}
                        variant="contained">{lang.login}</Button>

                    <Button onClick={showResetHandler}
                            style={{textTransform: 'none', textDecoration: 'underline'}}
                            variant="contained"
                            className="simple-button"
                            color="primary">{lang.reset_password}</Button>

                    <p style={{marginTop: '20px', marginBottom: '20px', color: '#666666'}}> {lang.questions_call_us}: &nbsp;
                        <a style={{color: '#666666'}} href="tel:+31880883434 ">+31 88 088 3434</a>
                    </p>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AppMakeLoginPopup