import styled from 'styled-components'

const ActiveReservationBoxStyled = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
  font-family: "Open Sans", sans-serif;
  
  .busImage {
    max-width: 120px;
    width: 100%;
    height: auto;
  }
  
  .activeReservationTitle {
    font-size: 14px;
    text-align: left;
  }
  
  hr {
    width: 100%;
    border: 1px solid #F5F5F5;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  table {
    
    width: 100%;

    td {
      font-size: 14px !important;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }

    tr td:first-child {
      width: 40%;
      font-weight: 600;
    }

    tr td:last-child {
      width: 60%;
      color: #666666;
    }
  }
  
  @media (min-width: 1000px) {
    .activeReservationTitle {
      font-size: 18px;
    }
    
    table td {
      font-size: 18px !important;
      padding-bottom: 10px !important;
    }
    
    .busImage {
      max-width: 300px;
    }
    
    .manage-top {
      flex-direction: column;
    }
  }
`

export default ActiveReservationBoxStyled