import React, {useEffect, useState} from 'react'
import AppPopup from "./AppPopup";
import {useDispatch, useSelector} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, Grid, TextField} from "@mui/material";
import TextStyled from "../styles/TextStyled";
import {getVehicleStatus} from "../redux/actions";
import notificationSound from '../assets/notificationsound.mp3'

const GasolinePopup = () => {

    const lang = useSelector(state => state.lang)
    const dispatch = useDispatch()
    const apiToken = useSelector(state => state.apiToken)
    const [isActive, setIsActive] = useState(false)
    const activeReservation = useSelector(state => state.activeReservation)
    const [vehicleInfo, setVehicleInfo] = useState(false)

    useEffect(() => {
      if (activeReservation !== false) {
        dispatch(
            getVehicleStatus({
                apiToken,
                vehicleId: activeReservation.vehicle.id,
                onSuccess: (res) => {
                    setVehicleInfo(res)
                }
            })
        )
      }
    }, [activeReservation])

    useEffect(() => {
        if (vehicleInfo) {
            if (vehicleInfo.fuel_level != null && vehicleInfo.fuel_level <= 20) {
                setIsActive(true)
            }
        }
    }, [vehicleInfo])

    useEffect(() => {
        if (isActive == true) {
            const myAudio = new Audio(notificationSound);
            myAudio.play()
        }
    }, [isActive])

    return (
        <div>
            <Dialog
                open={isActive}
                onClose={() => {setIsActive(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextStyled>
                                <p style={{fontSize: '18px', textAlign: 'center'}}>
                                    {lang.gasoline_1} <b style={{fontWeight: 'bold'}}>{vehicleInfo ? vehicleInfo.fuel_level : '-'}%</b> {lang.gasoline_2}
                                    {lang.gasoline_3}
                                    <b style={{textTransform: 'capitalize', fontWeight: 'bold'}}> {activeReservation ? activeReservation.vehicle.model.fuel_type : '-'}</b> {lang.gasoline_5}
                                    {lang.gasoline_4}</p>
                            </TextStyled>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center',
                    justifyContent: 'space-around'
                }}>
                    <Button
                        className="main-button main-button-register popup-action-button-margin"
                        style={{width: '200px', fontWeight: '600', marginTop: '15px'}}
                        onClick={() => {setIsActive(false)}}
                        variant="contained">{lang.ok}</Button>

                    <p style={{marginTop: '20px', marginBottom: '20px', color: '#666666'}}> {lang.questions_call_us} &nbsp;
                        <a style={{color: '#666666'}} href="tel:+31880883434 ">+31 88 088 3434</a>
                    </p>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default GasolinePopup