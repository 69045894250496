import styled from "styled-components";

const SkyeTimePickerStyled = styled.div`

  width: 100%;

  .popup-wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 50;
  }

  .popup {
    background-color: #fff;
    min-width: 300px;
    padding: 20px 10px 10px 10px;
    border-radius: 6px;
    font-family: "Open Sans", sans-serif;
    position: fixed;
    z-index: 51;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }

  .popup-timer {
    display: flex;
    width: 100%;
  }

  .popup-timer-inner {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .popup-title-timer {
    font-size: 16px;
    position: relative;
    margin-bottom: 4px;
  }

  .popup-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    
    button {
      color: #E9392C;
      padding: 5px 10px;
    }
  }

  .popup-info {
    margin-bottom: 10px;
  }
  
  .popup-info-date {
    font-weight: bold;
    text-align: center;
  }
  
  .popup-info-delta {
    color: #E9392C;
    text-align: center;
    font-size: 12px;
  }
`

export default SkyeTimePickerStyled