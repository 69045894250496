import React, {useEffect} from 'react'
import {Button, Grid} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {changePage} from "../redux/actions"
import AppHeader from "../components/AppHeader";
import idCardBack from '../assets/idcard_back.jpg'
import ReactGA from "react-ga";

const IdCardBackPage = () => {

    const dispatch = useDispatch()

    const handleNextStep = () => {
        dispatch(changePage({pageName: 'idcard-photo-back-page'}))
    }

    const lang = useSelector(state => state.lang)

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'Id card back page'
        });
    }, [])

    return (
        <Grid container spacing={2}
              style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4px'}}>
            <AppHeader/>
            <Grid className="containerAlignBottom" container item spacing={2} xs={11}>
                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} item xs={12}>
                    <h1 className="secondH1Title">Back of ID card</h1>

                    <img className="document-image"
                         src={idCardBack} alt=""/>

                    <h2 style={{fontWeight: 'bold'}}>{lang.attention}</h2>

                    <ol className="ol-list" type="1">
                        <li>
                            {lang.at_1}
                        </li>
                        <li>
                            {lang.at_2}
                        </li>
                    </ol>
                </Grid>
                <Grid
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end'}}
                    item xs={12}>
                    <Button
                        className="main-button main-button-register"
                        onClick={handleNextStep}
                        variant="contained">{lang.take_a_photo}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default IdCardBackPage
