import React, {useEffect} from 'react'

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {Grid} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {changeField} from "../redux/actions";

const AppHeader = ({closeable = false}) => {

    const language = useSelector(state => state.language)

    const dispatch = useDispatch()

    const handleChange = (e) => {
        dispatch(changeField({
            field: 'language',
            value: e.target.value
        }))
    }

    return (

        <Grid container item justifyContent="space-between" spacing={2} xs={12}>
            <Grid item xs={1}>

            </Grid>
            <Grid style={{display: 'flex', justifyContent: 'flex-start'}} item xs={3}>
                <Select
                    className="app-language-select"
                    value={language}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleChange}
                >
                    <MenuItem value="en">EN</MenuItem>
                    <MenuItem value="nl">NL</MenuItem>
                </Select>
            </Grid>
        </Grid>
    )
}

export default AppHeader