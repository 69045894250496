import React, {useEffect, useState} from 'react'
import imageBus from "../assets/bushome.png";
import styled from "styled-components";
import openDoors from '../assets/openDoors.png'
import damageReportIcon from '../assets/damageReport.png'
import navigate from '../assets/navigateReservation.png'
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {useDispatch, useSelector} from "react-redux";
import {
    editReservation,
    getDamageReportList,
    getReservationInformation,
    getVehicleInformation,
    getVehicleStatus, showErrorMessage
} from "../redux/actions";
import filterTimeFormatted from "../services/filterTimeFormatted";
import AppPopup from "./AppPopup"
import startGif from '../assets/startingInstructions-invers_cloudboxx.gif'
import {useHistory} from "react-router-dom";
import CustomerPreloader from "./CustomerPreloader";
import CarRentalOutlinedIcon from '@mui/icons-material/CarRentalOutlined'
import {BrowserView, MobileView, isBrowser, isMobile} from 'react-device-detect'

const TableStyled = styled.div`

  margin-top: 5px;

  table {

    td {
      font-size: 16px;
    }

    tr td:first-child {
      width: 40%;
      font-weight: 600;
    }

    tr td:last-child {
      width: 60%;
      color: #666666;
    }
  }

  @media (min-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    table {
      td {
        font-size: 16px !important;
        padding-bottom: 5px;
      }
    }
  }
`

const ActiveReservation = () => {

    const dispatch = useDispatch()
    const apiToken = useSelector(state => state.apiToken)
    const activeReservation = useSelector(state => state.activeReservation)
    const [reservationDetail, setReservationDetail] = useState(false)
    const [isStartPopupActive, setIsStartPopupActive] = useState(false)
    const [isInstructionPopup, setIsInstructionPopup] = useState(false)
    const [damageReport, setDamageReport] = useState([])
    const history = useHistory()
    const lang = useSelector(state => state.lang)

    const [vehicleInformation, setVehicleInformation] = useState(false)
    const [locationUrl, setLocationUrl] = useState('')

    useEffect(() => {
        if (activeReservation) {
            dispatch(
                getVehicleStatus({
                    apiToken,
                    vehicleId: activeReservation.vehicle.id,
                    onSuccess: (res) => {
                        setVehicleInformation(res)
                    }
                })
            )
        }
    }, [activeReservation])

    const [isActive, setIsActive] = useState(true)

    const returnLocationHandler = () => {

        if (vehicleInformation) {
            if (!navigator.geolocation) {
                setLocationUrl(`https://maps.google.com/?q=${vehicleInformation.position.latitude},${vehicleInformation.position.longitude}`)
            } else {
                navigator.geolocation.getCurrentPosition((position) => {
                    const {latitude, longitude} = position.coords
                    setLocationUrl(`https://maps.google.com/?saddr=${latitude},${longitude}&daddr=${vehicleInformation.position.latitude},${vehicleInformation.position.longitude}&dirflg=w`)
                }, (err) => {
                    setLocationUrl(`https://maps.google.com/?q=${vehicleInformation.position.latitude},${vehicleInformation.position.longitude}`)
                })
            }
        }
    }

    useEffect(() => {
        if (apiToken && activeReservation) {

            dispatch(
                getReservationInformation({
                    apiToken,
                    reservationId: activeReservation.id,
                    onSuccess: (res) => {
                        setReservationDetail(res)
                    }
                })
            )

            dispatch(
                getDamageReportList({
                    apiToken,
                    vehicleId: activeReservation.vehicle.id,
                    onSuccess: (res) => {
                        setDamageReport(res)
                    }
                })
            )
        }

    }, [activeReservation, apiToken])

    useEffect(() => {
        returnLocationHandler()
    }, [vehicleInformation])

    const onStartReservationHandler = () => {

        const resDate = {
            id: activeReservation.id,
            startDate: activeReservation.planned_end_date
        }

        window.localStorage.setItem('resDate', JSON.stringify(resDate))

        dispatch(
            editReservation({
                apiToken,
                reservationId: activeReservation.id,
                status: 'picked_up',
                onSuccess: (res) => {
                    //setIsStartPopupActive(true)
                }
            })
        )
    }

    if (reservationDetail !== false) {
        return (
            <div className="dashboard-reservations-active">
                <div
                    className="active-manage-image"
                    onClick={() => {
                        setIsActive(!isActive)
                    }}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: '10px'
                    }}>
                    <img src={imageBus} style={{maxWidth: '110px', height: 'auto'}} alt=""/>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{marginRight: '5px'}}>
                            <p className="dashboard-reservations-active-id"
                               style={{fontSize: '13px'}}>{lang.active_reservation} <b># {activeReservation.id}</b></p>
                            <p className="dashboard-reservations-active-id" style={{
                                color: '#666666',
                                fontSize: '13px'
                            }}>{activeReservation.pick_up_branch.address}</p>
                        </div>
                        <div className="active-manage-fixed">
                            {
                                isActive ?
                                    <KeyboardArrowUpIcon/>
                                    :
                                    <KeyboardArrowUpIcon style={{transform: 'rotate(180deg)'}}/>
                            }
                        </div>
                    </div>
                </div>
                <hr style={{border: '1px solid #F5F5F5', width: '100%'}}/>

                {
                    isActive ?
                        <TableStyled>
                            <table style={{fontSize: '16px'}}>
                                <tbody>
                                <tr>
                                    <td>{lang.license_plate}:</td>
                                    <td style={{
                                        color: '#666666',
                                        fontSize: '14px'
                                    }}>{activeReservation.vehicle.license_plate}</td>
                                </tr>
                                <tr>
                                    <td>{lang.location}:</td>
                                    <td style={{
                                        color: '#666666',
                                        fontSize: '14px'
                                    }}>{activeReservation.pick_up_branch.address}</td>
                                </tr>
                                <tr>
                                    <td>{lang.pick_up_date}:</td>
                                    <td style={{
                                        color: '#666666',
                                        fontSize: '14px'
                                    }}>{filterTimeFormatted.fromPhpToMomentJsFormat(activeReservation.planned_start_date)}</td>
                                </tr>
                                <tr>
                                    <td>{lang.return_date}:</td>
                                    <td style={{
                                        color: '#666666',
                                        fontSize: '14px'
                                    }}>{filterTimeFormatted.fromPhpToMomentJsFormat(activeReservation.planned_end_date)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </TableStyled>
                        :
                        ''
                }

                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '5px'}}>
                    <button
                        onClick={() => {
                            history.push(`/damage-report/${reservationDetail.vehicle.id}/${reservationDetail.id}`)
                        }}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'transparent',
                            cursor: 'pointer',
                            border: '1px solid #E9392C',
                            borderRadius: '20px',
                            width: '31%',
                            height: '91px',
                            fontFamily: 'Open Sans',
                            color: '#E9392C',
                            textTransform: 'uppercase',
                            fontSize: '14px',
                            padding: '5px'
                        }}
                        type="button">
                        <img src={damageReportIcon} alt=""/>
                        {lang.damage_report}
                        ({damageReport.length})
                    </button>
                    <a
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'transparent',
                            cursor: 'pointer',
                            border: '1px solid #E9392C',
                            borderRadius: '20px',
                            width: '31%',
                            height: '91px',
                            fontFamily: 'Open Sans',
                            color: '#E9392C',
                            textTransform: 'uppercase',
                            fontSize: '14px',
                            textDecoration: 'none',
                            textAlign: 'center',
                            padding: '5px'
                        }}
                        target="_blank"
                        href={locationUrl}
                    ><img src={navigate} alt=""/> {lang.navigate_to_vehicle}</a>

                    {reservationDetail.status == 'new' ?
                        <button
                            onClick={() => {
                                if (isMobile) {
                                    setIsStartPopupActive(true)
                                } else {
                                    dispatch(
                                        showErrorMessage({
                                            title: '',
                                            message: lang.manage_reservation_popup
                                        })
                                    )
                                }

                            }}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'transparent',
                                cursor: 'pointer',
                                border: '1px solid #E9392C',
                                borderRadius: '20px',
                                width: '31%',
                                height: '91px',
                                fontFamily: 'Open Sans',
                                color: '#E9392C',
                                textTransform: 'uppercase',
                                fontSize: '14px',
                                padding: '5px'
                            }}
                            type="button">
                            {/*<img src={openDoors} alt=""/>*/}
                            <CarRentalOutlinedIcon style={{fontSize: '36px'}}/>
                            {lang.open_doors}
                        </button>
                        :
                        <button
                            onClick={() => {
                                if (isMobile) {
                                    history.push(`/manage/${reservationDetail.id}`)
                                } else {
                                    dispatch(
                                        showErrorMessage({
                                            title: '',
                                            message: lang.manage_reservation_popup
                                        })
                                    )
                                }
                            }}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'transparent',
                                cursor: 'pointer',
                                border: '1px solid #269B13',
                                borderRadius: '20px',
                                width: '31%',
                                height: '91px',
                                fontFamily: 'Open Sans',
                                color: '#28AF12',
                                textTransform: 'uppercase',
                                fontSize: '14px',
                                padding: '5px'
                            }}
                            type="button">
                            <img style={{width: '30px'}} src={openDoors} alt=""/>
                            {lang.manage_reservation}
                        </button>
                    }
                </div>
                <AppPopup
                    open={isStartPopupActive}
                    title={lang.open_doors_1}
                    description={lang.open_doors_2}
                    nextText={lang.yes}
                    closeText={lang.cancel}
                    handleClose={() => {
                        setIsStartPopupActive(false)
                    }}
                    handleNext={() => {
                        onStartReservationHandler()
                        setIsStartPopupActive(false)
                        setIsInstructionPopup(true)
                    }}
                />

                <AppPopup
                    open={isInstructionPopup}
                    description={
                        `
<div style="margin-bottom: 10px">
<p>
${lang.open_doors_3}
</p>
<ul style="font-size: 14px; padding-left: 20px">
<li>${lang.open_doors_4}</li>
<li>${lang.open_doors_5}</li>
<li>${lang.open_doors_6}</li>
</ul>
<p>${lang.open_doors_7}</p>
</div>
<img style="max-width: 100%" src=${startGif}>`
                    }
                    nextText={lang.ok}
                    handleNext={() => {
                        setIsInstructionPopup(false)
                        history.push('/manage/' + activeReservation.id)
                        //onStartReservationHandler()
                    }}
                />

            </div>
        )
    } else {
        return (
            <div>
                <CustomerPreloader/>
            </div>
        )
    }


}

export default ActiveReservation