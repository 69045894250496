import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import InputField from "./InputField";
import useDebounce from "../hooks/useDebounce";
import {useDispatch, useSelector} from "react-redux";
import {googleAddressGeo, googleAutocomplete} from "../redux/actions";

const AutocompleteStyled = styled.div`

  position: relative;

  input {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    padding-left: 5px !important;
    padding-right: 40px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    border-radius: 4px;
  }

  .autocompleteSub {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    max-height: 150px;
    overflow: auto;
    width: 100%;
    background-color: #f1f1f1;
    top: 44px;
  }

  .autocompleteSubItem {
    border-top: 1px solid rgba(0, 0, 0, .1);
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 5px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
  }
`

const LocationAutocomplete = ({onPlaceSelected, initAddress}) => {

    // @return {
    //         address.geometry.location.lat(),
    //         address.geometry.location.lng(),
    //         address.formatted_address
    // }

    const dispatch = useDispatch()
    const apiToken = useSelector(state => state.apiToken)
    const [address, setAddress] = useState('')
    const [isActive, setIsActive] = useState(true)
    const [predications, setPredications] = useState([])

    useEffect(() => {
      setAddress(initAddress)
    }, [initAddress])

    const addressToSearch = useDebounce(address, 100)

    const onSearchHandler = (search) => {
        if (search.length > 0) {
            dispatch(googleAutocomplete({
                search,
                onSuccess: (res) => {
                    setPredications(res.data.predictions)
                    console.log(res.data.predictions)
                }
            }))
        }
    }

    useEffect(() => {
        onSearchHandler(addressToSearch)
    }, [addressToSearch])

    const onSelectClick = (address) => {
        dispatch(googleAddressGeo({
            apiToken,
            address,
            onSuccess: (res) => {
                const location = res.data.results[0]

                const resLocationObject = {
                    formatted_address: location.formatted_address,
                    geometry: {
                        location: {

                            lat() {
                                return location.geometry.location.lat
                            },

                            lng() {
                                return location.geometry.location.lng
                            }

                            //lat: () => { return location.geometry.lat},
                            //lng: () => { return location.geometry.lng},
                        }
                    }
                }

                onPlaceSelected(resLocationObject)
            }
        }))
    }

    return (
        <AutocompleteStyled>
            <InputField
                value={address}
                name="address"
                onChange={(name, value) => {
                    setAddress(value)
                }}
            />
            {
                isActive ?
                    <div className="autocompleteSub">
                        {
                            predications.map((item) => {
                                return (
                                    <div
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {onSelectClick(item.description)}}
                                        key={item.place_id} className="autocompleteSubItem">{item.description}</div>
                                )
                            })
                        }
                    </div>
                    :
                    ''
            }
        </AutocompleteStyled>
    )
}

export default LocationAutocomplete