import React, {useEffect, useMemo, useState} from 'react'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField
} from "@mui/material"
import OutlinedButton from "../styles/OutlinedButton"
import countryList from 'react-select-country-list'
import {findError} from "../services/findError";
import AppInputMaskedDate from "./AppInputMaskedDate";
import InputMask from "react-input-mask";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changeField, dlUploadHandler, idCardUploadHandler, psUploadHandler} from "../redux/actions";

const DocumentIdCardPopup = (handleClose) => {

    const lang = useSelector(state => state.lang)

    const history = useHistory()
    const dispatch = useDispatch()
    const countries = useMemo(() => countryList().getData(), [])
    const docPhoto = useSelector(state => state.docPhoto)
    const [isValid, setIsValid] = useState(false)
    const apiToken = useSelector(state => state.apiToken)

    const onCloseHandler = () => {
        dispatch(
            changeField({
                field: 'docPhoto',
                value: {
                    isActive: false,
                    type: '',
                    front: false,
                    back: false,
                    country: 'NL',
                    number: '',
                    expiration: '',
                    categoryB: false
                }
            })
        )
    }

    const onInputHandler = (e) => {
        dispatch(
            changeField({
                field: 'docPhoto',
                value: {
                    ...docPhoto,
                    [e.target.name]: e.target.value
                }
            })
        )
    }

    const uploadHandler = () => {
        dispatch(
            idCardUploadHandler({
                apiToken,
                number: docPhoto.number,
                expiration: docPhoto.expiration,
                country: docPhoto.country,
                front: docPhoto.front,
                back: docPhoto.back,
                onSuccess: (res) => {
                    history.push('/profile')
                },
                onError: (err) => {}
            })
        )
    }

    useEffect(() => {
        let _isValid = true

        if (docPhoto.number.length <= 2) {
            _isValid = false
        }

        if (docPhoto.expiration.length <= 5) {
            _isValid = false
        }

        if (docPhoto.front == false || docPhoto.back == false) {
            _isValid = false
        }

        setIsValid(_isValid)
    }, [docPhoto])

    return (
        <Dialog
            open={docPhoto.type == 'idcard' && docPhoto.isActive}
            onClose={onCloseHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid style={{width: '100%', position: 'relative'}} item md={12}>
                        <p style={{textAlign: 'center', color: '#E9392C', fontWeight: 'bold'}}>
                            {lang.id_card}
                        </p>
                        <span
                            onClick={onCloseHandler}
                            style={{
                                position: 'absolute',
                                top: '-10px',
                                right: '-10px',
                                color: '#E9392C',
                                fontSize: '36px'
                            }}>&times;</span>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                            <p style={{width: '120px', marginBottom: '5px'}}>{lang.image}</p>
                            <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                                <OutlinedButton
                                    onClick={() => {
                                        history.push('/make-photo/idcard/front')
                                    }}
                                    style={{
                                        marginRight: '5px',
                                        color: docPhoto.front ? '#7EE96D' : '#E9392C',
                                        borderColor: docPhoto.front ? '#7EE96D' : '#E9392C',
                                    }}>+ {lang.front}</OutlinedButton>

                                <OutlinedButton
                                    onClick={() => {
                                        history.push('/make-photo/idcard/back')
                                    }}
                                    style={{
                                        marginRight: '5px',
                                        color: docPhoto.back ? '#7EE96D' : '#E9392C',
                                        borderColor: docPhoto.back ? '#7EE96D' : '#E9392C',
                                    }}>+ {lang.back}</OutlinedButton>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                            <p style={{width: '120px', marginBottom: '5px'}}>{lang.country}</p>
                            <TextField
                                style={{width: '100%'}}
                                name="country"
                                select
                                value={docPhoto.country}
                                onChange={onInputHandler}
                            >
                                {countries.map((item) => {
                                    return (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                            <p style={{width: '120px', marginBottom: '5px'}}>{lang.number}</p>
                            <TextField
                                name="number"
                                autoComplete="off"
                                value={docPhoto.number}
                                onChange={onInputHandler}
                                className="zipCode"
                                style={{width: '100%'}}
                                variant="outlined"/>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                            <p style={{width: '120px', marginBottom: '5px'}}>{lang.expiration}</p>
                            <InputMask mask="39-19-9999"
                                       value={docPhoto.expiration}
                                       name="expiration"
                                       formatChars={
                                           {
                                               '3': '[0-3]',
                                               '9': '[0-9]',
                                               '1': '[0-1]'
                                           }
                                       }
                                       onChange={onInputHandler}>
                                {
                                    (inputProps) => <TextField
                                        style={{width: '100%'}}
                                        {...inputProps}
                                        placeholder="dd-mm-yyyy"
                                        value={docPhoto.expiration}
                                        name="expiration"
                                        InputLabelProps={{shrink: true}}
                                        label=""
                                        variant="outlined"/>
                                }

                            </InputMask>
                        </div>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center',
                justifyContent: 'space-around', marginTop: '40px'
            }}>
                <Button onClick={uploadHandler}
                        style={{textTransform: 'none', borderRadius: '30px', color: 'white', backgroundColor: '#E9392C'}}
                        variant="contained"
                        disabled={!isValid}
                        className="nextButtonTextWidth"
                        color="primary">{lang.upload}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DocumentIdCardPopup