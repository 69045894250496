import React, {useEffect} from 'react'
import {useGeolocated} from "react-geolocated";
import {useDispatch, useSelector} from "react-redux";
import {changeField} from "../redux/actions";
import Cookies from "universal-cookie";

const Geolocation = () => {

    const dispatch = useDispatch()

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });

    useEffect(() => {

        if (isGeolocationEnabled == false) {
            const cookies = new Cookies()
            cookies.set('geo_available', false)

            dispatch(
                changeField({
                    field: 'isGeoAvailable',
                    value: false
                })
            )
        }

        if (isGeolocationEnabled == true) {
            const cookies = new Cookies()
            cookies.set('geo_available', true)

            dispatch(
                changeField({
                    field: 'isGeoAvailable',
                    value: true
                })
            )

        }
    }, [isGeolocationEnabled])

    useEffect(() => {

        if (isGeolocationAvailable && isGeolocationEnabled && coords) {

            dispatch(
                changeField({
                    field: 'map',
                    value: {
                        zoom: 10,
                        coords: {
                            lat: coords.latitude,
                            lng: coords.longitude
                        }
                    }
                })
            )

            dispatch(
                changeField({
                    field: 'map',
                    value: {
                        zoom: 10,
                        coords: {
                            lat: coords.latitude,
                            lng: coords.longitude
                        }
                    }
                })
            )

            dispatch(
                changeField({
                    field: 'geoCoords',
                    value: {
                        lat: coords.latitude,
                        lng: coords.longitude
                    }
                })
            )

            dispatch(
                changeField({
                    field: 'locationInfo',
                    value : {
                        type: 'geo',
                        address: '',
                        coordinates: {
                            lat: coords.latitude,
                            lng: coords.longitude
                        }
                    }
                })
            )
        }

    }, [coords])

    return (
        ''
    )
}

export default Geolocation