import React, {useState} from 'react'
import BoxWrapperStyled from "../styles/BoxWrapperStyled"
import styled from "styled-components"
import ArticleIcon from '@mui/icons-material/Article'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import TableStyled from "../styles/TableStyled";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import filterTimeFormatted from "../services/filterTimeFormatted";
import AppPopup from "./AppPopup";
import {useDispatch, useSelector} from "react-redux";
import {deleteDocument} from "../redux/actions";

const DocumentItemStyled = styled.div`

  font-family: "Open Sans", sans-serif;

  .document-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const DocumentItem = ({document, onUpdateNeed}) => {

    const simpleDocuments = [
        'bank_statement',
        'residence_permit',
        'other'
    ];

    const dispatch = useDispatch()
    const [isActive, setIsActive] = useState(false)
    const [isDeleteActive, setIsDeleteActive] = useState(false)
    const apiToken = useSelector(state => state.apiToken)
    const lang = useSelector(state => state.lang)

    const timeAgo = () => {
        const time = filterTimeFormatted.getTotalTimeWithYear(
            document.expiration_date,
            new Date()
        )

        if (simpleDocuments.includes(document.type)) {
            return false
        }

        if (time.months < 0) {
            return false
        }

        if (time.years) {
            return `${time.years} ${lang.years}`
        }

        if (time.months) {
            return `${time.months} ${lang.months}`
        }

        if (time.days) {
            return `${time.days} ${lang.days}`
        }
    }

    const formatDocumentName = () => {

        if (document.type == 'drivers_license') {
            return lang.drivers_license
        }

        if (document.type == 'passport') {
            return lang.passport
        }

        return document.type.replace('_', ' ')
    }

    const onDeleteHandler = () => {
        dispatch(
            deleteDocument({
                apiToken,
                docType: document.type,
                id: document.id,
                onSuccess: (res) => {
                    onUpdateNeed()
                    setIsDeleteActive(false)
                }
            })
        )
    }

    return (
        <>
            <BoxWrapperStyled>
                <DocumentItemStyled>
                    <div className="document-item-header" onClick={() => {
                        setIsActive(!isActive)
                    }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <ArticleIcon style={{
                                color: '#E9392C',
                                fontSize: '48px',
                                marginRight: '5px'
                            }}/>
                            <div>
                                <p style={{fontSize: '16px', textTransform: 'capitalize'}}>{formatDocumentName()}</p>

                                {
                                    !simpleDocuments.includes(document.type) ?
                                        <p style={{fontSize: '13px', color: '#666666'}}>{document.number},
                                            {
                                                timeAgo() === false ?
                                                    `${lang.expired}!` :
                                                    `${lang.expires_in} ` + timeAgo()
                                            }
                                        </p>
                                        :
                                        ''
                                }

                            </div>
                        </div>
                        <KeyboardArrowDownIcon/>
                    </div>
                    {
                        isActive ?
                            <div className="document-item-content">
                                <TableStyled>
                                    <table>

                                        {
                                            !simpleDocuments.includes(document.type) ?
                                            <>
                                                <tr>
                                                    <td>{lang.number}</td>
                                                    <td>{document.number}</td>
                                                </tr>
                                                <tr>
                                                    <td>{lang.country}</td>
                                                    <td>{document.country}</td>
                                                </tr>
                                                <tr>
                                                    <td>{lang.expiration}</td>
                                                    <td>{document.expiration_date}</td>
                                                </tr>
                                            </>
                                                :
                                                <tr>
                                                    <td>{lang.image}</td>
                                                    <td>
                                                        <img style={{maxWidth: '100px', height: 'auto'}} src={document.images[0].thumb} alt=""/>
                                                    </td>
                                                </tr>
                                        }

                                        {
                                            document.type === 'drivers_license' ?
                                                <tr>
                                                    <td>{lang.category}</td>
                                                    <td>{document.drivers_license_categories.join(', ')}</td>
                                                </tr>
                                                :
                                                false
                                        }

                                    </table>
                                </TableStyled>
                                <div style={{
                                    fontSize: '14px', color: '#E9392C', display: 'flex', alignItems: 'center',
                                    cursor: 'pointer', marginTop: '5px'
                                }}
                                     onClick={() => {
                                         setIsDeleteActive(true)
                                     }}
                                >
                                    <DeleteOutlineIcon/>
                                    {lang.delete}
                                </div>
                            </div>
                            :
                            ''
                    }
                </DocumentItemStyled>
            </BoxWrapperStyled>
            <AppPopup
                open={isDeleteActive}
                title={`${lang.confirm_deletion}`}
                description={`${lang.confirm_deletion_1}`}
                nextText={`${lang.yes}`}
                closeText={`${lang.no}`}
                handleClose={() => {
                    setIsDeleteActive(false)
                }}
                handleNext={onDeleteHandler}
            />
        </>
    )
}

export default DocumentItem