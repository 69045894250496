import React, {useEffect, useState} from 'react'
import Collapse from "./Collapse"
import filterIcon from "../assets/filterIcon.png"
import styled from "styled-components"
import FilterItem from "./FilterItem";
import busImage from "../assets/filterImage.png"
import FilterSliderItem from "./FilterSliderItem";
import PrimaryButton from "../styles/PrimaryButton";
import {useDispatch, useSelector} from "react-redux";
import {changeField, getFleetModels} from "../redux/actions";
import OutlinedButton from "../styles/OutlinedButton";

const FilterInner = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 100%;
  padding: 20px 10px;
  margin-top: 20px;
  
  .blinking {
    animation: blinkingAnimation 1s infinite;
  }
  
  .no-results {
    color: #E9392C;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    font-family: "Open Sans", sans-serif;
  }
  
  .vehicle-selection {
    color: #000000;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  @media (max-width: 400px) {
    max-width: ${props => props.type ? '94%': '100%'};
    height: ${props => props.type ? '300px': 'auto'};
    overflow: auto;
  }
`

const FilterSubTitle = styled.div`
  color: black;
  font-weight: bold;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  display: flex;
  align-items: center;
`

const FilterSubItems = styled.div`
  display: flex;
  flex-direction: column;
`

const ReservationFilter = ({type = 'full'}) => {

    const lang = useSelector(state => state.lang)
    const dispatch = useDispatch()
    const [models, setModels] = useState([])
    const filter = useSelector(state => state.filter)
    const filterVans = useSelector(state => state.filterVans)

    useEffect(() => {
        dispatch(
            getFleetModels({
                onSuccess: (res) => {
                    setModels(res.data)
                    console.log(res.data)
                },
                onError: (err) => {
                    alert(err)
                }
            })
        )
    }, [])

    const onModelFilter = (id) => {

        let ids = filter.vehicles

        if (!filter.vehicles.includes(id)) {
            ids.push(id)
        } else {
            ids = ids.filter((item) => {
                if (item == id) return false
                return true
            })
        }

        dispatch(
            changeField({
                field: 'filter',
                value: {
                    ...filter,
                    vehicles: ids
                }
            })
        )
    }

    const onTransmissionFilter = (type) => {
        let types = filter.transmissions

        if (!filter.transmissions.includes(type)) {
            types.push(type)
        } else {
            types = types.filter((item) => {
                if (item == type) return false
                return true
            })
        }

        dispatch(
            changeField({
                field: 'filter',
                value: {
                    ...filter,
                    transmissions: types
                }
            })
        )
    }

    const resetFilterHandler = () => {
        dispatch(
            changeField({
                field: 'filter',
                value: {
                    ...filter,
                    transmissions: [],
                    vehicles: [],
                    distance: 0
                }
            })
        )
    }

    if (type == 'mini') {
        return (
            <div>
                <FilterInner type="mini">
                    {
                        filterVans.filtered.length == 0 ?
                            <p className="blinking no-results">
                                {lang.no_vehicle_for_crit}
                            </p>
                            :
                            ''
                    }

                    <p className="vehicle-selection">
                        {lang.vehicle_selection}: {filterVans.filtered}/{filterVans.total}
                    </p>
                    <FilterSubTitle>
                        {lang.vehicle_selection}:
                    </FilterSubTitle>
                    <FilterSubItems style={{overflow: 'auto'}}>
                        {
                            models.map((model) => {
                                return (
                                    <FilterItem key={model.id}
                                                onClick={() => {
                                                    onModelFilter(model.id)
                                                }}
                                                isActive={!filter.vehicles.includes(model.id)}
                                                title={`${model.brand} ${model.model}`}
                                                description={`${lang.loading_space}: ${model.loading_space}`}
                                    />
                                )
                            })
                        }
                    </FilterSubItems>

                    <FilterSubTitle>
                        {lang.transmission}:
                    </FilterSubTitle>
                    <FilterSubItems>
                        <FilterItem onClick={() => {
                            onTransmissionFilter('auto')
                        }}
                                    isActive={!filter.transmissions.includes('auto')}
                                    title={`${lang.auto}`}/>
                        <FilterItem onClick={() => {
                            onTransmissionFilter('manual')
                        }}
                                    isActive={!filter.transmissions.includes('manual')}
                                    title={`${lang.manual}`}/>
                    </FilterSubItems>

                    <FilterSubTitle>
                        {lang.distance_to_vehicle}: <b style={{fontSize: '28px', marginBottom: '3px', marginLeft: '5px'}}>
                        {filter.distance === 0 ? <span>&#8734;</span> : filter.distance}
                    </b>
                    </FilterSubTitle>
                    <FilterSubItems style={{padding: '0 20px'}}>
                        <FilterSliderItem/>
                    </FilterSubItems>

                    {
                        filter.transmissions.length > 0 || filter.vehicles.length > 0 || filter.distance != 0 ?
                            <OutlinedButton style={{margin: '0 auto'}} onClick={resetFilterHandler}>
                                {lang.reset_filter}
                            </OutlinedButton>
                            :
                            false
                    }

                </FilterInner>
            </div>
        )
    } else {
        return (
            <div>
                <Collapse isActiveInit={false} icon={filterIcon} title={`${lang.filter_settings}`}>
                    <FilterInner>
                        <FilterSubTitle>
                            {lang.vehicle_selection}:
                        </FilterSubTitle>
                        <FilterSubItems>
                            {
                                models.map((model) => {
                                    return (
                                        <FilterItem key={model.id}
                                                    onClick={() => {
                                                        onModelFilter(model.id)
                                                    }}
                                                    isActive={!filter.vehicles.includes(model.id)}
                                                    title={`${model.brand} ${model.model}`}
                                                    description={`${lang.loading_space}: ${model.loading_space}`}
                                                    icon={model.image ? model.image.thumb: false}
                                        />
                                    )
                                })
                            }
                        </FilterSubItems>

                        <FilterSubTitle>
                            {lang.transmission}:
                        </FilterSubTitle>
                        <FilterSubItems>
                            <FilterItem onClick={() => {
                                onTransmissionFilter('auto')
                            }}
                                        isActive={!filter.transmissions.includes('auto')}
                                        title={`${lang.auto}`}/>
                            <FilterItem onClick={() => {
                                onTransmissionFilter('manual')
                            }}
                                        isActive={!filter.transmissions.includes('manual')}
                                        title={`${lang.manual}`}/>
                        </FilterSubItems>

                        <FilterSubTitle>
                            {lang.distance_to_vehicle}: <b style={{fontSize: '28px', marginBottom: '3px', marginLeft: '5px'}}>
                            {filter.distance === 0 ? <span>&#8734;</span> : filter.distance}
                        </b>
                        </FilterSubTitle>
                        <FilterSubItems style={{padding: '0 30px'}}>
                            <FilterSliderItem/>
                        </FilterSubItems>

                        {
                            filter.transmissions.length > 0 || filter.vehicles.length > 0 || filter.distance != 0 ?
                                <OutlinedButton style={{margin: '0 auto'}} onClick={resetFilterHandler}>
                                    {lang.reset_filter}
                                </OutlinedButton>
                                :
                                false
                        }

                    </FilterInner>
                </Collapse>
            </div>
        )
    }
}

export default ReservationFilter
