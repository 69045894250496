import styled from "styled-components"

const PageSubtitleStyled = styled.div`
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: space-between;
  color: #666666;
  padding: 0 20px;
`

export default PageSubtitleStyled