import React, {useEffect, useState} from 'react'
import {Button, Grid} from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';
import CustomerConsent from "../components/CustomerConsent";
import {useDispatch, useSelector} from "react-redux";
import {changePage, preloaderStart, preloaderStop, showLoginPopup} from "../redux/actions";
import AppHeader from "../components/AppHeader";
import dlVideo from '../assets/makephotodl.mp4'
import AppPopup from "../components/AppPopup";
import {canUseWebP} from "../services/canUseWebp";
import config from '../config'
import AppMakeLoginPopup from "../components/AppMakeLoginPopup";
import AppResetPassword from "../components/AppResetPassword";
import ReactGA from "react-ga";
import BlockCameraPopup from "../components/BlockCameraPopup";

const IndexPage = () => {

    const [isDialogActive, setIsDialogActive] = useState(false)
    const dispatch = useDispatch()
    const [homeBusImage, setHomeBusImage] = useState('')

    useEffect(() => {
        if (canUseWebP()) {
            import('../assets/indexPageBus.webp').then((busImage) => {
                setHomeBusImage(busImage.default)
            })
        } else {
            import('../assets/indexPageBus.jpg').then((busImage) => {
                setHomeBusImage(busImage.default)
            })
        }

    }, [])

    const handleCloseCustomer = () => {
        setIsDialogActive(false)
    }

    const handleAgree = () => {

        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'Start registration'
        });

        dispatch(changePage({pageName: 'document-verification'}))
    }

    const [isError, setIsError] = useState(false)

    const onCloseError = () => {
        setIsError(false)
    }

    const handleRegisterBtn = async () => {

        dispatch(preloaderStart())

        let stream = false

        try {
            stream = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {
                    width: 800,
                    height: 800,
                }
            })

            stream.getTracks()
                .forEach((track) => track.stop())
        } catch (e) {
            setIsError(true)
            dispatch(preloaderStop())
            return
        }

        dispatch(preloaderStop())
        setIsDialogActive(true)
    }

    const lang = useSelector(state => state.lang)

    const [isLoginActive, setIsLoginActive] = useState(false)
    const [isResetPasswordActive, setIsResetPasswordActive] = useState(false)

    const onLoginClickHandler = () => {
        setIsLoginActive(true)
    }

    return (
        <Grid container spacing={2}
              style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4px'}}>
            <AppHeader closeable={true}/>
            <video autoPlay={true}
                   playsInline={true}
                   muted={true}
                   style={{opacity: '0', position: 'fixed', zIndex: '-1'}} src={dlVideo}/>

            <h1 className="indexPageTitle hide-mobile" dangerouslySetInnerHTML={{ __html: lang.create_account }} />

            <Grid className="containerAlignBottom" container item spacing={2} xs={11}>
                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} item xs={12}
                      className="homeLeft"
                      md={6}>
                    <h1 className="indexPageTitle hide-desktop" dangerouslySetInnerHTML={{ __html: lang.create_account }} />
                    <img className="indexPageBusImage"
                         style={{marginTop: '20px', marginBottom: '10px'}} src={homeBusImage} alt=""/>

                    <div className="indexPageCenterContainer hide-desktop">
                        <h2 style={{textAlign: 'center'}}>{lang.to_register_you_will_need}</h2>
                        <ul className="home-page-list">
                            <li><CheckIcon/> {lang.reg_term_1}</li>
                            <li><CheckIcon/> {lang.reg_term_2}</li>
                            <li><CheckIcon/> {lang.reg_term_3}</li>
                            <li><CheckIcon/> {lang.reg_term_4}</li>
                        </ul>
                    </div>

                </Grid>
                <Grid
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end'}}
                    item className="homeRight" md={6} xs={12}>

                    <h2 className="hide-mobile">{lang.to_register_you_will_need}</h2>
                    <ul className="hide-mobile home-page-list">
                        <li><CheckIcon/> {lang.reg_term_1}</li>
                        <li><CheckIcon/> {lang.reg_term_2}</li>
                        <li><CheckIcon/> {lang.reg_term_3}</li>
                        <li><CheckIcon/> {lang.reg_term_4}</li>
                    </ul>

                    <Button
                        className="main-button main-button-register main-button-register-home"
                        onClick={handleRegisterBtn}
                        variant="contained">{lang.register}</Button>

                    <Button
                        className="outlined-button"
                        onClick={onLoginClickHandler}
                        variant="contained">{lang.have_account}</Button>
                </Grid>
            </Grid>

            <AppMakeLoginPopup
                isActive={isLoginActive}
                showResetHandler={() => setIsResetPasswordActive(true)}
                handleClose={() => {setIsLoginActive(!isLoginActive)}} />
            <AppResetPassword isActive={isResetPasswordActive} handleClose={() => {setIsResetPasswordActive(!isResetPasswordActive)}} />

            <BlockCameraPopup title={lang.the_are_problems_with_your_camera}
                      open={isError}
                      closeText={lang.cancel}
                      handleClose={onCloseError}
                      description={lang.permission_to_camera}/>

            <CustomerConsent open={isDialogActive} handleClose={handleCloseCustomer} title=""
                             handleAgree={handleAgree}/>
        </Grid>
    )
}

export default IndexPage