import React, {useState} from 'react'
import bankIconMini from "../assets/bankIconMini.png";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const HeaderHavbarAccountBalance = ({value}) => {

    const lang = useSelector(state => state.lang)
    const [isActive, setIsActive] = useState(false)

    return (
        <div className="navbar-account-balance" style={{marginRight: '5px'}}>
            <span
                style={{cursor: 'pointer'}}
                onClick={() => {
                setIsActive(!isActive)
            }} >€ { value }

                <div style={{position: 'absolute', right: '-7px', top: '0'}} className="navbar-account-balance-menu-dots">
                    <span style={{width: '3px', height: '3px'}}></span>
                    <span style={{width: '3px', height: '3px'}}></span>
                    <span style={{width: '3px', height: '3px'}}></span>
                </div>
            </span>
            {isActive ?
                <>
                    <div style={{
                        position: 'absolute',
                        top: '40px',
                        background: '#FFFFFF',
                        boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
                        borderRadius: '4px',
                        minWidth: '190px',
                        padding: '10px',
                        right: 0,
                        zIndex: 60000
                    }}>
                        <Link to="/top-up-balance" style={{
                            display: 'flex',
                            textDecoration: 'none',
                            alignItems: 'center'
                        }}>
                            <img src={bankIconMini} alt=""/>
                            <span style={{color: '#666666'}}>{lang.top_up_balance}</span>
                        </Link>
                    </div>
                    <div onClick={() => { setIsActive(false) }}
                         style={{position: 'fixed', zIndex: 50000, width: '100%', height: '100vh', top: 0, left: 0}}></div>
                </>

                :
                ''
            }
        </div>
    )
}

export default HeaderHavbarAccountBalance