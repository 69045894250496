import React, {useEffect, useState} from 'react'
import GoogleMapStyled from "../styles/GoogleMapStyled";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
import ClusterMarker from "./ClusterMarker";
import {useDispatch, useSelector} from "react-redux";
import {changeField, searchVehicles} from "../redux/actions";
import supercluster from "points-cluster";
import filterTimeFormatted from "../services/filterTimeFormatted";
import moment from "moment";

const MapComponentInner = () => {

    const center = {lat: 52.07222855854113, lng: 6.902552998038657}
    const filter = useSelector(state => state.filter)
    const locationInfo = useSelector(state => state.locationInfo)
    const [vehicles, setVehicles] = useState([])
    const activeVans = useSelector(state => state.activeVans)

    const formatVanCoordinates = (vans) => {
        let coordinates = []

        coordinates = vans.map((item) => {
            return {
                id: item.id,
                lat: item.branch.coordinates.latitude,
                lng: item.branch.coordinates.longitude,
                address: item.branch.address,
                van: item
            }
        })

        return coordinates
    }

    const [mapOptions, setMapOptions] = useState({
        center: center,
        zoom: 8
    })

    const [clusters, setClusters] = useState([])
    const [vanCoordinates, setVanCoordinates] = useState([])

    const dispatch = useDispatch()

    const handleMapChange = ({center, zoom, bounds}) => {

        dispatch(
            changeField({
                field: 'map',
                value: {
                    zoom: zoom,
                    coords: center
                }
            })
        )

        setMapOptions({
            center,
            zoom,
            bounds,
        })
    }

    function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1);
        var a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c; // Distance in km
        return d;
    }

    function deg2rad(deg) {
        return deg * (Math.PI/180)
    }

    const createClusters = () => {

        const newClusters = mapOptions.bounds ?
            getClusters().map(({wx, wy, numPoints, points}) => ({
                lat: wy,
                lng: wx,
                numPoints,
                id: points[0].id,
                points,
            }))
            :
            []

        setClusters(newClusters)
    }

    const getClusters = () => {
        const clusters = supercluster(vanCoordinates, {
            minZoom: 0,
            maxZoom: 19,
            radius: 60,
        });

        return clusters(mapOptions);
    }

    useEffect(() => {
        createClusters()
    }, [mapOptions])

    useEffect(() => {
        createClusters()
    }, [vanCoordinates])

    const filterVans = (vans) => {

        let tempVans = vans

        if (filter.vehicles.length > 0) {
            tempVans = tempVans.filter((item) => {
                if (!filter.vehicles.includes(item.model.id)) return true
                return false
            })
        }

        if (filter.transmissions.length > 0) {
            tempVans = tempVans.filter((item) => {
                if (!filter.transmissions.includes(item.model.transmission)) return true
                return false
            })
        }

        if (filter.distance > 0) {
            tempVans = tempVans.filter((item) => {
                const res = getDistanceFromLatLonInKm(
                    locationInfo.coordinates.lat,
                    locationInfo.coordinates.lng,
                    item.branch.coordinates.latitude,
                    item.branch.coordinates.longitude)

                if (res <= filter.distance) return true
                return false
            })
        }

        dispatch(
            changeField({
                field: 'filterVans',
                value: {
                    total: vans.length,
                    filtered: tempVans.length
                }
            })
        )

        return tempVans
    }

    const updateActiveVans = (searchVans) => {

        const newActiveVans = []

        searchVans.forEach((item) => {
            activeVans.forEach((aItem) => {
                if (aItem.van.id == item.id) {
                    newActiveVans.push({
                        ...aItem,
                        van: {
                            ...aItem.van,
                            available: item.available
                        }
                    })
                }
            })
        })

        console.log('NEW ACTIVE VANS')
        console.log(newActiveVans)

        dispatch(
            changeField({
                field: 'activeVans',
                value: newActiveVans
            })
        )
    }

    useEffect(() => {
        const filterStart = filterTimeFormatted.apiFormat(filter.dateStart)
        const filterEnd = filterTimeFormatted.apiFormat(filter.dateEnd)

        dispatch(searchVehicles({
            apiToken: '',
            startDate: filterStart,
            endDate: filterEnd,
            onSuccess: (res) => {
                setVehicles(res.data.vehicles)
                updateActiveVans(res.data.vehicles)
            },
            onError: (err) => {
                console.log(err)
            }
        }))
    }, [filter.dateStart, filter.dateEnd])

    useEffect(() => {
        setVanCoordinates(
            formatVanCoordinates(
                filterVans(vehicles)
            )
        )
    }, [vehicles])

    useEffect(() => {
        setVanCoordinates(
            formatVanCoordinates(
                filterVans(vehicles)
            )
        )
    }, [filter])

    const mapZoom = useSelector(state => state.map)

    useEffect(() => {

        console.log(mapZoom)

        setMapOptions({
            ...mapOptions,
            zoom: mapZoom.zoom,
            center: mapZoom.coords
        })

    }, [mapZoom])

    return (
        <GoogleMapStyled>
            <GoogleMapReact
                defaultZoom={8}
                defaultCenter={center}
                zoom={mapZoom.zoom}
                center={mapZoom.coords}
                options={{
                    maxZoom: 19,
                    disableDefaultUI: true
                }}
                onChange={handleMapChange}
                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{key: 'AIzaSyB65u0DapLI8cWLbt0Cf2fxC9k9pUldB2w'}}
            >
                {clusters.map(item => {
                    if (item.numPoints === 1) {
                        // console.log(item)
                        return (
                            <Marker
                                key={item.id}
                                lat={item.points[0].lat}
                                lng={item.points[0].lng}
                                points={item.points}
                            />
                        );
                    } else {
                        return (
                            <ClusterMarker
                                key={item.id}
                                lat={item.lat}
                                lng={item.lng}
                                points={item.points}
                            />
                        )
                    }


                })}
            </GoogleMapReact>
        </GoogleMapStyled>
    )
}

export default MapComponentInner