import React, {useEffect, useState} from 'react'
import {Slider} from "@mui/material";
import styled from "styled-components"
import {useDispatch, useSelector} from "react-redux";
import {changeField} from "../redux/actions";

const FilterSliderItem = () => {

    const dispatch = useDispatch()
    const filter = useSelector(state => state.filter)
    const [sliderValue, setSliderValue] = useState(filter.distance)

    const onSliderChange = (event, newValue) => {
        setSliderValue(newValue)
    }

    useEffect(() => {
        dispatch(
            changeField({
                field: 'filter',
                value: {
                    ...filter,
                    distance: sliderValue
                }
            })
        )
    }, [sliderValue])

    return (
        <Slider style={{color: '#E9392C'}}
                defaultValue={0}
                max={20}
                value={filter.distance}
                onChange={onSliderChange}
                aria-label="Default"/>
    )
}

export default FilterSliderItem