import React, {useState} from 'react'
import {Grid} from "@mui/material"
import callCenterIcon from "../assets/contact_center.png"
import calculatorIcon from "../assets/calculator_icon.png"
import {useHistory} from "react-router-dom"
import Cookies from 'universal-cookie'
import AppPopup from "./AppPopup";
import {useDispatch, useSelector} from "react-redux";
import {useCookies} from "react-cookie";
import {showErrorMessage} from "../redux/actions";

const AppFooter = ({reservationActive}) => {

    const [cookies, setCookie, removeCookie] = useCookies(['reservation_vat_show'])
    const history = useHistory()
    const [reservationAlertActive, setReservationAlertActive] = useState(false)
    const lang = useSelector(state => state.lang)
    const isDocumentExpiredGlobal = useSelector(state => state.isDocumentExpiredGlobal)
    const futureReservations = useSelector(state => state.futureReservations)
    const activeReservation = useSelector(state => state.activeReservation)
    const dispatch = useDispatch()

    const onReservationClick = () => {

        if (futureReservations.length > 0 || activeReservation !== false) {
            dispatch(
                showErrorMessage({
                    title: '',
                    message: lang.you_have_active_reservation
                })
            )

            return
        }

        if (cookies.reservation_vat_show == 'true') {
            history.push('/manage-reservation')
        } else {
            setReservationAlertActive(true)
            setCookie('reservation_vat_show', true, {
                expires: new Date(Date.now() + 99990000000)
            })
        }
    }

    return (
        <Grid className="app-footer" item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
            <div style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%',
                marginBottom: '15px'
            }}>
                <div
                    onClick={() => {
                        history.push('/help')
                    }}
                    className="app-footer-btn">
                    <img style={{marginRight: '5px'}} src={callCenterIcon} alt=""/>
                    {lang.help}
                </div>
                <div
                    onClick={() => {
                        history.push('/calculator')
                    }}
                    className="app-footer-btn">
                    {lang.calculate}
                    <img style={{marginLeft: '5px'}} src={calculatorIcon} alt=""/>
                </div>
            </div>

            <div>
                {
                    !reservationActive && isDocumentExpiredGlobal === false ?
                        <div
                            style={
                                futureReservations.length > 0 || activeReservation !== false ?
                                    {backgroundColor: 'gray'}
                                    :
                                    {}
                            }

                            onClick={onReservationClick}
                            className="start-reservation-button">
                            {lang.add_reservation}
                        </div>
                        :
                        ''
                }
            </div>
            <AppPopup open={reservationAlertActive}
                      title=""
                      nextText={lang.ok}
                      handleNext={() => {
                          setReservationAlertActive(false);
                          history.push('/manage-reservation');
                      }}
                      description={`${lang.res_not_1}`}/>
        </Grid>
    )
}

export default AppFooter