import styled from "styled-components"

const OutlinedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FDFDFD;
  border: 1px solid #E9392C;
  border-radius: 20px;
  padding: 5px 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`

export default OutlinedButton