import './App.css';
import PageManager from "./components/PageManager";
import Preloader from "./components/Preloader";
import AppErrorMessage from "./components/AppErrorMessage";
import AppLoginPopup from "./components/AppLoginPopup";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import en from './languages/en'
import nl from './languages/nl'
import {
    changeField,
    editUserInformation, editUserLanguage,
    getClientInfo,
    getReservationList,
    getSubsidiaryInfo, getUserDocumentList,
    getUserInfo
} from "./redux/actions";
import axios from "axios";
import Routes from "./Routes";
import {BrowserRouter as Router, useHistory} from "react-router-dom";
import firebase from './firebase'
import PWAInstallComponent from "./components/PWAInstallComponent";
import {useCookies} from "react-cookie";
import AppPopup from "./components/AppPopup";
import GasolinePopup from "./components/GasolinePopup";
import PinValidateComponent from "./components/PinValidateComponent";
import { isMacOs, isIOS } from "react-device-detect"
import userDocumentValidator from "./services/userDocumentValidator";
import GlobalDocumentValidator from "./components/GlobalDocumentValidator";
import {blockNativeNavigation} from "./services/blockNativeNavigation";

function App() {

    const dispatch = useDispatch()
    const language = useSelector(state => state.language)
    const apiToken = useSelector(state => state.apiToken)
    const history = useHistory()
    const localApiToken = window.localStorage.getItem('apiToken')
    const apiTokenV3 = window.localStorage.getItem('apiTokenV3')
    const [cookies, setCookie, removeCookie] = useCookies(['push_enable'])
    const isPinSuccess = useSelector(state => state.isPinSuccess)

    let msg = false;

    if (isMacOs || isIOS) {
        console.log("push doesn't support")
    } else {
        msg = firebase.messaging();
    }

    useEffect(() => {
        if (apiToken) {
            dispatch(
                editUserLanguage({
                    apiToken,
                    language
                })
            )
        }
    }, [apiToken, language])

    useEffect(() => {
        if (apiToken) {

            if (cookies.push_enable == 'true') {

                if (msg !== false) {
                    msg.requestPermission()
                        .then(() => {
                            return msg.getToken();
                        })
                        .catch((err) => {
                            console.log('notification permission error')
                        })
                        .then((data) => {
                            console.log("token", data)

                            if (data) {
                                dispatch(
                                    editUserInformation({
                                        apiToken,
                                        pushToken: data
                                    })
                                )
                            }

                            msg.onMessage((payload) => {
                                console.log("PUSH: ", payload)
                            })

                        })
                }
            }
        }
    }, [apiToken])

    useEffect(() => {
        dispatch(
            getSubsidiaryInfo()
        )
    }, [])

    history.listen((location, action) => {
        dispatch(
            changeField({
                field: 'isSidebarActive',
                value: false
            })
        )
    })

    if (localApiToken) {
        dispatch(
            changeField({
                field: 'apiToken',
                value: localApiToken
            })
        )

        dispatch(
            changeField({
                field: 'apiTokenV3',
                value: apiTokenV3
            })
        )

    }

    useEffect(() => {

        if (apiToken && apiToken.length > 0) {
            dispatch(
                getUserInfo({
                    apiToken,
                    onError: () => {
                        window.localStorage.removeItem('apiToken')
                        document.location.href = '/'
                    }
                })
            )

            dispatch(
                getClientInfo({
                    apiToken
                })
            )

            //history.push('/dashboard')
        }
    }, [apiToken])

    // useEffect(() => {
    //     const userLang = navigator.language || navigator.userLanguage;
    //
    //     if (userLang === 'nl_NL' || userLang === 'nl') {
    //
    //         dispatch(changeField({
    //             field: 'language',
    //             value: 'nl'
    //         }))
    //
    //     } else {
    //         dispatch(changeField({
    //             field: 'language',
    //             value: 'en'
    //         }))
    //     }
    //
    // }, [])

    useEffect(() => {
        const localLanguage = window.localStorage.getItem('language')
        if (localLanguage) {
            dispatch(
                changeField({
                    field: 'language',
                    value: localLanguage
                })
            )
        }
    }, [])

    useEffect(() => {

        window.localStorage.setItem('language', language);

        if (language === 'en') {
            dispatch(changeField({
                field: 'lang',
                value: en
            }))
        }

        if (language === 'nl') {
            dispatch(changeField({
                field: 'lang',
                value: nl
            }))
        }

    }, [language])

    const subsidiaryInfo = useSelector(state => state.subsidiaryInfo)

    useEffect(() => {

        if (apiToken && subsidiaryInfo) {
            dispatch(
                getReservationList({
                    apiToken,
                    clientId: '',
                    status: '',
                    subsidiaryInfo,
                })
            )
        }

        const timer = setInterval(() => {
            if (apiToken && subsidiaryInfo) {
                dispatch(
                    getReservationList({
                        apiToken,
                        clientId: '',
                        status: '',
                        subsidiaryInfo,
                    })
                )
            }
        }, 300000)

        return () => {
            clearInterval(timer)
        }
    }, [apiToken, subsidiaryInfo])

    useEffect(() => {
        if (apiToken) {
            dispatch(
                getUserDocumentList({
                    apiToken,
                    onSuccess: (documents) => {
                        const validationResult = userDocumentValidator.validateExpirationDocuments(documents)

                        dispatch(
                            changeField({
                                field: 'userDocuments',
                                value: documents
                            })
                        )

                        if (validationResult !== true) {
                            dispatch(
                                changeField({
                                    field: 'isDocumentExpiredGlobal',
                                    value: {
                                        type: validationResult
                                    }
                                })
                            )
                        }
                    }
                })
            )
        }
    }, [apiToken])

    history.listen((location, action) => {
        if (location.pathname == '/dashboard' || location.pathname == '/profile/add-document' || location.pathname.startsWith('/end-reservation')) {
            console.log('block nav')
            window.history.pushState(null, document.title, window.location.href);
            window.addEventListener('popstate', blockNativeNavigation);
        } else {
            console.log('remove')
            window.removeEventListener('popstate', blockNativeNavigation)
        }
    })

    return (
        <>
            <AppErrorMessage/>
            <Preloader/>
            <Routes/>
            <PWAInstallComponent />
            <GasolinePopup />
            <GlobalDocumentValidator />
            {
                isPinSuccess ?
                    ''
                    :
                    <PinValidateComponent />
            }
        </>
    )
}

export default App
