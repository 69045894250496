import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import nagivateIcon from "../assets/navigateicon.png";
import OutlinedLink from "../styles/OutlinedLink";

const NavigateLink = ({van}) => {

    const lang = useSelector(state => state.lang)

    const [navigateUrl, setNavigateUrl] = useState('')

    useEffect(() => {
        onNavigate(van)
    }, [van])

    const onNavigate = (van) => {

        if (!navigator.geolocation) {
            setNavigateUrl(`https://maps.google.com/?q=${van.branch.coordinates.latitude},${van.branch.coordinates.longitude}`)
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                const {latitude, longitude} = position.coords
                setNavigateUrl(`https://maps.google.com/?saddr=${latitude},${longitude}&daddr=${van.branch.coordinates.latitude},${van.branch.coordinates.longitude}&dirflg=w`)
            }, (err) => {
                setNavigateUrl(`https://maps.google.com/?q=${van.branch.coordinates.latitude},${van.branch.coordinates.longitude}`)
            })
        }
    }

    return (
        <OutlinedLink>
            <a style={{maxWidth: '150px'}} target="_blank" href={navigateUrl}>
                <img style={{marginRight: '5px', width: '100%', height: 'auto'}}
                     src={nagivateIcon} alt=""/>
                {lang.navigate}
            </a>
        </OutlinedLink>

    )
}

export default NavigateLink