import React, {useEffect, useState} from 'react'
import filterTimeFormatted from "../services/filterTimeFormatted";
import OutlinedButton from "../styles/OutlinedButton";
import {
    actionReservationMockEdit,
    cancelReservation,
    editReservation,
    getClientInfo,
    preloaderStart,
    preloaderStop
} from "../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import AppPopup from "./AppPopup";
import {Link, useHistory} from "react-router-dom"
import AppPopupTermsOfUse from "./AppPopupTermsOfUse";

const FutureReservationItem = ({item, handleOnUpdateNeed}) => {

    const [isCancelActive, setIsCancelActive] = useState(false)

    const dispatch = useDispatch()
    const apiToken = useSelector(state => state.apiToken)
    const lang = useSelector(state => state.lang)
    const [cancellationPrice, setCancellationPrice] = useState('...')

    useEffect(() => {
        if (isCancelActive === true) {
            dispatch(actionReservationMockEdit({
                apiToken,
                reservationId: item.id,
                onSuccess: (res) => {
                    setCancellationPrice(res.data.total_price_including_vat.toFixed(2))
                }
            }))
        }
    }, [isCancelActive])

    const onCancelReservation = () => {

        setIsCancelActive(false)

        dispatch(
            editReservation({
                apiToken,
                reservationId: item.id,
                status: 'cancelled',
                onSuccess: (res) => {
                    handleOnUpdateNeed()
                }
            })
        )

        setTimeout(() => {
            dispatch(
                getClientInfo({
                    apiToken
                })
            )
        }, 1000)
    }

    const formatReservationTime = (timeObj) => {
        return `${lang.in} ${timeObj.days} d, ${timeObj.hours} h, ${timeObj.minutes} m`
    }

    const getTaxAmount = () => {



        const hours = filterTimeFormatted.getTimeDiffHours(Date.now(), item.planned_start_date)

        if (hours <= 24) {
            return '19.50'
        }

        return '5.00'
    }

    return (
        <>
            <div className="future-reservation-item">
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <p style={{fontSize: '14px', fontWeight: '600'}}>
                        {lang.reservation} #{item.id}
                    </p>
                    <p style={{fontSize: '14px'}}>
                        {
                            formatReservationTime(filterTimeFormatted
                                .getTotalTime(item.planned_start_date, Date.now()))
                        }
                    </p>
                </div>

                <hr style={{
                    border: '1px solid #F5F5F5',
                    marginBottom: '10px',
                    marginTop: '10px'
                }}/>

                <table>
                    <tbody>
                    <tr>
                        <td style={{fontSize: '14px'}}>{lang.license_plate}:</td>
                        <td style={{fontSize: '14px'}}>{item.vehicle.license_plate}</td>
                    </tr>
                    <tr>
                        <td style={{fontSize: '14px'}}>{lang.location}:</td>
                        <td style={{fontSize: '14px'}}>{item.pick_up_branch.address}</td>
                    </tr>
                    <tr>
                        <td style={{fontSize: '14px'}}>{lang.pick_up_date}:</td>
                        <td style={{fontSize: '14px'}}>{filterTimeFormatted.fromPhpToMomentJsFormat(item.planned_start_date)}</td>
                    </tr>
                    <tr>
                        <td style={{fontSize: '14px'}}>{lang.return_date}:</td>
                        <td style={{fontSize: '14px'}}>{filterTimeFormatted.fromPhpToMomentJsFormat(item.planned_end_date)}</td>
                    </tr>
                    </tbody>
                </table>

                <div className="future-reservation-buttons">
                    <OutlinedButton
                        onClick={() => setIsCancelActive(true)}
                        style={{color: '#E9392C'}}>{lang.cancel}</OutlinedButton>
                </div>
            </div>
            <AppPopupTermsOfUse
                closeText={`${lang.no}`}
                nextText={`${lang.yes}`}
                handleClose={() => {
                    setIsCancelActive(false)
                }}
                handleNext={onCancelReservation}
                open={isCancelActive}
                title={lang.cancel_reservation}
                description={cancellationPrice}
            />
        </>
    )
}

export default FutureReservationItem