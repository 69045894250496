import React, {useEffect, useState} from 'react'
import VanComponentStyled from "../styles/VanComponentStyled"
import busIcon from '../assets/bushome.png'
import homeIcon from '../assets/homeicon.svg'
import plateIcon from '../assets/dlp_min.png'
import fuelIcon from '../assets/fuelicon.png'
import transmissionIcon from '../assets/transmission.png'
import OutlinedButton from "../styles/OutlinedButton"
import nagivateIcon from '../assets/navigateicon.png'
import PrimaryButton from "../styles/PrimaryButton"
import rightIcon from "../assets/rightslidericon.svg"
import {useDispatch, useSelector} from "react-redux"
import {Swiper, SwiperSlide} from "swiper/react"
import SwiperCore, {Navigation} from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import VanComponentWrapper from "../styles/VanComponentWrapper"
import {changeField} from "../redux/actions"
import unvailableIcon from '../assets/unvailable.png'
import {useHistory} from "react-router-dom";
import filterTimeFormatted from "../services/filterTimeFormatted";
import moment from "moment";
import NavigateLink from "./NavigateLink";
import ReactGA from "react-ga";
import { v4 as uuidv4 } from 'uuid';

SwiperCore.use([Navigation])

const VanComponent = () => {

    const lang = useSelector(state => state.lang)
    const activeVans = useSelector(state => state.activeVans)

    const [localActiveVans, setLocalActiveVans] = useState(activeVans)

    useEffect(() => {
        const newVans = activeVans.sort((a, b) => {
            if (a.van.available == true) {
                return -1
            } else {
                return 1
            }
        })

        console.log('new active vans')
        console.log(newVans)

        setLocalActiveVans(newVans)
    }, [activeVans])

    const dispatch = useDispatch()
    const history = useHistory()

    const onCloseClick = () => {
        dispatch(
            changeField({
                field: 'activeVans',
                value: []
            })
        )
    }

    const onChooseVan = (van) => {

        dispatch(
            changeField({
                field: 'selectedVan',
                value: van
            })
        )

        history.push('/confirm-reservation')
    }

    const onNavigate = (van) => {

        if (!navigator.geolocation) {
            return `https://maps.google.com/?q=${van.branch.coordinates.latitude},${van.branch.coordinates.longitude}`
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                const {latitude, longitude} = position.coords
                return `https://maps.google.com/?saddr=${latitude},${longitude}&daddr=${van.branch.coordinates.latitude},${van.branch.coordinates.longitude}&dirflg=w`
            }, (err) => {
                return `https://maps.google.com/?q=${van.branch.coordinates.latitude},${van.branch.coordinates.longitude}`
            })
        }
    }

    // useEffect(() => {
    //     if (localActiveVans.length > 0) {
    //         let isAllVansReserved = true
    //
    //         localActiveVans.forEach((van) => {
    //             if (van.van.available == true) {
    //                 isAllVansReserved = false
    //             }
    //         })
    //     }
    // }, [localActiveVans])

    return (
        <VanComponentWrapper>

            <Swiper
                modules={[Navigation]}
                navigation={localActiveVans.length > 1}
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                {
                    localActiveVans.map((van) => {
                        return (
                            <SwiperSlide key={van.van.id}>
                                <VanComponentStyled>

                                    {
                                        van.van.available == false && van.van.occupied_at ?
                                            <div className="vanComponentUnavailable">
                                                <div>
                                                    <span>{lang.van_busy}</span>
                                                    <div>
                                                        <ul>
                                                            {
                                                                van.van.occupied_at ? van.van.occupied_at.map((item) => {
                                                                    return (
                                                                        <li>{filterTimeFormatted.visualFormat(moment(item.start))} - {filterTimeFormatted.visualFormat(moment(item.end))}</li>
                                                                    )
                                                                })
                                                                    :
                                                                    ''
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p>{lang.unavailable}</p>
                                            </div>
                                            :
                                            ''
                                    }

                                    {
                                        van.van.available == false && !van.van.occupied_at ?
                                            <div className="vanComponentUnavailable">
                                                <p style={{position: 'static', fontSize: '24px'}}>{lang.unavailable}</p>
                                            </div>
                                            :
                                            ''
                                    }

                                    <div className="vanComponentNavigation">
                                        <img src={rightIcon} alt=""/>
                                        <img src={rightIcon} alt=""/>
                                    </div>
                                    <span onClick={onCloseClick} className="vanComponentClose">&times;</span>
                                    <div className="vanComponentInformation">
                                        <div className="vanComponentImageWrapper">
                                            <div
                                                className="vanComponentImage"
                                                style={{backgroundImage: `url(${van.van.model.image ? van.van.model.image.thumb : busIcon})`}}
                                            />
                                        </div>
                                        <div className="vanComponentName">
                                            <p><b>{van.van.model.brand}</b> <span>{van.van.model.model}</span></p>
                                            <hr style={{
                                                width: '100%',
                                                height: '1px',
                                                backgroundColor: '#CFCFCF',
                                                marginTop: '6px',
                                                marginBottom: '10px'
                                            }}/>
                                        </div>
                                        <div className="vanComponentTechnicalInformation">
                                            <div className="vanComponentPlateIcon vanComponentTechnical"
                                                 style={{backgroundImage: `url(${plateIcon})`}}>
                                                <p>{van.van.license_plate}</p>
                                            </div>
                                            <div className="vanComponentTechnical">
                                                <img src={fuelIcon} alt=""/>
                                                <p>{van.van.model.fuel_type}</p>
                                            </div>
                                            <div className="vanComponentTechnical">
                                                <img src={transmissionIcon} alt=""/>
                                                <p>{van.van.model.transmission}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vanComponentButtons">
                                        <div>
                                            <div className="vanComponentAddress">
                                                <img src={homeIcon} alt=""/>
                                                <p>{van.van.branch.address}</p>
                                            </div>

                                            <NavigateLink van={van.van} />
                                        </div>
                                        <PrimaryButton
                                            onClick={() => onChooseVan(van.van)}
                                            style={{height: '45px', width: '144px'}}>
                                            <span style={{marginBottom: '3px'}}>{lang.continue_1}</span>
                                        </PrimaryButton>
                                    </div>
                                </VanComponentStyled>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </VanComponentWrapper>
    )
}

export default VanComponent