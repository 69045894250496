import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {showErrorMessage} from "../redux/actions";

const GlobalDocumentValidator = () => {

    const dispatch = useDispatch()
    const isDocumentExpiredGlobal = useSelector(state => state.isDocumentExpiredGlobal)
    const lang = useSelector(state => state.lang)

    useEffect(() => {
        if (isDocumentExpiredGlobal !== false) {
            dispatch(
                showErrorMessage({
                    title: '',
                    message: lang.document_expired_global.replace('{document}', isDocumentExpiredGlobal.type)
                })
            )
        }
    }, [isDocumentExpiredGlobal])

    return (
        false
    )
}

export default GlobalDocumentValidator