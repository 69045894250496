import React, {useEffect, useState} from 'react'
import {usePWAInstall} from 'react-use-pwa-install'
import {isMacOs, isIOS} from "react-device-detect"
import {browserName, CustomView} from 'react-device-detect'
import {useCookies} from "react-cookie";
import safari_share_icon from '../assets/safari_share_icon.svg'
import styled from "styled-components";
import ReactGA from "react-ga";
import {useSelector} from "react-redux";

const WrapperStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100px;
  background-color: rgba(29, 29, 27, .9);
  z-index: 100;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: white;
    font-size: 18px;
    line-height: 1.5rem;
    text-align: center;
    width: 100%;
  }

  button {
    padding: 10px 30px;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
  }

  @media (max-width: 800px) {

    padding: 10px 30px;
    text-align: center;

    p {
      font-size: 14px;
      line-height: 1.1rem;
    }

    span {
      right: -20px !important;
    }
  }
`

const PWAInstallComponent = () => {

    const lang = useSelector(state => state.lang)
    const install = usePWAInstall()
    const [isActive, setIsActive] = useState(true)
    const [cookies, setCookie, removeCookie] = useCookies(['pwa_showed', 'pwa_installed'])

    useEffect(() => {
        const pwaShowedCount = window.localStorage.getItem('pwa_count')

        if (pwaShowedCount && parseInt(pwaShowedCount) >= 3) {
            setCookie('pwa_showed', true, {
                maxAge: 99999999
            })
        } else {
            let showCount = 0

            if (pwaShowedCount) {
                showCount = parseInt(pwaShowedCount)
            }

            showCount = showCount + 1

            window.localStorage.setItem('pwa_count', showCount)
        }
    }, [])

    useEffect(() => {
        if (cookies.pwa_showed == 'true' || cookies.pwa_installed == 'true') {
            setIsActive(false)
        }
    }, [cookies])

    useEffect(() => {
        if (install === null) {
            if (browserName.toLowerCase().indexOf('safari') !== -1) {
                if (typeof window !== 'undefined' && window.navigator.standalone == true) {

                    setCookie('pwa_installed', true, {
                        maxAge: 99999999
                    })

                    setIsActive(false)
                } else {

                    if (cookies.pwa_showed == 'true' || cookies.pwa_installed == 'true') {
                        setIsActive(false)
                    } else {
                        setIsActive(true)
                    }

                }
            } else {

                if ((isMacOs || isIOS) && browserName.toLowerCase().indexOf('safari') === -1) {
                    setIsActive(false)
                } else {
                    if (cookies.pwa_installed == 'true' || cookies.pwa_showed == 'true') {
                        setIsActive(false)
                    } else {
                        setIsActive(true)
                    }
                }
            }
        } else {
            if (cookies.pwa_showed != 'true') {
                setIsActive(true)
            }
        }

        console.log(install)
    }, [install])

    const onInstallHandler = () => {
        install()
        setIsActive(false)
        setCookie('pwa_installed', true, {
            maxAge: 9999999999
        })

        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'pwa install'
        });
    }

    if (isActive) {

        console.log(browserName)
        if (typeof window !== 'undefined') {
            console.log(window.navigator.standalone)
        }

        return (
            browserName.toLowerCase().indexOf('safari') !== -1 ?
                <WrapperStyled>
                    <div style={{
                        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                        position: 'relative', width: '100%'
                    }}>
                <span
                    onClick={() => {
                        setIsActive(false)
                        setCookie('pwa_showed', true, {
                            maxAge: 86400
                        })
                    }}
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '-15px',
                        cursor: 'pointer',
                        color: 'white',
                        fontSize: '36px',
                    }}>&times;</span>
                        <p>{lang.pwa_1}</p>
                        <p>{lang.pwa_2}
                            <img style={{
                                maxWidth: '20px',
                                height: 'auto',
                                filter: 'invert(1)',
                                marginRight: '5px',
                                marginLeft: '5px'
                            }} src={safari_share_icon} alt=""/>
                            {lang.pwa_3}
                        </p>
                    </div>
                </WrapperStyled>
                :
                <WrapperStyled>
                    <div style={{
                        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                        position: 'relative', width: '100%'
                    }}>
                <span
                    onClick={() => {
                        setIsActive(false)
                        setCookie('pwa_showed', true, {
                            maxAge: 86400
                        })
                    }}
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '-15px',
                        cursor: 'pointer',
                        color: 'white',
                        fontSize: '36px',
                    }}>&times;</span>
                        <p>{lang.pwa_1} <br/> {lang.pwa_4}</p>
                        <button onClick={onInstallHandler} type="button">{lang.install}</button>
                    </div>
                </WrapperStyled>
        )
    } else {
        return false
    }
}

export default PWAInstallComponent