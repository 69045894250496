import React, {useEffect, useState} from 'react'
import {Grid} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import PageTitleStyled from "../styles/PageTitleStyled";
import {useDispatch, useSelector} from "react-redux";
import {getFullReservationList, getReservationList} from "../redux/actions";
import ReservationHistoryItem from "../components/ReservationHistoryItem";
import {Link} from "react-router-dom";

const ReservationHistoryPage = () => {

    const lang = useSelector(state => state.lang)
    const dispatch = useDispatch()
    const apiToken = useSelector(state => state.apiToken)
    const [reservations, setReservations] = useState([])
    const client = useSelector(state => state.client)

    useEffect(() => {
        if (apiToken) {
            dispatch(
                getFullReservationList({
                    apiToken,
                    clientId: client.id,
                    limit: 100,
                    onSuccess: (res) => {
                        setReservations(res)
                    }
                })
            )
        }
    }, [apiToken])

    return (
        <div className="app-page-wrapper">
            <Grid container spacing={2}>
                <AppReservationHeader isBackActive={true}/>

                <Grid item xs={12}>
                    <PageTitleStyled>{lang.res_history}</PageTitleStyled>
                </Grid>

                {
                    reservations.length === 0 ?
                       <Grid item xs={12}>
                           <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                               <p style={{fontFamily: 'Open Sans', textAlign: 'center'}}>
                                   {lang.res_h_1}
                                   <Link style={{fontWeight: 'bold', color: '#E9392C'}} to="/dashboard"> {lang.res_h_2}</Link>!
                               </p>
                           </Grid>
                       </Grid>
                        :
                        reservations.map((item) => {
                            return (
                                <Grid key={item.id} item xs={12}>
                                    <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                                        <ReservationHistoryItem key={item.id} reservation={item}/>
                                    </Grid>
                                </Grid>
                            )
                        })
                }

            </Grid>
        </div>
    )
}

export default ReservationHistoryPage