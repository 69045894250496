import React from 'react'
import styled from "styled-components"

const InputFieldStyled = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  height: 43px;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;

  img {
    position: absolute;
    left: 8px;
  }

  input {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    padding-left: 50px;
    padding-right: 40px;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
  }

  span {
    position: absolute;
    right: 10px;
  }
`

const InputField = ({icon, postfix, value, name, onChange, type = 'text', placeholder = ''}) => {
    return (
        <InputFieldStyled>
            <img src={icon} alt=""/>
            <input onChange={(e) => {
                onChange(name, e.target.value)
            }} value={value} type={type} placeholder={placeholder}/>
            <span>{postfix}</span>
        </InputFieldStyled>
    )
}

export default InputField