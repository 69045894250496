import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel} from "@mui/material";
import {useSelector} from "react-redux";

const FormControlLabelWrapper = styled.div`
  label > span:first-child {
    margin-right: 15px;
  }
`;

const CustomerConsent = ({open, handleClose, title, handleAgree}) => {

    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
    });

    const [isActive, setActive] = React.useState(true);

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
    };

    useEffect(() => {

        let active = true;

        Object.values(state).map((item) => {
            if (!item) {
                active = false;
            }
        });

        if (active) {
            setActive(false);
        } else {
            setActive(true);
        }
    }, [state, isActive]);

    const lang = useSelector(state => state.lang)

    return (

        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <FormControlLabelWrapper>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className="checkboxKav2go"
                                    checked={state.checkedA}
                                    onChange={handleChange}
                                    name="checkedA"
                                    color="primary"
                                />
                            }
                            label={lang.sign_1}
                        />
                    </FormControlLabelWrapper>
                    <FormControlLabelWrapper>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.checkedB}
                                    className="checkboxKav2go"
                                    onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={lang.sign_2}
                        />
                    </FormControlLabelWrapper>

                </DialogContent>
                <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button className="cancelButtonText" onClick={handleClose}>
                        {lang.cancel}
                    </Button>
                    <Button onClick={handleAgree}
                            disabled={isActive}
                            className="confirmConsentButton"
                            variant="contained" color="primary">
                        {lang.confirm}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CustomerConsent