const errorService = {
    translate: (errorCode, language) => {
        if (errorCode == 'rentPermissionCheckResult.noDateOfBirth') {
            return language.no_date_of_birth
        }

        if (errorCode == 'rentPermissionCheckResult.noDriversLicense') {
            return language.no_drivers_license
        }

        if (errorCode == 'rentPermissionCheckResult.driversLicenseNotChecked') {
            return language.driversLicenseNotChecked
        }

        if (errorCode == 'rentPermissionCheckResult.invalidDriversLicense') {
            return language.invalidDriversLicense
        }

        if (errorCode == 'amountDue') {
            return language.amountDue
        }

        if (errorCode == 'rentPermissionCheckResult.amountDue') {
            return language.amountDue
        }

        if (errorCode == 'rentPermissionCheckResult.invalidScreening') {
            return language.invalidScreening
        }

        return errorCode
    }
}

export default errorService