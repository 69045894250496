import React, {useEffect} from 'react'
import {useGeolocated} from "react-geolocated";
import {useDispatch, useSelector} from "react-redux";
import {changeField} from "../redux/actions";
import Cookies from "universal-cookie";

const GeolocationCheck = () => {

    const dispatch = useDispatch()

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });

    useEffect(() => {

        if (isGeolocationEnabled == false) {
            const cookies = new Cookies()
            cookies.set('geo_available', false)

            dispatch(
                changeField({
                    field: 'isGeoAvailable',
                    value: false
                })
            )
        }

        if (isGeolocationEnabled == true) {
            const cookies = new Cookies()
            cookies.set('geo_available', true)

            dispatch(
                changeField({
                    field: 'isGeoAvailable',
                    value: true
                })
            )

        }
    }, [isGeolocationEnabled])

    return (
        ''
    )
}

export default GeolocationCheck