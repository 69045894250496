import React, {useEffect, useState} from 'react'
import {Grid} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import PageTitleStyled from "../styles/PageTitleStyled";
import AddDocumentDLItem from "../components/AddDocumentDLItem";
import {useDispatch, useSelector} from "react-redux";
import {changeField, preloaderStop, showErrorMessage} from "../redux/actions";
import AddDocumentPSItem from "../components/AddDocumentPSItem";
import AddDocumentIdcardItem from "../components/AddDocumentIdcardItem";
import AddSimpleDocument from "../components/AddSimpleDocument";
import {useHistory} from "react-router-dom";
import BlockCameraPopup from "../components/BlockCameraPopup";

const AddDocumentPage = () => {

    const dispatch = useDispatch()
    const lang = useSelector(state => state.lang)

    const [isError, setIsError] = useState(false)
    const [isActive, setIsActive] = useState(false)

    useEffect(async () => {
        let stream = false

        try {
            stream = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {
                    width: 800,
                    height: 800,
                }
            })

            stream.getTracks()
                .forEach((track) => track.stop())
        } catch (e) {

            setIsActive(true)

            setIsError(true)
            dispatch(preloaderStop())
            return
        }
    }, [])

    const history = useHistory()

    // useEffect(() => {
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event) {
    //         window.history.pushState(null, document.title, window.location.href);
    //     });
    // }, [])

    return (
        <div className="app-page-wrapper">
            <Grid container spacing={2}>
                <AppReservationHeader onBackHandler={() => {
                    history.push('/profile')
                }} isBackActive={true}/>

                <Grid item xs={12}>

                    <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <Grid item xs={12}>
                            <PageTitleStyled>{lang.add_document}</PageTitleStyled>
                        </Grid>
                        <Grid style={{marginTop: '10px'}} item xs={12}>
                            {
                                !isError ?
                                    <AddDocumentDLItem/>
                                    :
                                    <div style={{position: 'relative'}} onClick={() => {setIsActive(true)}}>
                                        <div style={{opacity: '0.4', pointerEvents: 'none'}}>
                                            <AddDocumentDLItem/>
                                        </div>
                                    </div>
                            }
                        </Grid>
                        <Grid style={{marginTop: '10px'}} item xs={12}>
                            {
                                !isError ?
                                    <AddDocumentPSItem/>
                                    :
                                    <div style={{position: 'relative'}} onClick={() => {setIsActive(true)}}>
                                        <div style={{opacity: '0.4', pointerEvents: 'none'}}>
                                            <AddDocumentPSItem/>
                                        </div>
                                    </div>

                            }

                        </Grid>
                        <Grid style={{marginTop: '10px'}} item xs={12}>
                            {
                                !isError ?
                                    <AddDocumentIdcardItem/>
                                    :
                                    <div style={{position: 'relative'}} onClick={() => {setIsActive(true)}}>
                                        <div style={{opacity: '0.4', pointerEvents: 'none'}}>
                                            <AddDocumentIdcardItem/>
                                        </div>
                                    </div>
                            }

                        </Grid>
                        <Grid item xs={12} style={{marginTop: '10px'}}>
                            <AddSimpleDocument
                                title={`${lang.bank_statement}`}
                                type="bank_statement"
                            />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '10px'}}>
                            <AddSimpleDocument
                                title={`${lang.residence_permit}`}
                                type="residence_permit"
                            />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '10px'}}>
                            <AddSimpleDocument
                                title={`${lang.other}`}
                                type="other"
                            />
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>

            <BlockCameraPopup
                open={isActive}
                handleClose={() => {
                    setIsActive(false)
                }}
                closeText="test"
            />
        </div>
    )
}

export default AddDocumentPage