import React from 'react'
import PreloaderWrapper from "../styles/PreloaderWrapper";
import {useSelector} from "react-redux";

const Preloader = () => {

    const isPreloaderActive = useSelector(state => state.isPreloaderActive)
    const preloaderText = useSelector(state => state.preloaderText)

    if (isPreloaderActive) {

        return (
            <PreloaderWrapper>
                <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {
                    preloaderText.length > 0 ?
                        <div style={{
                            position: 'absolute',
                            zIndex: 1000000,
                            color: 'white',
                            fontSize: '21px',
                            fontWeight: 'bold',
                            backgroundColor: 'black'
                        }}>{preloaderText}</div>
                        :
                        ''
                }
            </PreloaderWrapper>
        )

    } else {
        return false
    }
}

export default Preloader