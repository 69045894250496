import React, {useState} from 'react';

import Marker from "./Marker";
import MarkerGroup from "../styles/MarkerGroup";
import MarkerCounter from "../styles/MarkerCounter";
import {changeField} from "../redux/actions";
import {useDispatch, useSelector} from "react-redux";

const ClusterMarker = ({points}) => {
    
    const [clusterFaceMarkers, setClusterFaceMarkers] = useState(points.slice(0,1))

    const dispatch = useDispatch()
    const mapZoom = useSelector(state => state.map.zoom)

    const onClick = () => {

        const addresses = []

        points.forEach((item) => {
            if (!addresses.includes(item.address)) {
                addresses.push(item.address)
            }
        })

        if (addresses.length > 1) {

            dispatch(
                changeField({
                    field: 'map',
                    value: {
                        zoom: mapZoom + 2,
                        coords: {lat: clusterFaceMarkers[0].lat, lng: clusterFaceMarkers[0].lng}
                    }
                })
            )

            dispatch(changeField({
                field: 'activeVans',
                value: []
            }))

        } else {
            dispatch(changeField({
                field: 'activeVans',
                value: points
            }))
        }

    }

    return (

        <MarkerGroup length={points.length}>
            {clusterFaceMarkers.map(marker =>
                <Marker
                    key={marker.id}
                    lat={marker.lat}
                    lng={marker.lng}
                    name={marker.id}
                    address={marker.address}
                    points={points}
                    inGroup
                />
            )}
            {points.length > 1 &&
                <MarkerCounter onClick={onClick}>
                    +{points.length}
                </MarkerCounter>}
        </MarkerGroup>
    )
}

export default ClusterMarker;
