import React, {useEffect, useState} from 'react'
import balanceIcon from "../assets/balance_icon.png"
import {Link, useHistory} from "react-router-dom";
import bankIconMini from '../assets/bankIconMini.png'
import {useSelector} from "react-redux";
import {useCookies} from "react-cookie";

const NavbarAccountBalance = () => {

    const [isActive, setIsActive] = useState(false)
    const client = useSelector(state => state.client)
    const [cookies, setCookie, removeCookie] = useCookies(['is_first_visit'])
    const lang = useSelector(state => state.lang)

    useEffect(() => {
        if (cookies.is_first_visit != 'true') {
            setIsActive(true)
            setCookie('is_first_visit', true, {
                expires: new Date(Date.now()+99990000000)
            })
        }
    }, [cookies])

    return (
        <div style={{position: 'relative'}}>
            <div onClick={() => {setIsActive(!isActive)}} className="navbar-account-balance-menu">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img style={{marginRight: '10px'}} src={balanceIcon} alt=""/>
                    <span style={{fontSize: '16px'}}>€ { client !== false ? parseFloat(client.contract.current_balance).toFixed(2) : '-' }</span>
                </div>
                <div className="navbar-account-balance-menu-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            {
                isActive ?
                    <div className="navbar-account-balance-submenu">
                        <Link to="/top-up-balance">
                            <img src={bankIconMini} alt=""/>
                            <span>{lang.top_up_balance}</span>
                        </Link>
                        <Link to="/reclaim-balance">
                            <img src={bankIconMini} alt=""/>
                            <span>{lang.reclaim_balance}</span>
                        </Link>
                    </div>
                    :
                    ''
            }

        </div>
    )
}

export default NavbarAccountBalance