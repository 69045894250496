import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useSelector} from "react-redux";

const PendingPopup = ({open, onCloseHandler}) => {

    const lang = useSelector(state => state.lang)

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => {
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="popup-content-wrapper"
                               style={{

                               }}
                >

                    <div style={{
                        width: '250px',
                        height: '30vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            background: 'transparent',
                            border: '5px solid #3458eb',
                            borderRadius: '50%',
                            width: '100px',
                            height: '100px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>

                            <div className="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>

                        </div>

                        <p style={{
                            fontFamily: 'Open Sans',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginTop: '10px'
                        }}>{lang.in_progress}</p>

                        <button type="button"
                                onClick={onCloseHandler}
                        style={{
                            fontFamily: 'Open Sans',
                            fontSize: '14px',
                            backgroundColor: 'white',
                            border: 'none',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            marginTop: '20px'
                        }}
                        >
                            {lang.cancel}
                        </button>
                    </div>

                </DialogContent>
            </Dialog>
        </div>
    )
}

export default PendingPopup