import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import TextStyled from "../styles/TextStyled";

const BottomNotificationStyled = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  height: 60px;
  width: 95%;
  background-color: #E9392C;
  border-radius: 6px;
  color: white;
  padding: 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`

const BottomNotification = ({description}) => {

    return (
        <BottomNotificationStyled>
            <TextStyled>
                {description}
            </TextStyled>

        </BottomNotificationStyled>
    )
}

export default BottomNotification