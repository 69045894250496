import styled from "styled-components"

const PrimaryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E9392C;
  border-radius: 20px;
  color: white;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  padding: 5px 20px;
  font-size: 14px;
  border: none;
  cursor: pointer;
`

export default PrimaryButton