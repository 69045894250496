import React, {useState} from 'react'
import styled from "styled-components";
import BoxWrapperStyled from "../styles/BoxWrapperStyled";
import ArticleIcon from "@mui/icons-material/Article";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DocumentDLPopup from "./DocumentDLPopup";
import {useDispatch} from "react-redux";
import {changeField} from "../redux/actions";
import DocumentPassportPopup from "./DocumentPassportPopup";
import DocumentIdCardPopup from "./DocumentIdCardPopup";
import DocumentBankStatementPopup from "./DocumentBankStatementPopup";

const DocumentItemStyled = styled.div`

  font-family: "Open Sans", sans-serif;

  .document-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const AddSimpleDocument = ({title, type}) => {

    const [isActive, setIsActive] = useState(false)

    const onOpenHandler = () => {
        setIsActive(true)
    }

    const onCloseHandler = () => {
        setIsActive(false)
    }

    return (
        <>
            <BoxWrapperStyled>
                <DocumentItemStyled>
                    <div className="document-item-header" onClick={onOpenHandler}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <ArticleIcon style={{
                                color: '#E9392C',
                                fontSize: '48px',
                                marginRight: '5px'
                            }}/>
                            <div>
                                <p style={{fontSize: '16px'}}>
                                    {title}
                                </p>
                            </div>
                        </div>
                        <AddCircleOutlineOutlinedIcon style={{color: '#E9392C'}}/>
                    </div>
                </DocumentItemStyled>
            </BoxWrapperStyled>
            <DocumentBankStatementPopup title={title}
                                        type={type}
                                        onCloseHandler={onCloseHandler} isActive={isActive} />
        </>
    )
}

export default AddSimpleDocument