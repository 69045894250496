import React from 'react'
import styled from "styled-components";

const ReservationHistoryStatusStyled = styled.div`
  padding: 3px 10px;
  border: 1px solid #E9392C;
  border-radius: 4px;
  color: #E9392C;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  text-transform: capitalize;
`

const ReservationHistoryStatus = ({status}) => {
    if (status == 'expired' || status == 'cancelled') {
        return (
            <ReservationHistoryStatusStyled>
                {status}
            </ReservationHistoryStatusStyled>
        )
    } else {
        return false
    }

}

export default ReservationHistoryStatus