import React from 'react'
import PinCodeComponent from "./PinCodeComponent";
import {useDispatch, useSelector} from "react-redux";
import {changeField} from "../redux/actions";

const PinValidateComponent = () => {

    const lang = useSelector(state => state.lang)
    const dispatch = useDispatch()

    const onSuccess = (pin) => {
        const currentPin = window.localStorage.getItem('pin')
        const pinString = pin.join('')

        if (currentPin == pinString) {

            dispatch(
                changeField({
                    field: 'isPinActive',
                    value: true
                })
            )

            dispatch(
                changeField({
                    field: 'isPinSuccess',
                    value: true
                })
            )
        }
    }

    if (window.localStorage.getItem('pin_setup') == 'true') {
        return (
            <div>
                <PinCodeComponent title={`${lang.enter_correct_pin_code}`} onSuccess={onSuccess} />
            </div>
        )
    } else {
        return false
    }


}

export default PinValidateComponent