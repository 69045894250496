import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

export default function AppPopupV2({
                                       open,
                                       handleClose = false,
                                       title,
                                       description = '',
                                       closeText = 'close',
                                       handleNext = false,
                                       nextText = 'yes',
                                       needLink = false,
                                       link = false,
                                       extraStyle = {textAlign: 'center'}
                                   }) {

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose ? handleClose : () => {
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className="popup-title"
                             style={{textAlign: 'center', position: 'relative', overflow: 'hidden'}}>
                    {title}

                    {
                        handleClose ?
                            <Button
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '-12px'
                                }}
                                className="cancelButtonText" onClick={handleClose} color="primary">
                                <span style={{lineHeight: '20px', fontSize: '42px', color: '#E9392C'}}>&times;</span>
                            </Button>
                            :
                            ''
                    }
                </DialogTitle>
                <DialogContent className="popup-content-wrapper">

                    <DialogContentText
                        style={extraStyle}
                        dangerouslySetInnerHTML={{__html: description}}>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{display: 'flex', width: '100%', justifyContent: 'center'}}>

                    {handleNext ?

                        needLink ?
                            <a className="nextButtonText"
                               style={{
                                   minWidth: '100px',
                                   textDecoration: 'none',
                                   padding: '10px 20px',
                                   textAlign: 'center'
                               }}
                               target="_blank" href={link} onClick={handleNext}>
                                {nextText}
                            </a>
                            :
                            <Button className="nextButtonText"
                                    style={{minWidth: '100px'}}
                                    onClick={handleNext}
                                    variant="contained"
                                    color="primary">
                                {nextText}
                            </Button>
                        :
                        false
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}