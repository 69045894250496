import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material"
import {BrowserView, MobileView, isBrowser, isMobile} from 'react-device-detect'
import {useSelector} from "react-redux";

export default function BlockCameraPopup({
                                     open,
                                     handleClose = false,
                                     title,
                                     description = '',
                                     closeText = 'close',
                                     handleNext = false,
                                     nextText = 'yes'
                                 }) {

    const lang = useSelector(state => state.lang)

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose ? handleClose: () => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className="popup-title" style={{textAlign: 'center'}}>{lang.video_perm_3}</DialogTitle>
                <DialogContent className="popup-content-wrapper">
                    <DialogContentText style={{textAlign: 'center', color: '#E9392C', fontWeight: 'bold'}}>
                        {
                            !isMobile ?
                                <p>{lang.video_perm_1}</p>
                                :
                                <p>{lang.video_perm_2}</p>
                        }
                        <p style={{marginTop: '10px'}}>
                            <a
                                style={{color: 'gray'}}
                                href="https://kav2go.nl/how-to-grant-the-necessary-permissions-in-the-browser/" target="_blank">
                                {lang.how_to_give_permissions}
                        </a></p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={ handleClose && handleNext ? {display: 'flex', flexDirection: 'column',
                        justifyContent: 'space-between'} :
                    {display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>

                    {
                        handleClose ?
                            <Button className="nextButtonText"
                                    style={{minWidth: '150px'}}
                                    onClick={handleClose}
                                    variant="contained"
                                    color="primary">
                                {lang.close}
                            </Button>
                            :
                            ''
                    }

                    <p style={{marginTop: '10px', textAlign: 'center'}} dangerouslySetInnerHTML={{__html: lang.call_us_12}}></p>

                    {handleNext ?
                        (
                            <Button className="nextButtonText"
                                    onClick={handleNext}
                                    variant="contained"
                                    color="primary">
                                {nextText}
                            </Button>
                        )
                        :
                        false
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}