import React, {useEffect} from 'react'
import {Button, Checkbox, Grid} from "@mui/material"

import {useDispatch, useSelector} from "react-redux"
import {changeField, changePage, makeRegistration} from "../redux/actions"
import {UserModel} from '../models/UserModel'

import AppHeader from "../components/AppHeader"
import ReactGA from "react-ga";
import {useHistory} from "react-router-dom";

const SingYourContractPage = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const curState = useSelector(state => state)

    const handleNextStep = (e) => {

        const user = UserModel.createFromState(curState)

        dispatch(makeRegistration({
            userObject: user,
            onApiToken: (apiToken) => {

                // dispatch(changePage({pageName: 'done-page'}))

                window.localStorage.setItem('apiToken', apiToken)

                setTimeout(() => {
                    dispatch(changeField({
                        field: 'apiToken',
                        value: apiToken
                    }))

                    history.push('/dashboard')
                }, [500])
            }
        }))
    }

    const lang = useSelector(state => state.lang)

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'Sing contract page'
        });
    }, [])

    return (
        <Grid container spacing={2}
              style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4px'}}>
            <AppHeader/>

            <Grid className="containerAlignBottom" container item spacing={2} xs={11}>
                <Grid style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} item xs={12}>
                    <h1 className="secondH1Title">{lang.sign_your_new_contract}</h1>

                    <Grid
                        style={{marginTop: '10px'}}
                        container item spacing={2} md={6} xs={12}>
                        <Grid className="contact-item" item xs={12}>
                            <Checkbox
                                value="checkedA"
                                checked={true}
                                color="default"
                            />
                            <p>
                                {lang.agree_1}
                                <a href="https://kav2go.nl/wp-content/uploads/2022/08/Terms-and-conditions.pdf">{lang.read_terms_1}</a>
                            </p>
                        </Grid>
                        <Grid className="contact-item" md={6} item xs={12}>
                            <Checkbox
                                value="checkedA"
                                checked={true}
                                color="default"
                            />
                            <p>
                                {lang.agree_2}
                                <a
                                    target="_blank"
                                    href="https://kav2go.nl/wp-content/uploads/2022/08/House-rules.pdf">
                                    {lang.read_terms_1}
                                </a>
                            </p>
                        </Grid>
                        <Grid className="contact-item" item xs={12}>
                            <Checkbox
                                value="checkedA"
                                checked={true}
                                color="default"
                            />
                            <p>
                                {lang.agree_3}
                                <a
                                    target="_blank"
                                    href="https://kav2go.nl/wp-content/uploads/2022/08/customer-contract.pdf">
                                    {lang.read_terms_1}
                                </a>
                            </p>
                        </Grid>
                        <Grid className="contact-item" item xs={12}>
                            <Checkbox
                                value="checkedA"
                                checked={true}
                                color="default"
                            />
                            <p>
                                {lang.agree_4}
                                <a target="_blank" href="https://kav2go.nl/wp-content/uploads/2022/08/Procedure-description.pdf">
                                    {lang.read_terms_1}
                                </a>
                            </p>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid
                    style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center'}}
                    item xs={12}>
                    <Button
                        className="main-button main-button-register"
                        style={{marginBottom: '20px'}}
                        data-disabled={false}
                        onClick={handleNextStep}
                        variant="contained">{lang.continue}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SingYourContractPage
