import {applyMiddleware, createStore, compose} from "redux"
import thunk from "redux-thunk"

import reducer from './redux/reducer'

const store = createStore(reducer, compose(applyMiddleware(thunk),  typeof window === "object" &&
typeof window.devToolsExtension !== "undefined" ?
    window.devToolsExtension() :
    f => f) )

export default store