import React, {useEffect, useRef, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {Grid} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import PageTitleStyled from "../styles/PageTitleStyled";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import styled from 'styled-components'
import {useDispatch, useSelector} from "react-redux";
import {createNewDamageReport, getReservationInformation} from "../redux/actions";

const CameraStyled = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 340px;
  width: 100%;
  height: 180px;
`

const SelectStyled = styled.div`
  select {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    border: none;
    background-color: #fff;
  }
`

const TextAreaStyled = styled.div`
    textarea {
      font-family: "Open Sans", sans-serif;
      width: 100%;
      height: 130px;
      border: 1px solid #D7D7D7;
      border-radius: 6px;
      resize: none;
      padding: 5px;
      font-size: 16px;
    }
`

const AddDamageReportPage = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const apiToken = useSelector(state => state.apiToken)
    const {id, reservationId} = useParams()
    const lang = useSelector(state => state.lang)

    const [isValid, setIsValid] = useState(false)
    const imageRef = useRef(null)
    const [image, setImage] = useState({
        mimeType: '',
        data: '',
        base64: ''
    })
    const [description, setDescription] = useState('')
    const [type, setType] = useState('')

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const [reservation, setReservation] = useState(false)

    useEffect(() => {
        if (id && reservationId) {
            dispatch(
                getReservationInformation({
                    apiToken,
                    reservationId,
                    onSuccess: (res) => {
                        setReservation(res)
                    }
                })
            )
        }
    }, [id, reservationId])

    const onChangeImage = (e) => {

        if (e.target.files) {
            const file = e.target.files[0]
            const type = file.type

            convertBase64(file).then((base64) => {
                let correctBase64 = base64.replace('data:image/jpeg;base64,', '')
                correctBase64 = correctBase64.replace('data:image/png;base64,', '')

                setImage({
                    mimeType: type,
                    data: correctBase64,
                    base64: base64
                })
            })
        }
    }

    useEffect(() => {
        if (image.base64.length > 0 && description.length > 0 && type.length > 0) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [image, description, type])

    const onSendHandler = () => {
        dispatch(
            createNewDamageReport({
                apiToken,
                vehicleId: id,
                reservationId,
                photo: {
                    data: image.data,
                    mime_type: image.mimeType
                },
                location: type,
                description,
                onSuccess: (res) => {
                    history.push(`/damage-report/${id}/${reservationId}`)
                }
            })
        )
    }

    return (
        <div className="app-page-wrapper">

            <input onChange={onChangeImage} style={{display: 'none'}} ref={imageRef} type="file" accept="image/*" capture="filesystem" />

            <Grid container spacing={2}>
                <AppReservationHeader isBackActive={true}/>

                <Grid item xs={12}>
                    <PageTitleStyled>{lang.report_a_new_damage}</PageTitleStyled>
                    <PageTitleStyled style={{color: 'black', textTransform: 'uppercase'}}>
                        {
                            reservation ?
                                reservation.vehicle.license_plate
                                :
                                ''
                        }
                    </PageTitleStyled>
                </Grid>

                <Grid
                    onClick={() => {imageRef.current.click()}}
                    style={{display: 'flex', justifyContent: 'center'}} item xs={12}>
                    <CameraStyled>
                        {
                            image.data.length > 0 ?
                                <img style={{maxWidth: '100%', width: 'auto', height: 'auto', maxHeight: '100%'}} src={image.base64} alt=""/>
                                :
                                <CameraAltIcon style={{color: '#E9392C', fontSize: '68px'}}/>
                        }
                    </CameraStyled>
                </Grid>

                <Grid item xs={12}>
                    <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <SelectStyled>
                            <select style={{color: type == '' ? 'tomato': 'black'}} value={type} onChange={(e) => {setType(e.target.value)}}>
                                <option value="">{lang.select_location}*</option>
                                <option value="left_front">{lang.left_front}</option>
                                <option value="right_front">{lang.right_front}</option>
                                <option value="hood">{lang.hood}</option>
                                <option value="roof">{lang.roof}</option>
                                <option value="door_left_front">{lang.door_left_front}</option>
                                <option value="door_left_back">{lang.door_left_back}</option>
                                <option value="door_right_front">{lang.door_right_front}</option>
                                <option value="door_right_back">{lang.door_right_back}</option>
                                <option value="left_back">{lang.left_back}</option>
                                <option value="right_back">{lang.right_back}</option>
                                <option value="trunk">{lang.trunk}</option>
                                <option value="other">{lang.other}</option>
                            </select>
                        </SelectStyled>
                    </Grid>

                </Grid>

                <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                    <TextAreaStyled>
                        <textarea value={description} onChange={(e) => {setDescription(e.target.value)}}
                                  placeholder={`${lang.description}`} />
                    </TextAreaStyled>
                </Grid>

                {
                    isValid ?
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <div onClick={onSendHandler} style={{width: '300px'}} className="start-reservation-button">
                                {lang.upload_damage_report}
                            </div>
                        </Grid>
                        :
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{width: '300px', backgroundColor: 'gray', opacity: '0.6'}} className="start-reservation-button">
                                {lang.upload_damage_report}
                            </div>
                        </Grid>
                }

            </Grid>
        </div>
    )
}

export default AddDamageReportPage