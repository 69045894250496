import React, {useEffect, useState} from 'react'
import {Grid} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import PageTitleStyled from "../styles/PageTitleStyled";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {actionGetTextUrl} from "../redux/actions";

const TextStyled = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #666666;
  
  strong {
    margin-bottom: 5px;
    margin-top: 5px;
    display: inline-block;
  }
  
  @media (min-width: 1000px) {
    
    p {
      font-size: 16px !important; 
    }
  }
`

const TermsOfUsePage = () => {

    const dispatch = useDispatch()
    const [text, setText] = useState('')
    const language = useSelector(state => state.language)
    const lang = useSelector(state => state.lang)

    useEffect(() => {
        dispatch(
            actionGetTextUrl({
                apiToken: '',
                key: 'terms_of_use__main',
                onSuccess: (res) => {
                    setText(res.data[language])
                }
            })
        )
    }, [language])

    return (
        <div className="app-page-wrapper">
            <Grid container spacing={2}>
                <AppReservationHeader isBackActive={true}/>

                <Grid item xs={12}>
                    <PageTitleStyled>{lang.terms_of_use}</PageTitleStyled>
                </Grid>

                <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                    <TextStyled>
                        <div style={{fontSize: '14px'}} dangerouslySetInnerHTML={{__html: text.terms_of_use__main}} />
                    </TextStyled>
                    <a
                        style={{
                            color: 'black',
                            textAlign: 'center',
                            display: 'block',
                            marginTop: '10px'
                        }}
                        href="tel:+31880883434">{lang.call_us_1}</a>
                </Grid>

                <Grid item xs={12}>
                    <div style={{
                        margin: '0 auto',
                        backgroundColor: '#E9392C',
                        width: '100%',
                        bottom: '0',
                        left: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px',
                        marginTop: '10px'
                    }}>
                        <p style={{fontSize: '16px', color: 'white', fontWeight: 'bold', textAlign: 'center', lineHeight: '1.5rem'}}>
                            {lang.t_p_1}
                        </p>
                        <p style={{fontSize: '16px', color: 'white', fontWeight: 'bold', textAlign: 'center', lineHeight: '1.5rem'}}>
                            {lang.t_p_2}
                        </p>
                        <p style={{fontSize: '16px', color: 'white', fontWeight: 'bold', textAlign: 'center', lineHeight: '1.5rem'}}>
                            {lang.t_p_3}
                        </p>
                    </div>

                </Grid>

            </Grid>
        </div>
    )
}

export default TermsOfUsePage