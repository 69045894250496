import React, {useEffect, useState} from 'react'
import {FormControlLabel, Grid, Radio, TextField} from "@mui/material";
import AppReservationHeader from "../components/AppReservationHeader";
import PageTitleStyled from "../styles/PageTitleStyled";
import {useDispatch, useSelector} from "react-redux";
import {changeField} from "../redux/actions";
import {useHistory} from "react-router-dom";

const TopUpBalancePage = () => {

    const lang = useSelector(state => state.lang)
    const [paymentType, setPaymentType] = useState('200payment')
    const [amount, setAmount] = useState()
    const [resultPayment, setResultPayment] = useState(0)
    const client = useSelector(state => state.client)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0,0)
        }, 1000)
    }, [])

    useEffect(() => {
        if (paymentType == '200payment') {
            setResultPayment(200)
        }

        if (paymentType == '100payment') {
            setResultPayment(100)
        }
    }, [paymentType])

    useEffect(() => {
        if (paymentType == 'customPayment') {
            setResultPayment(amount)
        }
    }, [amount, paymentType])

    const onPayHandler = () => {
        dispatch(
            changeField({
                field: 'payment',
                value: {
                    amount: parseFloat(resultPayment),
                    active: true,
                    information: false
                }
            })
        )

        history.push('/payment')
    }

    return (
        <div className="app-page-wrapper">
            <Grid container spacing={2} style={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '96vh'
            }}>

                <Grid item xs={12}>
                    <AppReservationHeader isBackActive={true}/>

                    <Grid item xs={12} style={{marginTop: '16px'}}>
                        <PageTitleStyled>{lang.payment}</PageTitleStyled>
                    </Grid>

                    <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <Grid item xs={12} style={{marginTop: '16px'}}>
                            <p>{lang.select_payment_amount}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: '16px'}}>
                            <FormControlLabel
                                style={{
                                    color: '#666666', fontSize: '16px',
                                }}
                                value="200payment"
                                onClick={() => {
                                    setPaymentType('200payment')
                                }}
                                checked={paymentType == '200payment' ? true : false}
                                control={<Radio style={{color: '#E9392C'}}/>}
                                label={`€ 200`}/>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: '8px'}}>
                            <FormControlLabel
                                style={{
                                    color: '#666666', fontSize: '16px',
                                }}
                                value="100payment"
                                onClick={() => {
                                    setPaymentType('100payment')
                                }}
                                checked={paymentType == '100payment' ? true : false}
                                control={<Radio style={{color: '#E9392C'}}/>}
                                label={`€ 100`}/>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: '8px'}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <FormControlLabel
                                        style={{
                                            color: '#666666', fontSize: '16px',
                                        }}
                                        value="customPayment"
                                        onClick={() => {
                                            setPaymentType('customPayment')
                                        }}
                                        checked={paymentType == 'customPayment' ? true : false}
                                        control={<Radio style={{color: '#E9392C'}}/>}
                                        label={`€`}/>

                                    <div style={{width: '150px'}}>
                                        <TextField
                                            onClick={() => {setPaymentType('customPayment')}}
                                            name="number"
                                            type="number"
                                            autoComplete="off"
                                            value={amount}
                                            onChange={(e) => {
                                                setAmount(e.target.value)
                                            }}
                                            className="zipCode"
                                            label={`${lang.amount}`}
                                            placeholder="0"
                                            variant="outlined"/>
                                    </div>
                                </div>

                                <p style={{fontSize: '14px', marginLeft: '10px'}}>
                                    {lang.balance_is} <b>€
                                    {client !== false ? parseFloat(client.contract.current_balance).toFixed(2) : '-'}</b></p>
                            </div>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{
                    display: 'flex',
                    alignItems: 'flex-start'
                }}>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        {
                            resultPayment > 0 ?
                                <div onClick={onPayHandler} className="start-reservation-button">
                                    {lang.pay} € {resultPayment}
                                </div>
                                :
                                <div style={{backgroundColor: 'gray'}} className="start-reservation-button">
                                    {lang.pay}
                                </div>
                        }
                    </Grid>
                </Grid>

            </Grid>
        </div>
    )
}

export default TopUpBalancePage