const findError = (fieldName, errors) => {
    let isError = false

    errors.forEach((item) => {
        if (item.type === fieldName) {
            isError = true
        }
    })

    return isError
}

export {findError}