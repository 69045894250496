import React, {useEffect, useState} from 'react'
import {Button, Checkbox, FormControlLabel, Grid,TextField} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {changeField, changePage} from "../redux/actions"
import AppHeader from "../components/AppHeader"
import AppPopup from "../components/AppPopup";
import AppPopupErrors from "../components/AppPopupErrors";
import checkPasswordStrength from "../services/checkPassword"
import checkedIcon from '../assets/checked.svg'
import ReactGA from "react-ga";

const CredentialsPage = () => {

    const [isAlertShow, setIsAlertShow] = useState(false)

    const dispatch = useDispatch()

    const need24password = useSelector(state => state.need24password)
    const password = useSelector(state => state.password)
    const repassword = useSelector(state => state.repassword)

    const [errors, setErrors] = useState([])
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    const [isApproveOpen, setIsApproveOpen] = useState(false)
    const [pasPower, setPasPower] = useState(0)

    const handleNextStep = (e) => {

        const isDisabled = e.target.getAttribute('data-disabled')

        if (isDisabled === 'true') {
            if (!need24password) {
                setErrors([
                    {type: 'password', value: '123', message: lang.pas_error}
                ])
                setIsAlertShow(true)
            }
        } else {
            setIsApproveOpen(true)
        }
    }

    const handleChangeCountry = (e) => {

        dispatch(
            changeField({
                field: e.target.name,
                value: e.target.value
            })
        )
    }

    useEffect(() => {
        const pow = checkPasswordStrength(password).score

        if (pow >= 40 && password === repassword) {
            setIsButtonDisabled(false)
        } else {
            setIsButtonDisabled(true)
        }

        setPasPower(pow)
    }, [password, repassword])

    useEffect(() => {
        if (need24password === true) {
            setIsButtonDisabled(false)
        } else {

            const pow = checkPasswordStrength(password).score

            if (pow >= 40 && password === repassword) {
                setIsButtonDisabled(false)
            } else {
                setIsButtonDisabled(true)
            }
        }
    }, [need24password])

    const handleAlertClose = () => {
        setIsAlertShow(false)
    }

    const handleApproveClose = () => {
        setIsApproveOpen(false)
    }

    const goToNextHandler = () => {
        dispatch(changePage({pageName: 'sign-your-contract-page'}))
    }

    const onChangeInvoice = () => {
        dispatch(changeField({
            field: 'need24password',
            value: !need24password
        }))
    }

    const lang = useSelector(state => state.lang)

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'Credentials Page'
        });
    }, [])

    return (
        <Grid container spacing={2}
              style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4px'}}>
            <AppHeader/>

            <AppPopup title={lang.is_info_correct}
                      open={isApproveOpen}
                      handleClose={handleApproveClose}
                      description=""
                      nextText={lang.yes}
                      closeText={lang.no}
                      handleNext={goToNextHandler}
            />

            <AppPopupErrors title={lang.error}
                            open={isAlertShow}
                            closeText={lang.cancel}
                            handleClose={handleAlertClose}
                            errors={errors}
            />

            <Grid className="containerAlignBottom" container item spacing={2} xs={11}>
                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} item xs={12}>
                    <h1 className="secondH1Title">{lang.credentials}</h1>

                    {
                        !need24password ?
                            <Grid style={{marginTop: '10px'}} container item spacing={2} xs={12}>

                                {
                                    !need24password ?
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{width: '100%'}}
                                                name="password"
                                                type="password"
                                                value={password}
                                                label={lang.password}
                                                onChange={handleChangeCountry}
                                                variant="outlined"/>
                                        </Grid>
                                        :
                                        ''
                                }

                                {
                                    pasPower < 40 && !need24password ?
                                        <Grid container item spacing={2} xs={12}>
                                            <Grid item xs={12}>
                                                <ul className="password-list">
                                                    {
                                                        pasPower < 20 ?
                                                            <li><img src={checkedIcon} alt=""/>
                                                                {lang.pas_req_1}</li>
                                                            :
                                                            ''
                                                    }

                                                    {
                                                        pasPower < 40 ?
                                                            <li><img src={checkedIcon} alt=""/>
                                                                {lang.pas_req_2}</li>
                                                            :
                                                            ''
                                                    }

                                                </ul>
                                            </Grid>
                                        </Grid>
                                        :
                                        ''
                                }

                                {
                                    !need24password ?
                                        <Grid container item spacing={2} xs={12}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="repassword"
                                                    type="password"
                                                    value={repassword}
                                                    onChange={handleChangeCountry}
                                                    style={{width: '100%'}}
                                                    label={lang.repeat_password} variant="outlined"/>
                                            </Grid>
                                        </Grid>
                                        :
                                        ''
                                }

                            </Grid>
                            :
                            ''
                    }

                    <Grid style={{marginTop: '10px'}} container item spacing={2} xs={12}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox onChange={onChangeInvoice}
                                                   name="need24password"
                                                   checked={need24password}
                                                   className="kav2go-checkbox-style"/>}
                                label={lang.set_24_password} />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} item xs={12}>
                    <Button
                        className="main-button main-button-register"
                        style={{marginBottom: '20px'}}
                        data-disabled={isButtonDisabled}
                        onClick={handleNextStep}
                        variant="contained">{lang.continue}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CredentialsPage