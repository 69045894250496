import React, {useEffect, useState} from 'react'
import {Grid} from "@mui/material"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import FutureReservationListStyled from "../styles/FutureReservationListStyled";
import filterTimeFormatted from "../services/filterTimeFormatted";
import {cancelReservation, changeField, getReservationList, preloaderStop} from "../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import OutlinedButton from "../styles/OutlinedButton";
import AppPopup from "./AppPopup";
import FutureReservationItem from "./FutureReservationItem";
import moment from "moment";

const FutureReservations = () => {

    const [isActive, setIsActive] = useState(true)

    const dispatch = useDispatch()
    const client = useSelector(state => state.client)
    const apiToken = useSelector(state => state.apiToken)
    const subsidiaryInfo = useSelector(state => state.subsidiaryInfo)
    const futureReservations = useSelector(state => state.futureReservations)
    const lang = useSelector(state => state.lang)

    const getData = () => {
        dispatch(
            getReservationList({
                apiToken,
                clientId: '',
                status: '',
                subsidiaryInfo,
            })
        )
    }

    useEffect(() => {
        if (client) {
            getData()
        }
    }, [client])

    const handleOnUpdateNeed = () => {
        getData()
    }

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         getData()
    //     }, 300000)
    //
    //     return () => {
    //         clearInterval(timer)
    //     }
    // }, [])

    useEffect(() => {

        let activeReservation = false
        const currentDate = moment(new Date())

        futureReservations.forEach((item) => {
            const dateStart = moment(item.planned_start_date)
            const diff = dateStart.diff(currentDate, 'minutes')

            if (diff <= 0) {
                dispatch(
                    changeField({
                        field: 'activeReservation',
                        value: item
                    })
                )
            }
        })

    }, [futureReservations])

    return (
        <Grid style={{paddingBottom: '100px', marginLeft: 'auto', marginRight: 'auto'}} item xs={12} md={6}>
            <div onClick={() => setIsActive(!isActive)} className="future-reservations-header">
                <p className="future-reservations-header-title">{lang.future_reservations} ({futureReservations.length})</p>
                <span>
                    {
                        isActive ?
                            <KeyboardArrowUpIcon/>
                            :
                            <KeyboardArrowDownIcon/>
                    }
                </span>
            </div>
            {
                isActive ?

                    futureReservations.length == 0 ?
                        <div className="future-reservations-content">
                            <p className="future-reservations-content-no-item">{lang.no_future_reservations}</p>
                        </div>
                        :
                        <FutureReservationListStyled>
                            {
                                futureReservations.map((item) => {
                                    return (
                                        <FutureReservationItem key={item.id} item={item} handleOnUpdateNeed={handleOnUpdateNeed} />
                                    )
                                })
                            }
                        </FutureReservationListStyled>
                    :
                    ''
            }

        </Grid>
    )
}

export default FutureReservations