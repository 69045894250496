import React, {useEffect, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, Grid, TextField} from "@mui/material";
import {findError} from "../services/findError";
import {useDispatch, useSelector} from "react-redux";
import validator from "../services/validator";
import {resetPassword} from "../redux/actions";

const AppResetPassword = ({handleClose, isActive}) => {

    const lang = useSelector(state => state.lang)
    const dispatch = useDispatch()

    const [email, setEmail] = useState('')
    const [isValid, setIsValid] = useState(false)
    const [isSuccessActive, setIsSuccessActive] = useState(false)

    useEffect(() => {
        let _isValid = true

        if (!validator.isEmailAddress(email)) {
            _isValid = false
        }

        setIsValid(_isValid)

    }, [email])

    const onResetHandler = () => {
        dispatch(
            resetPassword({
                email,
                cbSuccess: (res) => {
                    handleClose()
                    setIsSuccessActive(true)
                }
            })
        )
    }

    return (
        <div>
            <Dialog
                open={isSuccessActive}
                onClose={() => {setIsSuccessActive(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid style={{width: '100%'}} item md={12}>
                            <p style={{textAlign: 'center', fontWeight: '400', fontSize: '18px', lineHeight: '24px'}}>
                                {lang.reset_1_text} <br/>
                                {lang.reset_2_text}: <br/>
                                <b>{email}</b> <br/>
                                <span style={{fontSize: '14px'}}>{lang.reset_3_text}</span>
                            </p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center',
                    justifyContent: 'space-around'
                }}>

                    <Button
                        className="main-button main-button-register popup-action-button-margin"
                        style={{width: '200px', fontWeight: '600', marginTop: '15px'}}
                        disabled={!isValid}
                        onClick={() => {setIsSuccessActive(false)}}
                        variant="contained">{lang.ok}</Button>

                    <p style={{marginTop: '20px', marginBottom: '20px', color: '#666666'}}> {lang.questions_call_us}: &nbsp;
                        <a style={{color: '#666666'}} href="tel:+31880883434 ">+31 88 088 3434</a>
                    </p>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isActive}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid style={{width: '100%'}} item md={12}>
                            <p style={{textAlign: 'center', fontWeight: '600', fontSize: '18px'}}>
                                {lang.reset_password}
                            </p>
                            <p style={{textAlign: 'center', marginTop: '10px', fontSize: '18px'}}>
                                {lang.enter_reset_1} <br/>
                                {lang.enter_reset_2}
                            </p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center',
                    justifyContent: 'space-around'
                }}>

                    <Grid container spacing={2}>
                        <Grid item md={12} style={{width: '100%'}}>
                            <TextField
                                name="zipCodeLiving"
                                onChange={(e) => {setEmail(e.target.value)}}
                                value={email}
                                style={{width: '100%'}}
                                label={`Email`}
                                type="email"
                                variant="outlined" />
                        </Grid>
                    </Grid>

                    <Button
                        className="main-button main-button-register popup-action-button-margin"
                        style={{width: '200px', fontWeight: '600', marginTop: '15px'}}
                        disabled={!isValid}
                        onClick={onResetHandler}
                        variant="contained">{lang.send}</Button>

                    <p style={{textAlign: 'center'}}>
                        {lang.reset_1}
                        <span onClick={handleClose} style={{color: '#E9392C'}}> {lang.or_return}</span>
                    </p>

                    <p style={{marginTop: '20px', marginBottom: '20px', color: '#666666'}}> {lang.questions_call_us}: &nbsp;
                        <a style={{color: '#666666'}} href="tel:+31880883434 ">+31 88 088 3434</a>
                    </p>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AppResetPassword