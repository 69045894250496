import moment from "moment"

const filterTimeFormatted = {

    //format 2022-05-22T8:00
    apiFormat: (date) => {
        const formattedDate = date.format('YYYY-MM-DD')
        const formattedTime = date.format('HH:mm')

        return `${formattedDate}T${formattedTime}`
    },

    visualFormat: (date) => {
        const formattedDate = date.format('DD MMMM')
        const formattedTime = date.format('HH:mm')

        return `${formattedDate} ${formattedTime}`
    },

    dateFormat: (date) => {
        const formattedDate = date.format('DD-MM-yyyy')
        return formattedDate
    },

    timeFormat: (date) => {
        const formattedTime = date.format('HH:mm')
        return formattedTime
    },

    fromPhpToMomentJsFormat: (date) => {
        const dateObject = new Date(date)
        return moment(dateObject).format("DD MMMM - HH:mm")
    },

    getTimeDiffHours: (time1, time2) => {
        const dateObject1 = new Date(time1)
        const dateObject2 = new Date(time2)

        const dateMoment1 = moment(dateObject1)
        const dateMoment2 = moment(dateObject2)

        return dateMoment2.diff(dateMoment1, 'hours')
    },

    getTimeDiff: (time1, time2) => {
        const dateObject1 = new Date(time1)
        const dateObject2 = new Date(time2)

        const dateMoment1 = moment(dateObject1)
        const dateMoment2 = moment(dateObject2)

        const days = dateMoment2.diff(dateMoment1, 'days')
        dateMoment2.add(days, 'days')

        const hours = dateMoment2.diff(dateMoment1, 'hours')
        dateMoment2.add(hours, 'hours')

        const minutes = dateMoment2.diff(dateMoment1, 'minutes')

        return {
            days,
            hours,
            minutes
        }
    },

    getTotalTime: (time1, time2) => {
        const dateObject1 = new Date(time1)
        const dateObject2 = new Date(time2)

        const dateMoment1 = moment(dateObject1)
        const dateMoment2 = moment(dateObject2)

        const days = dateMoment1.diff(dateMoment2, 'days')
        dateMoment2.add(days, 'days')

        const hours = dateMoment1.diff(dateMoment2, 'hours')
        dateMoment2.add(hours, 'hours')

        const minutes = dateMoment1.diff(dateMoment2, 'minutes')

        return {
            days,
            hours,
            minutes
        }
    },

    roundTime: (time) => {

        const minutes = time.minutes()
        const hours = time.hours()

        let newMinutes = 0

        if (minutes >= 0 && minutes < 15) newMinutes = 0
        if (minutes >= 15 && minutes < 30) newMinutes = 15
        if (minutes >= 30 && minutes < 45) newMinutes = 30
        if (minutes >= 45 && minutes <= 59) newMinutes = 45

        const newTime = moment().set({
            'hour': hours,
            'minute': newMinutes,
            // 'month': time.month(),
            // 'year': time.year(),
            // 'day': time.day()
        })

        return newTime
    },

    roundTimeDate: (time) => {

        const minutes = time.minutes()
        const hours = time.hours()

        let newMinutes = 0

        if (minutes >= 0 && minutes < 15) newMinutes = 0
        if (minutes >= 15 && minutes < 30) newMinutes = 15
        if (minutes >= 30 && minutes < 45) newMinutes = 30
        if (minutes >= 45 && minutes <= 59) newMinutes = 45

        const newTime = moment().set({
            'hour': hours,
            'minute': newMinutes,
            'month': time.month(),
            'year': time.year(),
            'date': time.day()
        })

        return newTime
    },

    getTotalTimeWithYear: (time1, time2) => {
        const dateObject1 = new Date(time1)
        const dateObject2 = new Date(time2)

        const dateMoment1 = moment(dateObject1)
        const dateMoment2 = moment(dateObject2)

        const years = dateMoment1.diff(dateMoment2, 'years')
        dateMoment2.add(years, 'years')

        const months = dateMoment1.diff(dateMoment2, 'months')
        dateMoment2.add(months, 'months')

        const days = dateMoment1.diff(dateMoment2, 'days')
        dateMoment2.add(days, 'days')

        const hours = dateMoment1.diff(dateMoment2, 'hours')
        dateMoment2.add(hours, 'hours')

        const minutes = dateMoment1.diff(dateMoment2, 'minutes')

        return {
            years,
            months,
            days,
            hours,
            minutes
        }
    },
}

export default filterTimeFormatted