import React, {useEffect, useState} from 'react'
import {Button, Grid} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {changePage} from "../redux/actions"
import dlVideo from '../assets/makephotodl.mp4'
import photoWithPhone from '../assets/photoWithPhone.jpg'
import AppHeader from "../components/AppHeader";
import ReactGA from "react-ga";

const DocumentVerification = () => {

    const dispatch = useDispatch()

    const [placeholderVideo, setPlaceholderVideo] = useState(true)

    const handleNextStep = () => {
        dispatch(changePage({pageName: 'dl-front-page'}))
    }

    const onLoadVideo = () => {
        setPlaceholderVideo(false)
        console.log('video downloaded')
    }

    const lang = useSelector(state => state.lang)

    useEffect(() => {
        ReactGA.initialize("UA-211006469-1", {
            debug: false
        })

        ReactGA.event({
            category: 'User',
            action: 'Document Verification'
        });
    }, [])

    return (
        <Grid container spacing={2}
              style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4px'}}>
            <AppHeader/>
            <Grid className="containerAlignBottom" container item spacing={2} xs={11}>
                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} item xs={12}>
                    <h1 className="secondH1Title">{lang.document_verification}</h1>

                    <video loop={true}
                           style={{display: placeholderVideo ? 'none': 'block'}}
                           onLoadedData={onLoadVideo}
                           className="document-video"
                           autoPlay={true} muted={true} playsInline src={dlVideo}/>

                    <img
                        className="videoPlaceholderImage"
                        style={{display: placeholderVideo ? 'block': 'none'}}
                        src={photoWithPhone} alt=""/>

                    <ul className="beauty-list">
                        <li>
                            <span>1</span>
                            {lang.dv_1}
                        </li>
                        <li>
                            <span>2</span>
                            {lang.dv_2}
                        </li>
                        <li>
                            <span>3</span>
                            {lang.dv_3}
                        </li>
                    </ul>
                </Grid>
                <Grid
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end'}}
                    item xs={12}>
                    <Button
                        className="main-button main-button-register"
                        onClick={handleNextStep}
                        variant="contained">{lang.continue}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DocumentVerification