import React from 'react'
import {Grid} from "@mui/material"
import AppReservationHeader from "../components/AppReservationHeader"
import PageTitleStyled from "../styles/PageTitleStyled"
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined'
import styled from "styled-components"
import fb from '../assets/fb.png'
import inst from '../assets/inst.png'
import telega from '../assets/telega.png'
import {useSelector} from "react-redux";

const HelpItemStyledIcon = styled.div`
  color: #E9392C;

  svg {
    font-size: 60px;
  }
`

const HelpItemStyledContent = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  width: 200px;
  height: 43px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(233, 57, 44, 0.5);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  a {
    color: #666666;
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`

const HelpPage = () => {

    const lang = useSelector(state => state.lang)

    return (
        <div className="app-page-wrapper">
            <Grid container spacing={2}>
                <AppReservationHeader isBackActive={true}/>

                <Grid item xs={12}>
                    <PageTitleStyled>{lang.help}</PageTitleStyled>
                </Grid>
                <Grid item xs={12}>
                    <p style={{
                        fontFamily: "Open Sans",
                        fontSize: '18px',
                        textAlign: 'center'
                    }}>{lang.you_can_contact_us}:</p>
                </Grid>

                <Grid item xs={12} md={6} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                    <Grid style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} container item xs={12}>
                        <Grid item xs={3} md={2}>
                            <HelpItemStyledIcon>
                                <EmailOutlinedIcon />
                            </HelpItemStyledIcon>

                        </Grid>
                        <Grid item xs={9} md={2}>
                            <HelpItemStyledContent>
                                <a href="mailto:info@kav2go.nl" target="_blank">info@kav2go.nl</a>
                            </HelpItemStyledContent>
                        </Grid>
                    </Grid>
                    <Grid style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} container item xs={12}>
                        <Grid item xs={3} md={2}>
                            <HelpItemStyledIcon>
                                <LocalPhoneOutlinedIcon />
                            </HelpItemStyledIcon>

                        </Grid>
                        <Grid item xs={9} md={2}>
                            <HelpItemStyledContent>
                                <a href="tel:+31880883434" target="_blank">+31 88 088 34 34</a>
                            </HelpItemStyledContent>
                        </Grid>
                    </Grid>
                    <Grid style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} container item xs={12}>
                        <Grid item xs={3} md={2}>
                            <HelpItemStyledIcon>
                                <QuestionMarkOutlinedIcon />
                            </HelpItemStyledIcon>

                        </Grid>
                        <Grid item xs={9} md={2}>
                            <HelpItemStyledContent>
                                <a href="https://kav2go.nl/faq/" target="_blank">FAQ</a>
                            </HelpItemStyledContent>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid style={{
                    position: 'fixed',
                    backgroundColor: '#E9392C',
                    height: '115px',
                    width: '100%',
                    bottom: '0',
                    left: '0',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div style={{marginBottom: '10px', display: 'flex'}}>
                        <a style={{padding: '0 5px', display: 'block'}} href="#">
                            <img src={fb} alt=""/>
                        </a>
                        <a style={{padding: '0 5px', display: 'block'}}  href="#">
                            <img src={inst} alt=""/>
                        </a>
                        <a style={{padding: '0 5px', display: 'block'}} target="_blank" href="https://t.me/s/kav2go">
                            <img src={telega} alt=""/>
                        </a>
                    </div>
                    <p style={{color: 'white', fontSize: '18px', fontWeight:'bold', marginBottom: '3px'}}>{lang.help_1}</p>
                    <p style={{fontSize: '14px', color: 'white', fontWeight: 'bold'}}>{lang.help_2}</p>
                </Grid>

            </Grid>
        </div>
    )
}

export default HelpPage