import styled from 'styled-components'

const BoxWrapperStyled = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 5px 10px;
`

export default BoxWrapperStyled