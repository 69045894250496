import React, {useEffect, useMemo, useRef, useState} from 'react'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField
} from "@mui/material"
import OutlinedButton from "../styles/OutlinedButton"
import countryList from 'react-select-country-list'
import {findError} from "../services/findError";
import AppInputMaskedDate from "./AppInputMaskedDate";
import InputMask from "react-input-mask";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    changeField,
    dlUploadHandler,
    idCardUploadHandler,
    psUploadHandler,
    uploadSimpleDocument
} from "../redux/actions";

const DocumentBankStatementPopup = ({title, type, onCloseHandler, isActive}) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const [isValid, setIsValid] = useState(false)
    const apiToken = useSelector(state => state.apiToken)

    const lang = useSelector(state => state.lang)
    const imageRef = useRef(null)
    const [image, setImage] = useState({
        mimeType: '',
        data: '',
        base64: ''
    })

    const uploadHandler = () => {
        dispatch(
            uploadSimpleDocument({
                apiToken,
                image,
                type,
                onSuccess: (res) => {
                    history.push('/profile')
                }
            })
        )
    }

    useEffect(() => {
        let _isValid = true

        if (image.base64.length <= 0) {
            _isValid = false
        }

        setIsValid(_isValid)
    }, [image])

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }



    const onChangeImage = (e) => {

        if (e.target.files) {
            const file = e.target.files[0]
            const type = file.type

            convertBase64(file).then((base64) => {
                let correctBase64 = base64.replace('data:image/jpeg;base64,', '')
                correctBase64 = correctBase64.replace('data:image/png;base64,', '')

                setImage({
                    mimeType: type,
                    data: correctBase64,
                    base64: base64
                })
            })
        }
    }

    return (
        <Dialog
            open={isActive}
            onClose={onCloseHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth={true}
        >
            <input onChange={onChangeImage} style={{opacity: '0'}} ref={imageRef} type="file" accept="image/*" />

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid style={{width: '100%', position: 'relative'}} item md={12}>
                        <p style={{textAlign: 'center', color: '#E9392C', fontWeight: 'bold'}}>
                            {title}
                        </p>
                        <span
                            onClick={onCloseHandler}
                            style={{
                                position: 'absolute',
                                top: '-10px',
                                right: '-10px',
                                color: '#E9392C',
                                fontSize: '36px'
                            }}>&times;</span>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                            <p style={{width: '120px', marginBottom: '5px'}}>{lang.image}</p>
                            <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                                <OutlinedButton
                                    onClick={() => {
                                        imageRef.current.click()
                                    }}
                                    style={{
                                        marginRight: '5px',
                                        color: image.base64 ? '#7EE96D' : '#E9392C',
                                        borderColor: image.base64 ? '#7EE96D' : '#E9392C',
                                    }}>+ {lang.upload_image}</OutlinedButton>
                            </div>
                        </div>
                    </Grid>

                    {
                        image.base64 ?
                            <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12}>
                                <img style={{maxWidth: '100%', height: 'auto'}} src={image.base64} alt=""/>
                            </Grid>
                            :
                            ''
                    }

                </Grid>
            </DialogContent>
            <DialogActions style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center',
                justifyContent: 'space-around', marginTop: '40px'
            }}>
                <Button onClick={uploadHandler}
                        style={{textTransform: 'none', borderRadius: '30px', color: 'white', backgroundColor: '#E9392C'}}
                        variant="contained"
                        disabled={!isValid}
                        className="nextButtonTextWidth"
                        color="primary">{lang.upload}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DocumentBankStatementPopup