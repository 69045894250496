import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {hideErrorMessage} from "../redux/actions";
import {Button, Dialog, DialogActions, DialogContent, Grid} from "@mui/material";

const AppErrorMessage = () => {

    const dispatch = useDispatch()
    const isErrorShow = useSelector(state => state.error.isActive)
    const message = useSelector(state => state.error.message)
    const title = useSelector(state => state.error.title)

    const handleClose = () => {
        dispatch(hideErrorMessage())
    }

    const lang = useSelector(state => state.lang)

    return (
        <div>
            <Dialog
                open={isErrorShow}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div>
                                <p className="form-wrapper-title">{title}</p>
                            </div>
                        </Grid>
                        <Grid item md={12}>
                            <p style={{textAlign: 'center'}}>
                                {message}
                            </p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{paddingRight: '22px', display: 'flex', justifyContent: 'center'}}>
                    <Button onClick={handleClose}
                            variant="contained"
                            className="nextButtonText"
                            color="primary">{lang.close}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AppErrorMessage;